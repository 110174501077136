import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import RecordButtonBox from '../RecordButtonBox';
import StorySoundButton from '../StorySoundButton';
import PopUp from '../PopUp';
import { useStudyCreate } from '../../../utils/pm/api';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as S from 'src/pages/PhonicsMonster/style';
import { userInfoVar } from 'src/apollo/cache';

let MainContent = styled.div`
   {
    font-size: 10px;
    font-family: 'BalsamiqSans';
    user-select: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-self: stretch;
    flex: 1 1;
    flex-direction: column;

    & .top_box {
      font-size: 10px;
      font-family: 'BalsamiqSans';
      user-select: none;
      margin: 0;
      align-items: center;
      padding: 1.6rem 0;
      border-radius: 1.6rem 1.6rem 0 0;
      display: flex;
      flex-direction: column;
      flex: 1 1;
      margin-bottom: 0.8rem;
    }

    & .sentence {
      font-size: 10px;
      font-family: 'BalsamiqSans';
      user-select: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      flex: 1 1;
      margin-bottom: 0.8rem;
      width: 80%;
      border-radius: 1.6rem;
      background-color: hsla(0, 0%, 100%, 0.5);
      min-height: 30vh;
    }

    & .sentence p {
      user-select: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1;
      font-size: 2.4vh;
    }

    & .play_box {
      font-size: 10px;
      font-family: 'BalsamiqSans';
      user-select: none;
      margin: 0;
      display: inline-flex;
      align-items: center;
      padding: 0.4rem 0.8rem;
      border-radius: 0.8rem;
      background-color: #333;
    }

    & .play_box button {
      user-select: none;
      margin: 0;
      padding: 0;
      border: none;
      font-family: 'Alata', 'NanumSquareRound', sans-serif;
      color: #333;
      vertical-align: middle;
      outline: none;
      width: 2.8rem;
      background-color: transparent;
    }

    & .progress_list {
      font-size: 10px;
      font-family: 'BalsamiqSans';
      user-select: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      position: relative;
      width: 24rem;
      height: 1.6rem;
      margin-left: 0.4rem;
      border-radius: 1.6rem;
      background-color: #ff9f43;
    }

    & .bottom_box {
      font-size: 10px;
      font-family: 'BalsamiqSans';
      user-select: none;
      margin: 0;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.6rem 0;
      border-radius: 0 0 1.6rem 1.6rem;
    }
  }
`;

const RecordStage = ({ unitInfo, bookInfo, stages }) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState('');
  const stageNumber = Number(window.location.pathname.split('/stage/')[1]);

  const history = useHistory();

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setPopUpType('correct');
      setShowPopUp(true);
    },
  });

  const {
    customData: { sentences, sound, bgImage },
  } = stages;

  const handleRecordComplete = (data) => {
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
      exam_total: data.exam_total,
      exam_correct: data.exam_correct,
      wpm: data.wpm,
      recording_data: data.recording_data,
    });
  };

  const handleGoodJobSoundEnd = () => {
    history.push(`${window.location.pathname.split('/stage')[0]}${window.location.search}`, { lesson: stageNumber > 5 });
  };

  return (
    <>
      <MainContent className="main_content  stage8_content">
        <div className="top_box" style={{ backgroundImage: `url(${bgImage})` }}>
          <RecordSentenceBox sentences={sentences} />
          <StorySoundButton src={sound} />
        </div>

        <div className="bottom_box">
          <RecordButtonBox bookInfo={bookInfo} onRecordComplete={handleRecordComplete} sentences={sentences} />
        </div>
      </MainContent>

      {showPopUp ? <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
    </>
  );
};

export default RecordStage;

const RecordSentenceBox = ({ sentences }) => {
  return <section className="sentence">{sentences && sentences.map((item, key) => <p key={`sentence-item-${key}`}>{item}</p>)}</section>;
};
