import React, { useState, useEffect } from 'react';
//import WaveSurfer from 'wavesurfer.js';
import { getSaveRecord, isVowel } from '../../utils/pm/util';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import _ from 'lodash';
import reactStringReplace from 'react-string-replace';
import SoundButton from './SoundButton';
import PageNum from './PageNum';
import PopupCloseButton from './PopupCloseButton';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import * as mutations from '../../operations/mutations';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
// import {ToastContainer, toast} from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

let bookNameUnitStage = undefined;
let popup_bg_add = undefined;
const PopUp = ({
  setShowPopUp,
  popUpType,
  setPopUpType,
  customData,
  onSoundEffectEnd,
  problem,
  problems,
  setSteps,
  steps,
  onStepEnd,
  onClose = undefined,
  isExampleWordSoundEnd,
  handleProblemPopup = false,
}) => {
  const { bookName, unit, stage } = useParams();

  if ((bookName == '2a' || '2b') && (unit == 1 || 3 || 6) && (stage == 2 || 3 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  if (bookName == '2a' && unit == 1 && stage == 2) {
    popup_bg_add = true;
  }
  if (bookName == '2a' && unit == 2 && stage == 2) {
    popup_bg_add = true;
  }
  if (bookName == '2a' && unit == 4 && stage == 2) {
    popup_bg_add = true;
  }
  if (bookName == '2b' && unit == 6 && stage == 2) {
    popup_bg_add = true;
  }
  if (bookName == '2b' && unit == 7 && stage == 2) {
    popup_bg_add = true;
  }
  if (bookName == '2b' && unit == 8 && stage == 2) {
    popup_bg_add = true;
  }
  if (bookName == '2b' && unit == 9 && stage == 2) {
    popup_bg_add = true;
  }

  const type = popUpType || 'default';

  const close = () => {
    if (!_.isNil(setShowPopUp)) {
      setShowPopUp(true);
    }
  };
  const next = () => {
    if (!_.isNil(setShowPopUp)) {
      setShowPopUp(false);
      if (!_.isNil(problems[steps + 1])) setSteps((prev) => prev + 1);
      else {
        setPopUpType('correct');
        setShowPopUp(true);
      }
    }
  };

  const handleStepEnd = () => {
    if (!_.isNil(setShowPopUp)) {
      setShowPopUp(false);
    }
    onStepEnd();
  };

  if (type === 'audio') {
    return <AudioPopup close={close} customData={customData} />;
  }

  if (type === 'correct') {
    return <CorrectPopup close={close} onSoundEffectEnd={onSoundEffectEnd} />;
  }

  if (type === 'loading') {
    return <LoadingPopup close={close} />;
  }

  if (type === 'exampleWord') {
    return (
      <ExampleWord
        next={next}
        exampleWord={problem && problem.exampleWord}
        exSound={!_.isNil(problem.exSound) ? problem.exSound : undefined}
        isExampleWordSoundEnd={isExampleWordSoundEnd}
        onClose={onClose}
      />
    );
  }

  if (type === 'imageAndAlphabet') {
    return (
      <ImageAndAlphabet
        next={next}
        image={problem && problem.imageWord}
        mp3={problem && problem.mp3}
        alphabet={problem && problem.alphabet}
        step={steps}
        total={problems && problems.length}
        onStepEnd={handleStepEnd}
        onClose={onClose}
      />
    );
  }
  // if (type === "CredentialPopup") {
  // 	return <CredentialPopup onClose={onClose} />;
  // }
  if (type === 'ProblemSolvePopup') {
    return <ProblemSolvePopup onClose={onClose} handleProblemPopup={handleProblemPopup} />;
  }
  return <DefaultPopup close={close} />;

  //아래 코드로 하면 조건부 랜더링이 동작을 잘 안하는 듯...

  // return (
  // 	<>

  // 		{
  // 			{
  // 				audio: <AudioPopup close={close} customData={customData} />,
  // 				correct: <CorrectPopup close={close} onSoundEffectEnd={onSoundEffectEnd} />,
  // 				loading: <LoadingPopup close={close} />,
  // 				exampleWord: (
  // 					<ExampleWord
  // 						next={next}
  // 						exampleWord={problem && problem.exampleWord}
  // 						exSound={!_.isNil(problem.exSound) ? problem.exSound : undefined}
  // 						isExampleWordSoundEnd={isExampleWordSoundEnd}
  // 					/>
  // 				),
  // 				imageAndAlphabet: (
  // 					<ImageAndAlphabet
  // 						next={next}
  // 						image={problem && problem.imageWord}
  // 						mp3={problem && problem.mp3}
  // 						alphabet={problem && problem.alphabet}
  // 						step={steps}
  // 						total={problems && problems.length}
  // 						onStepEnd={handleStepEnd}
  // 						onClose={onClose}
  // 					/>
  // 				),
  // 				default: <DefaultPopup close={close} />,
  // 			}[type]
  // 		}
  // 	</>
  // );
};

export default PopUp;

const ExampleWord = ({ next, exampleWord, exSound, isExampleWordSoundEnd, onClose }) => {
  const [dingdongSound, setDingdongSound] = useState(undefined);
  const [soundClicked, setSoundClicked] = useState(false);
  const handleDindongSoundEnd = () => {
    setDingdongSound(undefined);
    next();
  };
  return (
    <>
      <div className="popup_wrap active">
        <div className={popup_bg_add == true ? 'popup_imgword_wrap popup_bg_add' : 'popup_imgword_wrap popup_bg'}>
          <div className="popup_border">
            <SoundButton
              src={`${!_.isNil(exSound) ? exSound : exampleWord}.mp3`}
              onSoundEnd={() => {
                setSoundClicked(true);
              }}
            />
            <p className="img_box">
              <img alt={exampleWord} src={`https://cdn.cloubot.com/PM/image/level2/image_${exampleWord}.png`} />
            </p>
            <p className="word_box">
              {bookNameUnitStage
                ? _.map(exampleWord, (item, mapkey) => (
                    <span data="workingif popupjs" className={`font_${mapkey == 0 ? 'blue' : 'pink'}`}>
                      {item}
                    </span>
                  ))
                : _.map(exampleWord, (item, mapkey) => (
                    <span data="workingelse popupjs" className={`font_${mapkey == 0 ? 'pink' : 'blue'}`}>
                      {item}
                    </span>
                  ))}
            </p>
            <a
              className="btn_type2"
              style={{
                position: 'absolute',
                right: '2.4rem',
                bottom: '2.4rem',
                visibility: `${isExampleWordSoundEnd || soundClicked ? 'visible' : 'hidden'}`,
              }}
              href="#none"
              onClick={(e) => {
                e.preventDefault();
                setDingdongSound('https://cdn.cloubot.com/PM/audio/sound-effect/dingdong.mp3');
                // next();
              }}
            >
              <img src="/images/pm/i_next.svg" alt="next" />
            </a>
          </div>
        </div>
        <PopupCloseButton onClose={onClose} />
      </div>
      <SoundEffectPM url={dingdongSound} onEnd={handleDindongSoundEnd} />
    </>
  );
};

const ImageAndAlphabet = ({ next, image, mp3, alphabet, step, total, onStepEnd, onClose }) => {
  return (
    <S.PopUpWrap className="popup_wrap active">
      <S.PopUpRed className="popup_red">
        <S.PopUpContents className="popup_contents">
          <S.PopUpInnerWrap style={{ justifyContent: 'center' }} className="inner_wrap center">
            <S.WordCardArea className="wordcard_area col1">
              <S.CardSpeaker className="card_speaker">
                <S.CardArea className="card_area" style={{ position: 'relative' }}>
                  <S.CardSmall className="card small">
                    <S.SelCard active={true} className="sel_card active">
                      <img style={{ maxWidth: '90%' }} src={image} alt="" />
                    </S.SelCard>
                  </S.CardSmall>
                  <SoundButton
                    src={mp3}
                    isStartPlaying={true}
                    style={{ position: 'absolute', top: '50%', right: '-70px', transform: 'translateY(-50%)' }}
                  />
                </S.CardArea>
              </S.CardSpeaker>
              <S.TextArea className="text_area">{alphabet && `${alphabet.toUpperCase()}${alphabet}`}</S.TextArea>
            </S.WordCardArea>
          </S.PopUpInnerWrap>
        </S.PopUpContents>
        <S.PopUpBottom className="popup_bottom">
          <S.PopUpBottomRight class="right">
            <S.Button2
              className="btn_type2"
              // href="#none"
              onClick={(e) => {
                // e.preventDefault();
                onStepEnd();
              }}
            >
              <img src="/images/pm/i_next.svg" style={{ width: '80%' }} alt="next" />
            </S.Button2>
          </S.PopUpBottomRight>
        </S.PopUpBottom>
      </S.PopUpRed>
      <PopupCloseButton onClose={onClose} />
    </S.PopUpWrap>
  );
};

const AudioPopup = ({ close, customData }) => {
  return (
    <div className="popup_wrap">
      <div className="popup_box_record">
        <h4>Listen to your voice</h4>
        <dl>
          <dt>
            <span className="text_default">{customData && customData.sentence}</span>
          </dt>
          <dd>
            <p id="wave_form" className="">
              <audio id="audio_player" controls>
                <source src={customData && customData.recordKey ? getSaveRecord(customData.recordKey) : ''}></source>
              </audio>
            </p>
          </dd>
          <dd>
            <ul className="button">
              <li className="btn_default btn_blue" onClick={close}>
                <span className="btn_icon i_close"></span>
              </li>
            </ul>
          </dd>
        </dl>
      </div>
    </div>
  );
};

const CorrectPopup = ({ close, onSoundEffectEnd }) => {
  const [playSound, setPlaySound] = useState(false);
  const handleSoundEffectEnd = () => {
    if (onSoundEffectEnd instanceof Function) {
      onSoundEffectEnd();
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setPlaySound(true);
    }, 10);
  }, []);
  return (
    <>
      <PopUpWrap className="popup_wrap active" onClick={close}>
        <GoodJobWrap className="popup_goodjob_wrap">
          <GoodJobWrapImgWrap>
            <img src="/images/pm/popup_goodjob_txt.png" alt="Good job" />
          </GoodJobWrapImgWrap>
        </GoodJobWrap>
      </PopUpWrap>
      {playSound && <SoundEffectPM url="https://cdn.cloubot.com/PM/audio/sound-effect/good-job-victory.mp3" onEnd={handleSoundEffectEnd} />}
    </>
  );
};

const DefaultPopup = ({ close }) => {
  return (
    <div className="popup_wrap" onClick={close} style={{ zIndex: 99999 }}>
      <div className="popup_box">
        <dl>
          <dt>
            <span className="popupTit_Text">학습목표</span>
          </dt>
          <dd>
            <span className="popupTxt_Text">학습목표가 보이는 영역입니다.</span>
          </dd>
        </dl>
      </div>
    </div>
  );
};

const ProblemSolvePopup = ({ close, onSoundEffectEnd, handleProblemPopup }) => {
  const handlManageScore = () => {
    handleProblemPopup(true);
  };
  return (
    <>
      <S.PopupSolveWrap className="popup_wrap problem-solve active" onClick={close}>
        <div className="popup_goodjob_wrap">
          <S.PopupSolveInner className="problem-solve-inner">
            <span>
              문제를 모두 풀었네요.
              <br />
              정답을 확인해 볼까요?
            </span>
            <S.Button2 onClick={handlManageScore} className="problem-solve">
              확인
            </S.Button2>
          </S.PopupSolveInner>
        </div>
      </S.PopupSolveWrap>
    </>
  );
};
const LoadingPopup = ({ close }) => {
  return (
    <div className="popup_wrap active" onClick={close} style={{ zIndex: 12 }}>
      <div className="popup_loading_wrap">
        <img src="https://cdn.cloubot.com/PM/image/main_character.png" alt="몬스터 이미지" />
        <p data-content="Loading...">Loading...</p>
      </div>
    </div>
  );
};

export const ImagePopup = ({ discImage, wordImage, word, number, total, sound, onNext, onClose }) => {
  return (
    <div class="popup_wrap active">
      <div class="popup_red">
        <div class="popup_contents">
          <div class="inner_wrap">
            <div class="lv4_st2_pop_wrap">
              <div class="card_wrap active">
                <img src={discImage} class="imgonly" alt="" />
              </div>
              <div class="card_wrap">
                <div class="card_inner">
                  <img src={wordImage} class="imgtext" alt="" />
                  <div class="text">
                    {reactStringReplace(word, /##(\w+)##/g, (match) => (
                      <span className="strong">{match}</span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pop_btn_area rt">
            <SoundButton src={sound} isStartPlaying={true} />
          </div>
        </div>
        <div class="popup_bottom">
          <PageNum num={number} total={total} />
          <p style={{ position: 'absolute', right: '10px', bottom: '10px' }}>
            <button type="button" class="btn_type2" onClick={onNext}>
              <img src="/images/pm/i_next.svg" alt="next" />
            </button>
          </p>
        </div>
      </div>
      <PopupCloseButton onClose={onClose} />
    </div>
  );
};
const PopUpWrap = styled(S.RowFlex)`
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`;
const GoodJobWrap = styled.div`
  position: relative;
  border: 6px solid #fff;
  border-radius: 16px;
`;
const GoodJobWrapImgWrap = styled.div`
  padding: 40px;
  border-radius: 8px;
  border: 10px solid #ee7470;
  border-top: 90px solid #ee7470;
  background-color: #fff;
  ::after {
    content: '';
    display: block;
    position: absolute;
    top: -194px;
    left: 0;
    right: 0;
    width: 500px;
    height: 400px;
    margin: 0 auto;
    background: url(/images/pm/popup_goodjob_ai.svg) no-repeat top center;
  }
`;
