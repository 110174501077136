import ReactDOM from 'react-dom';
import { Col, Row, Progress, Table } from 'antd';
import styled from 'styled-components';
import React, { useState, useMemo, useEffect } from 'react';
import { GET_ORDER_ITEM_LIST_META_REPORT, GET_META_EXAM_AVG_SCORE } from 'src/operations/queries/getLevelTest';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { storage } from 'src/utils/hooks';
import { useApolloClient } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';
import { getMetaExamId, getLixileScoreObject, getCefr } from 'src/utils';
// import { GENERATE_PDF_FILE_META } from 'src/operations/mutations/uploadFileS3';
import { META_EXAM_SAVE_REPORT } from 'src/operations/mutations/createTests';
import axios from 'axios';
import { GET_USER_INFO } from 'src/operations/queries/campus';

const InnerWrap = styled.div`
  width: 100%;
  max-width: 2000px;
  height: calc(100% - 7rem);
  padding: 0rem 0rem;
  margin: 0rem auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16/9) {
    max-width: 2600px;
  }

  @media (max-width: 1200px) and (max-height: 800px) and (orientation: landscape) {
    width: 100%;
    max-width: 1200px;
    padding: 0;
  }

  @media (max-width: 800px) and (max-height: 1200px) and (orientation: portrait) {
    width: 100%;
    max-width: 800px;
    padding: 0;
  }
`;

const ReportWrap = styled.div`
  width: calc(100% - 17rem);
  height: calc(100% - 7rem);
  padding: 1rem 0 0;
  margin: 0rem auto 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) and (max-height: 1200px) {
    width: 100%;
    max-width: 700px;
  }
`;

const PrintWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media print {
    page-break-before: always;
  }
`;

const columns = [
  {
    title: 'ATTEMPT',
    dataIndex: 'attempts',
    key: 'attempts',
  },
  {
    title: 'CEFR',
    dataIndex: 'cefr',
    key: 'cefr',
  },
  {
    title: 'AGL',
    dataIndex: 'agl',
    key: 'agl',
  },
  {
    title: 'GRL',
    dataIndex: 'grl',
    key: 'grl',
  },
  {
    title: 'DEV',
    dataIndex: 'dev',
    key: 'dev',
    render: (text, record) => {
      // if (parseInt(text) !== parseInt(record.actual_dev)) {
      //   return <span>{`${text} (${record.actual_dev})`}</span>;
      // } else {
      //   return <span>{text}</span>;
      // }

      return <span>{`${text} (${record.actual_dev})`}</span>;
    },
  },
  {
    title: 'MX LEVEL',
    dataIndex: 'mx',
    key: 'mx',
  },
];

const columnsPre = [
  {
    title: 'ATTEMPT',
    dataIndex: 'attempts',
    key: 'attempts',
  },
  {
    title: 'CEFR',
    dataIndex: 'cefr',
    key: 'cefr',
  },
  {
    title: 'AGL',
    dataIndex: 'agl',
    key: 'agl',
  },
  {
    title: 'DEV',
    dataIndex: 'dev',
    key: 'dev',
    render: (text, record) => {
      return <span>{`${text} (${record.actual_dev})`}</span>;
    },
  },
  {
    title: 'MX LEVEL',
    dataIndex: 'mx',
    key: 'mx',
    render: (text, record) => {
      return <span>{parseInt(text) < 0 ? 'Phonics' : parseInt(text) === 0 ? 'Phonics2' : text}</span>;
    },
  },
];

const OldMetaReport = () => {
  // const [upload, { loading: loadingUpload }] = useMutation(GENERATE_PDF_FILE_META);
  const [saveReport, { loading: updateLoading }] = useMutation(META_EXAM_SAVE_REPORT);
  const [globalMx, setglobalMx] = useState(0);
  const [uploadTos3, setuploadTos3] = useState(false);
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const order_id = params.get('id') ? Number(params.get('id')) : null;
  const company_idx = params.get('company_idx') ? Number(params.get('company_idx')) : null;
  const forceMXLevel = params.get('mx_level') ? Number(params.get('mx_level')) : null;
  const history = useHistory();
  const client = useApolloClient();
  const location = useLocation();
  const [userObj, setUserObj] = useState({});
  const [lexileScore, setlexileScore] = useState(0);
  const [batchType, setBatchType] = useState('');
  const [lastDate, setlastDate] = useState('');
  const [testIdxList, settestIdxList] = useState([]);
  const [tableData, settableData] = useState([]);
  const [mxchangeText, setmxchangeText] = useState(
    'In your Lexile measure group, your total score is close to the average score; therefore, there is no need to change your mx-level at this time.',
  );
  const [devPercentage, setdevPercentage] = useState({ vocabulary: 50, reading: 50, grammar: 50, listening: 50 });

  const isPreTest = useMemo(() => {
    if (batchType.startsWith('PRE')) {
      return true;
    }
    return false;
  }, [batchType]);

  const isCType = useMemo(() => {
    if (batchType.startsWith('C-')) {
      return true;
    }
    return false;
  }, [batchType]);

  if (!location?.state?.idx_list && localStorage.getItem('meta_idx_list')) {
    location.state = { idx_list: JSON.parse(localStorage.getItem('meta_idx_list')) };
  }
  if (order_id) {
    location.state = { idx_list: [order_id] };
  }

  const { data: dataUser } = useQuery(GET_USER_INFO, {
    skip: company_idx !== null,
  });
  const { loading, data } = useQuery(GET_ORDER_ITEM_LIST_META_REPORT, { variables: { idx_array: location?.state?.idx_list } });
  const companyIdx = company_idx ? company_idx : dataUser?.getUserData?.campus?.company?.idx;

  const {
    data: avgData,
    loading: avgDataLoading,
    error,
  } = useQuery(GET_META_EXAM_AVG_SCORE, {
    variables: { idx_array: testIdxList, grade: userObj.grade, user_idx: userObj.user_idx, batch_type: batchType, order_idx: order_id },
    skip: !userObj.user_idx || !batchType,
  });
  const avgScoreData = useMemo(() => {
    if (avgData) {
      return avgData?.metaExamAvgScore?.json;
    }
    return {};
  }, [avgData]);

  useEffect(() => {
    if (userObj.id && uploadTos3) {
      exportToS3(userObj.id);
    }
  }, [userObj, uploadTos3]);

  const exportToS3 = (filename) => {
    let url =
      window.location.protocol +
      '//' +
      window.location.host +
      location.pathname +
      '?id=' +
      location.state.idx_list[0] +
      `&company_idx=${companyIdx}`;

    if (forceMXLevel !== null) {
      url += '&mx_level=' + forceMXLevel;
    }
    return new Promise(async (resolve, reject) => {
      try {
        const fileData = await axios.post('https://llf63tb67l3wj7nqerumdipm440stovy.lambda-url.ap-northeast-2.on.aws', {
          url: url,
          fileName: '-' + filename + '.pdf',
        });
        const fileUrl = fileData.data;
        // const fullUploader = await upload({ variables: { url: url, filename: '-' + filename + '.pdf' } });
        const variables = {
          order_id: Number(order_id),
          mx: forceMXLevel !== null ? forceMXLevel.toString() : globalMx.toString(),
          pdf_url: fileUrl,
          // pdf_url: fullUploader?.data?.generatePdfS3Meta?.id,
          assigned_test_user_idx: levels['reading']?.assigned_test_user_idx,
        };
        try {
          await saveReport({ variables: variables });
          window.location = fileUrl;
          // window.location = fullUploader?.data?.generatePdfS3Meta?.id;
          return;
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    });
  };

  const lexileScoreObject = useMemo(() => {
    console.log(lexileScore, batchType);
    if (batchType !== '') {
      return getLixileScoreObject(lexileScore, batchType);
    }
    return null;
  }, [batchType, lexileScore]);

  useEffect(() => {
    if (batchType !== '') {
      const cefr = getCefr(lexileScore);
      let dev = 0;
      let mx = Number(lexileScoreObject?.mx);
      if (levels && Object.keys(levels).length != 0) {
        dev += Number(levels['vocabulary']?.score) - Number(lexileScoreObject?.mean_values?.vocabulary);
        dev += Number(levels['listening']?.score) - Number(lexileScoreObject?.mean_values?.listening);
        if (levels['grammar']) dev += Number(levels['grammar']?.score) - Number(lexileScoreObject?.mean_values?.grammar);
        // Add reading too here

        setdevPercentage({
          vocabulary: Number(levels['vocabulary']?.score) - Number(lexileScoreObject?.mean_values?.vocabulary),
          reading: 0, // Not using now
          listening: Number(levels['listening']?.score) - Number(lexileScoreObject?.mean_values?.listening),
          grammar: Number(levels['grammar']?.score) - Number(lexileScoreObject?.mean_values?.grammar),
        });
      }
      let mxDev = dev;

      if (isPreTest) {
        if (dev <= -5 && mx > -1) {
          setmxchangeText('Your Lexile Measure is lower than other two skills by one mx level.');
          mx--;
          mxDev = mxDev + 5;
        } else if (dev >= 5) {
          setmxchangeText('Your Lexile Measure is lower than other two skills by one mx level.');
          mx++;
          mxDev = mxDev - 5;

          if (mx < 5 && dev >= 10) {
            mx++;
            mxDev = mxDev - 5;
          }
        } else {
          setmxchangeText('Your Lexile Measure did not get effected by two other skill scores.');
        }

        setglobalMx(mx);
        settableData([
          {
            key: '1',
            attempts: avgScoreData?.attempts,
            cefr: cefr.cefr,
            agl:
              dev > 5
                ? isNaN(lexileScoreObject?.agl)
                  ? lexileScoreObject?.agl
                  : parseFloat(lexileScoreObject?.agl) + 0.4
                : lexileScoreObject?.agl,
            grl: lexileScoreObject?.grl,
            dev: mxDev,
            actual_dev: dev,
            mx: forceMXLevel !== null ? forceMXLevel.toString() : mx,
          },
        ]);
      } else {
        if (dev <= -6) {
          setmxchangeText(
            'In your Lexile measure group, your total score in the other three skills is lower than that of other students by one mx-level. Your mx-level has been lowered by one level.',
          );
          mx--;
          mxDev = mxDev + 6;
        }
        if (dev >= 6) {
          setmxchangeText(
            'In your Lexile measure group, your total score in the other three skills is higher than that of other students by one mx-level. Your mx-level has been upgraded by one level.',
          );
          mx++;
          mxDev = mxDev - 6;

          // if (mx < 6 && dev >= 12) {
          //   mx++;
          //   mxDev = mxDev - 6;
          // }
        }
        setglobalMx(mx);
        settableData([
          {
            key: '1',
            attempts: avgScoreData?.attempts,
            cefr: cefr.cefr,
            agl:
              dev >= 6
                ? isNaN(lexileScoreObject?.agl)
                  ? lexileScoreObject?.agl
                  : parseFloat(lexileScoreObject?.agl) + 0.5
                : lexileScoreObject?.agl,
            grl: lexileScoreObject?.grl,
            dev: mxDev,
            actual_dev: dev,
            mx: forceMXLevel !== null ? forceMXLevel.toString() : mx,
          },
        ]);
      }
    }
  }, [lexileScore, batchType, avgScoreData, isPreTest]);
  const levels = useMemo(() => {
    let levels = {};
    if (data) {
      for (let i = 0; i < data?.orderItemListByIdx?.length; i++) {
        if (data?.orderItemListByIdx[i].order_leveltest) {
          for (let j = 0; j < data?.orderItemListByIdx[i]?.order_leveltest?.length; j++) {
            const item = data?.orderItemListByIdx[i]?.order_leveltest[j];
            let done = false;
            if (item?.assigned_tests?.assigned_test_users) {
              if (item?.assigned_tests?.assigned_test_users[0].status !== 1) {
                done = true;
              }
            }
            if (
              Object.keys(userObj).length === 0 &&
              item?.assigned_tests?.assigned_test_users?.length > 0 &&
              item?.assigned_tests?.assigned_test_users[0]?.user
            ) {
              const newUserObj = item?.assigned_tests?.assigned_test_users[0]?.user;
              setUserObj({
                id: getMetaExamId(newUserObj.birthday, newUserObj?.parent_phone),
                korean_name: newUserObj.english_name,
                name: newUserObj.name,
                grade: item?.order?.school_info,
                user_idx: newUserObj.idx,
              });
            }
            if (item?.assigned_tests?.tests?.test_type) {
              levels[item?.assigned_tests?.tests?.test_type] = {
                title: `${item?.assigned_tests?.tests?.title}`,
                assigned_tests_idx: item?.assigned_tests?.idx,
                done,
                batch_type: `${item?.assigned_tests?.tests?.batch_type}`,
                score: item?.assigned_tests?.assigned_test_users[0].score,
                total: `${item?.assigned_tests?.tests?.test_answers?.length}`,
                assigned_test_user_idx: item?.assigned_tests?.assigned_test_users[0].idx,
              };
              if (item?.assigned_tests?.tests?.test_type === 'reading' && item?.assigned_tests?.assigned_test_users[0].extra_data) {
                const extraData = JSON.parse(item?.assigned_tests?.assigned_test_users[0].extra_data);
                let ability = extraData?.ability;
                setlexileScore(Math.round(ability / 5) * 5);
                setBatchType(item?.assigned_tests?.tests?.batch_type);
                if (!JSON.parse(item?.assigned_tests?.assigned_test_users[0].extra_data)?.report_pdf_meta) {
                  setTimeout(() => {
                    setuploadTos3(true);
                  }, 2000);
                }
                // else if (JSON.parse(item?.assigned_tests?.assigned_test_users[0].extra_data)?.report_pdf_meta) {
                //   window.location = JSON.parse(item?.assigned_tests?.assigned_test_users[0].extra_data)?.report_pdf_meta;
                // }
              }
              if (!testIdxList.includes(item?.assigned_tests?.tests?.idx)) {
                settestIdxList((prevState) => [...prevState, item?.assigned_tests?.tests?.idx]);
              }
            }
            if (
              item?.assigned_tests?.assigned_test_users[0]?.end_time &&
              lastDate < item?.assigned_tests?.assigned_test_users[0]?.end_time
            ) {
              setlastDate(item?.assigned_tests?.assigned_test_users[0]?.end_time);
            }
          }
        }
      }
    }
    return levels;
  }, [data]);

  if (!location?.state?.idx_list) {
    isLogedIn(false);
    userInfoVar(null);
    client.clearStore();
    client.cache.gc();
    storage.removeItem('culp_token');
    history.push('/MetaExam/MetaLogin');
    return <Redirect to={`/MetaExam/MetaLogin`} />;
  }
  const type = Object.keys(levels).length == 0 ? '' : levels[Object.keys(levels)[0]].batch_type;
  const calculateAvgScore = (avgScoreData, levels, type, grade = null) => {
    if (Object.keys(levels).length == 0) {
      return 0;
    }
    if (avgScoreData[type]?.total?.total_user_count == 0) {
      return 0;
    }
    if (!grade) {
      return (avgScoreData[type]?.total?.total_score / (levels[type].total * avgScoreData[type]?.total?.total_user_count)) * 100;
    }
    return (avgScoreData[type]?.total?.total_score / (levels[type].total * avgScoreData[type]?.total?.total_user_count)) * 100;
  };
  const lexileScoreRemeinder = Math.round(lexileScore / 5) * 5;
  return (
    <>
      <div className={`meta-report-con allPage ${isPreTest ? 'pre' : ''}`}>
        <InnerWrap>
          <div className="lexile-logo-con">
            <img src="../images/Meta-reportLogo.png" alt="logo" width="1000px" />
          </div>
          <ReportWrap>
            <div className="userInfo-con">
              <div
                className="userInfo left"
                style={{
                  width: '50%',
                  marginTop: '34px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  justifyContent: 'stretch',
                }}
              >
                <Row>
                  <h2 style={{ fontSize: '16px' }}>Date: {lastDate.split('T')[0]}</h2>
                  <h2 style={{ fontSize: '16px' }}>Type: {batchType}</h2>
                </Row>
                {isPreTest && (
                  <Row>
                    <h2 style={{ fontSize: '16px', color: '#506050' }}>
                      Lexile Range:{' '}
                      {lexileScoreRemeinder < 0
                        ? `${isPreTest ? 'BR ' + Math.abs(lexileScoreRemeinder) : 'BR'}`
                        : `${lexileScoreRemeinder}L`}
                    </h2>
                  </Row>
                )}
              </div>

              <div className="userInfo right">
                <Row>
                  <Col span={7}>Name</Col>
                  <Col span={17}>{userObj?.name}</Col>
                </Row>
                <Row>
                  <Col span={7}>Grade</Col>
                  <Col span={17}>{userObj?.grade}</Col>
                </Row>
              </div>
            </div>
          </ReportWrap>

          <ReportWrap className="report-top">
            <div className="part-con">
              <img src={'../images/Meta-lexileLogo.png'} alt="logo" />
              <h1 className="userName" style={{ textTransform: 'capitalize', fontSize: '28px' }}>
                {userObj?.name}
              </h1>
              <h4>Student Id: {userObj?.id}</h4>
            </div>
            <div className="part-con">
              {/* chart donut in here*/}
              <div className="donut-wrap" style={{ left: '12px', top: '-24px' }}>
                <img src="../images/Meta-rightDonut.png" alt="right_donut" />
                <div style={{ textAlign: 'center' }}>
                  <h3>Lexile® Measure</h3>
                  <h2 className="measure-score">
                    {lexileScoreRemeinder < 0 ? `${isPreTest ? 'BR' + Math.abs(lexileScoreRemeinder) : 'BR'}` : `${lexileScoreRemeinder}L`}
                  </h2>
                </div>
              </div>
            </div>
          </ReportWrap>
          {Object.keys(levels).length != 0 && (
            <ReportWrap className="report-middle-new">
              {levels['vocabulary'] && (
                <div className="card-con">
                  <div className="bg">
                    <div className="subject-con">
                      <h3>Vocabulary</h3>
                      <h3>
                        {levels['vocabulary'].score}/{levels['vocabulary'].total}
                      </h3>
                    </div>
                  </div>

                  <div className="progress-con">
                    <div className="progress-wrap ur-score">
                      <span>Your SCR: </span>
                      <Progress
                        status="normal"
                        strokeColor={'#2d75b3'}
                        strokeWidth={12}
                        percent={(levels['vocabulary'].score / levels['vocabulary'].total) * 100}
                        showInfo={false}
                      />
                    </div>

                    <div className="progress-wrap avg-score">
                      <span>Avg SCR: </span>
                      <Progress
                        status="normal"
                        strokeColor={'#FFC631'}
                        strokeWidth={12}
                        percent={(Number(lexileScoreObject?.mean_values?.vocabulary) / levels['vocabulary'].total) * 100}
                        showInfo={false}
                      />
                    </div>

                    <div className="progress-wrap avg-dev">
                      <span>Avg DEV: </span>
                      <Progress
                        status="normal"
                        strokeColor={devPercentage['vocabulary'] < 0 ? '#6a6b72' : '#F76B11'}
                        strokeWidth={12}
                        percent={(Math.abs(devPercentage['vocabulary']) / levels['vocabulary'].total) * 100}
                        showInfo={false}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* */}
              {levels['reading'] && (
                <div className="card-con">
                  <div className="bg">
                    <div className="subject-con">
                      <h3>Reading</h3>
                      <h3>
                        {levels['reading'].score}/{levels['reading'].total}
                      </h3>
                    </div>
                  </div>

                  <div className="progress-con">
                    <div className="progress-wrap ur-score">
                      <span>Your SCR: </span>
                      <Progress
                        status="normal"
                        strokeColor={'#2d75b3'}
                        strokeWidth={12}
                        percent={(levels['reading'].score / levels['reading'].total) * 100}
                        showInfo={false}
                      />
                    </div>

                    <div className="progress-wrap avg-score">
                      <span>Avg SCR: </span>
                      <Progress
                        status="normal"
                        strokeColor={'#FFC631'}
                        strokeWidth={12}
                        percent={calculateAvgScore(avgScoreData, levels, 'reading')}
                        showInfo={false}
                      />
                    </div>

                    <div className="progress-wrap avg-dev">
                      <span>Avg DEV: </span>
                      <Progress status="normal" strokeColor={'#F76B11'} strokeWidth={12} percent={0} showInfo={false} />
                    </div>
                  </div>
                </div>
              )}
              {/* */}
              {levels['listening'] && (
                <div className="card-con">
                  <div className="bg">
                    <div className="subject-con">
                      <h3>Listening</h3>
                      <h3>
                        {levels['listening'].score}/{levels['listening'].total}
                      </h3>
                    </div>
                  </div>

                  <div className="progress-con">
                    <div className="progress-wrap ur-score">
                      <span>Your SCR: </span>
                      <Progress
                        status="normal"
                        strokeColor={'#2d75b3'}
                        strokeWidth={12}
                        percent={(levels['listening'].score / levels['listening'].total) * 100}
                        showInfo={false}
                      />
                    </div>

                    <div className="progress-wrap avg-score">
                      <span>Avg SCR: </span>
                      <Progress
                        status="normal"
                        strokeColor={'#FFC631'}
                        strokeWidth={12}
                        percent={(Number(lexileScoreObject?.mean_values?.listening) / levels['listening'].total) * 100}
                        showInfo={false}
                      />
                    </div>

                    <div className="progress-wrap avg-dev">
                      <span>Avg DEV: </span>
                      <Progress
                        status="normal"
                        strokeColor={devPercentage['listening'] < 0 ? '#6a6b72' : '#F76B11'}
                        strokeWidth={12}
                        percent={(Math.abs(devPercentage['listening']) / levels['listening'].total) * 100}
                        showInfo={false}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* */}
              {levels['grammar'] && (
                <div className="card-con">
                  <div className="bg">
                    <div className="subject-con">
                      <h3>Grammar</h3>
                      <h3>
                        {levels['grammar'].score}/{levels['grammar'].total}
                      </h3>
                    </div>
                  </div>

                  <div className="progress-con">
                    <div className="progress-wrap ur-score">
                      <span>Your SCR: </span>
                      <Progress
                        status="normal"
                        strokeColor={'#2d75b3'}
                        strokeWidth={12}
                        percent={(levels['grammar'].score / levels['grammar'].total) * 100}
                        showInfo={false}
                      />
                    </div>

                    <div className="progress-wrap avg-score">
                      <span>Avg SCR: </span>
                      <Progress
                        status="normal"
                        strokeColor={'#FFC631'}
                        strokeWidth={12}
                        percent={(Number(lexileScoreObject?.mean_values?.grammar) / levels['grammar'].total) * 100}
                        showInfo={false}
                      />
                    </div>

                    <div className="progress-wrap avg-dev">
                      <span>Avg DEV: </span>
                      <Progress
                        status="normal"
                        strokeColor={devPercentage['grammar'] < 0 ? '#6a6b72' : '#F76B11'}
                        strokeWidth={12}
                        percent={(Math.abs(devPercentage['grammar']) / levels['grammar'].total) * 100}
                        showInfo={false}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* */}
            </ReportWrap>
          )}
          <ReportWrap className="bookUrl-con">
            <div style={{ position: 'relative', display: 'block' }}>
              <div style={{ width: '49%', margin: 0, border: 0, padding: 0, lineHeight: '20px', float: 'left' }}>
                <h3 style={{ fontSize: '11px' }}> {mxchangeText}</h3>
              </div>
              <div style={{ width: '49%', margin: 0, border: 0, padding: 0, float: 'right' }}>
                <img style={{ height: '65px', width: '250px', marginTop: '-6px' }} src="../images/Meta-lexileBook.png" alt="logo" />
                <a href="https://hub.lexile.com/find-a-book"> https://hub.lexile.com/find-a-book </a>
              </div>
            </div>
          </ReportWrap>

          <ReportWrap className="result-table-con">
            <Table tableLayout="fixed" pagination={false} columns={isPreTest ? columnsPre : columns} dataSource={tableData} />
          </ReportWrap>

          <ReportWrap className="report-footer-con">
            <h5>
              Powered by
              <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
                &nbsp;Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> AI
              </span>
            </h5>

            <h5 style={{ maxWidth: '55%', fontSize: '10px' }}>
              LEXILE® and the LEXILE® logo are trademarks of MetaMetrics, Inc., and are registered in the United States and abroad.
              <br /> Copyright &nbsp; © 2022 MetaMetrics, Inc. All rights reserved.
            </h5>
          </ReportWrap>
        </InnerWrap>
        <PrintWrapper>
          <img src={`/images/meta/${isPreTest ? 'pre-' : ''}report1.png`} alt="report" style={{ width: '99%' }} />
        </PrintWrapper>

        <PrintWrapper>
          <img src={`/images/meta/${isPreTest ? 'pre-' : ''}report2.png`} alt="report" style={{ width: '99%' }} />
        </PrintWrapper>

        <PrintWrapper>
          <img src={`/images/meta/${isPreTest ? 'pre-' : ''}report3.png`} alt="report" style={{ width: '99%' }} />
        </PrintWrapper>
      </div>
    </>
  );
};

export default OldMetaReport;
