import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { userInfoVar } from 'src/apollo/cache';
import { Modal, Button } from 'antd';
import { FAVORITE_BOOK } from 'src/operations/mutations/externalUserStudy';
import { getLixileScoreObject } from 'src/utils/index';
import { useQuery, useMutation } from '@apollo/client';
import SoundEffect from 'src/components/common/SoundEffect';
import { EXTERNAL_STUDY_BOOK, EXTERNAL_USER_STUDY_LEVELUP } from 'src/operations/queries/externalStudy';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #453ec8;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  /* max-width: 1500px; */
  min-width: 1250px;
  margin: 0 auto;
  transform: ${(props)=>`scale(${(props.width*16) /(props.height*9) >=2 ?(props.width+props.height)/2:Math.max(props.width,props.height)})`};
  transform-origin:top;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled(ColumnFlex)`
  border-radius: 0 0 13px 13px;
  /* padding: 20px 30px; */
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  min-height: 700px;
  align-items: center;
  min-width: 1000px;
`;
const CoverWrapper = styled(ColumnFlex)`
  align-items: center;
`;
const Cover = styled.div`
  width: 200px;
  height: 263px;
  background-image: url(${(props) => props.url});
  background-size: 200px 263px;
  border-radius: 20px 0 0 20px;
`;
const InfoOutWrapper = styled(RowFlex)`
  width: 80%;
  margin: 25px 0;
  /* padding: 20px 35px; */
  height: 263px;
  border-radius: 20px;
  background-color: #fef189;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
  @media (max-width: 1280px) {
    width: 95%;
  }
`;
const InfoTopWrapper = styled(RowFlex)`
  justify-content: space-between;
  align-items: start;
  /* padding: 20px 15px 0; */
  /* height: 33%; */
  /* width: 65%; */
`;
const InfoMidWrapper = styled(RowFlex)`
  justify-content: space-between;
  align-items: end;
  /* padding: 20px 15px; */
  /* height: 33%; */
  /* width: 30%; */
  /* height: 68%; */
`;
const InfoInnerWrapper = styled(RowFlex)`
  align-items: start;
`;
const InfoSummaryWrapper = styled(ColumnFlex)`
  align-items: start;
  /* padding: 20px 15px; */
  width: 100%;
`;
const InfoTitle = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  padding: 7px;
  width: 100px;
  min-width: 100px;
`;
const InfoText = styled(RowFlex)`
  background-color: #fff;
  font-size: 18px;
  color: #717171;
  /* line-height: 2.2; */
  align-items: center;
  padding: 7px;
  /* border: 1px solid #c4b693; */
  border-radius: 5px;
  width: 100%;
  height: 32px;
  min-width: ${(props) => (props.right ? '100px' : '200px')};
  justify-content: ${(props) => props.right && 'center'};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* @media (max-width: 1280px) {
    font-size: 16px;
  } */
`;

const SummaryText = styled.div`
  /* border: 1px solid #c4b693; */
  border-radius: 5px;
  background-color: #fff;
  color: #717171;
  font-size: 18px;
  line-height: 1.6;
  height: 110px;
  padding: 10px;
  overflow: auto;
  /* white-space: nowrap; */
  width: 100%;
  /* @media (max-width: 1280px) {
    font-size: 16px;
  } */
`;
const InfoWrapper = styled(ColumnFlex)`
  justify-content: space-between;
  padding: 15px;
`;
const LikeImage = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  background-image: url(${(props) => (props.toggle ? '/images/book/heart-on.png' : '/images/book/heart-off.png')});
  background-size: 23px 20px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 16px;
  background-color: #fff;
  /* margin: 20px 25px 20px 15px; */
`;
const StageButtonOutWrapper = styled(RowFlex)`
  width: 85%;
  padding: 50px 60px 30px;
  justify-content: space-between;
  @media (max-width: 1280px) {
    padding: 20px 60px;
  }
`;
const StageButtonWrapper = styled(ColumnFlex)`
  align-items: center;
`;
const StageButton = styled.div`
  width: 150px;
  height: 132px;
  border-radius: 30px;
  /* border: 1px red solid; */
  cursor: pointer;
  background-image: url(${(props) => props.bg});
  background-size: 150px 132px;
  background-repeat: no-repeat;
  @media (max-width: 1280px) {
    width: 114px;
    height: 100px;
    border-radius: 20px;
    background-size: 114px 100px;
  }
`;
const StageText = styled.div`
  cursor: pointer;
  width: 130px;
  border: 3px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  border-radius: 25px;
  margin: 10px;
  @media (max-width: 1280px) {
    width: 95px;
    font-size: 16px;
    border-radius: 23px;
  }
`;
const BGMButton = styled.div`
  width: 35px;
  height: 35px;
  background-image: url(${(props) => (props.off ? '/images/LevelUp/study/bgm_off.png' : '/images/LevelUp/study/bgm_on.png')});
  /* background-image: url('/images/LevelUp/study/bgm_on.png'); */
  background-size: 35px 35px;
  background-repeat: no-repeat;
  cursor: pointer;
  /* float: right; */
  margin-right: 15px;
`;
const XButton = styled.div`
  width: 28px;
  height: 28px;
  background-image: url('/images/LevelUp/study/X_button.png');
  background-size: 28px 28px;
  background-repeat: no-repeat;
  cursor: pointer;
  /* float: right; */
`;

const useCheckScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return [width > 1600 ? 1 : width / 1600, height > 900 ? 1 : height / 900];
};

export default function LevelUpBookStudyPage() {
  let width = useCheckScreen()[0];
  let height = useCheckScreen()[1];
  const { idx } = useParams();
  const location = useLocation();
  const assigned_idx = location.state?.assigned_idx;
  const history = useHistory();
  const [bookData, setBookData] = useState({});
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const user_idx = userInfoVar()?.idx || null;
  const setMxLevel = (lexile) => {
    const mx = getLixileScoreObject(lexile, 'A-1');
    return mx;
  };
  const bgmurl = '/audio/LevelUp/bgm_level_up.mp3';
  const [bgm, setBgm] = useState(bgmurl);
  const setLexileValue = (lexile) => {
    if (lexile.includes('BR')) {
      return 0;
    } else if (lexile.includes('NP')) {
      return 1000;
    } else {
      return lexile === '' ? '' : parseInt(lexile);
    }
  };
  const {
    data: userBook,
    loading,
    refetch,
  } = useQuery(EXTERNAL_STUDY_BOOK, {
    variables: {
      type: 'levelup',
      code: idx,
    },
    fetchPolicy: 'no-cache',
  });
  const { data: stageData } = useQuery(EXTERNAL_USER_STUDY_LEVELUP, {
    skip:!userBook?.getExternalUserBook[0]?.book_no&&!assigned_idx,
    variables: {
      user_idx: parseInt(user_idx),
      type: 'levelup',
      limit: 1,
      external_study_idx: assigned_idx ? null : parseInt(userBook?.getExternalUserBook[0]?.book_no),
      assigned_idx: assigned_idx ? parseInt(assigned_idx) : null,
    },
    fetchPolicy: 'no-cache',
  });
  const doneStage = useMemo(() => {
    if (stageData) {
      return stageData?.getExternalStuiesList[0]?.external_study_stage.map((e) => e.stage_no);
    }
  }, [stageData]);
  useEffect(() => {
    try {
      let currentBook = userBook?.getExternalUserBook[0];

      currentBook.lexileVal = currentBook.lexile_val ? currentBook.lexile_val : setLexileValue(currentBook.lexile);
      currentBook.mxObj = setMxLevel(currentBook.lexileVal);
      currentBook.level = currentBook.mx_level !== undefined ? currentBook.mx_level : currentBook.mxObj?.mx;
      setBookData({
        ...currentBook,
        assignedIdx: assigned_idx,
      });
    } catch (e) {
      setError(e);
    }
  }, [userBook, assigned_idx]);

  const [favBook, { loading: loadingDelete }] = useMutation(FAVORITE_BOOK, {
    onCompleted: (res) => {},
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  const handleLike = async (e) => {
    e.preventDefault();
    await favBook({ variables: { book_id: bookData?.book_no, type: 'levelup' } });
    await refetch();
  };
  const bgmToggle = () => {
    if (bgm) {
      setBgm(null);
      window.localStorage.setItem('levelup_bgm_toggle', 'off');
    } else {
      setBgm(bgmurl);
      window.localStorage.removeItem('levelup_bgm_toggle');
    }
  };
  useEffect(() => {
    const localbgmToggle = window.localStorage.getItem('levelup_bgm_toggle');
    if (localbgmToggle==='off') {
      setBgm(null);
    } else {
      setBgm(bgmurl);
    }
  }, []);
  const goBackInfo = useMemo(() => {
    let tmp = '?lv=';
    if (bookData) {
      if (bookData?.mx_level === 'PH') {
        if (bookData.is_interactive === 1) {
          tmp = tmp + '-1';
        } else {
          tmp = tmp + '0';
        }
      } else {
        tmp = tmp + bookData?.mx_level;
      }
      return tmp;
    }
    return '';
  }, [bookData]);
  const handleX = ()=>{
    if(userInfoVar()?.type > 0){history.push(`/level-up/library${goBackInfo}`)}
    else{
      if(stageData?.getExternalStuiesList[0]?.external_study_stage.length===5){
        setVisible(true)
      }
else{
  history.push('/level-up')
}
    }
  }
  return (
    <>
      <Wrapper>
        <MainWrapper width={width} height={height}>
          <Main>
            <Title>
              {bookData.title}
              <RowFlex style={{ float: 'right', alignItems: 'center' }}>
                <BGMButton off={!bgm} onClick={bgmToggle} />
                <XButton onClick={handleX} />
                {/* <XButton onClick={() => history.push(userInfoVar()?.type > 0 ? `/level-up/library${goBackInfo}` : '/level-up')} /> */}
              </RowFlex>
            </Title>
            <InfoOutWrapper>
              <CoverWrapper>
                <Cover url={`https://cdn.cloubot.com/LEVELUP/covers/${idx}.png`} />
              </CoverWrapper>
              <InfoWrapper>
                <InfoTopWrapper>
                  <InfoInnerWrapper>
                    <InfoTitle>Author</InfoTitle>
                    <InfoText>{bookData?.author}</InfoText>
                  </InfoInnerWrapper>
                  <InfoInnerWrapper>
                    <InfoTitle>mx Level</InfoTitle>
                    <InfoText right> {bookData?.mx_level || bookData?.mxObj?.mx}</InfoText>
                  </InfoInnerWrapper>
                  <InfoTitle style={{ width: '200px', padding: '5px', display: 'flex', justifyContent: 'end' }}>
                    <LikeImage onClick={handleLike} toggle={bookData.is_fav > 0} />
                  </InfoTitle>
                </InfoTopWrapper>

                <InfoMidWrapper>
                  <InfoInnerWrapper>
                    <InfoTitle>Genre</InfoTitle>
                    <InfoText> {bookData?.genre}</InfoText>
                  </InfoInnerWrapper>
                  <InfoInnerWrapper>
                    <InfoTitle>Lexile</InfoTitle>
                    <InfoText right> {bookData?.lexileVal < 0 ? `BR${Math.abs(bookData?.lexileVal)}L` : `${bookData?.lexile_val}L`}</InfoText>
                  </InfoInnerWrapper>
                  <InfoInnerWrapper>
                    <InfoTitle>GRL</InfoTitle>
                    <InfoText right> {bookData?.genre__1}</InfoText>
                  </InfoInnerWrapper>
                </InfoMidWrapper>
                <InfoSummaryWrapper>
                  <InfoTitle>Summary</InfoTitle>
                  <SummaryText>{bookData?.summary}</SummaryText>
                </InfoSummaryWrapper>
              </InfoWrapper>
            </InfoOutWrapper>
            <StageButtonOutWrapper>
              <StageButtonWrapper
                onClick={() => {
                  history.push(`/level-up/${idx}/voca/card`, { bookData: bookData });
                }}
              >
                <StageButton bg={`/images/LevelUp/study/main/words${doneStage && doneStage.includes(1) ? '_gray' : ''}.png`}></StageButton>
                <StageText color={doneStage && doneStage.includes(1) ? '#9fa0a0' : '#ff005c'}>Words</StageText>
              </StageButtonWrapper>
              <StageButtonWrapper
                onClick={() => {
                  history.push(`/level-up/reader/${idx}`, { bookData: bookData });
                }}
              >
                <StageButton
                  bg={`/images/LevelUp/study/main/reading${doneStage && doneStage.includes(2) ? '_gray' : ''}.png`}
                ></StageButton>
                <StageText color={doneStage && doneStage.includes(2) ? '#9fa0a0' : '#feda02'}>Reading</StageText>
              </StageButtonWrapper>
              <StageButtonWrapper
                onClick={() => {
                  history.push(`/level-up/${idx}/voca/quiz`, { bookData: bookData });
                }}
              >
                <StageButton bg={`/images/LevelUp/study/main/quiz${doneStage && doneStage.includes(3) ? '_gray' : ''}.png`}></StageButton>
                <StageText color={doneStage && doneStage.includes(3) ? '#9fa0a0' : '#49ada1'}>Quiz</StageText>
              </StageButtonWrapper>
              <StageButtonWrapper
                onClick={() => {
                  history.push(`/level-up/${idx}/voca/match`, { bookData: bookData });
                }}
              >
                <StageButton
                  bg={`/images/LevelUp/study/main/activity${doneStage && doneStage.includes(4) ? '_gray' : ''}.png`}
                ></StageButton>
                <StageText color={doneStage && doneStage.includes(4) ? '#9fa0a0' : '#0ba7fe'}>Activity</StageText>
              </StageButtonWrapper>
              <StageButtonWrapper
                onClick={() => {
                  history.push(`/level-up/${idx}/${bookData?.level > 4 ? 'crosspuzzlegame' : 'matchinggame'}`, { bookData: bookData });
                }}
              >
                <StageButton bg={`/images/LevelUp/study/main/game${doneStage && doneStage.includes(5) ? '_gray' : ''}.png`}></StageButton>
                <StageText color={doneStage && doneStage.includes(5) ? '#9fa0a0' : '#453ec8'}>Game</StageText>
              </StageButtonWrapper>
            </StageButtonOutWrapper>
          </Main>
        </MainWrapper>
      </Wrapper>
      <>
        <SoundEffect src={bgm} replayCnt={0} volume={30} />
        <Modal
          centered
          visible={visible}
          onOk={() => {
            history.push('/level-up/portfolio');
          }}
          onCancel={() => {
            history.push('/level-up');
          }}
          width={'400px'}
          footer={[
            <Button
              type="primary"
              onClick={() => {
                history.push('/level-up/portfolio');
              }}
            >
              Yes
            </Button>,
            <Button
              onClick={() => {
                history.push('/level-up');
              }}
            >
              No
            </Button>,
          ]}
        >
          <div>All done!</div>
          <div>Would you like to go to the portfolio page? </div>
        </Modal>
      </>
    </>
  );
}
