import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import AlphabetDnD from "./AlphabetDnD";
import AlphabetDnD2 from "./AlphabetDnD2";
import AlphabetDnD3 from "./AlphabetDnD3";
import AlphabetDnD4 from "./AlphabetDnD4";
import AlphabetDnD5 from "./AlphabetDnD5";
import AlphabetDnD6 from "./AlphabetDnD6";
import AlphabetDnD7 from "./AlphabetDnD7";
import PopUp from "./PopUp";
// import {changeShowPageNav} from "../../utils/pm/store/modules/Common";

const AlphabetDnDCommon = props => {
	const {problemType, popUpType, setPopUpType, showPopUp, setShowPopUp, handleGoodJobSoundEnd, steps, setSteps, problems} = props;
	const type = problemType || "default";

	const [isExampleWordSoundEnd, setIsExampleWordSoundEnd] = useState(false);

	const [alphabetDnDKey, setAlphabetDnDKey] = useState(0);

	const handleWordSoundEnd = () => {
		setIsExampleWordSoundEnd(true);
	}; 

	const handlePopupClose = () => {
		setShowPopUp(false);
		//꼼수임.. key가 바뀌면 새로 생성이 되는 원리를 이용해서 리셋해줌.
		setAlphabetDnDKey(alphabetDnDKey + 1);
	};

	const dispatch = useDispatch();

	// useEffect(() => {
	// 	dispatch(changeShowPageNav(!showPopUp));
	// }, [showPopUp]);

	useEffect(() => {
		setIsExampleWordSoundEnd(false);
	}, [steps]);
	return (
		<>
			{
				{
					dndType1: <AlphabetDnD {...props} key={`AlphabetDnD-key${alphabetDnDKey}`} onWordSoundEnd={handleWordSoundEnd} />,
					dndType2: <AlphabetDnD2 {...props} />,
					dndType3: <AlphabetDnD3 {...props} />,
					dndType4: <AlphabetDnD4 {...props} />, //siginle, double
					dndType5: <AlphabetDnD5 {...props} />, //double only
					dndType6: <AlphabetDnD6 {...props} />, //hard, soft
					dndType7: <AlphabetDnD7 {...props} />, //long, short oo
					// default: <div>Not Found</div>,
				}[type]
			}
			{showPopUp ? (
				<PopUp
					popUpType={popUpType}
					setPopUpType={setPopUpType}
					setShowPopUp={setShowPopUp}
					onSoundEffectEnd={handleGoodJobSoundEnd}
					steps={steps}
					setSteps={setSteps}
					problems={problems}
					problem={props.stages.customData.problem[steps]}
					isExampleWordSoundEnd={isExampleWordSoundEnd}
					onClose={handlePopupClose}
				/>
			) : (
				undefined
			)}
		</>
	);
};

export default AlphabetDnDCommon;
