import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  DASHBOARD_MANAGER_BOOK_LIST,
  DASHBOARD_CLASS_LESSON_LIST,
  DASHBOARD_MANAGER_TEST_LIST,
  DASHBOARD_MANAGER_ZOOM_LIST,
  DASHBOARD_MANAGER_EXTERNAL_STUDY_LIST,
  DASHBOARD_MANAGER_TEST_GROUP_LIST,
} from 'src/operations/queries/getClass';
import renderContent from 'src/components/Calendar/renderContent';
import { classStoreData } from 'src/operations/store';
import { rootStateVar } from 'src/apollo/cache';
import * as dummy from 'src/dummy';
import moment from 'moment';
import * as queries from 'src/operations/queries';

const CalendarBlock = styled.div`
  padding: 12px;
  background: #fff;
  //box-shadow: 2px 1px 5px 5px #dddddd;
  border-radius: 10px;
  min-height: calc(100vh - 18vh);
  h2.fc-toolbar-title {
    display: inline-block;
  }
  .fc-prev-button,
  .fc-next-button {
    background-color: #fff;
    color: #666;
    border: 0;
    font-weight: bold;
    font-size: 1.5em;
  }
  .fc-prev-button:hover,
  .fc-next-button:hover {
    background-color: #fff;
    color: #666;
    border: 0;
  }
`;

const CalendarArea = () => {
  const calendarRef = useRef();
  const defaultClass = useSelector(classStoreData);
  const [events, setEvents] = useState([]);
  const [testEvents, setTestEvents] = useState([]);
  const [testGroupEvents, setTestGroupEvents] = useState([]);
  const [zoomEvents, setZoomEvents] = useState([]);
  const [bookEvents, setBookEvents] = useState([]);
  const [externalStudyEvents, setExternalStudyEvents] = useState([]);
  const [externalStudyEventsDateWise, setExternalStudyEventsDateWise] = useState([]);
  const [bookrEvents, setBookrEvents] = useState([]);
  const [levelupEvents, setLevelupEvents] = useState([]);
  const [bookrEventsDateWise, setBookrEventsDateWise] = useState([]);
  const [PhonicsEvents, setPhonicsEvents] = useState([]);
  const [StarwordsEvents, setStarwordsEvents] = useState([]);
  const [OfflineSyllabusEvents, setOfflineSyllabusEvents] = useState([]);
  const [aiTopics, setAiTopics] = useState([]);
  const [bookReportTopics, setBookReportTopics] = useState([]);
  const [aiTalkingReportTopics, setaiTalkingReportTopics] = useState([]);
  // console.log('calendardarea')
  // const classColor = useMemo(
  //   () => ({
  //     //ClassLesson: '#11783D',
  //     ClassLesson: defaultClass === 'lucid' ? '#FF0000' : '#11783D',
  //     UserLesson: '#11783D',
  //     // AssignedTests: '#ed5565',
  //     OfflineLesson: '#7030A0',
  //     PhonicsLesson: '#ffce54',
  //     AssignedTests: '#ff7f00',
  //     // AssignedBooks: 'blue',
  //     AssignedBooks: defaultClass === 'lucid' ? '#FF9900' : 'blue',
  //     ZoomSchedule: '#3C79F6',
  //   }),
  //   [],
  // );

  /*const classType = useMemo(
    () => ({
      ClassLesson: 'H',
      UserLesson: 'H',
      AssignedTests: 'WB',
      ZoomSchedule: 'Z',
      AssignedElibrary: 'L',
      EvineAssignedElibrary : 'E'
    }),
    [],
  );*/
  const classColor = useMemo(() => (dummy.settings.settings[defaultClass] ? dummy.settings.settings[defaultClass].classColor : {}), []);
  const classType = useMemo(() => (dummy.settings.settings[defaultClass] ? dummy.settings.settings[defaultClass].classType : {}), []);
  const settings = useMemo(() => (dummy.settings.settings[defaultClass] ? dummy.settings.settings[defaultClass] : {}), []);

  const [getPhonicsSchedule, { data: fetchPhonicsData }] = useLazyQuery(queries.phonicsUnitList.DASHBOARD_CLASS_PHONICS_DATEWISE_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [getOfflineSchedule, { data: fetchOfflineData }] = useLazyQuery(
    queries.offlinesyllabus.DASHBOARD_CLASS_OFFLINE_SYLLABUS_DATEWISE_LIST,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getStudySchedule, { data }] = useLazyQuery(DASHBOARD_CLASS_LESSON_LIST, { fetchPolicy: 'no-cache' });
  const [getTestSchedule, { data: fetchTestData }] = useLazyQuery(DASHBOARD_MANAGER_TEST_LIST, { fetchPolicy: 'no-cache' });
  const [getTestGroupSchedule, { data: fetchTestGroupData }] = useLazyQuery(DASHBOARD_MANAGER_TEST_GROUP_LIST, { fetchPolicy: 'no-cache' });
  const [getAiTopicList, { data: aiTopicsData }] = useLazyQuery(queries.getClass.DASHBOARD_TEACHER_AI_TOPIC_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [getBookReportTopicList, { data: bookReportTopicsData }] = useLazyQuery(queries.getClass.DASHBOARD_TEACHER_BOOK_REPORT_TOPIC_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [getAiTalkingReportTopicList, { data: aiTalkingReportTopicsData }] = useLazyQuery(queries.getClass.DASHBOARD_TEACHER_AI_TALKING_REPORT_TOPIC_LIST, {
    fetchPolicy: 'no-cache',
  })
  const [getZoomSchedule, { data: fetchZoomData }] = useLazyQuery(DASHBOARD_MANAGER_ZOOM_LIST, { fetchPolicy: 'no-cache' });

  const [getBookSchedule, { data: fetchBookData }] = useLazyQuery(DASHBOARD_MANAGER_BOOK_LIST, { fetchPolicy: 'no-cache' });
  const [getExternalStudySchedule, { data: fetchExternalStudyData }] = useLazyQuery(DASHBOARD_MANAGER_EXTERNAL_STUDY_LIST, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (fetchPhonicsData !== undefined) {
      var phonics = [];
      var starwords = [];

      fetchPhonicsData.CamppusAssignedPhonicsDailySchedue.map((item, i) => {
        if (item.phonics_assign_date !== undefined) {
          // if (item?.PHCount) {
          phonics.push({
            key: i,
            date: moment(new Date(item.phonics_assign_date.split('T')[0])).format(),
            color: classColor['PhonicsLesson'],
            title: `${item?.count}: Phonics`,
            // title: 'Phonics',
            type: 'PhonicsLesson',
            textColor: 'PhonicsLesson',
          });
          // }

          // if (item?.SWCount) {
          //   starwords.push({
          //     key: i,
          //     date: moment(new Date(item.phonics_assign_date.split('T')[0])).format(),
          //     color: classColor['StarwordsLesson'],
          //     title: `${item?.SWCount} : StawWords`,
          //     type: 'StarwordsLesson',
          //     textColor: 'StarwordsLesson',
          //   });
          // }
        }
      });

      setPhonicsEvents(phonics);
      setStarwordsEvents(starwords);
    }

    if (fetchBookData) {
      setBookEvents(
        fetchBookData.dashBoardBookList.map((item, i) => {
          return {
            key: i,
            date: moment(new Date(item.DateOnly)).format(),
            title: `${item?.class_elibrary_cnt}: Elibrary`,
            color: classColor['AssignedBooks'],
            type: 'AssignedBooks',
            textColor: 'AssignedBooks',
          };
        }),
      );
    }
    if (fetchExternalStudyData) {
      const newso = [];
      const bookr = [];
      const newso_assign = [];
      const levelup = []

      for (let item of fetchExternalStudyData?.dashBoardExternalStudyList) {
        if (item.type === 'newsomatic') {
          newso.push(item);
        } else if (item.type === 'bookr') {
          bookr.push(item);
        } else if (item.type === 'newsomatic-assign') {
          newso_assign.push(item);
        }
        else if (item.type === 'levelup') {
          levelup.push(item);
        }
      }
      // const countAccordingToDate = fetchExternalStudyData.dashBoardExternalStudyList.reduce((acc, item) => {
      //   if (acc[item.DateOnly]) {
      //     acc[item.DateOnly]['count'] += 1;
      //   } else {
      //     acc[item.DateOnly] = { count: 1, date: item.DateOnly };
      //   }
      //   return acc;
      // }, {});
      // setExternalStudyEvents(
      //   Object.values(countAccordingToDate).map((item, i) => {
      //     return {
      //       key: `external-${i}`,
      //       date: moment(new Date(item.date)).format(),
      //       title: `${item?.count}`,
      //       color: classColor['ExternalStudy'],
      //       type: 'ExternalStudy',
      //       textColor: 'ExternalStudy',
      //     };
      //   }),
      // );
      const newsoAccordingToDate = newso.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly };
        }
        return acc;
      }, {});
      setExternalStudyEvents(
        Object.values(newsoAccordingToDate).map((item, i) => {
          return {
            key: `external-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Newsomatic`,
            color: classColor['ExternalStudy'],
            type: 'ExternalStudy',
            textColor: 'ExternalStudy',
          };
        }),
      );
      const newsoAccordingToDate_assign = newso_assign.reduce((acc, item) => {
        const dateOnly_assign = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly_assign]) {
          acc[dateOnly_assign]['count'] += 1;
        } else {
          acc[dateOnly_assign] = { count: 1, date: dateOnly_assign };
        }
        return acc;
      }, {});
      setExternalStudyEventsDateWise(
        Object.values(newsoAccordingToDate_assign).map((item, i) => {
          return {
            key: `external-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Newsomatic`,
            color: classColor['News_O_Matic'],
            type: 'ExternalStudyForStudent',
            textColor: 'ExternalStudyForStudent',
          };
        }),
      );
      const levelupAccordingToDate = levelup.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly };
        }
        return acc;
      }, {});
      setLevelupEvents(
        Object.values(levelupAccordingToDate).map((item, i) => {
          return {
            key: `LevelUp-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Level-Up`,
            color: classColor['LevelUp'],
            type: 'LevelUp',
            textColor: 'LevelUp',
          };
        }),
      );
      const bookrAccordingToDate = bookr.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly };
        }
        return acc;
      }, {});
      setBookrEvents(
        Object.values(bookrAccordingToDate).map((item, i) => {
          return {
            key: `bookr-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Bookr`,
            color: classColor['Bookr'],
            type: 'Bookr',
            textColor: 'Bookr',
          };
        }),
      );
    }

    if (fetchOfflineData !== undefined && fetchOfflineData !== null) {
      setOfflineSyllabusEvents(
        fetchOfflineData.OfflineAssignedDailySchedue.map((item, i) => {
          if (item.offline_assign_date !== undefined) {
            return {
              key: i,
              date: moment(new Date(item.offline_assign_date.split('T')[0])).format(),
              color: classColor['OfflineLesson'],
              title: `${item?.count} : Offline Lesson`,
              type: 'OfflineLesson',
              textColor: 'OfflineLesson',
            };
          }
        }),
      );
    }

    if (data) {
      setEvents(
        data.dashBoardClassLessonList.map((item, i) => {
          return {
            key: i,
            date: item.study_date,
            title: `${item?.lesson_cnt} : Homework`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }

    if (fetchTestData) {
      setTestEvents(
        fetchTestData.dashBoardTestList.map((item, i) => {
          return {
            key: i,
            date: moment(new Date(item.DateOnly)).format(),
            title: `${item?.class_test_cnt} : Tests`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }
    if (fetchTestGroupData) {
      setTestGroupEvents(
        fetchTestGroupData.dashBoardTestGroupList.map((item, i) => {
          console.log('item', item, classColor[`${item.__typename}`]);
          return {
            key: i,
            date: moment(new Date(item.DateOnly)).format(),
            title: `${item?.class_test_cnt} : Test Group`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }

    if (fetchZoomData) {
      setZoomEvents(
        fetchZoomData.dashBoardZoomList.map((item, i) => {
          return {
            key: i,
            date: moment(new Date(item.DateOnly)).format(),
            title: `${item?.class_zoom_cnt} : Zoom`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }
  }, [classColor, data, fetchTestData, fetchZoomData, fetchBookData, fetchPhonicsData, fetchOfflineData, fetchExternalStudyData]);

  useEffect(() => {
    if (aiTopicsData) {
      setAiTopics(
        aiTopicsData.dashBoardAiTopicList
          .filter((item) => item.ai_topic_cnt)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.ai_topic_cnt} : AI Topic`,
              color: classColor[`${item.__typename}`],
              type: item?.__typename,
              textColor: item?.__typename,
              backgroundColor: classColor['AI_Topics'],
            };
          }),
      );
    }
  }, [aiTopicsData]);
  useEffect(() => {
    if (bookReportTopicsData) {
      setBookReportTopics(
        bookReportTopicsData.dashBoardBookReportTopicList
          .filter((item) => item.ai_topic_cnt)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.ai_topic_cnt} : Writing Coach`,
              color: classColor[`${item.__typename}`],
              type: item?.__typename,
              textColor: item?.__typename,
              backgroundColor: classColor['Book_Report'],
            };
          }),
      );
    }
  }, [bookReportTopicsData]);


  useEffect(() => {
    if (aiTalkingReportTopicsData) {
      setaiTalkingReportTopics(
        aiTalkingReportTopicsData.dashBoardAiTalkingTopicList
          .filter((item) => item.ai_topic_cnt)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.ai_topic_cnt} : Speaking tutor`,
              color: classColor[`${item.__typename}`],
              type: item?.__typename,
              textColor: item?.__typename,
              backgroundColor: classColor['AI_Talking'],
            };
          }),
      );
    }
  }, [aiTalkingReportTopicsData]);

  function handleCalendarClick({ startStr }) {
    rootStateVar({
      ...rootStateVar(),
      calendar: {
        ...rootStateVar().calendar,
        selectDay: startStr,
      },
    });
  }

  function handleEventClick({ event }) {
    const selectDay = moment(event.start).format('yyyy-MM-DD');
    const calendarApi = calendarRef.current.getApi();
    calendarApi.select(selectDay);
  }

  function handleDateClick(e) {
    const selectDay = moment(e.date).format('yyyy-MM-DD');
    const calendarApi = calendarRef.current.getApi();
    calendarApi.select(selectDay);
  }

  function handleRenderContent(event) {
    renderContent(event);
  }

  return (
    <>
      <CalendarBlock>
        <FullCalendar
          ref={calendarRef}
          //selectable={true}
          datesSet={({ start, end }) => {
            const startDate = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
            const endDate = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;
            rootStateVar({
              ...rootStateVar(),
              calendar: {
                start: startDate,
                end: endDate,
              },
            });

            getStudySchedule({
              variables: {
                start,
                end,
              },
            });

            getTestSchedule({
              variables: {
                start,
                end,
              },
            });
            getTestGroupSchedule({
              variables: {
                start,
                end,
              },
            });

            getZoomSchedule({
              variables: {
                start,
                end,
              },
            });

            getBookSchedule({
              variables: {
                start,
                end,
              },
            });

            getPhonicsSchedule({
              variables: {
                start: startDate,
                end: endDate,
                input: 'phonicsdates',
              },
            });

            getOfflineSchedule({
              variables: {
                start: startDate,
                end: endDate,
                input: 'offlinedates',
              },
            });

            getExternalStudySchedule({
              variables: {
                start,
                end,
              },
            });

            getAiTopicList({
              variables: {
                start: startDate,
                end: endDate,
              },
            });

            getBookReportTopicList({
              variables: {
                start: startDate,
                end: endDate,
              },
            });
            getAiTalkingReportTopicList({
              variables: {
                start: startDate,
                end: endDate,
              },
            });
          }}
          plugins={[dayGridPlugin, interactionPlugin]}
          height={'calc(100vh - 25vh)'}
          initialView="dayGridMonth"
          events={[
            ...events,
            ...testEvents,
            ...zoomEvents,
            ...bookEvents,
            ...PhonicsEvents,
            ...OfflineSyllabusEvents,
            ...externalStudyEvents,
            ...bookrEvents,
            ...levelupEvents,
            ...externalStudyEventsDateWise,
            ...aiTopics,
            ...bookReportTopics,
            ...aiTalkingReportTopics,
            ...testGroupEvents,
            ...bookrEventsDateWise,
          ]}
          eventContent={(eventInfo, i) => {
            return (
              <>
                <div
                  style={{
                    backgroundColor: eventInfo.backgroundColor,
                    color: 'white',
                    borderRadius: 10,
                    width: 25,
                    height: 18,
                    textAlign: 'center',
                    marginRight: 5,
                  }}
                  className={`iconcalander ${settings.eventsIcon[eventInfo.textColor]}`}
                >
                  {/* className="icon_userlesson" */}
                  {/* className="icon_assignedtests" */}
                  {/* className="icon_zoomschedule" */}
                  {settings.calendarEventsIn ? classType[eventInfo.textColor] : eventInfo.event.title}
                </div>
                <div className={`iconcommontext ${settings.eventsIcon[eventInfo.textColor]}`}>
                  {' '}
                  {!settings.calendarEventsIn ? classType[eventInfo.textColor] : eventInfo.event.title}
                </div>
              </>
            );
          }}
          dayMaxEvents={2}
          select={handleCalendarClick}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
          //locale={'ko-KR'}
          longPressDelay={0}
          eventLongPressDelay={0}
          selectLongPressDelay={0}
          headerToolbar={{
            start: '',
            center: 'prev,title,next',
            end: 'todayButton',
          }}
          customButtons={{
            todayButton: {
              text: 'today',
              click: () => {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.today();
                calendarApi.select(moment(new Date()).format('yyyy-MM-DD'));
              },
            },
          }}
        />
        {defaultClass === 'lucid' ? (
          <div>
            <div className="dasboard-list">
              <span style={{ backgroundColor: '#FF0000', borderRadius: '10px' }}>H</span>
              <span style={{ backgroundColor: '#FF9900', borderRadius: '10px' }}>L</span>
              <span style={{ backgroundColor: '#000066', borderRadius: '10px' }}>T</span>
              <span style={{ backgroundColor: '#7030A0', borderRadius: '10px' }}>O</span>
            </div>
          </div>
        ) : (
          ''
        )}
      </CalendarBlock>
    </>
  );
};

export default CalendarArea;
