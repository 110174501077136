import { useState, useCallback, useRef, useEffect, useMemo, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { getCurrentToken } from 'src/utils';
import { useQueryProxy } from 'src/operations/proxy/user';
import * as queries from 'src/operations/queries';
import { useLazyQuery, useApolloClient, useQuery } from '@apollo/client';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { GET_LEXILE_SCORE } from 'src/operations/queries/consult';
import fscreen from 'fscreen';
import * as axios from 'axios';

export const useForm = (callback, initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const onChange = (event) => {
    if (event.target.name === 'email') {
      setValues({
        ...values,
        [event.target.name]: event.target.value ? event.target.value : '',
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value ? event.target.value : event.target.checked,
      });
    }
  };

  const onSelectChange = (value, select) => {
    setValues({ ...values, [select.name]: select.value });
  };

  const onSubmit = () => {
    callback();
  };

  return {
    values,
    onChange,
    onSelectChange,
    onSubmit,
  };
};

export const useModal = () => {
  const [visible, setVisible] = useState(false);
  const [popupMode, setPopupMode] = useState('');

  const showModal = (mode = 'create') => {
    setPopupMode(mode);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return {
    visible,
    showModal,
    handleCancel,
    popupMode,
  };
};

export const storage = {
  getItem: (keyword) => {
    return window.localStorage.getItem(keyword);
  },

  getItemArray: (array) => {
    return array.length ? array.split(',') : array;
  },

  setItem: (keyword, value) => {
    window.localStorage.setItem(keyword, value);
  },
  removeItem: (keyword) => {
    window.localStorage.removeItem(keyword);
  },
};

export const useInput = (initialValue = null) => {
  const [value, setValue] = useState(initialValue);
  const handler = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const reset = useCallback(() => setValue(initialValue), [initialValue]);

  return [value, handler, reset];
};

export const useSelect = (key, initialValue = null) => {
  const [value, setValue] = useState(initialValue);
  const handler = useCallback((key) => {
    setValue(key);
  }, []);

  return [value, handler];
};

//아임포트 연동 hook 구현
const loadScript = (script_url, script_id) => {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script');
      script.id = script_id;
      script.src = script_url;
      script.onload = () => resolve();
      document.head.appendChild(script);
    } catch (e) {
      reject(e);
    }
  });
};

const JQUERY_URL = `https://code.jquery.com/jquery-1.12.4.min.js`;
const IAMPORT_URL = `https://cdn.iamport.kr/js/iamport.payment-1.1.5.js`;

export const useIamport = (iamportCode) => {
  const iamportInstance = useRef({});
  const [loaded, setLoaded] = useState(false);
  const requestPay = (params, onSuccess, onFail) => {
    iamportInstance.current.request_pay(params, (resp) => {
      if (resp.success) {
        onSuccess && onSuccess(resp);
      } else {
        onFail && onFail(resp);
      }
    });
  };

  useEffect(() => {
    (async () => {
      await loadScript(JQUERY_URL, 'react-use-iamport-jquery');
      await loadScript(IAMPORT_URL, 'react-use-iamport-iamport');
      const { IMP } = window;
      iamportInstance.current = IMP;
      IMP.init(iamportCode);
      setLoaded(true);
    })();
  }, [iamportCode]);
  return { loaded, requestPay };
};

const NOT_ACCEPTED_PATH = ['/teachers', '/students', '/fcManager', '/learning'];
export const useAuthCheck = () => {
  const location = useLocation();
  const token = getCurrentToken();
  const { data } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });
  const userType = useMemo(() => data?.me?.type, [data]);
  const isAccepted = useMemo(() => {
    if (userType !== undefined) {
      if (userType === 0) {
        const find = NOT_ACCEPTED_PATH.find((item) => {
          return location.pathname.indexOf(item) !== -1;
        });
        if (find) {
          return false;
        }
      }
    }
    return true;
  }, [userType, location]);

  //console.log('location.pathname', location.pathname);

  return { isAccepted };
};

export const useLoginTo = (isReturn) => {
  const client = useApolloClient();
  const history = useHistory();
  const [loginUser, { data: dataLogin }] = useLazyQuery(queries.getUser.LOGIN_USER);
  const loginTo = async (id) => {
    if (!isReturn && userInfoVar()?.id) {
      window.localStorage.setItem('culp_return_id', userInfoVar().id);
    }
    isLogedIn(false);
    userInfoVar(null);
    await client.clearStore();
    client.cache.gc();
    window.localStorage.removeItem('culp_token');
    loginUser({ variables: { id, pw: '1', ingnore_pw: 'yes' } });
  };

  useEffect(() => {
    if (dataLogin?.login) {
      window.localStorage.setItem('culp_token', JSON.stringify(dataLogin.login));
      if (isReturn) {
        window.localStorage.removeItem('culp_return_id');
      }
      if (window.Android && window.Android.SaveUserToken) {
        window.Android.SaveUserToken(JSON.stringify(dataLogin.login));
      }
      isLogedIn(!!dataLogin?.login);
      window.location.href = '/';
    }
    // eslint-disable-next-line
  }, [dataLogin]);
  return loginTo;
};

export const useGetLexileScoreFromApi = (formId, numberCorrect) => {
  const {
    data: getLexileScore,
    loading,
    refetch,
  } = useQuery(GET_LEXILE_SCORE, {
    fetchPolicy: 'no-cache',
    variables: { formId, numberCorrect },
  });
  return getLexileScore;
};

export const useFullscreenStatus = (elRef) => {
  const [isFullscreen, setIsFullscreen] = useState(document[getBrowserFullscreenElementProp()] != null);

  const setFullscreen = (val) => {
    if (elRef.current == null) return;

    if (val === false) {
      try {
        fscreen.exitFullscreen();
      } catch (e) {
        console.log(e);
        setIsFullscreen(false);
      }
    } else {
      if (fscreen.fullscreenEnabled) {
        try {
          fscreen.addEventListener(
            'fullscreenchange',
            () => {
              if (fscreen.fullscreenElement !== null) {
                setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
              } else {
                setIsFullscreen(false);
              }
            },
            false,
          );
          fscreen.requestFullscreen(elRef.current);
        } catch (e) {
          console.log(e);
          setIsFullscreen(false);
        }
      }
    }
  };

  useLayoutEffect(() => {
    document.onfullscreenchange = () => setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);

    return () => (document.onfullscreenchange = undefined);
  });

  return [isFullscreen, setFullscreen];
};

function getBrowserFullscreenElementProp() {
  if (typeof document.fullscreenElement !== 'undefined') {
    return 'fullscreenElement';
  } else if (typeof document.mozFullScreenElement !== 'undefined') {
    return 'mozFullScreenElement';
  } else if (typeof document.msFullscreenElement !== 'undefined') {
    return 'msFullscreenElement';
  } else if (typeof document.webkitFullscreenElement !== 'undefined') {
    return 'webkitFullscreenElement';
  } else {
    throw new Error('fullscreenElement is not supported by this browser');
  }
}

export const useTextToSpeach = (speed, voice) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [texts, setTexts] = useState([]);
  const audio = useRef(new Audio());

  const processTTS = useCallback(
    async (text) => {
      const res = await axios('https://a2vdkjqiwop2mrkfprrzmp7yqq0xdicc.lambda-url.ap-northeast-2.on.aws/', {
        method: 'POST',
        data: {
          text,
          action: 'tts',
          speed: speed,
          voice: voice,
        },
        //speed,voice
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return res.data;
    },
    [speed, voice],
  );

  const speak = async (text, cb, audioPaths = []) => {
    // split id .mp3 exist in text
    setIsSpeaking(true);

    /// convert from markdown to text
    text = text.startsWith('markdown') ? text.split('markdown')[1] : text;

    //regex replace all images
    text = text.replace(/!\[[^\]]*\]\((?<filename>.*?)(?=\"|\))(?<optionalpart>\".*\")?\)/g, '');
    const audioRegex = /\[[^\]]*\]\((?<filename>.*?)(?=\"|\))(?<optionalpart>\".*\")?\)/g;
    let splitText = text.split(audioRegex);

    let textArray = [];
    splitText.forEach((text) => {
      if (text !== '' && text !== undefined) {
        if ((text.endsWith('.mp3') || text.endsWith('.mp4')) && !text.includes('http')) {
          let findIndx = audioPaths.findIndex((path) => path.endsWith(text));
          if (findIndx !== -1) {
            text = audioPaths[findIndx];
          }
        }

        textArray.push(text);
      }
    });

    setTexts(textArray);

    if (cb) {
      cb();
    }
  };

  useEffect(() => {
    if (texts.length > 0) {
      playAudio(0);
    }
  }, [texts]);

  useEffect(() => {
    if (isSpeaking === false) {
      audio.current.pause();
    }
  }, [isSpeaking]);

  const playAudio = async (i) => {
    if (texts.length === 0) return;
    if (texts[i].startsWith('http')) {
      if (texts[i].includes('.mp3')) {
        audio.current.src = texts[i];
      }
    } else {
      let res = await processTTS(texts[i]);
      audio.current.src = res.url;
    }
    audio.current.play();
    audio.current.onended = () => {
      if (texts.length > 1) {
        setTexts(texts.slice(1));
      } else {
        setIsSpeaking(false);
      }
    };
  };

  const stop = () => {
    if (audio.current.src !== '') audio.current.pause();
    setTexts([]);
    setIsSpeaking(false);
  };

  return { isSpeaking, texts, speak, stop };
};

export function useMobileOrientation() {
  const [state, setState] = useState(() => {
    let orientation = window.innerWidth > window.innerHeight ? 90 : 0;
    orientation = window.innerWidth === window.innerHeight ? 90 : orientation;
    return {
      isPortrait: orientation === 0,
      isLandscape: orientation === 90,
      orientation: orientation === 0 ? 'portrait' : 'landscape',
    };
  });
  const handleOrientationChange = useCallback(() => {
    let orientation = window.innerWidth > window.innerHeight ? 90 : 0;
    orientation = window.innerWidth === window.innerHeight ? 90 : orientation;
    const next = {
      isPortrait: orientation === 0,
      isLandscape: orientation === 90,
      orientation: orientation === 0 ? 'portrait' : 'landscape',
    };
    state.orientation !== next.orientation && setState(next);
  }, [state.orientation]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleOrientationChange();
      window.addEventListener('load', handleOrientationChange, false);
      window.addEventListener('resize', handleOrientationChange, false);
    }
    return () => {
      window.removeEventListener('resize', handleOrientationChange, false);
      window.removeEventListener('load', handleOrientationChange, false);
    };
  }, [handleOrientationChange]);
  return state;
}
