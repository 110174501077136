import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import { AI_TOPIC_USER_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import Loading from 'src/components/common/Loading';
import Writing from './Writing';
import Recording from './Recording';

const BookReportStudy = () => {
  const { idx } = useParams();
  const history = useHistory();
  const [showRecording, setShowRecording] = useState(false);
  const [topicData, setTopicData] = useState(null);
  const [level, setLevel] = useState(null);
  const [feedback, setFeedback] = useState(false);
  const [revise, setRevise] = useState(false);
  const [sentences, setSentences] = useState('sentences');
  const [version, setVersion] = useState('short');
  const [chatList, setChatList] = useState(null);
  const [answer, setAnswer] = useState('');
  const [prompt, setPrompt] = useState('');
  const [copyPaste, setCopyPaste] = useState(false);
  const {
    data: dataHistory,
    loading: loadingHistory,
    error: errorHistory,
  } = useQuery(AI_TOPIC_USER_ASSIGNMENT_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      idx: parseInt(idx),
    },
  });

  const handleShowRecording = (ans) => {
    setAnswer(ans);
    setShowRecording(true);
  };

  useEffect(() => {
    if (dataHistory?.aiTopicUserAssignmentDetails) {
      setTopicData({
        ...dataHistory?.aiTopicUserAssignmentDetails?.ai_topic,
        data: JSON.parse(
          dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.data ||
            dataHistory?.aiTopicUserAssignmentDetails?.ai_topic_date_assignment?.data,
        ),
      });

      setAnswer(dataHistory?.aiTopicUserAssignmentDetails?.answer);

      if (dataHistory?.aiTopicUserAssignmentDetails?.status === 2) {
        history.push('/');
      } else if (dataHistory?.aiTopicUserAssignmentDetails?.status === 1) {
        setShowRecording(true);
      }

      if (dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.data) {
        const data = JSON.parse(
          dataHistory?.aiTopicUserAssignmentDetails?.ai_topic_date_assignment?.data ||
            dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.data,
        );
        if (data?.level) {
          setLevel(data?.level);
        }
        if (data?.feedback) {
          setFeedback(data?.feedback);
        }
        if (data?.revise) {
          setRevise(data?.revise);
        }

        if (data?.sentences) {
          setSentences(data?.sentences);
        }

        if (data?.version) {
          setVersion(data?.version);
        }
        if (data?.copyPaste) {
          setCopyPaste(data?.copyPaste);
        }

        const tempPrompt = data?.revisedPrompt || dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.topic || '';

        setPrompt(tempPrompt.replaceAll('\n', '<br />'));
      }

      let resChats = [];
      dataHistory?.aiTopicUserAssignmentDetails?.ai_request_responses.forEach((item) => {
        let curData = item?.data ? JSON.parse(item?.data) : {};
        if (curData.type !== 'word') {
          resChats.push({
            user: userInfoVar()?.id,
            chat_user_name: userInfoVar()?.name,
            content: item.prompt_text,
            urls: [],
            created: item.idate,
            data: curData,
          });
          resChats.push({
            user: 'bot',
            chat_user_name: 'AI봇',
            content: item.completion_text,
            urls: item.image_urls ? JSON.parse(item.image_urls) : [],
            created: item.idate,
            data: curData,
          });
        }
      });
      setChatList(resChats);
    }
  }, [dataHistory]);

  return (
    <>
      {loadingHistory || !chatList || !topicData ? (
        <Loading />
      ) : showRecording ? (
        answer && (
          <Recording
            dataNew={[
              {
                page: 1,
                text: answer,
              },
            ]}
            articleData={topicData}
            idx={idx || null}
            userData={userInfoVar()}
            code={topicData?.level_up_code}
          />
        )
      ) : (
        <Writing
          defautTopic={prompt}
          answer={answer}
          topicData={topicData}
          defaultLevel={level}
          defaultFeedback={feedback}
          defaultRevise={revise}
          defaultSentences={sentences}
          defaultVersion={version}
          defaultChatList={chatList}
          defaultCopyPaste={copyPaste}
          idx={idx}
          onShowRecording={handleShowRecording}
        />
      )}
    </>
  );
};

export default BookReportStudy;
