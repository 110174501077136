import moment from 'moment';
import { isValidElement } from 'react';
import innerText from 'react-innertext';
import { File } from 'better-xlsx';
import { saveAs } from 'file-saver';

import { storage } from 'src/utils/hooks';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import lexileScoreRange from 'src/constants/lexileScoreRange';
export { Color } from './theme';

export function queryAncestors(array, current, parentId, id = 'id') {
  const result = [current];
  const hashMap = new Map();
  array.forEach((item) => hashMap.set(item[id], item));

  const getPath = (current) => {
    const currentParentId = hashMap.get(current[id])[parentId];
    if (currentParentId) {
      result.push(hashMap.get(currentParentId));
      getPath(hashMap.get(currentParentId));
    }
  };

  getPath(current);
  return result;
}

export const saveToken = async (token) => {
  await window.localStorage.removeItem('culp_token');
  await window.localStorage.setItem('culp_token', token);
};

export const saveExternalToken = (token) => {
  window.localStorage.removeItem('culp_external_token');
  window.localStorage.setItem('culp_external_token', token);
};

export const getCurrentToken = () => {
  const culp_token = window.localStorage.getItem('culp_token');
  const { accessToken } = culp_token ? JSON.parse(culp_token) : { accessToken: null };
  return accessToken;
};

export function customDayOfWeek(classInfo) {
  const week = ['일', '월', '화', '수', '목', '금', '토'];

  return [...classInfo?.day_of_week]
    .reduce((srt, value, i) => {
      if (value === '1') {
        srt = [...srt, week[i]];
      }
      return srt;
    }, [])
    .join(', ');
}
export function customDayOfWeekView(classInfoview) {
  const week = ['일', '월', '화', '수', '목', '금', '토'];

  return [...classInfoview?.day_of_week_view]
    .reduce((srt, value, i) => {
      if (value === '1') {
        srt = [...srt, week[i]];
      }
      return srt;
    }, [])
    .join(', ');
}
export const getYearMonthArray = (startYearMonth = '2021-01', offset) => {
  const months = [];
  let start = moment();
  const end = moment(startYearMonth + '-01');

  if (offset) {
    start = start.add(offset, 'months');
  }

  for (let i = start; i.format('YYYY-MM') >= end.format('YYYY-MM'); i = i.subtract(1, 'months')) {
    months.push(i.format('YYYY-MM'));
  }
  return months;
};

export const getMonthDateRange = (date) => {
  const dateArray = date.split('-');
  let startDate = null;
  let endDate = null;
  if (dateArray[1] === '12') {
    startDate = `${dateArray[0]}-${dateArray[1]}-01`;
    endDate = `${dateArray[0]}-${dateArray[1]}-31`;
  } else {
    startDate = moment(dateArray).add(-1, 'month').format('YYYY-MM-DD');
    endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
  }
  return { start: startDate, end: endDate };
};

export const isPermitted = (value, user) => {
  return (
    value?.user?.idx === parseInt(user.idx) ||
    (value?.campus?.idx && [2, 3, 4, 5].includes(parseInt(user.type)) && !value?.company) ||
    (value?.company?.idx && [4, 5].includes(parseInt(user.type)))
  );
};

export const phoneNumberCheck = (org_phone) => {
  let phone = org_phone?.trim()?.replace(/[^0-9]/gi, '');

  if (phone && phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  if (phone && phone.length === 11) {
    return phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }

  if (phone && phone.length === 13) {
    return phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }

  return phone;
};

export const role = (type, path) => {
  const permission = {
    0: {
      notice: false,
      data: false,
      qa: true,
    },
    1: {
      notice: true,
      data: false,
      qa: true,
    },
    2: {
      notice: true,
      data: false,
      qa: true,
    },
    6: {
      notice: true,
      data: false,
      qa: true,
    },
    4: {
      notice: true,
      data: true,
      qa: true,
    },
  };

  return permission[type][path];
};

export const getHumanReadablePhoneNumber = (phoneNum) => {
  //https://devkimgoon.tistory.com/36
  return phoneNum ? phoneNum.toString().replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3') : '';
};

//https://www.codestudyblog.com/sfb20react1/0306012635.html
export function ExportExcel(column, dataSource, fileName = 'example') {
  //  new working spectrum
  const file = new File();
  //  the new table
  let sheet = file.addSheet('sheet-test');
  //  gets the number of header rows
  let depth = getDepth(column);
  //  gets the number of columns in the header
  let columnNum = getColumns(column);
  //  new number of header rows
  let rowArr = [];
  for (let k = 0; k < depth; k++) {
    rowArr.push(sheet.addRow());
  }
  //  populate the cells according to the number of columns
  rowArr.map((ele) => {
    for (let j = 0; j < columnNum; j++) {
      let cell = ele.addCell();
      cell.value = j;
    }
    return true;
  });
  //  initializes the header
  init(column, 0, 0);
  //  unfold the columns in order
  let columnLineArr = [];
  columnLine(column);
  //  according to the column, the dataSource the data inside is sorted and converted into a two-dimensional array
  let dataSourceArr = [];
  dataSource.map((ele) => {
    let dataTemp = [];

    columnLineArr.map((item) => {
      const eachVal =
        ele[item.dataIndex] instanceof Object
          ? isValidElement(ele[item.dataIndex])
            ? innerText(ele[item.dataIndex])
            : '-'
          : ele[item.dataIndex];
      dataTemp.push({
        [item.dataIndex]: eachVal,
        value: eachVal,
      });
      return true;
    });
    dataSourceArr.push(dataTemp);
    return true;
  });
  // debugger;
  //  drawing table data
  dataSourceArr.forEach((item, index) => {
    // according to the data, create the corresponding number of rows
    let row = sheet.addRow();
    row.setHeightCM(0.8);
    // creates a cell for that number
    item.map((ele) => {
      let cell = row.addCell();
      if (ele.hasOwnProperty('num')) {
        cell.value = index + 1;
      } else {
        cell.value = ele.value;
      }
      cell.style.align.v = 'center';
      cell.style.align.h = 'center';
      return true;
    });
  });
  // set the width of each column
  for (var i = 0; i < 4; i++) {
    sheet.col(i).width = 20;
  }
  file.saveAs('blob').then(function (content) {
    saveAs(content, fileName + '.xlsx');
  });

  //  unfold the columns in order
  function columnLine(column) {
    column.map((ele) => {
      if (ele.children === undefined || ele.children.length === 0) {
        columnLineArr.push(ele);
      } else {
        columnLine(ele.children);
      }
      return true;
    });
  }
  //  initializes the header
  function init(column, rowIndex, columnIndex) {
    column.map((item, index) => {
      let hCell = sheet.cell(rowIndex, columnIndex);
      //  if there are no child elements,   all the columns
      if (item.title === ' operation ') {
        hCell.value = '';
        return true;
      } else if (item.children === undefined || item.children.length === 0) {
        //  add a cell to the first row
        hCell.value = item.title;
        hCell.vMerge = depth - rowIndex - 1;
        hCell.style.align.h = 'center';
        hCell.style.align.v = 'center';
        columnIndex++;
        // rowIndex++
      } else {
        let childrenNum = 0;
        function getColumns(arr) {
          arr.map((ele) => {
            if (ele.children) {
              getColumns(ele.children);
            } else {
              childrenNum++;
            }
            return true;
          });
        }
        getColumns(item.children);
        hCell.hMerge = childrenNum - 1;
        hCell.value = item.title;
        hCell.style.align.h = 'center';
        hCell.style.align.v = 'center';
        let rowCopy = rowIndex;
        rowCopy++;
        init(item.children, rowCopy, columnIndex);
        //  next cell start
        columnIndex = columnIndex + childrenNum;
      }
      return true;
    });
  }
  //  gets table head rows
  function getDepth(arr) {
    const eleDepths = [];
    arr.forEach((ele) => {
      let depth = 0;
      if (Array.isArray(ele.children)) {
        depth = getDepth(ele.children);
      }
      eleDepths.push(depth);
    });
    return 1 + max(eleDepths);
  }

  function max(arr) {
    return arr.reduce((accu, curr) => {
      if (curr > accu) return curr;
      return accu;
    });
  }
  //  calculates the number of header columns
  function getColumns(arr) {
    let columnNum = 0;
    arr.map((ele) => {
      if (ele.children) {
        getColumns(ele.children);
      } else {
        columnNum++;
      }
      return true;
    });
    return columnNum;
  }
}

export function ExportExcelAnswerSheet(column, dataSource, childColumn, fileName = 'example') {
  //  new working spectrum
  const file = new File();
  //  the new table
  let sheet = file.addSheet('sheet-test');
  //  gets the number of header rows
  let depth = getDepth(column);
  //  gets the number of columns in the header
  let columnNum = getColumns(column);
  //  new number of header rows
  let rowArr = [];
  for (let k = 0; k < depth; k++) {
    rowArr.push(sheet.addRow());
  }
  //  populate the cells according to the number of columns
  rowArr.map((ele) => {
    for (let j = 0; j < columnNum; j++) {
      let cell = ele.addCell();
      cell.value = j;
    }
    return true;
  });
  //  initializes the header
  init(column, 0, 0);
  if (childColumn) {
    initSecondRowColumn(column, 1, 1);
  }
  // init2(finalfooterColumn, dataSource?.length + 1, 1);
  //  unfold the columns in order
  let columnLineArr = [];
  columnLine(column);
  //  according to the column, the dataSource the data inside is sorted and converted into a two-dimensional array
  let dataSourceArr = [];
  dataSource.map((ele) => {
    let dataTemp = [];
    columnLineArr.map((item, indx) => {
      let eachVal;
      if (ele[item.dataIndex] instanceof Object) {
        if (isValidElement(ele[item.dataIndex])) {
          if (innerText(ele[item.dataIndex])) {
            eachVal = '-';
          }
        }
      } else {
        if (parseInt(item.dataIndex)) {
          if (ele?.ans?.length > 0) {
            if (childColumn) {
              eachVal = ele?.ans[item.i]?.[`q${item.i + 1}`];
            } else {
              eachVal = ele?.ans[item.i]?.[`q${item.i + 1}`] === parseInt(item.answer) ? 'O' : 'X';
            }
          } else {
            eachVal = '-';
          }
        } else {
          if (ele[item.dataIndex] === undefined) {
            eachVal = '-';
          } else {
            eachVal = ele[item.dataIndex];
          }
        }
      }

      dataTemp.push({
        [item.dataIndex]: eachVal,
        value: eachVal,
      });
      return true;
    });
    dataSourceArr.push(dataTemp);
    return true;
  });
  // debugger;
  //  drawing table data
  dataSourceArr.forEach((item, index) => {
    // according to the data, create the corresponding number of rows
    let row = sheet.addRow();

    row.setHeightCM(0.8);
    // creates a cell for that number
    item.map((ele) => {
      let cell = row.addCell();
      if (ele.hasOwnProperty('num')) {
        cell.value = index + 1;
      } else {
        cell.value = ele.value;
      }
      cell.style.align.v = 'center';
      cell.style.align.h = 'center';
      return true;
    });
  });
  // set the width of each column
  for (var i = 0; i < 4; i++) {
    sheet.col(i).width = 20;
  }
  file.saveAs('blob').then(function (content) {
    saveAs(content, fileName + '.xlsx');
  });

  //  unfold the columns in order
  function columnLine(column) {
    column.map((ele) => {
      if (ele.children === undefined || ele.children.length === 0) {
        columnLineArr.push(ele);
      } else {
        columnLine(ele.children);
      }
      return true;
    });
  }
  //  initializes the header
  function init(column, rowIndex, columnIndex) {
    column.map((item, index) => {
      let hCell = sheet.cell(rowIndex, columnIndex);
      //  if there are no child elements,   all the columns
      if (item.title === ' operation ') {
        hCell.value = '';
        return true;
      } else if (item.children === undefined || item.children.length === 0) {
        //  add a cell to the first row
        hCell.value = item.title;
        hCell.vMerge = depth - rowIndex - 1;
        hCell.style.align.h = 'center';
        hCell.style.align.v = 'center';
        columnIndex++;
        // rowIndex++
      } else {
        let childrenNum = 0;
        function getColumns(arr) {
          arr.map((ele) => {
            if (ele.children) {
              getColumns(ele.children);
            } else {
              childrenNum++;
            }
            return true;
          });
        }
        getColumns(item.children);
        hCell.hMerge = childrenNum - 1;
        hCell.value = item.title;
        hCell.style.align.h = 'center';
        hCell.style.align.v = 'center';
        let rowCopy = rowIndex;
        rowCopy++;
        init(item.children, rowCopy, columnIndex);
        columnIndex = columnIndex + childrenNum;
      }
      return true;
    });
  }
  function initSecondRowColumn(column, rowIndex, columnIndex) {
    column.map((item, index) => {
      if (item.i || item.i === 0) {
        let hCell = sheet.cell(rowIndex, index);
        hCell.value = item.title;
        hCell.vMerge = depth - rowIndex - 1;
        hCell.style.align.h = 'center';
        hCell.style.align.v = 'center';
      }
      return true;
    });
  }

  // function init2(column, rowIndex, columnIndex) {
  //   column.map((item, index) => {
  //     const entries = Object.entries(item);
  //     const key = entries[0][0];
  //     const value = entries[0][1];

  //     // let hCell = sheet.cell(rowIndex, columnIndex);
  //     //  if there are no child elements,   all the columns
  //     // if (item.i || item.i === 0) {
  //       let hCell = sheet.cell(rowIndex, parseInt(key));
  //       hCell.value = item.value;
  //       hCell.vMerge = depth - rowIndex - 1;
  //       hCell.style.align.h = 'center';
  //       hCell.style.align.v = 'center';
  //       // columnIndex++;
  //       // index ++;
  //     // }
  //     return true;
  //   });
  // }

  //  gets table head rows
  function getDepth(arr) {
    const eleDepths = [];
    arr.forEach((ele) => {
      let depth = 0;
      if (Array.isArray(ele.children)) {
        depth = getDepth(ele.children);
      }
      eleDepths.push(depth);
    });
    return 1 + max(eleDepths);
  }

  function max(arr) {
    return arr.reduce((accu, curr) => {
      if (curr > accu) return curr;
      return accu;
    });
  }
  //  calculates the number of header columns
  function getColumns(arr) {
    let columnNum = 0;
    arr.map((ele) => {
      if (ele.children) {
        getColumns(ele.children);
      } else {
        columnNum++;
      }
      return true;
    });
    return columnNum;
  }
}

export function ExportExcelUserSheet(column, dataSource, fileName = 'example') {
  //  new working spectrum
  const file = new File();
  //  the new table
  let sheet = file.addSheet('sheet-test');
  //  gets the number of header rows
  let depth = getDepth(column);
  //  gets the number of columns in the header
  let columnNum = getColumns(column);
  //  new number of header rows
  let rowArr = [];
  for (let k = 0; k < depth; k++) {
    rowArr.push(sheet.addRow());
  }
  //  populate the cells according to the number of columns
  rowArr.map((ele) => {
    for (let j = 0; j < columnNum; j++) {
      let cell = ele.addCell();
      cell.value = j;
    }
    return true;
  });
  //  initializes the header
  init(column, 0, 0);
  let columnLineArr = [];
  columnLine(column);
  let dataSourceArr = [];
  dataSource.map((ele, i) => {
    let dataTemp = [];
    columnLineArr.map((item, indx) => {
      let eachVal;
      if (ele[item.dataIndex] instanceof Object) {
        if (isValidElement(ele[item.dataIndex])) {
          if (innerText(ele[item.dataIndex])) {
            eachVal = '-';
          }
        }
      } else {
        if (item.dataIndex === 'Test') {
          if (ele?.test[item.key - 1].score === null) {
            eachVal = '-';
          } else {
            eachVal = ele?.test[item.key - 1].score;
          }
        } else {
          eachVal = ele[item.dataIndex];
        }
      }

      dataTemp.push({
        [item.dataIndex]: eachVal,
        value: eachVal,
      });
      return true;
    });
    dataSourceArr.push(dataTemp);
    return true;
  });
  // debugger;
  //  drawing table data
  dataSourceArr.forEach((item, index) => {
    // according to the data, create the corresponding number of rows
    let row = sheet.addRow();

    row.setHeightCM(0.8);
    // creates a cell for that number
    item.map((ele) => {
      let cell = row.addCell();
      if (ele.hasOwnProperty('num')) {
        cell.value = index + 1;
      } else {
        cell.value = ele.value;
      }
      cell.style.align.v = 'center';
      cell.style.align.h = 'center';
      return true;
    });
  });
  // set the width of each column
  for (var i = 0; i < 4; i++) {
    sheet.col(i).width = 20;
  }
  file.saveAs('blob').then(function (content) {
    saveAs(content, fileName + '.xlsx');
  });

  //  unfold the columns in order
  function columnLine(column) {
    column.map((ele) => {
      if (ele.children === undefined || ele.children.length === 0) {
        columnLineArr.push(ele);
      } else {
        columnLine(ele.children);
      }
      return true;
    });
  }
  //  initializes the header
  function init(column, rowIndex, columnIndex) {
    column.map((item, index) => {
      let hCell = sheet.cell(rowIndex, columnIndex);
      //  if there are no child elements,   all the columns
      if (item.title === ' operation ') {
        hCell.value = '';
        return true;
      } else if (item.children === undefined || item.children.length === 0) {
        //  add a cell to the first row
        hCell.value = item.title;
        hCell.vMerge = depth - rowIndex - 1;
        hCell.style.align.h = 'center';
        hCell.style.align.v = 'center';
        columnIndex++;
        // rowIndex++
      } else {
        let childrenNum = 0;
        function getColumns(arr) {
          arr.map((ele) => {
            if (ele.children) {
              getColumns(ele.children);
            } else {
              childrenNum++;
            }
            return true;
          });
        }
        getColumns(item.children);
        hCell.hMerge = childrenNum - 1;
        hCell.value = item.title;
        hCell.style.align.h = 'center';
        hCell.style.align.v = 'center';
        let rowCopy = rowIndex;
        rowCopy++;
        init(item.children, rowCopy, columnIndex);
        columnIndex = columnIndex + childrenNum;
      }
      return true;
    });
  }
  function initSecondRowColumn(column, rowIndex, columnIndex) {
    column.map((item, index) => {
      if (item.i || item.i === 0) {
        let hCell = sheet.cell(rowIndex, index);
        hCell.value = item.title;
        hCell.vMerge = depth - rowIndex - 1;
        hCell.style.align.h = 'center';
        hCell.style.align.v = 'center';
      }
      return true;
    });
  }

  function getDepth(arr) {
    const eleDepths = [];
    arr.forEach((ele) => {
      let depth = 0;
      if (Array.isArray(ele.children)) {
        depth = getDepth(ele.children);
      }
      eleDepths.push(depth);
    });
    return 1 + max(eleDepths);
  }

  function max(arr) {
    return arr.reduce((accu, curr) => {
      if (curr > accu) return curr;
      return accu;
    });
  }
  //  calculates the number of header columns
  function getColumns(arr) {
    let columnNum = 0;
    arr.map((ele) => {
      if (ele.children) {
        getColumns(ele.children);
      } else {
        columnNum++;
      }
      return true;
    });
    return columnNum;
  }
}

export const isBelongToStageGroup = (stageArray, stage) => {
  return stageArray.includes(stage);
};

export const loginTo = (id, client, loginUser, isReturn = false) => {
  if (!isReturn) {
    storage.setItem('culp_return_id', userInfoVar().id);
  }
  isLogedIn(false);
  userInfoVar(null);
  client.clearStore();
  client.cache.gc();
  storage.removeItem('culp_token');
  loginUser({ variables: { id, pw: '1', ingnore_pw: 'yes' } });
};

export const koreanNumberFormat = (number) => {
  return new Intl.NumberFormat('ko-KR').format(number);
};

export const getDifferentTitle = (book_code) => {
  const BOOK_MAP = new Map([
    ['SM-L0V1', 'Coco Phonics 1'],
    ['SM-L0V2', 'Coco Phonics 2'],
    ['SM-L0V3', 'Coco Phonics 3'],
    ['SM-L0V4', 'Coco Phonics 4'],
    ['SM-L0V5', 'Coco Phonics 5'],
    ['SM-L0V6', 'Coco Phonics 6'],
    ['SM-L0V7', 'Super Coco Phonics 1'],
    ['SM-L0V8', 'Super Coco Phonics 2'],
    ['SM-L0V9', 'Super Coco Phonics 3'],
    ['SK-L0V1', 'Coco Phonics 1'],
    ['SK-L0V2', 'Coco Phonics 2'],
    ['SK-L0V3', 'Coco Phonics 3'],
    ['SK-L0V4', 'Coco Phonics 4'],
    ['SK-L0V5', 'Coco Phonics 5'],
    ['SK-L0V6', 'Coco Phonics 6'],
    ['SK-L0V7', 'Super Coco Phonics 1'],
    ['SK-L0V8', 'Super Coco Phonics 2'],
    ['SK-L0V9', 'Super Coco Phonics 3'],
    ['TCL-L1V1', 'THE CLASS Reading Bridge-1'],
    ['TCL-L1V2', 'THE CLASS Reading Bridge-2'],
    ['TCL-L1V3', 'THE CLASS Reading Bridge-3'],
    ['TCL-L1V4', 'THE CLASS Listening Bridge-1'],
    ['TCL-L1V5', 'THE CLASS Listening Bridge-2'],
    ['TCL-L1V6', 'THE CLASS Listening Bridge-3'],
  ]);
  if (BOOK_MAP.has(book_code)) {
    return BOOK_MAP.get(book_code);
  }
  return 'undefined';
};

export const isDifferentTitle = (root_book_code, level_no) => {
  if (root_book_code === 'TCL') {
    return true;
  }
  if (['SM', 'SK'].includes(root_book_code) && level_no === 0) {
    return true;
  }
  return false;
};

export function rank(value, arr) {
  const sorted = arr.slice().sort(function (a, b) {
    return b - a;
  });
  const rank = sorted.indexOf(value);
  if (rank > -1) return rank + 1;
  return null;
}

export const captureUserMedia = (params, callback) => {
  navigator.mediaDevices.getUserMedia =
    navigator.mediaDevices?.getUserMedia ||
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;
  navigator.mediaDevices
    .getUserMedia(params)
    .then(callback)
    .catch((error) => {
      console.log(error);
      alert('녹음 준비 중 에러가 발생했습니다. 마이크나 스피커가 연결되어 있는지 확인 후 다시 이용해주세요.');
    });
};

export const captureDisplayMedia = (params, success) => {
  if (navigator.mediaDevices.getDisplayMedia) {
    navigator.mediaDevices
      .getDisplayMedia(params)
      .then(success)
      .catch((error) => {
        alert('녹음 준비 중 에러가 발생했습니다. 마이크나 스피커가 연결되어 있는지 확인 후 다시 이용해주세요.');
      });
  } else {
    navigator
      .getDisplayMedia(params)
      .then(success)
      .catch((error) => {
        alert('녹음 준비 중 에러가 발생했습니다. 마이크나 스피커가 연결되어 있는지 확인 후 다시 이용해주세요.');
      });
  }
};

export const isAfterSchool = (companyName) => {
  return ['smart-eclass', 'sneakersedu'].includes(companyName);
};
export const isPturn = (companyName) => {
  return ['smart-eclass', 'sneakersedu', 'englishtap'].includes(companyName);
};

export const isLucid = (companyName) => {
  return ['lucid'].includes(companyName);
};

export const isShowLessonDetail = (companyName) => {
  return ['smart-eclass', 'sneakersedu', 'creo', 'lucid'].includes(companyName);
};

export const isAllowExternalStudy = (companyName) => {
  return ['w_english', 'default', 'creo', 'cleveredu2', 'cleveredu', 'haveenglish', 'classboom', 'poppins'].includes(companyName);
};

export const getDashboardTitle = (companyName) => {
  switch (companyName) {
    case 'smart-eclass':
    case 'sneakersedu':
    case 'englishtap':
      return '스터디보드';
    case 'creo':
      return 'Dashboard';
    case 'w_english':
      return 'Board';
    default:
      return '대시보드';
  }
};

export const getByteLength = (s) => {
  let b = 0,
    i = 0,
    c;
  //for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1);//1글자당 3바이트인 경우
  for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 7 ? 2 : 1); //1글자당 2바이트인 경우
  return b;
};

export const checkIfCampusUser = (userType) => {
  return userType === 2 || userType === 6;
};
export const checkTeacher = (userType) => {
  return userType === 1;
};

export const getMetaExamId = (birthday, phone) => {
  const idFields = birthday.split('T')[0].split('-');
  const id = idFields[0].slice(-2) + idFields[1] + idFields[2] + '-' + phone.slice(3);
  return id;
};

export const getLixileScoreObject = (lexileScore, type) => {
  for (let i = 0; i < lexileScoreRange.mx_levels[type].length; i++) {
    const item = lexileScoreRange.mx_levels[type][i];
    if (lexileScore >= item.low_range && lexileScore <= item.high_range) {
      return item;
    }
  }
  return {};
};
export const getCefr = (lexileScore) => {
  for (let i = 0; i < lexileScoreRange.cefr_levels.length; i++) {
    const item = lexileScoreRange.cefr_levels[i];
    if (lexileScore >= item.low_range && lexileScore <= item.high_range) {
      return item;
    }
  }
  return {};
};

export const parseBookCode = (code) => {
  if (typeof code !== 'string') return false;

  const arr = code.split('-');
  if (arr[1]) {
    const codes = arr[1].match(/\d+/g);
    if (codes.length === 4) {
      return {
        level: codes[0],
        volume: codes[1],
      };
    }
    const level = arr[1].substr(1, 1);
    const volume = arr[1].substr(3, 1);
    return {
      level,
      volume,
    };
  }

  return false;
};

export const parseLessonCode = (code) => {
  if (typeof code !== 'string') return false;

  if (code.length < 11) {
    return false;
  }

  const arr = code.split('-');
  if (arr[1]) {
    const codes = arr[1].match(/\d+/g);
    if (codes.length === 4) {
      return {
        level: codes[0],
        volume: codes[1],
        unit: codes[2],
        day: codes[3],
      };
    }
    const level = arr[1].substr(1, 1);
    const volume = arr[1].substr(3, 1);
    const unit = arr[1].substr(5, 1);
    const day = arr[1].substr(7, 1);
    return {
      level,
      volume,
      unit,
      day,
    };
  }

  return false;
};

export const getBookGroupLevelLabels = (category) => {
  const BOOK_GROUP_LEVEL_MAP = new Map([
    ['Soil', [{ label: 'Phonics', value: 'Soil_' }]],
    ['Seed', [{ label: 'Seed', value: 'Seed_' }]],
    [
      'Root',
      [
        { label: 'Root_A', value: 'Root_A' },
        { label: 'Root_B', value: 'Root_B' },
      ],
    ],
    [
      'Leaf',
      [
        { label: 'Leaf_A', value: 'Leaf_A' },
        { label: 'Leaf_B', value: 'Leaf_B' },
        { label: 'Leaf_C', value: 'Leaf_C' },
      ],
    ],
    [
      'Stem',
      [
        { label: 'Stem_A', value: 'Stem_A' },
        { label: 'Stem_B', value: 'Stem_B' },
        { label: 'Stem_C', value: 'Stem_C' },
      ],
    ],
    [
      'Flower',
      [
        { label: 'Flower_A', value: 'Flower_A' },
        { label: 'Flower_B', value: 'Flower_B' },
      ],
    ],
    [
      'Fruit',
      [
        { label: 'Fruit_A', value: 'Fruit_A' },
        { label: 'Fruit_B', value: 'Fruit_B' },
      ],
    ],
  ]);
  if (BOOK_GROUP_LEVEL_MAP.has(category)) {
    return BOOK_GROUP_LEVEL_MAP.get(category);
  }
  return 'undefined';
};

export const getBookGroupVolumeLabels = (category) => {
  const BOOK_GROUP_LEVEL_MAP = new Map([
    [
      'Soil',
      [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
      ],
    ],
    [
      'Seed',
      [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
      ],
    ],
    [
      'Root',
      [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
      ],
    ],
    [
      'Leaf',
      [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
      ],
    ],
    [
      'Stem',
      [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
      ],
    ],
    [
      'Flower',
      [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
      ],
    ],
    [
      'Fruit',
      [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
      ],
    ],
  ]);
  if (BOOK_GROUP_LEVEL_MAP.has(category)) {
    return BOOK_GROUP_LEVEL_MAP.get(category);
  }
  return 'undefined';
};

export const getStudentBookGroupDivisionLabels = (category) => {
  const BOOK_GROUP_DIVISION_MAP = new Map([
    ['Soil', [{ label: 'Fun', value: '' }]],
    ['Seed', [{ label: '2종(R,LS)', value: 'R,L&S' }]],
    [
      'Root',
      [
        { label: '3종', value: 'R,L&S,W&G' },
        { label: '2종(R,LS)', value: 'R,L&S' },
        { label: '2종(R,WG)', value: 'R,W&G' },
        { label: '2종(LS,WG)', value: 'L&S,W&G' },
      ],
    ],
    [
      'Leaf',
      [
        { label: '3종', value: 'R,L&S,W&G' },
        { label: '2종(R,LS)', value: 'R,L&S' },
        { label: '2종(R,WG)', value: 'R,W&G' },
        { label: '2종(LS,WG)', value: 'L&S,W&G' },
      ],
    ],
    [
      'Stem',
      [
        { label: '3종', value: 'R,L&S,W&G' },
        { label: '2종(R,LS)', value: 'R,L&S' },
        { label: '2종(R,WG)', value: 'R,W&G' },
        { label: '2종(LS,WG)', value: 'L&S,W&G' },
      ],
    ],
    [
      'Flower',
      [
        { label: '3종', value: 'R,L&S,W&G' },
        { label: '2종(R,LS)', value: 'R,L&S' },
        { label: '2종(R,WG)', value: 'R,W&G' },
        { label: '2종(LS,WG)', value: 'L&S,W&G' },
      ],
    ],
    [
      'Fruit',
      [
        { label: '3종', value: 'R,L&S,W&G' },
        { label: '2종(R,LS)', value: 'R,L&S' },
        { label: '2종(R,WG)', value: 'R,W&G' },
        { label: '2종(LS,WG)', value: 'L&S,W&G' },
      ],
    ],
  ]);
  if (BOOK_GROUP_DIVISION_MAP.has(category)) {
    return BOOK_GROUP_DIVISION_MAP.get(category);
  }
  return 'undefined';
};

export const getReserchBookGroupDivisionLabels = (category) => {
  const BOOK_GROUP_DIVISION_MAP = new Map([
    ['Soil', [{ label: 'Fun', value: '' }]],
    [
      'Seed',
      [
        { label: 'Reading', value: 'R' },
        { label: 'LS', value: 'L&S' },
      ],
    ],
    [
      'Root',
      [
        { label: 'Reading', value: 'R' },
        { label: 'L&S', value: 'L&S' },
        { label: 'W&G', value: 'W&G' },
      ],
    ],
    [
      'Leaf',
      [
        { label: 'Reading', value: 'R' },
        { label: 'L&S', value: 'L&S' },
        { label: 'W&G', value: 'W&G' },
      ],
    ],
    [
      'Stem',
      [
        { label: 'Reading', value: 'R' },
        { label: 'L&S', value: 'L&S' },
        { label: 'W&G', value: 'W&G' },
      ],
    ],
    [
      'Flower',
      [
        { label: 'Reading', value: 'R' },
        { label: 'L&S', value: 'L&S' },
        { label: 'W&G', value: 'W&G' },
      ],
    ],
    [
      'Fruit',
      [
        { label: 'Reading', value: 'R' },
        { label: 'L&S', value: 'L&S' },
        { label: 'W&G', value: 'W&G' },
      ],
    ],
  ]);
  if (BOOK_GROUP_DIVISION_MAP.has(category)) {
    return BOOK_GROUP_DIVISION_MAP.get(category);
  }
  return 'undefined';
};

export const BOOK_REPORT_LEVEL_LIST = {
  'Grade 1': 'Basic A',
  'Grade 4': 'Basic B',
  'Grade 7': 'Intermediate',
  'Grade 10': 'Advanced',
  'College Level': 'College',
};
