import React, { useState, useEffect } from 'react';
import SoundEffectPM from './SoundEffectPM';
import { isVowel } from '../../utils/pm/util';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const correctSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/great.mp3';
const wrongSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/try-again.mp3';
const woopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';
let bookNameUnitStage = undefined;
let bookNameUnitStageChecklist = undefined;
let bookNameUnitStageAdd = undefined;
const MAX_WRONG_COUNT = 2;
const ImageWordBox = ({ userInputWord, problem, onFinish, steps, setSteps, hint }) => {
  const [image, setImage] = useState();
  const [word, setWord] = useState();
  const [isShow, setShow] = useState(false);
  const [correctOrWrongSound, setCorrectOrWrongSound] = useState(undefined);
  const [wordSound, setWordSound] = useState('');
  const [wannaShowHint, setWannaShowHint] = useState(false);
  const [wrongCount, setWrongCount] = useState(0);

  const { level, unit, stage } = useParams();
console.log( level, unit, stage,' level, unit, stage')
  if (level == '3a' && (unit == 1 || 2) && (stage == 2 || 4 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  if (level == '3b' && (unit == 6 || 7 || 8) && (stage == 2 || 4 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  if (level == '3b' && unit == 9 && stage == 6) {
    bookNameUnitStage = true;
    bookNameUnitStageChecklist = { e: true };
    bookNameUnitStageAdd = true;
  }

  const handleSoundEnd = () => {
    if ((correctOrWrongSound === correctSound || correctOrWrongSound === woopsSound) && !_.isEmpty(word)) {
      setWordSound(`https://cdn.cloubot.com/PM/audio/sounds/${word === 'june' ? 'June' : word}.mp3`);
    }
    setCorrectOrWrongSound(undefined);
  };

  const handleWordSoundEnd = () => {
    setShow(false);
    setSteps((prev) => prev + 1);
  };

  useEffect(() => {
    if (word && userInputWord) {
      if (word === userInputWord) {
        setWrongCount(0);
        setShow(true);
        setCorrectOrWrongSound(correctSound);
        // setTimeout(() => {
        // 	setShow(false);
        // 	setSteps(prev => prev + 1);
        // }, 1000);
      } else {
        if (wrongCount > 0) {
          setWannaShowHint(true);
          setShow(true);
          setWrongCount(0);
          setCorrectOrWrongSound(woopsSound);
        } else {
          setCorrectOrWrongSound(wrongSound);
          setWrongCount((prev) => prev + 1);
        }
      }
    }
    // eslint-disable-next-line
  }, [userInputWord]);

  useEffect(() => {
    setWannaShowHint(false);
    if (problem && steps > problem.length - 1) onFinish();
    // eslint-disable-next-line
  }, [steps]);

  useEffect(() => {
    if (problem instanceof Array && problem.length > 0 && steps < problem.length) {
      setImage(problem[steps].image);
      setWord(problem[steps].word);
    }
  }, [problem, steps]);

  return (
    <>
      <ImgBox className="img_box">
        <img style={{ width: '100%', objectFit: 'contain' }} src={image} alt="보기 이미지" />
      </ImgBox>
      <S.AlphabetWrap style={{ paddig: 0 }} className="alphabet_wrap">
        {word &&
          word.split('').map((item, key) => (
            <li style={{ marginLeft: 16 }} key={`charator_list_${key}`}>
              <AlphabetBox className="alphabet_box row1 yellow">
                {bookNameUnitStage ? (
                  bookNameUnitStageAdd ? (
                    <span
                      style={{ color: bookNameUnitStageChecklist[item] == true ? '#f368e0' : '#2e86de' }}
                      className={bookNameUnitStageChecklist[item] == true ? 'font_pink' : 'font_blue'}
                    >
                      {wannaShowHint && key === 0 ? item : undefined || isShow ? item : undefined}
                    </span>
                  ) : (
                    <span
                      style={{ color:key == 0 ? '#2e86de' : '#f368e0' }}
                      className={ key == 0 ? 'font_blue' : 'font_pink'}
                    >
                      {wannaShowHint && key === 0 ? item : undefined || isShow ? item : undefined}
                    </span>
                  )
                ) : (
                  <span style={{ color: isVowel(item) ? '#f368e0' : '#2e86de' }} className={isVowel(item) ? 'font_pink' : 'font_blue'}>
                    {wannaShowHint && key === 0 ? item : undefined || isShow ? item : undefined}
                  </span>
                )}
              </AlphabetBox>
            </li>
          ))}
      </S.AlphabetWrap>
      <SoundEffectPM url={correctOrWrongSound} onEnd={handleSoundEnd} />
      <SoundEffectPM url={wordSound} onEnd={handleWordSoundEnd} replayCnt={2} />
    </>
  );

  // return (
  // 	<>
  // 		<div className="cont_hori_write">
  // 			<div className="cont_hori_img_box">
  // 				<p>
  // 					<img src={image} alt="example img" />
  // 				</p>
  // 			</div>
  // 			<div className="cont_hori_block block_box">
  // 				<ul className="block_align">
  // 					{word &&
  // 						word.split("").map((item, key) => (
  // 							<li key={`charator_list_${key}`} className="block_yellow">
  // 								<span className={isVowel(item) ? "text_red" : "text_default"}>
  // 									{wannaShowHint && key === 0 ? item : undefined || isShow ? item : undefined}
  // 								</span>
  // 							</li>
  // 						))}
  // 				</ul>
  // 				{hint ? (
  // 					<p className="hint" onClick={() => setWannaShowHint(true)}>
  // 						<span className="btn_hint" />
  // 					</p>
  // 				) : (
  // 					undefined
  // 				)}
  // 			</div>
  // 		</div>
  // 		<SoundEffectPM url={correctOrWrongSound} onEnd={handleSoundEnd} />
  // 		<SoundEffectPM url={wordSound} onEnd={handleWordSoundEnd} replayCnt={2} />
  // 	</>
  // );
};

export default ImageWordBox;
const ImgBox = styled(S.RowFlex)`
  justify-content: center;
  align-items: center;
  width: 25%;
  border-radius: 16px;
  border: 3px solid #eee;
`;
const AlphabetBox = styled(S.AlphabetBox)`
  border: 0.4rem solid #feca57;
  box-shadow: 0 0.4rem 0 #ff9f43;
`;
