import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Checkbox } from 'antd';
import styled, { css } from 'styled-components';

import { Color } from 'src/utils/theme';
import { useForm } from 'src/utils/hooks';
import { LOGIN_USER } from 'src/operations/queries/getUser';

import { isLogedIn } from 'src/apollo/cache';
import Loading from 'src/components/common/Loading';
import { storage } from 'src/utils/hooks';
import queryString from 'query-string';
import Footer from 'src/components/Footer';
import { settings } from 'src/dummy/settings.js';
const LoginForm = styled.div`
  background-color: #fff;
  width: 476px;
  height: 462px;
  box-shadow: 2px 1px 15px 10px #dddddd;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const Logo = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${Color.borderBase};
  margin-top: 3rem;
`;

const LogoImageContainer = styled.div`
  width: 80%;
  background: ${Color.borderBase};
  border-radius: 10px;
  margin-top: 3rem;
`;

const LoginLogoImage = styled.div`
  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}

  background-size: contain;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
`;

const Login = () => {
  const history = useHistory();
  const [err, setErr] = useState('');
  const [campuses, setCampuses] = useState([]);
  const [loginType, setLoginType] = useState('normal');
  const parsed = queryString.parse(window.location.search.replace('?', ''));
  const cidx = parseInt(parsed.campus_idx);
  const subdomain = useMemo(() => {
    if (window.location.hostname) {
      return window.location.hostname.split('.')[0];
    }
    return 'default';
  }, []);
  const companySettings = useMemo(() => {
    return settings[subdomain] || settings.default;
  }, [subdomain]);

  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
    id: storage.getItem('culp_userid') || '',
    pw: storage.getItem('culp_pw') || '',
    campus_idx: cidx || 3,
    remember: storage.getItem('culp_userid') ? true : false,
  });

  const [loginUser, { loading, data, error }] = useLazyQuery(LOGIN_USER);

  const onDirectLogin = async (id, campus_id = null) => {
    let res = await fetch(`https://culp-api.cloubot.com/${id}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: values.id,
        pw: values.pw,
        campus_id: campus_id,
      }),
    });
    res = await res.json();
    console.log(res);
    if (res.success) {
      storage.setItem('culp_token', JSON.stringify(res.token));
      if (window.Android && window.Android.SaveUserToken) {
        window.Android.SaveUserToken(JSON.stringify(res.token));
      }
      isLogedIn(!!res.token);
      window.location.reload();

      if (values.remember) {
        storage.setItem('culp_userid', values.id);
        storage.setItem('culp_pw', values.pw);
      } else {
        storage.removeItem('culp_userid');
        storage.removeItem('culp_pw');
      }
    } else {
      if (res.campuses && res.campuses.length > 0) {
        setCampuses(res.campuses);
      } else {
        setErr(res.message);
        loginUser({
          variables: values,
        });
      }

      setTimeout(() => {
        setErr('');
      }, 2000);
    }
  };

  function loginUserCallback() {
    if (loginType === 'creo' || loginType === 'gnb2') {
      onDirectLogin(loginType);
    } else {
      loginUser({
        variables: values,
      });
    }
  }

  useEffect(() => {
    if (data?.login) {
      storage.setItem('culp_token', JSON.stringify(data.login));
      if (window.Android && window.Android.SaveUserToken) {
        window.Android.SaveUserToken(JSON.stringify(data.login));
      }
      isLogedIn(!!data?.login);
      window.location.reload();
    }

    if (values.remember) {
      storage.setItem('culp_userid', values.id);
      storage.setItem('culp_pw', values.pw);
    } else {
      storage.removeItem('culp_userid');
      storage.removeItem('culp_pw');
    }
  }, [data, history, values]);

  useEffect(() => {
    if (error) {
      setErr(error.message);
      setTimeout(() => {
        setErr('');
      }, 2000);
    }
  }, [error]);

  useEffect(() => {
    storage.removeItem('culp_return_id');
  }, []);

  if (isLogedIn()) {
    history.replace(subdomain === 'gnb2' || subdomain === 'educloud' ? '/level-up' : '/');
  }

  useEffect(() => {
    if (subdomain === 'creo' && window.ReactNativeWebView) {
      setLoginType('creo');
    }
    if (subdomain === 'gnb2') {
      setLoginType('gnb2');
    }
  }, [subdomain]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="mainlogin-wrappper">
            <LoginForm>
              {companySettings ? (
                <>
                  <LogoImageContainer>
                    <LoginLogoImage
                      image={companySettings?.loginLogo || companySettings.logo}
                      onClick={() => {
                        setLoginType('normal');
                      }}
                    />
                  </LogoImageContainer>
                </>
              ) : (
                <>
                  <Logo />
                  <h1>Company Name</h1>
                </>
              )}

              <h4 style={{ color: 'red' }}>{err && err}</h4>
              <Form
                className="login-form"
                onFinish={onSubmit}
                initialValues={{
                  userid: values.id,
                  password: values.pw,
                }}
              >
                <Form.Item
                  style={{ width: 240 }}
                  name="userid"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '아이디를 입력해 주세요.',
                    },
                  ]}
                >
                  {<Input placeholder="ID" size="large" name="id" onChange={onChange} />}
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '비밀번호를 입력해 주세요.',
                    },
                  ]}
                  name="password"
                >
                  {<Input type="password" placeholder="PW" size="large" name="pw" onChange={onChange} />}
                </Form.Item>
                <Form.Item name="remember">
                  <Checkbox name="remember" onChange={onChange} checked={values.remember}>
                    Remember me
                  </Checkbox>
                </Form.Item>
                {!campuses.length && (
                  <Form.Item>
                    <div>
                      <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: 240 }} loading={loading}>
                        Login
                      </Button>
                    </div>
                  </Form.Item>
                )}
              </Form>
              {campuses.length > 0 && (
                <div>
                  <h4>캠퍼스를 선택해주세요.</h4>
                  {campuses.map((campus) => (
                    <Button
                      key={campus.id}
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      style={{ width: 240 }}
                      onClick={() => {
                        onDirectLogin(loginType, campus.id);
                      }}
                    >
                      {campus.name}
                    </Button>
                  ))}
                </div>
              )}
            </LoginForm>
          </div>
          <Footer isIgnoreAdress={true} data={companySettings} />
          {/* <Footer isIgnoreAdress={companySettings['isIgnoreAdress']} data={companySettings} /> */}
        </>
      )}
    </>
  );
};

export default Login;
