import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PageNum from './PageNum';
import _ from 'lodash';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
import { WriteAlphabetImageSoundPopup } from './WritePopup';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';

const clickSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/mouse-click.mp3';
const stepEndSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/dingdong.mp3';

const ImageDndAlphabet = ({
  problem,
  step,
  total,
  setSteps,
  setShowPopUp,
  setPopUpType,
  isSecondStepEnd,
  setIsSecondStepEnd,
  resetStep,
  onPopupClose,
}) => {
  const [isDropped, setIsDropped] = useState(false);
  const [isShowWritePop, setIsShowWritePop] = useState(false);
  const [wordSound, setWordSound] = useState(undefined);
  const [dropSound, setDropSound] = useState(undefined);
  const [dingdongSound, setDingdongSound] = useState(undefined);
  const handleDragEnd = useCallback(
    (result) => {
      console.log('drag_end')
      const { destination } = result;
      if (_.isNil(destination)) {
        return;
      }
      setIsDropped(true);
      setDropSound(clickSound);
    },
    // eslint-disable-next-line
    [problem],
  );
  const handleWriteEnd = (correct) => {
    if (correct) {
      setDingdongSound(stepEndSound);
    } else {
      handleDingdongSoundEnd();
    }
  };

  const handleDingdongSoundEnd = () => {
    setDingdongSound(undefined);
    setIsShowWritePop(false);
    if (step < total - 1) {
      setSteps(step + 1);
    } else {
      setPopUpType('correct');
      setShowPopUp(true);
    }
  };

  const handleWordSoundEnd = () => {
    console.log('handleWordSoundEnd')
    setWordSound(undefined);
    setPopUpType('imageAndAlphabet');
    setShowPopUp(true);
  };

  const handleDropSoundEnd = () => {
    setDropSound(undefined);
    setWordSound(problem.mp3);
  };

  useEffect(() => {
    setIsDropped(false);
    setIsSecondStepEnd(false);
    // eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    if (isSecondStepEnd) {
      setIsShowWritePop(true);
    }
  }, [isSecondStepEnd]);

  useEffect(() => {
    setIsDropped(false);
  }, [resetStep]);
console.log(isShowWritePop,'isShowWritePop',dropSound,'dropsound',problem,'problem',wordSound,'wordSound')
  return (
    <>
      <S.ColumnFlex
        className="main_content stage2_content onewrap"
        style={{
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'space-around',
          flex: '1 1',
          borderRadius: '16px',
          border: '4px solid #ffb000',
          backgroundColor: '#fff',
          height: '90%',
          marginTop: 10,
        }}
      >
        <PageNum
          num={step + 1}
          total={total}
          style={{ position: 'absolute', top: '10px', right: '10px', fontSize: 20, fontWeight: 'bold' }}
        />
        <div className="contents_inner_wrap" style={{ width: '100%' }}>
          <ImageDndAlphabetDnDContext
            imageAlphabet={problem.imageAlphabet}
            imageWord={problem.imageWord}
            onDragEnd={handleDragEnd}
            isDropped={isDropped}
          />
        </div>
      </S.ColumnFlex>
      <SoundEffectPM url={!_.isNil(wordSound) ? wordSound : undefined} onEnd={handleWordSoundEnd} />
      <SoundEffectPM url={!_.isNil(dropSound) ? dropSound : undefined} onEnd={handleDropSoundEnd} />
      <SoundEffectPM url={!_.isNil(dingdongSound) ? dingdongSound : undefined} onEnd={handleDingdongSoundEnd} />
      {isShowWritePop && (
        <WriteAlphabetImageSoundPopup
          alphabet={problem.alphabet}
          word={problem.word}
          image={problem.imageWord}
          mp3={problem.mp3}
          step={step}
          total={total}
          onEnd={handleWriteEnd}
          onClose={() => {
            setIsShowWritePop(false);
            onPopupClose();
          }}
        />
      )}
    </>
  );
};

export default ImageDndAlphabet;

const ImageDndAlphabetDnDContext = ({ imageAlphabet, imageWord, isDropped, onDragEnd }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <S.RowFlex style={{ padding: 20, alignItems: 'center', width: '100%' }} className="contents_inner row">
        <Droppable droppableId={`dragZone`} type="alphabet" isDropDisabled={true}>
          {(providedDrop) => (
            <>
              {isDropped ? (
                <Card className="card">
                  <SelCard className="sel_card" style={{ borderColor: '#fff' }}>
                    <CardIMG src={imageAlphabet} alt="" style={{ visibility: 'hidden' }} />
                  </SelCard>
                </Card>
              ) : (
                <Card className="card" ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
                  <Draggable draggableId={`drag_alphabet`} index={1} type="alphabet">
                    {(provided) => (
                      // <button type="button" className="sel_card active" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      // 	<img src={imageAlphabet} alt="" />
                      // </button>

                      <SelCard
                        className="sel_card active"
                        active={true}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CardIMG src={imageAlphabet} alt="" />
                      </SelCard>
                    )}
                  </Draggable>
                </Card>
              )}
              {providedDrop.placeholder}
            </>
          )}
        </Droppable>

        <div className="arrow">
          <p className="arrow-right">
            <img style={{ width: 64, height: 64 }} src="/images/pm/i_arrow_right.svg" alt="오른쪽 방향 화살표" />
          </p>
        </div>
        <Card className="card">
          <Droppable droppableId={`dropZone`} type="alphabet">
            {(provided, snapshot) => (
              <>
                <SelCard
                  className={`sel_card ${isDropped ? 'active' : 'dash'}`}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined,
                  }}
                  notDropped={!isDropped}
                >
                  <CardIMG src={imageWord} alt="" />
                </SelCard>
                <div style={{ display: 'none' }}>{provided.placeholder}</div>
              </>
            )}
          </Droppable>
        </Card>
      </S.RowFlex>
    </DragDropContext>
  );
};

const Card = styled.div`
  flex-grow: 1;
  padding: 10px;
  /* width: 40%; */
`;
const SelCard = styled.div`
  text-align: center;
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;
  border: ${(props) =>
    props.active || !props.notDropped ? '3px solid #feca57' : props.notDropped ? '3px dashed #e5e5e5' : '3px solid #e5e5e5'};
  filter: ${(props) => props.notDropped && 'grayscale(100%)'};
  opacity: ${(props) => props.notDropped && '0.5'};
`;
const CardIMG = styled.img`
  width: 100%;
`;
