import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { userInfoVar } from 'src/apollo/cache';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { CREATE_EXTERNAL_USER_STUDY } from 'src/operations/mutations/externalUserStudy';
import LevelUpFinishPopUp from 'src/components/LevelUp/FinishPopUp';
import LevelUpExitPopUp from 'src/components/LevelUp/ExitPopUp';
import * as queries from 'src/operations/queries';
import SoundEffect from 'src/components/common/SoundEffect';
const { LEVEL_UP_STUDY_DATA } = queries.externalStudy;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 550px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: 25px;
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: 22px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 650px;
  @media (max-width: 1280px) {
    max-width: 1100px;
    min-width: 600px;
    width: 95%;
  }
  @media (max-height: 750px) {
    width: 95%;
    min-height: 530px;
  }
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const StageTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  padding: 30px 0;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;

const StageButton = styled.div`
  width: 72px;
  min-width: 72px;
  height: 50px;
  min-height: 50px;
  border-radius: 15px;
  background-image: url(${(props) => props.bg});
  background-size: 72px 50px;
  background-repeat: no-repeat;
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  cursor: pointer;
`;
const StageButtonWrapper = styled(RowFlex)`
  background-color: #fef189;
  height: 70px;
  width: 60%;
  max-width: 800px;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
`;
const StageButtonText = styled(ColumnFlex)`
  align-items: center;
  font-size: 12px;
  height: 100%;
  color: #453ec8;
  font-weight: 500;
  background-color: ${(props) => props.active && '#b8d9f8'};
  padding: 5px;
`;
const ArrowIcon = styled.div`
  width: 52px;
  min-width: 52px;
  height: 45px;
  min-height: 45px;
  margin: 0 35px;
  background-image: url(${(props) => props.src});
  background-size: 52px 45px;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const CardWrapper = styled(RowFlex)`
  width: 90%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const BGMButton = styled.div`
  width: 35px;
  height: 35px;
  background-image: url(${(props) => (props.off ? '/images/LevelUp/study/bgm_off.png' : '/images/LevelUp/study/bgm_on.png')});
  border-radius: 16px;
  box-shadow: ${(props) => !props.off && '2px 2px 3px 1px rgba(0, 0, 0, 0.2)'};
  background-size: 35px 35px;
  background-repeat: no-repeat;
  cursor: pointer;
  /* float: right; */
  margin-right: 15px;
`;
const XButton = styled.div`
  width: 28px;
  height: 28px;
  background-image: url('/images/LevelUp/study/X_button.png');
  background-size: 28px 28px;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const dummy = [
  { eng: 'cat', kor: '고양이' },
  { eng: 'kitten', kor: '아기고양이' },
  { eng: 'dog', kor: '개' },
  { eng: 'tree', kor: '나무' },
  { eng: 'book', kor: '책' },
  { eng: 'sun', kor: '태양' },
  { eng: 'moon', kor: '달' },
  { eng: 'star', kor: '별' },
  { eng: 'right', kor: '오른쪽' },
  { eng: 'left', kor: '왼쪽' },
];
const [bgm, choose, correct, wrong, finish] = [
  '/audio/LevelUp/Game_BGM.mp3',
  '/audio/LevelUp/Game_Choose.mp3',
  '/audio/LevelUp/Game_Correct.mp3',
  '/audio/LevelUp/Game_Wrong.mp3',
  '/audio/LevelUp/Game_Finish_Victory.mp3',
];
export default function CardMatchingGame({ location }) {
  const { state } = location;
  const { idx, assignedIdx } = useParams();
  const [selected, setSelected] = useState([null, null]);
  const [answerList, setAnswerList] = useState([]);
  const [sound, setSound] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [page, setPage] = useState(1);
  const [bgmOption, setBgmOption] = useState(bgm);
  const history = useHistory();
  const { data } = useQuery(LEVEL_UP_STUDY_DATA, { variables: { type: 'levelup', book_code: String(idx) }, skip: !idx });
  const [createExternalUserStudy, { loading: loadingSave }] = useMutation(CREATE_EXTERNAL_USER_STUDY, {
    onCompleted: () => {
      //저장까지 해야 완료
    },
    onError(error) {
      console.log('error', error);
    },
  });
  const studyData = data?.getExternalStudyData && data?.getExternalStudyData.length > 0 ? data?.getExternalStudyData : dummy;
  const bgmToggle = () => {
    if (bgmOption) {
      setBgmOption(null);
      window.localStorage.setItem('levelup_bgm_toggle', 'off');
    } else {
      setBgmOption(bgm);
      window.localStorage.removeItem('levelup_bgm_toggle');
    }
  };
  useEffect(() => {
    const localbgmToggle = window.localStorage.getItem('levelup_bgm_toggle');
    if (localbgmToggle === 'off') {
      setBgmOption(null);
    } else {
      setBgmOption(bgm);
    }
  }, []);
  const gotoNext = async () => {
    const external_user_study_input = {
      user_idx: !_.isNil(userInfoVar()?.idx) ? parseInt(userInfoVar()?.idx) : 0,
      external_study_idx: state?.bookData?.book_no,
      // assigned_idx: parseInt(assignedIdx),
      type: 'levelup',
      // lesson_code: lessonCode,
      // stage_no: parseInt(stageNumber),
      article_data: state?.bookData || null,
      exam_total: 100,
      exam_correct: 100,
      stage_no: 5,
      stage_answers: JSON.stringify(studyData),
    };

    if (state?.bookData?.assignedIdx) {
      external_user_study_input['assigned_idx'] = parseInt(state?.bookData?.assignedIdx);
    }

    await createExternalUserStudy({
      variables: { external_user_study_input },
    });
  };

  const mixed = useMemo(() => {
    const tmp = [];
    const returndata = [];
    const shuffled = _.shuffle(studyData);
    for (let i = 0; i < shuffled.length; i += 5) {
      const chunk = shuffled.slice(i, i + 5);
      tmp.push(chunk);
    }
    tmp.map((e, i) => {
      const tmp2 = [];
      e.map((word, wordi) => {
        tmp2.push({ isEnglish: true, text: word.eng, idx: wordi + i * 5, key: wordi + i * 5 + 'english' });
        tmp2.push({ isEnglish: false, text: word.kor, idx: wordi + i * 5, key: wordi + i * 5 + 'korean' });
      });
      returndata.push(_.shuffle(tmp2));
    });
    return returndata.map((chunk) =>
      chunk.map((e, i) => {
        e.checkidx = i;
        return e;
      }),
    );
  }, [studyData]);

  // console.log(mixed, 'mixed');
  // useEffect(()=>{},[])
  useEffect(() => {
    if (answerList.length === 5) {
      if (answerList.length === 5 && page * 5 === studyData.length) {
        gotoNext();
        setTimeout(() => {
          setSound(finish);
        }, 1000);
        setTimeout(() => {
          setIsFinished(true);
        }, 2000);
      } else {
        setTimeout(() => {
          setSound(correct);
          setPage((prev) => prev + 1);
          setAnswerList([]);
        }, 2000);
      }
    }
    setSound(correct);
  }, [answerList]);
  // console.log(answerList, page, studyData.length, 'answerList');
  const handleSoundEnd = () => {
    setSound(null);
  };
  return (
    <>
      {visible && (
        <LevelUpExitPopUp assigned_idx={parseInt(state?.bookData?.assignedIdx)} visible={visible} setVisible={setVisible} idx={idx} />
      )}
      {isFinished ? (
        <LevelUpFinishPopUp idx={idx} assigned_idx={parseInt(state?.bookData?.assignedIdx)} />
      ) : (
        <Wrapper>
          {/* <RowFlex style={{ width: '100%', justifyContent: 'right', alignItems: 'baseline', padding: '10px 30px' }}> */}
          {/* <FullScreenButton
              element={<ImgButton className="header-btn" src="/images/fullscreen.png" alt="back arrow in header" />}
              activeElement={<ImgButton className="header-btn" src="/images/fullscreenExit.png" alt="back arrow in header" />}
            /> */}
          {/* </RowFlex> */}
          <MainWrapper>
            <Main>
              <ColumnFlex style={{ justifyContent: 'space-between', height: '80%', width: '100%', alignItems: 'center' }}>
                <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '0 0 0 50px' }}>
                  <StageTitle>
                    <TitleSpan color={'#453ec8'}>Memory Game</TitleSpan> Flip the cards and match the words.
                  </StageTitle>
                  <RowFlex>
                    <NumberText>
                      Page{' '}
                      <QuizNumber>
                        <span style={{ color: '#433fc8' }}>{page}</span>/{mixed.length}
                      </QuizNumber>
                    </NumberText>
                    <RowFlex style={{ height: '70px', justifyContent: 'start', alignItems: 'start', padding: '0 20px' }}>
                      <BGMButton off={!bgmOption} onClick={bgmToggle} />
                      <XButton
                        onClick={() => {
                          setVisible(true);
                        }}
                      />
                    </RowFlex>
                  </RowFlex>
                </RowFlex>
                <CardWrapper>
                  {mixed[page - 1].map((e) => (
                    <Card
                      selected={selected}
                      setSelected={setSelected}
                      key={e.key}
                      vocadata={e}
                      answerList={answerList}
                      setAnswerList={setAnswerList}
                      setSound={setSound}
                      isFinished={answerList.length === 5 && page * 5 === studyData.length}
                    />
                  ))}
                </CardWrapper>
              </ColumnFlex>
            </Main>
          </MainWrapper>
        </Wrapper>
      )}
      <>
        <SoundEffect src={sound} replayCnt={1} onEnd={handleSoundEnd} />
        <SoundEffect src={bgmOption} replayCnt={0} volume={50} />
      </>
    </>
  );
}
const Card = ({ vocadata, key, setSelected, selected, answerList, setSound, setAnswerList, isFinished }) => {
  const handleToggle = () => {
    setSound(choose);
    if (!selected.includes(vocadata)) {
      if (!selected[0]) {
        // setClickToggle(true);
        setSelected([vocadata, null]);
      } else {
        if (selected[1]) {
          // alert('잠시 뒤에 시도하세요.');
          return;
        } else {
          if (selected[0].idx === vocadata.idx) {
            // setClickToggle(true);
            setSelected([null, null]);
            setAnswerList((prev) => [...prev, vocadata.idx]);
            // setTimeout(() => {
            //   rightMatchPopUp();
            // }, 600);
            // !isFinished&& setSound(correct)
          } else {
            setSelected([selected[0], vocadata]);
            setSound(wrong);
            setTimeout(() => {
              // wrongMatchPopUp();
              setSelected([null, null]);
            }, 600);
          }
        }
      }
    }
  };
  return (
    <>
      {/* {matchPopUp} */}
      {selected.includes(vocadata) || answerList.includes(vocadata.idx) ? (
        <Front onClick={handleToggle} clickToggle={selected.includes(vocadata) || answerList.includes(vocadata.idx)} key={key}>
          {(selected.includes(vocadata) || answerList.includes(vocadata.idx)) && vocadata.text}
        </Front>
      ) : (
        <Back onClick={handleToggle} clickToggle={selected.includes(vocadata) || answerList.includes(vocadata.idx)} key={key}></Back>
      )}
    </>
  );
};

const Back = styled.div`
  width: 285px;
  height: 95px;
  margin: 12px;
  background-image: url('/images/LevelUp/game/card_back.png');
  background-repeat: no-repeat;
  background-size: 276px 95px;
  @media (max-width: 1280px) {
    width: 210px;
    height: 70px;
    margin: 20px;
    background-size: 204px 70px;
  }
`;

const Front = styled.div`
  width: 285px;
  height: 95px;
  margin: 12px;
  font-size: 23px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fef189;
  background-image: url('/images/LevelUp/game/card_front.png');
  background-repeat: no-repeat;
  background-size: 276px 95px;
  @media (max-width: 1280px) {
    width: 210px;
    height: 70px;
    margin: 20px;
    background-size: 204px 70px;
  }
`;
