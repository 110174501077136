import React from 'react';
import { Tabs } from 'antd';
import { HeaderTitle } from 'src/components/common/Styles';
import styled from 'styled-components';

import CocoPhoincs from './CocoPhoincs';
import CocoActivity from './CocoActivity';
import SmartEbook from './SmartEbook';
import LimeTreeReaders from './LimeTreeReaders';
import TalkingTap from './TalkingTap';
import GrammarTap from './GrammarTap';
import EnglishTap from './EnglishTap';
import KidsTap from './KidsTap';
import HelloPhonics from './HelloPhonics';
import VocaTap from './VocaTap';
import TheClass from './TheClass';
import GrammarHouse from './GrammarHouse';

import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { userInfoVar } from 'src/apollo/cache';
const BookLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  min-width: 1200px;
  background-color: #fff;
`;

const SmartTabs = styled(Tabs)`
  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    background-color: #e6fcf5 !important;
  }
`;
const PhonicsTabs = styled(Tabs)`
  .ant-tabs-tab-active {
    background-color: #1890ff !important;
    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    background-color: #fff !important;
  }
`;
const { TabPane } = Tabs;

const SmartEclass = () => {
  const companyName = useSelector(classStoreData);
  const checkForEduco = companyName === 'educosneakers';
  const CATEGORY = checkForEduco
    ? [
        { name: 'Coco Phonics', comp: <CocoPhoincs checkForEduco={checkForEduco} /> },
        { name: 'Sneakers', comp: <SmartEbook checkForEduco={checkForEduco} /> },
        { name: 'Lime Tree Readers', comp: <LimeTreeReaders checkForEduco={checkForEduco} /> },
        { name: 'THE CLASS', comp: <TheClass checkForEduco={checkForEduco} /> },
        { name: 'Grammar House', comp: <GrammarHouse checkForEduco={checkForEduco} /> },
      ]
    : companyName === 'englishtap'
    ? [
        { name: 'English Tap', comp: <EnglishTap /> },
        { name: 'Lime Tree Readers', comp: <LimeTreeReaders /> },
        { name: 'Kids Tap Phonics', comp: <KidsTap /> },
        { name: 'VOCA TAP', comp: <VocaTap /> },
      ]
    : [
        { name: 'Phonics', comp: <PhonicsList /> },
        { name: companyName === 'sneakersedu' ? 'Sneakers' : 'SMART E Class', comp: <SmartEbook /> },
        { name: 'Lime Tree Readers', comp: <LimeTreeReaders /> },
        { name: 'Talking Tap', comp: <TalkingTap /> },
        { name: 'English Tap', comp: <EnglishTap /> },
        { name: 'Grammar Tap', comp: <GrammarTap /> },
        { name: 'THE CLASS', comp: <TheClass /> },
        { name: 'VOCA TAP', comp: <VocaTap /> },
        { name: 'Grammar House', comp: <GrammarHouse /> },
      ];
  const getComponent = (key) => {
    return CATEGORY[key].comp;
  };

  return (
    <>
      {/* {isAfterSchool(companyName) && <SimplePopup />} */}
      <HeaderTitle level={4}>EBook</HeaderTitle>
      <BookLayout>
        <SmartTabs defaultActiveKey={0} type="card" size="large" onChange={() => {}} style={{ width: '100%' }}>
          {CATEGORY.map((item, key) => (
            <TabPane tab={item.name} key={key}>
              {getComponent(key)}
            </TabPane>
          ))}
        </SmartTabs>
      </BookLayout>
    </>
  );
};

export default SmartEclass;
const PhonicsList = () => {
  const PHONICS = [
    { name: 'Coco Phonics', comp: <CocoPhoincs /> },
    { name: 'Kids Tap Phonics', comp: <KidsTap /> },
    { name: 'Hello Phonics', comp: <HelloPhonics /> },
    { name: 'Coco Activity Book', comp: <CocoActivity /> },
  ];

  const getComponentPhonics = (key) => {
    return PHONICS[key].comp;
  };
  return (
    <PhonicsTabs defaultActiveKey={0} type="card" size="small" onChange={() => {}} style={{ width: '100%' }}>
      {PHONICS.map((item, key) => (
        <TabPane tab={item.name} key={key}>
          {getComponentPhonics(key)}
        </TabPane>
      ))}
    </PhonicsTabs>
  );
};
