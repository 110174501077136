import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Col, Typography, Row, Button, List, Form, Checkbox, DatePicker, Select, Space } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { EditOutlined } from '@ant-design/icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import moment from 'moment';
import queryString from 'query-string';

import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from 'src/components/common/Notification';
import FormField from 'src/components/common/FormField';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { customDayOfWeek } from 'src/utils';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const yoilOptions = [
  { label: '일', value: '일' },
  { label: '월', value: '월' },
  { label: '화', value: '화' },
  { label: '수', value: '수' },
  { label: '목', value: '목' },
  { label: '금', value: '금' },
  { label: '토', value: '토' },
];

const TestBlock = styled.div`
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  overflow-x: hidden;
`;

const CalendarBlock = styled.div`
  padding: 2px;
  margin-bottom: 10px;
  background: #fff;
`;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

const SyllabusAssign = () => {
  const history = useHistory();
  const { id } = useParams();
  const [formControl] = Form.useForm();
  const [firstForm] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [threeForm] = Form.useForm();
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [selectYoil, setSelectYoil] = useState([]);
  const [selectStudents, setSelectStudents] = useState([]);
  const [testTime, setTestTime] = useState('00:00');
  const [events, setEvents] = useState([]);
  const [listData, setListData] = useState([]);
  const [rangeDate, setRangeDate] = useState({ start: null, end: null });
  const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));
  const { classIdx } = queryString.parse(window.location.search.replace('?', ''));

  const classQuery = useQuery(queries.getClass.GET_TYPE_CLASS);

  const { data: fetchSyllabus } = useQuery(queries.getTests.TEST_GROUP_INFO, {
    fetchPolicy: 'no-cache',
    variables: { idx: parseInt(id) },
  });

  const [getSelectClassStudents, { data: fetchStudents }] = useLazyQuery(queries.getClass.CLASS_STUDENT_LIST);

  const [assignTestGroup] = useMutation(mutations.createTests.ASSIGN_TEST_GROUP, {
    onCompleted: () => {
      openNotification('등록 완료!');
    },
  });

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }

    if (fetchStudents?.classStudentList) {
      setStudents(fetchStudents?.classStudentList);
    }

    if (classIdx) {
      setCheckedList([]);
      setSelectedClass(classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(classIdx))[0]);
      getSelectClassStudents({
        variables: { class_idx: parseInt(classIdx) },
      });
      firstForm.setFieldsValue({
        class: parseInt(classIdx),
      });
    }
  }, [classQuery, fetchStudents, classIdx]);

  const workBooks = useMemo(() => {
    if (fetchSyllabus) {
      return fetchSyllabus.testGroupInfo?.tests?.sort((a, b) => {
        return Number(a.title.match(/(\d+)/g)[0]) - Number(b.title.match(/(\d+)/g)[0]);
      });
    }

    return [];
  }, [fetchSyllabus]);

  function handleDateSelect(selectInfo) {
    formControl.setFieldsValue({
      date: moment(selectInfo.dateStr),
    });
  }

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(students.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }

  function onCheckCalendareList(selectYoil) {
    setEvents(
      selectYoil.map((item, i) => {
        return {
          date: item,
          color: '#378006',
          title: `${i + 1}회차`,
          key: i,
        };
      }),
    );
  }

  function onCheckGenerateList(yoil) {
    const checkStudents = onCheckStudents();
    const newListData = workBooks
      ?.map((item, index) => {
        const obj = {
          days: yoil[index],
          lesson: `${item.title}`,
          No: `${index + 1}회차`,
          key: index + 1,
        };
        return obj;
      })
      .filter((item) => !!item.days);

    checkStudents.length && setListData(newListData);
  }

  function handleChange(value) {
    setCheckedList([]);
    setSelectedClass(classes?.filter((classInfo) => classInfo?.idx === value)[0]);
    getSelectClassStudents({
      variables: { class_idx: value },
    });
  }

  const onCheckStudents = (data) => {
    const astAdd = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    setSelectStudents(students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user));
    return students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user);
  };

  function onRangeChange(dates, dateStrings) {
    setRangeDate({
      start: dateStrings[0],
      end: dateStrings[1],
    });

    if (selectedClass) {
      let dayOfWeek = customDayOfWeek(selectedClass);

      if (secondForm.getFieldValue('day_of_week')) {
        dayOfWeek = secondForm.getFieldValue('day_of_week').join(', ');
      } else if (!dayOfWeek) {
        dayOfWeek = '일, 월, 화, 수, 목, 금, 토';
      }

      //console.log('dayOfWeek', dayOfWeek);
      const selectYoil = getDateRangeData(dateStrings[0], dateStrings[1], dayOfWeek);

      setSelectYoil(selectYoil);
      onCheckCalendareList(selectYoil);
      onCheckGenerateList(selectYoil);
    }
  }

  function onTimeChange(time, TimeStrings) {
    setTestTime(TimeStrings);
  }

  function getDateRangeData(param1, param2, dayOfWeek) {
    //param1은 시작일, param2는 종료일이다.
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const res_day = [];
    const ss_day = new Date(param1);
    const ee_day = new Date(param2);

    let count = 0;
    while (ss_day.getTime() <= ee_day.getTime()) {
      let _mon_ = ss_day.getMonth() + 1;
      _mon_ = _mon_ < 10 ? '0' + _mon_ : _mon_;

      let _day_ = ss_day.getDate();
      _day_ = _day_ < 10 ? '0' + _day_ : _day_;

      let yoil = ss_day.getDay();

      if (dayOfWeek.includes(week[yoil]) && workBooks[count]) {
        res_day.push(ss_day.getFullYear() + '-' + _mon_ + '-' + _day_);
        count++;
      }

      ss_day.setDate(ss_day.getDate() + 1);
    }
    return res_day;
  }

  const handleFormSubmit = () => {
    formControl
      .validateFields()
      .then((formData) => {
        assignTestGroup({
          variables: {
            class_idx: selectedClass.idx,
            start_time: selectYoil, // 클래스별 설정된 요일 별 날자 체크(요일 없을시 하루간격)
            limit_time: 60,
            user_idx_list: selectStudents.map((students) => students.idx),
            test_group_idx: parseInt(id),
            start_date: rangeDate?.start, // 달력 시작일
            end_date: rangeDate?.end, // 달력 마지막일
            workbook_time: testTime,
          },
        });
        firstForm.resetFields();
        formControl.resetFields();
        secondForm.resetFields();
        threeForm.resetFields();
        setClasses([]);
        setStudents([]);
        setSelectedClass(-1);
        setSelectYoil([]);
        setSelectStudents([]);
        setTestTime([]);
        setEvents([]);
        setListData([]);
        setRangeDate({ start: null, end: null });
        history.push('/test/syllabus/main');
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  function showUpdateConfirm(changeInfo) {
    handleEventChange(changeInfo);
  }

  function handleEventChange(changeInfo, type = '') {
    const {
      start,
      extendedProps: { key },
    } = changeInfo.event.toPlainObject();

    setEvents((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item.date = start;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item = start;
        }
        return item;
      });
    });

    // updateClassLession({
    //   variables: {
    //     class_idx,
    //     class_lesson_idx: classLessonIdx,
    //     study_date: start,
    //     type,
    //   },
    // });
  }

  function handleDateChange(date, changeDate, index) {
    const name = `testDate-${index}`;
    formControl.setFieldsValue({
      [name]: changeDate && moment(changeDate, 'YYYY-MM-DD'),
    });
    // console.log('date', date);
    const searchIndex = index - 1;
    setEvents((prev) => {
      return prev.map((item, index) => {
        if (index === searchIndex) {
          item.date = changeDate;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        if (index === searchIndex) {
          item = changeDate;
        }
        return item;
      });
    });
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
      width: 50,
    },
    {
      title: 'Lesson',
      dataIndex: 'lesson',
      key: 'lesson',
      align: 'center',
    },
    {
      title: '날짜',
      dataIndex: 'days',
      align: 'center',
      key: 'days',
      width: 200,
      render: (text, record) => {
        //console.log('text', text);
        // console.log('record', record);
        const names = `testDate-${record.key}`;
        formControl.setFieldsValue({
          [names]: text && moment(text, 'YYYY-MM-DD'),
        });
        return (
          <>
            <Form.Item name={`testDate-${record.key}`}>
              <DatePicker
                name={`testDate-${record.key}`}
                initialValues={text && moment(text, 'YYYY-MM-DD')}
                //onChange={handleDateChange}
                onChange={(date, dateString) => {
                  handleDateChange(date, dateString, record.key);
                }}
              />
            </Form.Item>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Col className="test-syllabus-wrapper" span={24} style={{ marginTop: '10px' }}>
        <TestBlock className="test-syllabus-wrapper-tb">
          <Row style={{ padding: 10 }}>
            <Title level={5}>
              <span style={{ color: '#289428' }}>Auto Assign</span>
            </Title>
          </Row>
          <Row className="test-syllabus-box-listing" gutter={[24, 16]}>
            <Col className="test-syllabus-box-a" span={5}>
              <TestSectionBlock className="test-syllabus-box-a-tsb">
                <Title level={5}>&nbsp;</Title>
                <Form
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  layout="vertical"
                  form={firstForm}
                >
                  <Form.Item name="class" label="" style={{ marginBottom: 5 }}>
                    <Select style={{ width: '100%', minWidth: 100 }} placeholder="Class" onChange={handleChange}>
                      {classes.map((classInfo) => (
                        <Option key={classInfo.idx} value={classInfo.idx}>
                          {classInfo.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <List
                    header={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label>Name</label>
                        <div>
                          전체 선택 &nbsp; <Checkbox onChange={onCheckAllChange}></Checkbox>
                        </div>
                      </div>
                    }
                    footer={<div></div>}
                    bordered
                    dataSource={students}
                    renderItem={(item, idx) => (
                      <List.Item style={{ display: 'flex' }}>
                        <span>{item.user.name}</span> <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
                      </List.Item>
                    )}
                  />
                </Form>
              </TestSectionBlock>
            </Col>
            <Col className="test-syllabus-box-b" span={10} style={{ textAlign: 'center' }}>
              <TestSectionBlock className="test-syllabus-box-b-tsb">
                <Form form={secondForm}>
                  <Title level={5}>Test Date / Time</Title>
                  <FormItem name="day_of_week">
                    <Checkbox.Group options={yoilOptions} name="day_of_week" />
                  </FormItem>
                  <RangePicker style={{ marginBottom: 10 }} onChange={onRangeChange} name="rangeDate" />
                </Form>
                <CalendarBlock className="mini-calander">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    contentHeight={250}
                    expandRows={false}
                    initialView="dayGridMonth"
                    selectable={true}
                    events={events}
                    editable={true}
                    eventContent={(eventInfo, index) => {
                      return (
                        <>
                          <div
                            style={{
                              backgroundColor: eventInfo.backgroundColor,
                              color: 'white',
                              borderRadius: 10,
                              width: 20,
                              textAlign: 'center',
                              marginRight: 5,
                            }}
                          >
                            {eventInfo.event.title}
                          </div>
                          {/* <div>{classType[eventInfo.textColor]}</div> */}
                        </>
                      );
                    }}
                    eventChange={showUpdateConfirm}
                  />
                </CalendarBlock>

                <Form
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  layout="horizontal"
                  style={{ display: 'flex', justifyContent: 'space-evenly' }}
                  form={threeForm}
                >
                  <Form.Item label="Time">
                    <DatePicker
                      picker="time"
                      size="large"
                      format="HH:mm"
                      inputReadOnly={true}
                      showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                      onChange={onTimeChange}
                    />
                  </Form.Item>

                  {/* <Form.Item>
                    <Button type="primary" onClick={() => onCheckGenerateList(selectYoil)}>
                      Done
                    </Button>
                  </Form.Item> */}
                </Form>
              </TestSectionBlock>
            </Col>

            <Col className="test-syllabus-box-c" span={9} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Title level={5}>Confirmation</Title>
              <Form form={formControl} component={false}>
                <CustomTable
                  dataSource={listData}
                  columns={columns}
                  bordered
                  scroll={{ y: 'calc(100vh - 244px)' }}
                  pagination={{ pageSize: 8 }}
                  size="small"
                  color="#edf3fb"
                />

                <Button type="primary" shape="round" size="large" htmlType="submit" onClick={handleFormSubmit}>
                  Save
                </Button>
              </Form>
            </Col>
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

export default SyllabusAssign;
