import { useMemo } from 'react';
import _ from 'lodash';
import axios from 'axios';
import bookInfo from 'src/utils/pm/bookInfo';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_USER_STUDY } from 'src/operations/mutations/userStudy';
import { GET_USER_STUDY_COMPLETE_LIST } from 'src/operations/queries/study';

export const useUserStudyCompleteList = () => {
  const userLessonIdx = window.sessionStorage.getItem('user_lesson_idx')
    ? parseInt(window.sessionStorage.getItem('user_lesson_idx'))
    : null;
  const { data: dataUserStudy, loading } = useQuery(GET_USER_STUDY_COMPLETE_LIST, {
    variables: { user_lesson_idx: userLessonIdx },
    fetchPolicy: 'no-cache',
    skip: !userLessonIdx,
  });
  const complteStageList = useMemo(() => {
    if (dataUserStudy) {
      return dataUserStudy.getUserStudyCompleteList.map((item) => item.stage_no);
    }
    return [];
  }, [dataUserStudy]);

  return { data: complteStageList, loading };
};

export function useStudyCreate({ handleEnd }) {
  const [createUserStudy, { loading: loadingCreateStudy }] = useMutation(CREATE_USER_STUDY, {
    onCompleted: () => {
      //저장까지 해야 완료

      handleEnd();
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const handleCreateStudy = async (user_study_input) => {
    await createUserStudy({
      variables: {
        user_study_input,
      },
    });
  };

  return {
    handleCreateStudy,
    loadingCreateStudy,
  };
}

export function saveStage(userId, bookName, unit, stage, score = null) {
  return new Promise(function (resolve, reject) {
    const performData = window.sessionStorage.getItem('performData');
    if (_.isEmpty(performData)) {
      const data = [{ bookName, unit, stage, isComplete: true }];
      window.sessionStorage.setItem('performData', JSON.stringify(data));
      localStorage.setItem('performData', JSON.stringify(data));
    } else {
      const data = JSON.parse(performData);
      if (data instanceof Array) {
        const isExist = data.some((item) => {
          return JSON.stringify(item) === JSON.stringify({ bookName, unit, stage, isComplete: true });
        });
        if (!isExist) {
          const newData = [...data, { bookName, unit, stage, isComplete: true }];
          window.sessionStorage.setItem('performData', JSON.stringify(newData));
          localStorage.setItem('performData', JSON.stringify(newData));
        }
      }
    }
    var res = { success: true, msg: '' };
    resolve(res);
  });
}

export function fetchStagePerform(userId, bookName, unit) {
  return new Promise(function (resolve, reject) {
    let items = [];
    const performData = window.sessionStorage.getItem('performData');
    if (!_.isEmpty(performData)) {
      const data = JSON.parse(performData);
      if (data instanceof Array) {
        items = data
          .filter((item) => {
            //unit 이 숫자가 아닌 경우가 있음.
            if (item.bookName === bookName && _.toString(unit) === _.toString(item.unit)) {
              return true;
            }
            return false;
          })
          .map((item2) => {
            return { stage: item2.stage, isComplete: true };
          });
      }
    }
    resolve(items);
  });
}

export function fetchBookPerform(userId, bookName) {
  return new Promise(function (resolve, reject) {
    let items = [];
    const performData = window.sessionStorage.getItem('performData');
    if (!_.isEmpty(performData)) {
      const data = JSON.parse(performData);
      if (data instanceof Array) {
        items = data
          .filter((item) => {
            if (item.bookName === bookName) {
              return true;
            }
            return false;
          })
          .map((item2) => {
            return { unit: item2.unit, stage: item2.stage, isComplete: true };
          });
      }
    }
    resolve(items);
  });
}
const saveDataBackend = async (bookName, unit, stage, score = null) => {
  const phonicsUserItem = JSON.parse(window.sessionStorage.getItem('phonicsUserItem'));
  const tokenRes = localStorage.getItem('token');
  // const userData  = tokenRes !== null?jwtDecode(tokenRes):"";
  // const studentid = phonicsUserItem?.id?phonicsUserItem?.id:userData?.user_idx;
  // const tokenData = phonicsUserItem?.token?phonicsUserItem?.token:tokenRes
  // const studentid = userData?.user_idx;
  const studentid = localStorage.getItem('userIdx') ? localStorage.getItem('userIdx') : '';

  if (!score) {
    score = 0;
  }
  // if ((!phonicsUserItem?.token && !phonicsUserItem?.id) && (!tokenRes && !userData?.user_idx)) {
  if (!tokenRes && !studentid) {
    return;
  }
  // We will load this from env vars
  const baseUrl = process.env.REACT_APP_BACKEND_HOST ? process.env.REACT_APP_BACKEND_HOST : 'https://culp-api.cloubot.com';
  // When user logged in, check if all assigned stages are complete
  let unitInfo = {};
  let lesson = 1;
  let totalStageCompleted = 0;
  for (let i = bookInfo.length - 1; i >= 0; i--) {
    if (bookInfo[i]['bookName'] == bookName) {
      unitInfo = bookInfo[i]['unit'][unit];
    }
  }
  if (stage > Object.keys(unitInfo.stage).length / 2) {
    lesson = 2;
    const performData = JSON.parse(window.sessionStorage.getItem('performData'));
    if (performData) {
      for (let i = performData.length - 1; i >= 0; i--) {
        if (
          performData[i]['bookName'] == bookName &&
          performData[i]['unit'] == unit &&
          performData[i]['stage'] > Object.keys(unitInfo.stage).length / 2
        ) {
          totalStageCompleted++;
        }
      }
    }
  }
  if (stage <= Object.keys(unitInfo.stage).length / 2) {
    const performData = JSON.parse(window.sessionStorage.getItem('performData'));
    if (performData) {
      for (let i = performData.length - 1; i >= 0; i--) {
        if (
          performData[i]['bookName'] == bookName &&
          performData[i]['unit'] == unit &&
          performData[i]['stage'] <= Object.keys(unitInfo.stage).length / 2
        ) {
          totalStageCompleted++;
        }
      }
    }
  }
  const queryParams = new URLSearchParams(window.location.search);
  const redirect = new URLSearchParams(queryParams).get('redirect');
  bookName = bookName.toUpperCase();
  unit = 'U' + unit;
  stage = 'S' + stage;
  var studyIdx;
  if (localStorage.getItem('studyIdx')) {
    studyIdx = parseInt(localStorage.getItem('studyIdx'));
  } else {
    studyIdx = JSON.parse(window.sessionStorage.getItem('studyIdx'));
  }

  try {
    const response = await axios({
      method: 'POST',
      url: baseUrl,
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + tokenRes,
        accept: 'application/json',
      },
      data: {
        query: `mutation PhonicsStageUpdateAPI($studentid: Int!, $level: String!, $unit: String!, $stage: String!, $lesson:Int!,$score: Int, $idx: Int) {
						PhonicsStageUpdateAPI(studentid: $studentid, level:$level, unit: $unit, stage: $stage, lesson: $lesson,score: $score, idx: $idx)
					}`,
        operationName: 'PhonicsStageUpdateAPI',
        variables: {
          studentid: Number(studentid),
          level: bookName,
          unit: unit,
          stage: stage,
          score: score,
          lesson: Number(lesson),
          idx: Number(studyIdx),
        },
      },
    });
    if (totalStageCompleted + 1 >= Object.keys(unitInfo.stage).length / 2 && !window.sessionStorage.getItem('lesson_exit_shown')) {
      window.handleExit();
      window.sessionStorage.setItem('lesson_exit_shown', true);
    }
  } catch (e) {
    // TODO: Here we need to handle the token expire
  }
};
