import React from 'react';
import { Col, Row, Button } from 'antd';
import LevelTab, { useLevelTab } from './LevelTab';
import {
  BookCard,
  BookCover,
  BookMeta,
  BookTitle,
  BOOK_COL_STYLE,
  SMART_ECLASS_LINK_BUTTON_STYLE,
  SMART_ECLASS_CDN_BASE_URL,
  THUMBNAIL_BASE_URL,
} from './CommonComp';

const LEVELS = [1, 2, 3, 4, 5, 6];
const VOLUMES = [1, 2, 3];
const LINK_NAME = 'https://online.flipbuilder.com/sangbong91/';
const plusURL = [
  ['yzqx', 'zpru', 'wqse'],
  ['euyl', 'cgrz', 'afxk'],
  ['rzgm', 'ghrn', 'izex'],
  ['gzjf', 'gafc', 'quln'],
  ['qwbe', 'eqzh', 'ulhj'],
  ['cupf', 'lyrs', 'cvzb'],
];
const TalkingTap = () => {
  const { levelNo, onChangeLevelNo } = useLevelTab();
  const getUrl = (lv, vol) => {
    let url = `${LINK_NAME}${plusURL[lv - 1][vol - 1]}`;
    return url;
  };
  return (
    <div style={{ height: '72vh', overflowY: 'scroll', overflowX: 'hidden' }}>
      <LevelTab
        levelNo={levelNo}
        levels={LEVELS}
        onChage={(evt) => {
          const {
            target: { value },
          } = evt;
          onChangeLevelNo(value);
        }}
      />
      {LEVELS.filter((level) => (levelNo === 0 ? true : level === levelNo)).map((lv) => (
        <Row key={`row-${lv}`} gutter={[16, 16]} style={{ margin: '0 10px' }}>
          {VOLUMES.map((vol) => (
            <Col span={4} key={`col-${lv}-${vol}`} style={{ ...BOOK_COL_STYLE, borderRight: `${vol === 3 ? '1px solid #dedede' : ''}` }}>
              <BookTitle level={5} style={{ textAlign: 'center' }}>
                {`Talking Tap ${lv}-${vol}`}
              </BookTitle>
              <BookCard
                onClick={() => {
                  window.open(getUrl(lv, vol), '_blank', 'width=1400, height=900');
                }}
                cover={
                  <BookCover
                    style={{
                      backgroundImage: `url(${THUMBNAIL_BASE_URL.replace('SM','TT')}L${lv}V${vol}.jpg)`,
                    }}
                  />
                }
              >
                <BookMeta description={<EbookLinkGroup level={lv} volume={vol} />} />
              </BookCard>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default TalkingTap;

const EbookLinkGroup = ({ level, volume }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5, paddingBottom: 5 }}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          // if (level > 2 && volume > 1) {
          //   alert('준비중입니다.');
          // } else {
          window.open(`${SMART_ECLASS_CDN_BASE_URL}common/talking-tap/Talking_TAP_Level_${level}-${volume}_teacher_ppt.zip`);
          // }
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.4em', lineHeight: '1' }}
      >
        PPT <br />
        Download
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          alert('준비중입니다.');
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.6em' }}
      >
        E-learning
      </Button>
    </div>
  );
};
