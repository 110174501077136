import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import PageNum from './PageNum';
import { isVowel, alphabetBoxTypeChecker } from '../../utils/pm/util';
import { useParams } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
let bookunitstagechecklist = undefined;
const ImgDnD = ({ problems, steps, setSteps }) => {
  console.log('========================');
  const [dropSound, setDropSound] = useState(undefined);
  const [soundName, setSoundName] = useState(undefined);
  const [problem, setProblem] = useState(undefined);
  const [isDropped, setIsDropped] = useState(false);
  const { level, unit, stage } = useParams();

  if (level == '4a' && unit == 5 && stage == 5) {
    bookunitstagechecklist = true;
  }

  const handleDragEnd = useCallback((result) => {
    const { destination } = result;
    // Extra condition to prevent auto answer
    if (_.isNil(destination) || destination.index > 0 || !destination.droppableId.startsWith('answer')) {
      setDropSound('wrong_sound1');
      return;
    }
    setDropSound('mouse-click');
    setIsDropped(true);
  }, []);
  const handleDropSoundEnd = () => {
    if (dropSound === 'mouse-click') {
      if (_.isNil(problem.isBackEnd)) {
        if (_.isNil(problem.sound)) {
          setSoundName(`${problem.question}_${problem.answer}_${problem.question + problem.answer}`);
        } else {
          setSoundName(problem.sound);
        }
      } else {
        if (_.isNil(problem.sound)) {
          setSoundName(`${problem.answer}${problem.question}`);
        } else {
          setSoundName(problem.sound);
        }
      }
    }
    setDropSound(undefined);
  };
  const handleSoundEnd = () => {
    setIsDropped(false);
    setSteps(steps + 1);
  };

  useEffect(() => {
    if (steps < problems.length) {
      setProblem(problems[steps]);
    }
    // eslint-disable-next-line
  }, [problems, steps]);

  return (
    <>
      <S.RowFlex style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }} className="main_content  stage4_content  col2">
        <PageNum num={_.isNil(steps) ? 0 : steps + 1} total={_.isNil(problems) ? 0 : problems.length} />
        <S.ImgDndBox className="left_box">
          {problem && (
            <S.RowFlex style={{ justifyContent: 'center' }}>
              <img style={{ height: '44vh' }} src={problem.url} alt="example img" />
            </S.RowFlex>
          )}
        </S.ImgDndBox>
        <S.ImgDndBox style={{ marginLeft: '1.6rem' }} className="right_box">
          <DragDropContext onDragEnd={handleDragEnd}>
            <WordDropZone problem={problem} isDropped={isDropped} />
            <img
              style={{ height: '2.4rem', margin: '0 0 1rem' }}
              src="/images/pm/i_arrow_top.svg"
              alt="위로 향하는 화살표"
              className="top_arrow"
            />
            <WordDraggableBox
              words={problem ? problem.candidate : undefined}
              rightAnswer={problem ? problem.answer : undefined}
              isDragDisabled={isDropped}
            />
          </DragDropContext>
        </S.ImgDndBox>
      </S.RowFlex>
      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
      {!_.isNil(soundName) && <SoundEffectPM url={`https://cdn.cloubot.com/PM/audio/sounds/${soundName}.mp3`} onEnd={handleSoundEnd} />}
    </>
  );
};

export default ImgDnD;

const WordDropZone = ({ problem, isDropped }) => {
  if (!problem) return false;
  return (
    <S.ImgDndAlphabetJoin className="alphabet_join" style={!_.isNil(problem.isBackEnd) ? { flexDirection: 'row-reverse' } : {}}>
      <S.ImgDndAlphabetP className="font_blue">{problem && problem.question}</S.ImgDndAlphabetP>
      <S.AlphabetWrap style={{ flex: 'unset' }} className="alphabet_wrap">
        <PhonemeDropableBox problem={problem} isDropped={isDropped} />
      </S.AlphabetWrap>
      {problem && problem.question2 && <S.ImgDndAlphabetP className="font_blue">{problem.question2}</S.ImgDndAlphabetP>}
    </S.ImgDndAlphabetJoin>
  );
};

const WordDraggableBox = ({ words, rightAnswer, isDragDisabled }) => {
  const { level, unit, stage } = useParams();
  if (level == '4b' && unit == 10 && stage == 5) {
    bookunitstagechecklist = true;
  }
  return (
    <S.AlphabetBottomWrap className="gray_bg_border_top">
      <S.AlphabetBottomWrapUl className="alphabet_wrap">
        {words &&
          words.map((item, key) => (
            <Droppable key={`outer-droppable-wrap-${item}`} droppableId={item} type={item} isDropDisabled={true}>
              {(provided) => (
                <li
                  key={`dragWords-item-${key}`}
                  ref={provided.innerRef}
                  style={isDragDisabled && rightAnswer === item ? { display: 'none' } : {}}
                >
                  <Draggable draggableId={item} index={key} isDragDisabled={isDragDisabled}>
                    {(provided, snapshot) => (
                      <S.AlphabetBox
                        row={alphabetBoxTypeChecker(item && item.indexOf('-') !== -1 ? 6 : 3)}
                        className={`alphabet_box row3 green ${item && item.indexOf('-') !== -1 ? 'long-long-width' : ''} ${
                          isDragDisabled && rightAnswer === item ? 'area_hide' : ''
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {item && item.indexOf('-') === -1 ? (
                          item == 'ame' || 'ave' || 'ape' ? (
                            _.map(item, (each, key2) => (
                              <span
                                style={{ color: '#f368e0' }}
                                data={item}
                                key={`each-aphabet-item-${key2}`}
                                className={`font_${isVowel(each) ? 'pink' : 'pink'}`}
                              >
                                {each}
                              </span>
                            ))
                          ) : (
                            _.map(item, (each, key2) => (
                              <span
                                data={item}
                                key={`each-aphabet-item-${key2}`}
                                style={{ color: isVowel(item) ? '#f368e0' : '#2e86de' }}
                                className={`font_${isVowel(each) ? 'pink' : 'blue'}`}
                              >
                                {each}
                              </span>
                            ))
                          )
                        ) : bookunitstagechecklist ? (
                          <React.Fragment>
                            <span style={{ color: '#2e86de' }} className="font_blue">
                              {item.split('-')[0]}
                            </span>
                            <span style={{ color: '#f368e0' }} className="font_pink">
                              &nbsp;{item.split('-')[1]}
                            </span>
                          </React.Fragment>
                        ) : (
                          <span>{item.replace('-', ' ')}</span>
                        )}
                      </S.AlphabetBox>
                    )}
                  </Draggable>
                  {provided.placeholder}
                </li>
              )}
            </Droppable>
          ))}
      </S.AlphabetBottomWrapUl>
    </S.AlphabetBottomWrap>
  );
};

const PhonemeDropableBox = ({ problem, isDropped }) => {
  return (
    <li>
      <Droppable droppableId={`answer${problem && problem.answer}`} type={problem && problem.answer}>
        {(provided, snapshot) => (
          <>
            <S.AlphabetBox
              row={alphabetBoxTypeChecker(3)}
              gray={!isDropped}
              className={`alphabet_box row3 ${isDropped ? 'green' : 'gray'}`}
              id={problem && problem.answer}
              ref={provided.innerRef}
              style={{ backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined }}
            >
              {isDropped &&
                (problem.answer.indexOf('-') === -1
                  ? _.map(problem.answer, (item, key) => (
                      <span
                        data="item1"
                        key={`answer-vowel-item-${key}`}
                        style={{ color: '#f368e0' }}
                        className={`font_${isVowel(item) ? 'pink' : 'pink'}`}
                      >
                        {item}
                      </span>
                    ))
                  : problem.answer.split('-')[1] == 'C' || 'c' || 'g' || 'G'
                  ? _.map(problem.answer.split('-')[1], (item, key) => (
                      <span
                        key={`answer-vowel-item-${key}`}
                        style={{ color: '#f368e0' }}
                        className={`font_${isVowel(item) ? 'pink' : 'pink'}`}
                      >
                        {item.toLowerCase()}
                      </span>
                    ))
                  : _.map(problem.answer.split('-')[1], (item, key) => (
                      <span
                        data={`${problem.answer.split('-')[1]}`}
                        key={`answer-vowel-item-${key}`}
                        style={{ color: isVowel(item) ? '#f368e0' : '#2e86de' }}
                        className={`font_${isVowel(item) ? 'pink' : 'blue'}`}
                      >
                        {item.toLowerCase()}
                      </span>
                    )))}
            </S.AlphabetBox>
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </>
        )}
      </Droppable>
    </li>
  );
};
