import React, { useEffect, useState } from 'react';
import SoundButton from './SoundButton';
import PageNum from './PageNum';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
// import {useMyScript} from "./MyScriptHook";
// import {BgScripter} from "./Scripter";
import { handleAllExceptionLetters } from '../../utils/pm/util';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
//
import SwitchButtonBox from './SwitchButtonBox';
import Keyboard from './Keyboard';
//
const getWordBackgroundUrl = (sound, isEndSound) => {
  return '/images/Write_' + sound.replace('.mp3', `${isEndSound ? '_' : ''}.png`);
};

const veryGoodSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/very-good.mp3';
const woopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';
const tryAgainSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/try-again.mp3';
const MAX_WRONG_COUNT = 2;


const ImageSelectWrite = ({ steps, problem, directionEnd, setSteps, onFinish }) => {
  const stepCount = steps >= problem.length ? problem.length - 1 : steps;
  // const {myScriptEditorRef, script, onClear, error} = useMyScript();
  const [showWrite, setShowWrite] = useState(false);
  const [stepClearSound, setStepClearSound] = useState(undefined);
  const [rightWrongSound, setRightWrongSound] = useState(undefined);
  const [clickedSound, setClickedSound] = useState(false);
  const [soundFinished, setSoundFinished] = useState(true);
  const [wrongCount, setWrongCount] = useState(0);
  const [mode, setMode] = useState(false);
  const [getKey, setGetKey] = useState([]);
  const location = useLocation();
  const respath = location.pathname.split('/');

  // useEffect(() => {
  // 	if (error) {
  // 		setMode(false);
  // 	}
  // }, [error]);

  const handleImageClick = (sound) => {
    if (!soundFinished) return false;
    if (directionEnd === false && !clickedSound) return false;

    if (showWrite === true) return false;

    if (sound === problem[stepCount].rightSound) {
      setShowWrite(true);
      setRightWrongSound(veryGoodSound);
    } else {
      setRightWrongSound(tryAgainSound);
      //alert("oops");
    }
  };
  // const handleWriteEnd = () => {
  // 	if (error) {
  // 		setStepClearSound(woopsSound);
  // 		return;
  // 	}
  // 	if (_.isNil(problem[stepCount].letter) || _.isNil(script)) {
  // 		return;
  // 	}
  // 	//myscript에서 x를 제대로 인식 못해서 어쩔 수 없이 ...
  // 	let userInpurLetter = script.join("");
  // 	userInpurLetter = handleAllExceptionLetters(userInpurLetter, problem[stepCount].letter.toLowerCase());

  // 	if (problem[stepCount].letter.toLowerCase() === userInpurLetter) {
  // 		if (stepCount >= problem.length - 1) {
  // 			onFinish();
  // 			return;
  // 		}
  // 		if (problem[stepCount].letter.toLowerCase() == userInpurLetter) {
  // 			setRightWrongSound(veryGoodSound);
  // 		}
  // 		// setSteps(steps + 1);
  // 		// setShowWrite(false);
  // 		setStepClearSound("/sound-effect/dingdong.mp3");
  // 	} else {
  // 		if (wrongCount < MAX_WRONG_COUNT - 1) {
  // 			setRightWrongSound(tryAgainSound);
  // 			setWrongCount(wrongCount + 1);
  // 		} else {
  // 			setStepClearSound(woopsSound);
  // 			if (stepCount >= problem.length - 1) {
  // 				onFinish();
  // 				return;
  // 			}
  // 		}
  // 	}

  // 	onClear();
  // };

  const handleStepClearSoundEnd = () => {
    setStepClearSound(undefined);
    setSteps(steps + 1);
    setShowWrite(false);
    setWrongCount(0);
  };
  const handleSoundEnd = () => {
    setClickedSound(true);
    setSoundFinished(true);
  };
  const handleSoundStart = () => {
    setSoundFinished(false);
  };
  //
  const handleToggle = () => {
    setMode((prevMode) => !prevMode);
    // onClear();
  };
  // useEffect(() => {
  // 	setGetKey(script);
  // }, [script]);
  useEffect(() => {
    if (_.isNil(getKey)) {
      setGetKey([]);
    }
  }, [getKey]);
  const handleTypeEnd = () => {
    if (_.isNil(problem[stepCount].letter) || _.isNil(getKey)) {
      return;
    }
    //myscript에서 x를 제대로 인식 못해서 어쩔 수 없이 ...
    let userInpurLetter = getKey.join('');
    userInpurLetter = handleAllExceptionLetters(userInpurLetter, problem[stepCount].letter.toLowerCase());

    if (problem[stepCount].letter.toLowerCase() === userInpurLetter) {
      if (stepCount >= problem.length - 1) {
        onFinish();
        return;
      }

      setRightWrongSound(veryGoodSound);
      // setSteps(steps + 1);
      // setShowWrite(false);
      setStepClearSound('https://cdn.cloubot.com/PM/audio/sound-effect/dingdong.mp3');
    } else {
      if (wrongCount < MAX_WRONG_COUNT - 1) {
        setRightWrongSound(tryAgainSound);
        setWrongCount(wrongCount + 1);
      } else {
        setStepClearSound(woopsSound);
        if (stepCount >= problem.length - 1) {
          onFinish();
          return;
        }
      }
    }
    // onClear();
  };
  //
  return (
    <>
      <S.ColumnFlex
        style={{ flex: '1 1', alignSelf: 'stretch',height:'100%' }}
        className={
          isMobile
            ? (respath[2] === '1a' || respath[2] === '1b') && respath[6] == '7'
              ? 'main_content  stage6_content row2 custom-mobile-stage'
              : 'main_content  stage6_content row2'
            : (respath[2] === '1a' || respath[2] === '1b') && respath[6] == '7'
            ? 'main_content  stage6_content row2 custom-stage'
            : 'main_content  stage6_content row2'
        }
      >
        <S.ImageSelectTopBox className="top_box" style={{ height:'50%' }}>
          <PageNum num={stepCount + 1} total={problem.length} style={{ position: 'absolute', top: '10px', right: '10px',fontWeight:'bold',fontSize:'1.3rem' }} />
          <ImgUl className="image_box" >
            {problem[stepCount].examples &&
              problem[stepCount].examples.map((v, k) => (
                <ImgLi key={`ex-image-key${k}`}>
                  <img
                    src={v.image}
					style={{maxWidth:'50%'}}
                    alt=""
                    onClick={() => {
                      handleImageClick(v.sound);
                    }}
                  />
                  {
                    <SoundButton
                      src={`https://cdn.cloubot.com/PM/audio/sounds/${v.sound}`}
                      style={{ display: 'inline-block' }}
                      onSoundEnd={handleSoundEnd}
                      onStart={handleSoundStart}
                    />
                  }
                </ImgLi>
              ))}
          </ImgUl>
        </S.ImageSelectTopBox>
        {/* {showWrite && (
          <div style={{ position: 'relative', right: '0', }}>
            {mode ? (
              <SwitchButtonBox toggleDevice={handleToggle} device={true} />
            ) : (
              <SwitchButtonBox toggleDevice={handleToggle} device={false} />
            )}
          </div>
        )} */}
        <S.ImageSelectTopBox className="bottom_box" style={{ height:'',visibility: showWrite ? 'visible' : 'hidden' }}>
          {/* <BgScripter
						onWriteEnd={handleWriteEnd}
						getKey={script}
						editorRef={myScriptEditorRef}
						onErase={onClear}
						containerStyle={{
							display: mode ? "block" : "none",
							zIndex: "0",
						}}
						style={{
							background: `url(${getWordBackgroundUrl(
								problem[stepCount].rightSound,
								!_.isNil(problem[stepCount].isEndSound) ? true : false
							)}) center center no-repeat`,
							zIndex: "0",
						}}
						isNextBtn={error}
					/> */}

          <div
            style={{
              display: !mode ? 'block' : 'none',
              zIndex: '0',
            }}
          >
            <Keyboard getKey={getKey} setGetKey={setGetKey} onWriteEnd={handleTypeEnd} />
          </div>
        </S.ImageSelectTopBox>
      </S.ColumnFlex>

      {(directionEnd || clickedSound) && <SoundEffectPM url={`https://cdn.cloubot.com/PM/audio/sounds/${problem[stepCount].rightSound}`} />}
      {stepClearSound && <SoundEffectPM url={stepClearSound} onEnd={handleStepClearSoundEnd} />}
      <SoundEffectPM
        url={rightWrongSound}
        onEnd={() => {
          setRightWrongSound(undefined);
        }}
      />
    </>
  );
};

export default ImageSelectWrite;
const ImgUl = styled.ul`
    display: flex;
    justify-content: center;
	/* flex: 1 1; */
	list-style: none;
`;
const ImgLi = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0.8rem 0;
	margin-left: 2%;
    margin-right: 2%;
    border: 2px solid orange;
    border-radius: 5px;
    padding-right: 2%;
	list-style: none;
	
`;