import { gql } from '@apollo/client';

export const NEWS_O_MATIC_SEARCH = gql`
  query ($keyword: String, $page: Int, $take: Int) {
    searchNewsOMaticArticles(keyword: $keyword, page: $page, take: $take)
  }
`;

export const EXTERNAL_USER_STUDY_HISTORY = gql`
  query getExternalStuiesList($user_idx: Int!, $ym: String, $type: String) {
    getExternalStuiesList(user_idx: $user_idx, ym: $ym, type: $type) {
      idx
      external_study_idx
      assigned_idx
      type
      study_date
      data
      article_data
      recording_data
      wpm
      exam_total
      exam_correct
      idate
      last_study_timestamp
    }
  }
`;
export const EXT_READER_LIST = gql`
  query getExtReaderList($ym: String, $type: String, $status: Int, $class_idx: Int, $start_date: String, $end_date: String) {
    getExtReaderList(ym: $ym, type: $type, status: $status, class_idx: $class_idx, start_date: $start_date, end_date: $end_date)
  }
`;

export const EXTERNAL_USER_STUDY_LEVELUP = gql`
  query getExternalStuiesList(
    $user_idx: Int!
    $ym: String
    $type: String
    $status: Int
    $limit: Int
    $external_study_idx: Int
    $assigned_idx: Int
    $status_gte: Int
  ) {
    getExternalStuiesList(
      user_idx: $user_idx
      ym: $ym
      type: $type
      status: $status
      limit: $limit
      external_study_idx: $external_study_idx
      assigned_idx: $assigned_idx
      status_gte: $status_gte
    ) {
      idx
      external_study_idx
      assigned_idx
      type
      study_date
      data
      article_data
      recording_data
      wpm
      exam_total
      exam_correct
      idate
      last_study_timestamp
      external_study_data
      is_fav
      user {
        name
        id
      }
      external_study_stage {
        stage_no
        exam_total
        exam_correct
        answers
        study_date
      }
    }
  }
`;

export const ASSIGNED_EXTERNAL_STUDY_GROUP_LIST = gql`
  query assignedExternalStudyGroupList($class_idx: Int, $type: String) {
    assignedExternalStudyGroupList(class_idx: $class_idx, type: $type) {
      idx
      title
      start_time
      type
      user {
        idx
        name
      }

      class {
        idx
        name
      }

      assigned_external_study {
        idx
        start_date
        external_user_study {
          idx
        }
      }

      start_date
      end_date
      start_time
      idate
    }
  }
`;

export const EXTERNAL_PORTFOLIO_STATICS = gql`
  query getExternalStuiesStatic($ym: String, $type: String, $campus_idx: Int, $class_idx: Int) {
    getExternalStuiesStatic(ym: $ym, type: $type, campus_idx: $campus_idx, class_idx: $class_idx)
  }
`;

export const ASSIGNED_EXTERNAL_STUDY_GROUP = gql`
  query assignedExternalStudyGroup($idx: Int!) {
    assignedExternalStudyGroup(idx: $idx) {
      idx
      title
      start_time
      user {
        idx
        name
      }

      class {
        idx
        name
      }

      assigned_external_study {
        idx
        start_date
        external_study_data
        external_study_idx
        assigned_external_study_user {
          idx
          user_idx
          study_date
          assigned_ext_study_idx
        }
        external_user_study {
          user {
            idx
          }
        }
      }

      start_date
      end_date
      start_time
      idate
    }
  }
`;

export const ASSIGNED_EXTERNAL_STUDY_DETAILS = gql`
  query assignedExternalStudy($assigned_external_study_group_idx: Int, $page: Int, $take: Int) {
    assignedExternalStudy(assigned_external_study_group_idx: $assigned_external_study_group_idx, page: $page, take: $take) {
      idx
      start_date
      limit_time
      external_study_data
      external_study_idx
      assigned_external_study_group {
        class {
          name
        }
      }

      external_user_study {
        user {
          idx
          name
        }
        status
      }
    }
  }
`;

export const ASSIGNED_EXTERNAL_STUDY_DETAILS_WITH_DATE = gql`
  query assignedExternalStudyDate(
    $start_date: String!
    $end_date: String!
    $page: Int
    $take: Int
    $state: String
    $search_text: String
    $campus_idx: Int
    $type: String
    $class_idx: Int
  ) {
    assignedExternalStudyDate(
      start_date: $start_date
      end_date: $end_date
      page: $page
      take: $take
      state: $state
      search_text: $search_text
      campus_idx: $campus_idx
      type: $type
      class_idx: $class_idx
    ) {
      total
      page
      take
      assigned_external_study {
        idx
        limit_time
        external_study_data
        start_date
        type
        assigned_external_study_group {
          idx
          class {
            idx
            name
          }
        }

        external_user_study {
          user {
            idx
            id
            name
          }
          status
          study_date
          last_study_timestamp
          external_study_stage {
            stage_no
            study_date
            exam_total
            exam_correct
          }
        }

        assigned_external_study_user {
          user_idx
          study_date
        }
      }
    }
  }
`;

export const ASSIGNED_EXTERNAL_STUDY_STUDENT = gql`
  query assignedExternalStudyStudent(
    $start_date: String!
    $end_date: String!
    $page: Int
    $take: Int
    $state: String
    $search_text: String
    $campus_idx: Int
    $type: String
    $class_idx: Int
    $page_type: String
  ) {
    assignedExternalStudyStudent(
      start_date: $start_date
      end_date: $end_date
      page: $page
      take: $take
      state: $state
      search_text: $search_text
      campus_idx: $campus_idx
      type: $type
      class_idx: $class_idx
      page_type: $page_type
    ) {
      total
      page
      take
      external_user_study {
        idx
        recording_data
        wpm
        exam_correct
        exam_total
        data
        external_study_quiz_data {
          idx
          no
          quiz_type
          question
          options
          image
        }
        user {
          idx
          id
          name
          external_user_study_count(type: $type, start_date: $start_date, end_date: $end_date)
          external_book_report_count(type: $type, start_date: $start_date, end_date: $end_date)
        }
        status
        study_date
        last_study_timestamp
        external_study_stage {
          stage_no
          study_date
          exam_total
          exam_correct
          external_study_stage_answer {
            question_no
            user_answer
            is_correct
          }
        }
        assigned_external_study {
          idx
          limit_time
          external_study_data
          start_date
          type
          assigned_external_study_group {
            idx
            class {
              idx
              name
            }
          }
        }
      }
    }
  }
`;

export const SINGLE_ASSIGNED_EXTERNAL_STUDY = gql`
  query singleAssignedExternalStudy($idx: Int!) {
    singleAssignedExternalStudy(idx: $idx) {
      idx
      limit_time
      external_study_data
      external_study_idx
      start_date
      assigned_external_study_group {
        class {
          name
          lexile_level
          levelup_level
          idx
        }
      }
      assigned_external_study_user {
        idx
        user_idx
        study_date
        assigned_ext_study_idx
      }
      external_user_study {
        idx
        status
        recording_data
        data
        study_date
        last_study_timestamp
        exam_total
        exam_correct
        wpm
        assigned_external_study {
          external_study_data
          start_date
        }
        external_study_quiz_data {
          idx
          no
          quiz_type
          question
          options
          image
        }
        external_study_stage {
          stage_no
          study_date
          exam_total
          exam_correct
          external_study_stage_answer {
            question_no
            user_answer
            is_correct
          }
        }
        user {
          idx
          id
          name
        }
      }
    }
  }
`;
export const MULTIPLE_ASSIGNED_EXTERNAL_STUDY = gql`
  query multipleAssignedExternalStudy($study_date: String!, $assigned_ext_idx: Int!) {
    multipleAssignedExternalStudy(study_date: $study_date, assigned_ext_idx: $assigned_ext_idx) {
      external_user_study {
        idx
        status
        recording_data
        data
        article_data
        study_date
        last_study_timestamp
        exam_total
        exam_correct
        wpm
      }
      user {
        idx
        id
        name
      }
    }
  }
`;

export const LEVEL_UP_STUDY_DATA = gql`
  query getExternalStudyData($type: String!, $book_id: Int, $book_code: String) {
    getExternalStudyData(type: $type, book_id: $book_id, book_code: $book_code) {
      idx
      eng
      kor
      eng_image
      eng_audio
      eng_slice
      extra_data
      no
    }
  }
`;
export const LEVEL_UP_STUDY_QUIZ_DATA = gql`
  query getExternalStudyQuizData($type: String!, $book_id: Int, $book_code: String) {
    getExternalStudyQuizData(type: $type, book_id: $book_id, book_code: $book_code) {
      idx
      no
      quiz_type
      question
      options
      image
      audio
    }
  }
`;

export const EXTERNAL_STUDY_LIKES_COUNT = gql`
  query getExternalBookLikesCounts($user_idx: Int!, $type: String!) {
    getExternalBookLikesCounts(user_idx: $user_idx, type: $type)
  }
`;
export const EXTERNAL_STUDY_BOOK = gql`
  query getExternalUserBook(
    $type: String!
    $is_fav: Boolean
    $level: String
    $q: String
    $page: Int
    $take: Int
    $orderBy: String
    $code: String
    $book_no: Int
  ) {
    getExternalUserBook(
      type: $type
      is_fav: $is_fav
      level: $level
      q: $q
      page: $page
      take: $take
      orderBy: $orderBy
      code: $code
      book_no: $book_no
    ) {
      idx
      mx_level
      lexile
      lexile_val
      book_no
      code
      title
      title_prefix
      subtitle
      pages
      audio_length
      summary
      genre
      phonic
      publisher
      imprint
      series
      genre__1
      dewey
      Contributor1_Type
      author
      Contributor2_Type
      Contributor2_FirstLast
      category1
      category2
      category3
      category4
      is_voca
      is_interactive
      is_extra_audio
      is_fav
      fav_cnt
      is_completed
      ai_topic {
        topic
        data
      }
    }
  }
`;
export const ASSIGNED_EXTERNAL_STUDY_DATE_GROUP_LIST = gql`
  query assignedExternalStudyDateGroupList($class_idx: Int, $page: Int, $take: Int) {
    assignedExternalStudyDateGroupList(class_idx: $class_idx, page: $page, take: $take)
  }
`;

export const ASSIGNED_EXTERNAL_STUDY_RESULT = gql`
  query assignedExternalStudyResult(
    $start_date: String!
    $end_date: String!
    $page: Int
    $take: Int
    $state: String
    $search_text: String
    $type: String
    $user_idx: Int!
    $page_type: String
  ) {
    assignedExternalStudyResult(
      start_date: $start_date
      end_date: $end_date
      page: $page
      take: $take
      state: $state
      search_text: $search_text
      type: $type
      user_idx: $user_idx
      page_type: $page_type
    ) {
      total
      page
      take
      portfoilio
      assigned_external_study {
        idx
        limit_time
        external_study_data
        start_date
        type
        is_done
        aiTpoic
        assigned_external_study_group {
          idx
          class {
            idx
            name
          }
        }
        external_user_study {
          idx
          status
          recording_data
          data
          article_data
          study_date
          last_study_timestamp
          exam_total
          exam_correct
          wpm
          external_study_stage {
            stage_no
            study_date
            exam_total
            exam_correct
          }
          user {
            idx
            id
            name
          }
        }
      }
    }
  }
`;
