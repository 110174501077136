import React, { useState, useCallback, useEffect } from 'react';
import AlphabetTopArea from './AlphabetTopArea';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import SoundEffect from 'src/components/common/SoundEffect';
import SoundButton from './SoundButton';
import { ImagePopup } from './PopUp';

const AlphabetDnD6 = ({ customData, steps, setSteps }) => {
  const { problem } = customData;

  const [isDropEnds, setIsDropEnds] = useState(Array(2).fill(false));
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);

  const handleDragFinish = (idx) => {
    const copy = _.cloneDeep(isDropEnds);
    copy[idx] = true;
    setIsDropEnds(copy);
  };

  const handleNext = () => {
    if (wordIndex < problem[steps].words.length - 1) {
      setWordIndex(wordIndex + 1);
    } else {
      setSteps(steps + 1);
      setIsDropEnds([false, false]);
      setIsShowPopup(false);
      setWordIndex(0);
    }
  };

  useEffect(() => {
    console.log('isDropEnds', isDropEnds);
    if (isDropEnds.every((v) => v === true)) {
      //setSteps(steps + 1);
      //setIsDropEnds([false, false]);
      setIsShowPopup(true);
    }
  }, [isDropEnds, steps, setSteps]);
  return (
    <>
      <div className="main_content  stage2_content  col2">
        <div>
          <AlphabetTopArea title={`Hard ${problem[steps] && problem[steps].letter.toUpperCase()}`} />
          <AlphabetGroupDnDContextArea
            prefix="hard"
            letter={problem[steps] && problem[steps].letter}
            letterGroup={problem[steps] && problem[steps].hard}
            soundBaseUrl={!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : '/sounds/'}
            onDragFinish={() => {
              handleDragFinish(0);
            }}
          />
        </div>
        <div className="right_box">
          <AlphabetTopArea title={`Soft ${problem[steps] && problem[steps].letter.toUpperCase()}`} />
          <AlphabetGroupDnDContextArea
            prefix="soft"
            letter={problem[steps] && problem[steps].letter}
            letterGroup={problem[steps] && problem[steps].soft}
            soundBaseUrl={!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : '/sounds/'}
            onDragFinish={() => {
              handleDragFinish(1);
            }}
          />
        </div>
      </div>
      {isShowPopup && (
        <ImagePopup
          discImage={problem[steps].words[wordIndex].discImage}
          wordImage={problem[steps].words[wordIndex].wordImage}
          word={problem[steps].words[wordIndex].word}
          number={wordIndex + 1}
          total={problem[steps].words.length}
          sound={problem[steps].words[wordIndex].sound}
          onNext={handleNext}
        />
      )}
    </>
  );
};

export default AlphabetDnD6;

const AlphabetGroupDnDContextArea = ({ prefix, letter, letterGroup, soundBaseUrl, onDragFinish }) => {
  const [dropSound, setDropSound] = useState(undefined);
  const [eachSound, setEachSound] = useState(undefined);
  const [dropped, setDropped] = useState(false);

  const handleDragEnd = useCallback((result) => {
    const { destination } = result;

    if (_.isNil(destination)) {
      setDropSound('wrong_sound1');
      return;
    }
    setDropSound('mouse-click');
    setDropped(true);
  }, []);

  const handleDropSoundEnd = () => {
    setDropSound(undefined);
  };

  const handleEachSoundEnd = () => {
    console.log('handleEachSoundEnd');
    setEachSound(undefined);
    if (dropped) {
      onDragFinish();
    }
  };

  useEffect(() => {
    if (dropped) {
      setEachSound(`${soundBaseUrl}${prefix}_${letter}.mp3`);
    }
  }, [dropped]);

  useEffect(() => {
    setDropped(false);
  }, [letter]);

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <ul className="alphabet_wrap">
          <li>
            <div className="button_box">
              <SoundButton src={`${soundBaseUrl}${prefix}_${letter}.mp3`} />
            </div>
            <AlphabetGroupDroppableBox prefix={prefix} letter={letter} isDropped={dropped} />
          </li>
        </ul>
        <img src="/images/pm/i_arrow_top.svg" alt="위로 향하는 화살표" className="top_arrow" />
        <ul className="alphabet_wrap gray_bg_border_top">
          <AlphabetGroupDraggableBox prefix={prefix} letter={letter} letterGroup={letterGroup} idx={0} />
        </ul>
      </DragDropContext>
      <SoundEffect url={!_.isNil(dropSound) ? `/sound-effect/${dropSound}.mp3` : undefined} onEnd={handleDropSoundEnd} />
      {eachSound && <SoundEffect url={eachSound} onEnd={handleEachSoundEnd} />}
    </>
  );
};

const AlphabetGroupDroppableBox = ({ prefix, letter, isDropped }) => {
  return (
    <Droppable droppableId={`upper_${letter}`} type={`${prefix}_${letter}`}>
      {(provided, snapshot) => (
        <>
          <div
            className={`alphabet_box rows ${isDropped ? 'green' : 'gray'}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined,
            }}
          >
            <span className={`font_${isDropped ? 'pink' : 'gray'}`}>{letter && `${prefix} ${letter.toUpperCase()}`}</span>
          </div>
          <div style={{ display: 'none' }}>{provided.placeholder}</div>
        </>
      )}
    </Droppable>
  );
};

const AlphabetGroupDraggableBox = ({ prefix, letter, letterGroup, idx }) => {
  return (
    <Droppable droppableId={`under_${prefix}_${letter}`} type={`${prefix}_${letter}`} isDropDisabled={true}>
      {(providedDrop) => (
        <li ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
          <Draggable draggableId={`underDrag_${prefix}_${letter}`} index={idx} type={`${prefix}_${letter}`}>
            {(provided) => (
              <div className={`alphabet_box rows green`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <div className="inner_wrap">
                  <p className="large">
                    <span className="font_red">{letter}</span>
                  </p>
                  <p>+</p>
                  <p className="small">
                    <span className="font_blue">
                      {letterGroup &&
                        letterGroup.map((item, key) => (
                          <span key={`letter-group-key${key}`}>
                            {item}
                            <br />
                          </span>
                        ))}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </Draggable>
          <ul style={{ display: 'none' }}>{providedDrop.placeholder}</ul>
        </li>
      )}
    </Droppable>
  );
};
