import React, { useState, useEffect } from 'react';
import _ from 'lodash';
//import MyScriptHook from "./MyScriptHook";
// import {SmallScripter} from "./Scripter";
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import PopUp from './PopUp';
import SwitchButtonBox from './SwitchButtonBox';
import Keyboard from './Keyboard';
import { useLocation } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const correctSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/game-win-notify.mp3';
const wrongSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/wrong-error-sound.mp3';
const woopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';
const MAX_WRONG_COUNT = 2;

const MultiReviewType = ({ stages, steps, setSteps, onFinish }) => {
  const {
    customData: { problem },
  } = stages;
  // 여기에 유저가 적은 답이 표시된다...
  const [answer, setAnswer] = useState(Array(stages.customData.totalCount).fill(undefined));
  const [loadCompletes, setLoadCompletes] = useState(Array(stages.customData.totalCount).fill(false));
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [wrongCount, setWrongCount] = useState(0);
  const [mode, setMode] = useState(false);
  const [getKey, setGetKey] = useState([]);
  const [currentKeyboardStep, setCurrentKeyboardStep] = useState(0);

  const [correctCheck, setCorrectCheck] = useState([]);
  const [userAnswerCheck, setUserAnswerCheck] = useState([]);
  const [clickSound, setClickSound] = useState('');
  const location = useLocation();
  const resvowel = location.pathname.split('/');
  const totalCount = stages.customData.totalCount;

  const safeSteps = steps > problem.length - 1 ? problem.length - 1 : steps;
  const currentActiveStep = steps * totalCount + currentKeyboardStep;

  const handleToggle = () => {
    setMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    if (isError) {
      setMode(false);
    }
  }, [isError]);

  const handleMyScriptLoaded = (idx) => {
    const copy = _.cloneDeep(loadCompletes);
    copy[idx] = true;
    setLoadCompletes(copy);
  };

  const handleSoundEnd = () => {
    if (clickSound === correctSound) {
      setUserAnswerCheck([]);
      setSteps((steps) => steps + 1);
    }
    setClickSound('');
    setCurrentKeyboardStep(0);
  };

  const handleWriteEnd = () => {
    // 리뷰 unit 답체크하는 핸들러
    const answerArray = [];
    const userArray = [];
    _.each(answer, (item) => {
      if (item) userArray.push(item.join(''));
      else userArray.push(item);
    });
    Object.keys(problem[steps].exImage).forEach((inner) => {
      if (problem[steps].exImage[inner].isAnswer) {
        console.log(problem[steps].exImage[inner].image);
        answerArray.push(problem[steps].exImage[inner].image.split('/image_')[1].split('.png')[0]);
      }
    });
    const ansIsCorrect = [];
    _.each(userArray, (val) => {
      // user의 정답을 보고 answer에 있으면 해당 val을 뽑아내서 중복 정답을 막는다...
      if (answerArray.includes(val)) {
        answerArray.splice(answerArray.indexOf(val), 1);
        ansIsCorrect.push(true);
      } else {
        ansIsCorrect.push(false);
      }
    });
    setUserAnswerCheck(userArray);
    setCorrectCheck(ansIsCorrect);
    // refact : 정답 answer every인지 아닌지만 살핌...
    // 얘는 완전히 다 맞았을 때만 reset 되어야 하는데, 안 그러면 index가 꼬여버리기 때문임..
    // 일단 진행해보고 todo로 남겨두기
    if (ansIsCorrect.every((val) => val)) {
      if (steps <= problem.length - 1) {
        setClickSound(correctSound);
      }
    } else {
      if (wrongCount >= MAX_WRONG_COUNT - 1) {
        setClickSound(woopsSound);
        setWrongCount(0);

        setTimeout(() => {
          setUserAnswerCheck([]);
          setSteps((steps) => steps + 1);
          setCurrentKeyboardStep(0);
        }, 1000);
      } else {
        let tempAns = _.cloneDeep(answer);
        _.each(ansIsCorrect, (item, index) => {
          if (!item) tempAns[index] = undefined;
        });
        setClickSound(wrongSound);
        setAnswer(tempAns);
        setWrongCount(wrongCount + 1);
        setCurrentKeyboardStep(0);
      }
    }
  };

  useEffect(() => {
    if (correctCheck.every((v) => v)) {
      setCorrectCheck([]);
      setAnswer(Array(stages.customData.totalCount).fill(undefined));
    } else {
      setCorrectCheck([...correctCheck]);
      setAnswer([...answer]);
    }
    if (steps >= problem.length) {
      onFinish();
    }
    // eslint-disable-next-line
  }, [steps]);

  useEffect(() => {
    if (loadCompletes.every((item) => item === true)) {
      setIsLoading(false);
    }
  }, [loadCompletes]);

  return (
    <>
      {/* <div className="contents_align">
				<p>
					<span className="text_default">
						Step {safeSteps + 1}. {stages.customData.problem[safeSteps].exVowel[0]} Vowel&nbsp;-&nbsp;
					</span>
					<span className="text_red">{stages.customData.problem[safeSteps].exVowel[1]}</span>
				</p>
				<ul className="write_box">
					{_.map(answer, (__, index) => (
						// <MyScriptHook correctCheck={correctCheck} index={index} answer={answer} setAnswer={setAnswer} isReview={true} isMulti={true} />
						<SmallScripter
							key={`small-scripter-${index}`}
							correctCheck={correctCheck}
							index={index}
							answer={answer}
							setAnswer={setAnswer}
							steps={safeSteps}
							onLoaded={() => {
								handleMyScriptLoaded(index);
							}}
						/>
					))}
					<p className="pencil_enter" onClick={handleWriteEnd} style={{cursor: "pointer"}}>
						<span className="btn_icon i_enter"></span>
					</p>
				</ul>
			</div>
			<div className="contents_align">
				<ul className="images_box">
					{_.map(stages.customData.problem[safeSteps].exImage, item => (
						<li>
							<img src={item.image} alt={item.image} />
						</li>
					))}
				</ul>
			</div> */}
      <S.BorderRowBox
        style={{ padding: '1.2rem', alignSelf: 'stretch', flex: '1 1', position: 'relative', height: 'calc(100% - 70px)' }}
        id="lv3_review7_content"
        className={(resvowel[2] === '2a' || resvowel[2] === '2b') && resvowel[6] == '7' ? 'cust-vowel main_content' : 'main_content'}
      >
        <ImageUl className="img_box">
          {_.map(stages.customData.problem[safeSteps].exImage, (item, key) => (
            <ImageLi
              key={`ex-image-key-${key}`}
              className={
                userAnswerCheck.length > 0 && userAnswerCheck.indexOf(item.image.split('/image_')[1].split('.png')[0]) !== -1
                  ? 'active'
                  : ''
              }
            >
              <img style={{ height: '10vh' }} src={item.image} alt={item.image} />
            </ImageLi>
          ))}
        </ImageUl>

        <S.ColumnFlex style={{ flex: '3 1' }} className="write_box">
          <TitleTxt>
            Step {safeSteps + 1}. {stages.customData.problem[safeSteps].exVowel[0]} Vowel -{' '}
            <strong style={{color:'#ee5353'}} className="font_red">{stages.customData.problem[safeSteps].exVowel[1]}</strong>
          </TitleTxt>
          {/* {isError ? (
            <SwitchButtonBox toggleDevice={handleToggle} device={false} />
          ) : (
            <SwitchButtonBox toggleDevice={handleToggle} device={true} />
          )} */}
          {/* <ul className="small_scripter" style={mode ? { display: 'flex' } : { display: 'none' }}> */}
            {/* {_.map(answer, (__, index) => (
							// <MyScriptHook correctCheck={correctCheck} index={index} answer={answer} setAnswer={setAnswer} isReview={true} isMulti={true} />
							<SmallScripter
								key={`small-scripter-${index}`}
								correctCheck={correctCheck}
								index={index}
								answer={answer}
								setAnswer={setAnswer}
								steps={safeSteps}
								onLoaded={() => {
									handleMyScriptLoaded(index);
								}}
								onError={() => {
									setIsError(true);
								}}
							/>
						))} */}
          {/* </ul> */}

          <div
            style={
              mode
                ? { display: 'none' }
                : { margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }
            }
          >
            <p className="step_num" style={{ marginBottom: '10px',display: 'flex',
justifyContent: 'center',
alignItems: 'center',
width: '32px',
height: '32px',
borderRadius: '100%',
fontWeight: '600',
fontSize: '18px',
color: '#fff',
backgroundColor: '#54a0ff' }}>
              {currentKeyboardStep + 1}
            </p>
            {/* <p sty className="output_area">{answer[currentActiveStep] ? answer[currentActiveStep] : getKey || ''}</p> */}
            <Keyboard
              getKey={getKey}
              enabledWritePreview={true}
              setGetKey={setGetKey}
			  imageWordWrite={true}
              onWriteEnd={() => {
                answer[currentActiveStep] = getKey;
                setAnswer([...answer]);
                setGetKey([]);
                if (currentKeyboardStep < totalCount - 1) {
                  setCurrentKeyboardStep(currentKeyboardStep + 1);
                }
              }}
            />
          </div>

          <EnterBtn
            style={{
              display: (answer[currentActiveStep] && !mode) || mode ? 'inline-flex' : 'none',
              cursor: 'pointer',
            }}
            className="enter level3"
            onClick={handleWriteEnd}
          >
            <img style={{height:'3vh'}} src="/images/pm/i_enter.svg" alt="엔터 아이콘" />
          </EnterBtn>
        </S.ColumnFlex>
      </S.BorderRowBox>
      {/* {isLoading && <PopUp popUpType="loading" />} */}
      <SoundEffectPM url={clickSound} onEnd={handleSoundEnd} />
    </>
  );
};

export default MultiReviewType;
const ImageUl = styled.ul`
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(2, 1fr);
  grid-gap: 1rem;
  flex: 2 1;
  margin-right: 2rem;
  list-style: none;
  padding: 0;
`;
const ImageLi = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 1rem;
  border: 3px solid #e5e5e5;
`;
const TitleTxt = styled.h4`
  margin: 0;
  align-self: center;
  padding: 8px 32px;
  border-radius: 32px;
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  background-color: #ffeed8;
`;
const EnterBtn = styled.p`
    display: inline-flex;
    cursor: pointer;
	display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    width: 90px;
    height: 40px;
    margin: 0;
    background-color: #ff7474;
    border: 2px solid #fff;
    border-radius: 12px;
    box-shadow: 0 4px 0 #d53737;
`;