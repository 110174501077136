import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Typography, Row, Button, Form, Input, Select, Upload, Space, Checkbox } from 'antd';
import { ArrowRightOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';

import CustomTable from 'src/components/common/CustomTable';
import BackButton from '../common/BackButton';
import { openNotification } from 'src/components/common/Notification';
import { HalfWrapper } from '../common/Styles';
import CustomButton from 'src/components/common/CustomButton';
import { useModal } from 'src/utils/hooks';
import WorkBookModal from 'src/components/Modal/WorkBookModal';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { userInfoVar } from 'src/apollo/cache';

const { Title } = Typography;

const onHandleRowClick = (row, index, user) => {
  if (window.Android && row.isMeeting) {
    if (user.type === 1) {
      window.Android.joinZoomClass(JSON.stringify(user));
    } else {
      window.Android.startZoomClass(JSON.stringify(user));
    }
  }
};

const SyllabusEdit = () => {
  const { idx } = useParams();
  const [fileList, setFileList] = useState([]);
  const [testIdx, setTestIdx] = useState(null);
  const [form] = Form.useForm();
  const { visible, showModal, handleCancel } = useModal();

  const [initialFormValues, setInitialFormValues] = useState();

  const { data: fetchSyllabus, refetch } = useQuery(queries.getTests.TEST_GROUP_INFO, {
    fetchPolicy: 'no-cache',
    variables: { idx: parseInt(idx) },
  });

  const TestBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
`;

  const [updateTestGroup] = useMutation(mutations.updateTests.UPDATE_TEST_GROUP, {
    variables: { ...initialFormValues, testGroupIdx: parseInt(idx) },
    onCompleted: () => {
      refetch();
      openNotification('수정 완료!');
    },
  });

  const testsGroupData = useMemo(() => {
    if (fetchSyllabus) {
      let folderType = 'me';

      if (fetchSyllabus.testGroupInfo?.campus?.idx && !fetchSyllabus.testGroupInfo?.company?.idx) {
        folderType = 'campus';
      } else if (fetchSyllabus.testGroupInfo?.campus?.idx && fetchSyllabus.testGroupInfo?.company?.idx) {
        folderType = 'publisher';
      }

      return {
        idate: fetchSyllabus.testGroupInfo?.idate,
        idx: fetchSyllabus.testGroupInfo?.idx,
        name: fetchSyllabus.testGroupInfo?.name,
        code: fetchSyllabus.testGroupInfo?.base_code,
        folderType,
      };
    }
    return {};
  }, [fetchSyllabus]);

  const testsData = useMemo(() => {
    if (fetchSyllabus) {
      return fetchSyllabus.testGroupInfo?.tests
        .sort((a, b) => {
          return Number(a.title.match(/(\d+)/g)[0]) - Number(b.title.match(/(\d+)/g)[0]);
        })
        .reduce((acc, cur, index) => {
          const obj = {
            pdf: `${cur.title}.pdf`,
            excel: `${cur.title}.xlsx`,
            ex_p: cur.extra_pdf ? true : false,
            ex_m: cur.extra_audio ? true : false,
            ex_v: cur.extra_video ? true : false,
            No: index + 1,
            key: index + 1,
            test_idx: cur.idx,
            code: cur.code,
          };

          return [...acc, obj];
        }, []);
    }

    return [];
  }, [fetchSyllabus]);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        console.log('formData.folderType', formData.folderType);
        setInitialFormValues((prev) => ({
          code: formData.code,
          title: formData.title,
          folderType: formData.folderType,
          //files: prev.files,
        }));

        updateTestGroup();
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  const props = {
    multiple: true,
    onRemove: (file) => {
      setFileList((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });

      setInitialFormValues((prev) => {
        const index = prev.files.indexOf(file);
        const newFileList = prev.files.slice();
        newFileList.splice(index, 1);

        return {
          code: form.getFieldValue('code'),
          title: form.getFieldValue('title'),
          folderType: form.getFieldValue('folderType'),
          files: newFileList,
        };
      });
    },
    beforeUpload: (file) => {
      setFileList((prev) => [...prev, file]);
      setInitialFormValues((prev) => {
        return {
          code: form.getFieldValue('code'),
          title: form.getFieldValue('title'),
          folderType: form.getFieldValue('folderType'),
          files: [...prev.files, file],
        };
      });
      return false;
    },
    fileList,
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue({
      code: testsGroupData.code,
      title: testsGroupData.name,
      folderType: testsGroupData.folderType,
    });
  }, [form, initialFormValues, testsGroupData]);

  const showStudentEditModal = (idx) => {
    setTestIdx(idx);
    showModal();
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      width: 30,
      className: 'pad-4',
      align: 'center',
    },
    {
      title: 'Test Code',
      dataIndex: 'code',
      key: 'code',
      width: 170,
      align: 'center',
    },
    {
      title: 'PDF',
      dataIndex: 'pdf',
      key: 'pdf',
      ellipsis: true,
      className: 'pad-4',
      align: 'center',
    },
    {
      title: 'Excel',
      dataIndex: 'excel',
      key: 'excel',
      ellipsis: true,
      className: 'pad-4',
      align: 'center',
    },
    {
      title: 'P',
      key: 'ex_p',
      align: 'center',
      render: (text, record) => {
        return (
          <Space size="small" style={{ padding: 0 }}>
            <Checkbox name="" defaultChecked={record.ex_p} disabled />
          </Space>
        );
      },
      width: 40,
      className: 'pad-4',
    },
    {
      title: 'A',
      key: 'ex_m',
      align: 'center',
      render: (text, record) => {
        return (
          <Space size="small">
            <Checkbox name="" defaultChecked={record.ex_m} disabled />
          </Space>
        );
      },
      width: 40,
      className: 'pad-4',
    },
    {
      title: 'V',
      key: 'ex_v',
      align: 'center',
      render: (text, record) => {
        return (
          <Space size="small">
            <Checkbox name="" defaultChecked={record.ex_v} disabled />
          </Space>
        );
      },
      width: 40,
      className: 'pad-4',
    },
    {
      title: '수정',
      key: 'edit',
      align: 'center',
      width: 70,
      render: (text, record) => {
        return (
          <Space size="small">
            <CustomButton
              onClick={() => {
                showStudentEditModal(record.test_idx);
              }}
              size="small"
              icon={<EditOutlined />}
            />
            <Link to="#">
              {/* <Button size="small" style={{ color: '#1890ff' }}>
                Edit
              </Button> */}
            </Link>
          </Space>
        );
      },

      className: 'pad-4',
    },
  ];

  return (
    <>
      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItem: 'center',
          paddingTop: 15,
          paddingBottom: 10,
        }}
      >
        <BackButton />
      </Col>
      <Col className="addnew-testmanagment" span={24}>
        <TestBlock className="testmanagment-testblock">
          <Row gutter={[24, 16]}>
              <Col span={8} className="testmanagment-sectiona">
                <TestSectionBlock className="testmanagment-testsectionblock">
                  <Title level={5}>
                    <span>Edit</span>
                  </Title>
                  <Form
              form={form}
              labelCol={{
                span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="horizontal"
              style={{
                minHeight: 400,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
              }}
              onFinish={handleFormSubmit}
              onFinishFailed={onFinishFailed}
              //initialValues={initialFormValues}
            >
              <Form.Item label="Test Code" name="code" style={{ marginBottom: 5 }}>
                <Input />
              </Form.Item>

              <Form.Item name="title" label="WB Title" style={{ marginBottom: 5 }} rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item name="folderType" label="Folder Type" style={{ marginBottom: 5 }} rules={[{ required: true }]}>
                <Select
                  style={{color: '#065aa9' }}
                  //defaultValue="me"
                  placeholder="Select a folder type"
                  optionFilterProp="children"
                >
                  {userInfoVar()?.type > 1 && <Select.Option value="publisher">FC 본사</Select.Option>}
                  <Select.Option value="campus">우리 학원 컨텐츠</Select.Option>
                  <Select.Option value="me">내 개인 컨텐츠</Select.Option>
                </Select>
              </Form.Item>

              {/* <Form.Item name="files" label="WB ZIP Upload" valuePropName="files" style={{ marginBottom: 5 }}>
                <Upload {...props} name="files">
                  <Button icon={<UploadOutlined />}>Click to uplodad</Button>
                </Upload>
              </Form.Item> */}

              <Col
                style={{
                  textAlign: 'center',
                }}
              >
                <Button type="primary" htmlType="submit" shape="round" size="large">
                  Save
                </Button>
              </Col>
            </Form>
            </TestSectionBlock>
              </Col>
          
      <Col span={16} className="testmanagment-sectionb syllabusarow">
        <div className="syllabusarow-arrow">
          <ArrowRightOutlined style={{ fontSize: '2.5rem', color: '#40A9FF' }} />
          </div>
        <TestSectionBlock className="testmanagment-testsectionblock-two">
            <Row gutter={[8, 8]} align="top">
              <Col span={12}>
                <Title level={5}>Answer Sheet Check:</Title>
              </Col>
              <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}></Col>
            </Row>

            <CustomTable
              className="syllabustbl"
              dataSource={testsData}
              columns={columns}
              size="small"
              scroll={{ y: 'calc(100vh - 254px)' }}
              bordered
              pagination={{ pageSize: 10 }}
              color="#edf3fb"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => onHandleRowClick(record, rowIndex),
                };
              }}
            />
         
         </TestSectionBlock>
      </Col>
      </Row>
        </TestBlock>
      </Col>


      {visible ? <WorkBookModal popupMode="edit" handleCancel={handleCancel} visible={visible} testIdx={testIdx} /> : null}
    </>
  );
};

export default SyllabusEdit;
