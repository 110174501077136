import React from "react";
import SoundButton from "./SoundButton";
import * as S from 'src/pages/PhonicsMonster/style';

const AlphabetTopArea = ({title, soundUrl, isShortVowel}) => {
	return (
		<S.RowFlex style={{margin:'1.2rem'}} className="top_area">
			<S.RibbonTitle isShortVowel={isShortVowel} className={`tit_ribbon ${isShortVowel ? "hidden" : ""}`}>
				<span style={{color:'#fff', paddingBottom:6}}>{title}</span>
			</S.RibbonTitle>
			{soundUrl && <SoundButton src={soundUrl} />}
		</S.RowFlex>
	);
};

export default AlphabetTopArea;
