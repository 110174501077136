import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Row, Button, Select, Tooltip } from 'antd';
import { withRouter, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { AI_TOPIC_USER_ASSIGNMENT_LIST } from 'src/operations/queries/aiTopic';
import { UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import { useQuery, useMutation } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import LevelUpBookReportMenuGroup from 'src/components/common/LevelUpBookReportMenuGroup';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import BookReportAnswerModal from 'src/components/Modal/BookReportAnswerModal';
import GeneratePdfButton from 'src/components/common/GeneratePdfButton';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const CampusResults = (props) => {
  const query = new URLSearchParams(props.location.search);
  const isRefresh = query.get('refresh');
  const history = useHistory();
  const [classes, setClasses] = useState([]);
  const [ai_topic_user_assignments, setAssignedAITopics] = useState([]);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [topic, setTopic] = useState();
  const [currentChatList, setCurrentChatList] = useState([]);
  const [userTopicId, setUserTopicId] = useState();
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [topicAnswer, setTopicAnswer] = useState('');
  const [recordingData, setRecordingData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      class_idx: 0,
      page: 0,
      class_type: 0, //class_type - 0: 진행, 1: 종료
    },
    'ai_topic_result',
  );

  const [updateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });

  const { data, loading, refetch } = useQuery(AI_TOPIC_USER_ASSIGNMENT_LIST, {
    // variables: { class_idx, page, take },
    variables: { class_idx: searchValue.class_idx, class_type: searchValue.class_type, type: 'book-report' },
    fetchPolicy: 'no-cache',
  });

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  useEffect(() => {
    if (data?.aiTopicUserAssignmentList) {
      setAssignedAITopics(
        data?.aiTopicUserAssignmentList.ai_topic_user_assignments
          .sort((a, b) => new Date(b.ai_topic_date_assignment.start_date) - new Date(a.ai_topic_date_assignment.start_date))
          .map((item, index) => {
            return {
              ...item,
              no: index + 1,
              start_date: data?.aiTopicDateAssignmentDetails?.start_date,
              rubric_data: JSON.parse(item.rubric_data),
              ai_request_responses: item.ai_request_responses.map((item) => {
                return {
                  ...item,
                  promt: item.prompt_text,
                  content: item.completion_text,
                  data: JSON.parse(item.data),
                  created: item.idate,
                };
              }),
            };
          }),
      );

      setTotal(data?.aiTopicUserAssignmentList.total);
    }

    if (isRefresh) {
      refetch();
      props.history.replace({ search: '' });
    }
  }, [data, isRefresh]);

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }
  }, [classQuery]);

  const handleAITopicAssignDelete = (item) => {
    const idxs = [item.idx];
    props.onAITopicAssignDelete({ idxs });
    setTimeout(() => {
      refetch();
      classQuery.refetch();
    }, 1000);
  };
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
    },
    {
      title: 'A. Date',
      dataIndex: 'ai_topic_date_assignment',
      align: 'center',
      key: 'date',
      width: 100,
      sorter: (a, b) =>
        a.ai_topic_date_assignment.start_date && a.ai_topic_date_assignment.start_date.localeCompare(b.ai_topic_date_assignment.start_date),
      render: (text, record) => {
        return <Space size="middle">{record.ai_topic_date_assignment.start_date.substr(0, 10)}</Space>;
      },
    },
    {
      title: 'Stu_Name(id)',
      dataIndex: 'user',
      key: 'user',
      width: 180,
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.user.name + '(' + record.user.id + ')'}</Space>;
      },
    },
    {
      title: 'Topic/Prompt',
      dataIndex: 'topic',
      key: 'topic',
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.ai_topic.topic}</Space>;
      },
    },
    {
      title: 'Wpm',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">-</Space>;
      },
    },
    {
      title: 'Rubric',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status < 1) {
          return <Space size="middle">-</Space>;
        }

        if (record.rubric_data) {
          return <Space size="middle">{record.rubric_data?.score}</Space>;
        }
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                props.history.push('/level-up/results/rubric/' + record.idx);
              }}
            >
              Edit
            </Button>
          </Space>
        );
      },
    },
    {
      title: 'TOEFL',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record?.rubric_data?.toefl) {
          return <Space size="middle">{record?.rubric_data?.toefl}</Space>;
        } else return <Space size="middle">-</Space>;
      },
    },
    {
      title: 'Original',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  if (record.ai_request_responses.length > 0) {
                    setCurrentChatList([
                      {
                        ...record.ai_request_responses[0],
                        content: record.ai_request_responses[0]?.data?.currentText || '',
                      },
                    ]);
                  }

                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Check',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  setCurrentChatList(record.ai_request_responses.filter((item) => item?.data?.type === 'check'));
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Comment',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  setCurrentChatList(record.ai_request_responses.filter((item) => item?.data?.type === 'comment'));
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Rewrite',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  setCurrentChatList([
                    {
                      content: record.answer,
                      prompt: record.answer,
                      idate: record.idate,
                      data: '{}',
                    },
                  ]);
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Pt/Wpm',
      key: 'wpm',
      dataIndex: 'wpm',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status < 2) {
          return <span>-</span>;
        } else {
          return (
            <Space size="middle">
              <Button onClick={() => openRecordingDataModal({ ...record, accuracy: `${record.score}%` }, record?.user?.name)}>
                {text}
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Std.PDF',
      key: 'user',
      dataIndex: 'user',
      width: 90,
      align: 'center',
      render: (text, record) => {
        if (!record.rubric_data) {
          return <span>-</span>;
        } else {
          return (
            <Space size="middle">
              <GeneratePdfButton
                saveData={async (url) => {
                  await handleGeneratePdf(record.idx, { ...record.rubric_data, pdf_url: url });
                }}
                url={`https://culp.cloubot.com/level-up/report/generate/${record.idx}?pdfType=student`}
                pdfUrl={record.rubric_data?.pdf_url}
                filename={`-level-up-report-result-${record.idx}`}
                isEditUrl={`/level-up/report/generate/${record.idx}?pdfType=student`}
              />
            </Space>
          );
        }
      },
    },
    {
      title: 'T.PDF',
      key: 'user',
      dataIndex: 'user',
      width: 90,
      align: 'center',
      render: (text, record) => {
        if (!record.rubric_data) {
          return <span>-</span>;
        } else {
          let commentFull = record.ai_request_responses.filter((item) => item?.data?.type === 'comment-full');
          return (
            <Space size="middle">
              <GeneratePdfButton
                saveData={async (url) => {
                  await handleGeneratePdf(record.idx, { ...record.rubric_data, pdf_url_teacher: url });
                }}
                url={`https://culp.cloubot.com/level-up/report/generate/${record.idx}?pdfType=teacher&type=${
                  commentFull.length > 0 ? 'comment-full' : 'comment'
                }`}
                pdfUrl={record.rubric_data?.pdf_url_teacher}
                filename={`-level-up-report-result-full-${record.idx}`}
                isEditUrl={`/level-up/report/generate/${record.idx}?pdfType=teacher&type=${
                  commentFull.length > 0 ? 'comment-full' : 'comment'
                }`}
              />
            </Space>
          );
        }
      },
    },
  ];

  const handleGeneratePdf = async (idx, data) => {
    await updateAITopicUserAssignment({
      variables: {
        idx,
        rubric_data: JSON.stringify(data),
      },
    });
    refetch();
  };

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  function openRecordingDataModal(info, nameStr) {
    setIsRecordingVisible(true);
    setRecordingData(info);
    setCurrentUserName(nameStr);
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }

  return (
    <>
      <LevelUpBookReportMenuGroup currentMenu="level-up-report-result" />
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col className="contenttest-title-result" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>Book Topic Result</Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Select
                style={{ width: 150, color: '#065aa9', marginRight: 10 }}
                placeholder="Select a Class"
                //onChange={(idx) => setClassIdx(idx)}
                //defaultValue={searchValue.class_idx}
                value={searchValue.class_idx}
                onChange={(val) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      class_idx: parseInt(val),
                    };
                  })
                }
              >
                <Select.Option value={0}>전체</Select.Option>
                {classes.map((item) => (
                  <Select.Option key={item.idx} value={item.idx} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            loading={loading}
            dataSource={ai_topic_user_assignments}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            //scroll={{ y: 'calc(100vh - 254px) !important' }}
            //pagination={{ pageSize: take, total }}
            pagination={{ pageSize: take, current: searchValue.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            //onChange={(a) => setPage(a.current - 1)}
          />
        </MainBlock>
        {viewAnswerModal && (
          <BookReportAnswerModal
            visible={viewAnswerModal}
            chatList={currentChatList}
            handleCancel={() => {
              setViewAnswerModal(false);
            }}
            topic={topic}
          />
        )}
        <RecordingDataModal
          data={{ ...recordingData, currentUserName, className: '' }}
          isShow={isRecordingVisible}
          onPrint={null}
          componentRef={null}
          onOk={handleOkForRecordingData}
          hidePrint={true}
        />
      </Col>
    </>
  );
};

export default withRouter(CampusResults);
