import React, { useMemo, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Col, Typography, Row } from 'antd';
import moment from 'moment';
import FileSaver from 'file-saver';

import AssignedSyllabusStudentModal from '../Modal/AssignedSyllabusStudentModal';
import AddAssignTestStudents from '../Modal/AddAssignTestStudents';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import CustomButton from 'src/components/common/CustomButton';

import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';

const { Title, Text } = Typography;

const SyllabusAssignedDetail = (props) => {
  const { id } = useParams();
  const [userTestInfo, setuserTestInfo] = useState(null);
  const { visible, showModal, handleCancel, popupMode } = useModal();
  const {
    visible: addStudentVisible,
    showModal: addStudentShowModal,
    handleCancel: addStudentHandleCancel,
    popupMode: addStudentPopupMode,
  } = useModal();

  const { data: fetchTestGroupAssigned, refetch: assginedRefetch } = useQuery(queries.getTests.TEST_GROUP_ASSIGNED_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: { test_group_assigned_idx: parseInt(id) },
  });

  const TestGroupIdx = useMemo(() => {
    return fetchTestGroupAssigned?.testGroupAssignedDetail?.test_group?.idx;
  }, [fetchTestGroupAssigned?.testGroupAssignedDetail]);

  const TestGroupName = useMemo(() => {
    return fetchTestGroupAssigned?.testGroupAssignedDetail?.test_group?.name;
  }, [fetchTestGroupAssigned?.testGroupAssignedDetail]);

  const ClassName = useMemo(() => {
    return fetchTestGroupAssigned?.testGroupAssignedDetail?.class?.name;
  }, [fetchTestGroupAssigned?.testGroupAssignedDetail]);

  const classIdx = useMemo(() => {
    return fetchTestGroupAssigned?.testGroupAssignedDetail?.class?.idx;
  }, [fetchTestGroupAssigned?.testGroupAssignedDetail]);

  const assignedTests = useMemo(() => {
    const existAssignedTests = fetchTestGroupAssigned?.testGroupAssignedDetail?.assigned_tests;
    return existAssignedTests ? existAssignedTests : [];
  }, [fetchTestGroupAssigned?.testGroupAssignedDetail]);

  const assignedTestUsers = useMemo(() => {
    const firstAssignedTest = fetchTestGroupAssigned?.testGroupAssignedDetail?.assigned_tests[0];

    return firstAssignedTest?.assigned_test_users.length ? firstAssignedTest?.assigned_test_users : [];
  }, [fetchTestGroupAssigned?.testGroupAssignedDetail]);

  const addColumn = useMemo(() => {
    let added = [];

    if (assignedTests.length) {
      added = assignedTests
        .sort((a, b) => {
          return Number(a.title.match(/(\d+)/g)[0]) - Number(b.title.match(/(\d+)/g)[0]);
        })
        .map((item) => ({
          key: `tests-${item.idx}`,
          title: `${item.title}`,
          dataIndex: `tests-${item.idx}`,
          align: 'center',
          tests: item.idx,
          assignedTests: item?.idx,
        }));
    }

    return [...defaultColumns, ...added];
  }, [assignedTests]);

  const userTestsData = useMemo(() => {
    if (assignedTestUsers) {
      return assignedTestUsers.reduce((acc, cur, index) => {
        const obj = {
          key: `tests${index}`,
          No: index + 1,
          Name: cur?.user?.name,
        };

        let userTestsCompleted = 0;

        for (let i = 0; i < addColumn.length; i++) {
          if (addColumn[i].assignedTests) {
            const studyResult = getTestResultStatus(cur.user_idx, addColumn[i].assignedTests);
            //console.log('studyResult', studyResult);
            if (studyResult === 'y') {
              userTestsCompleted++;
              obj[addColumn[i].dataIndex] = <span style={{ color: '#289428' }}>완료</span>;
            } else if (studyResult === 'n') {
              obj[addColumn[i].dataIndex] = <span style={{ color: '#898888' }}>--</span>;
            } else if (studyResult === 'noTry') {
              obj[addColumn[i].dataIndex] = <span style={{ color: '#ef811a' }}>미응시</span>;
            } else {
              userTestsCompleted++;
              obj[addColumn[i].dataIndex] = studyResult;
            }

            // if (obj[addColumn[i].dataIndex] !== 'n') {
            //   userTestsCompleted++;
            // }
          }
        }

        obj['completed'] = `${userTestsCompleted} / ${assignedTests.length}`;

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [assignedTestUsers, addColumn]);

  function getTestResultStatus(user_idx, test_idx) {
    const userData = assignedTests
      .filter((assignedTest) => assignedTest.idx === test_idx)[0]
      .assigned_test_users.filter((user) => {
        return user.user_idx === user_idx;
      })[0];

    const startT = moment(userData?.assigned_tests?.start_time);

    if (moment().isAfter(startT) && userData.status === 1) {
      return 'noTry';
    }

    if (userData.status === 2 && userData.score >= 0) {
      const userTest = {
        user_idx: userData.user_idx,
        assignedTests: userData?.assigned_tests?.idx,
      };

      return (
        <span style={{ color: '#289428', cursor: 'pointer' }} onClick={() => studentDetail(userTest)}>
          {userData.score}
        </span>
      );
    }

    return userData.status === 2 ? 'y' : 'n';
    //return userData.status === 2 ? <span style={{ color: '#289428' }}>완료</span> : <span style={{ color: '#ff7f00' }}>--</span>;
  }

  // const handleDownload = async () => {
  //   let blob = await fetch(assigned_test.tests.main_pdf.path).then((r) => r.blob());
  //   FileSaver.saveAs(blob, assigned_test.tests.main_pdf.name);
  // };

  function studentDetail(userTest) {
    setuserTestInfo(userTest);
    showModal();
  }

  return (
    <>
      <Row justify="space-around">
        <Col span={12}>
          <HeaderTitle level={4}></HeaderTitle>
        </Col>
        <Col span={12} style={{ padding: '10px 0', textAlign: 'right' }}>
          <BackButton />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="syllabus-assigned-halfwrapper">
            <div
              className="syllabus-assigned-halfwrapper-dv"
              style={{
                display: 'flex',
                marginBottom: 5,
                justifyContent: 'space-between',
              }}
            >
              <Title level={5}>
                <span style={{ color: '#065aa9', marginLeft: 10 }}>Work Book Title : </span>
                {TestGroupName}
                <span style={{ color: '#065aa9', marginLeft: 10 }}>Class Name : </span>
                {ClassName}
              </Title>

              <Col className="syllabus-assigned-halfwrapper-btn">
                <CustomButton
                  style={{
                    marginRight: 10,
                    border: '1px solid #52c419',
                    width: '100%',
                  }}
                  onClick={addStudentShowModal}
                >
                  <Text type={'success'}>학생 추가/삭제</Text>
                </CustomButton>
              </Col>
            </div>
            <CustomTable
              dataSource={userTestsData}
              columns={addColumn}
              size="small"
              color="#edf3fb"
              pagination={{ hideOnSinglePage: true }}
              scroll={{ y: 'calc(100vh - 254px)' }}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {visible && (
        <AssignedSyllabusStudentModal userTestInfo={userTestInfo} popupMode={popupMode} handleCancel={handleCancel} visible={visible} />
      )}

      <AddAssignTestStudents
        visible={addStudentVisible}
        handleCancel={addStudentHandleCancel}
        class_idx={classIdx}
        classStudentsRefetch={assginedRefetch}
        testUsers={assignedTestUsers?.map((user) => user.user_idx)}
        testGroupIdx={TestGroupIdx}
        testGroupAssignedIdx={id}
        refetch={assginedRefetch}
      />
    </>
  );
};

const defaultColumns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'Name',
    align: 'center',
    width: 120,
    fixed: 'left',
  },
  {
    title: '총',
    dataIndex: 'completed',
    key: 'completed',
    align: 'center',
    width: 100,
    fixed: 'left',
  },
];

export default withRouter(SyllabusAssignedDetail);
