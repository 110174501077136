import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Select, Input, Modal, Button, Typography } from 'antd';
import queryString from 'query-string';
import { SearchOutlined, ArrowRightOutlined, ScheduleTwoTone } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import { useQuery } from '@apollo/client';
import { EXTERNAL_USER_STUDY_LEVELUP } from 'src/operations/queries/externalStudy';
import LevelUpLibraryMenuGroup from 'src/components/common/LevelUpLibraryMenuGroup';
import moment from 'moment';
import * as axios from 'axios';
import _, { set } from 'lodash';
import BackButton from 'src/components/common/BackButton';
import { useEffect } from 'react';
import { isPturn } from 'src/utils/index';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
const { Option } = Select;
const { Title } = Typography;
// const { Search } = Input;
const Wrapper = styled.div`
  width: 100%;

  height: 100%;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 0 0 13px 13px;
  padding: 0 15px 25px;
  background-color: #ffffff;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
`;
const MainHead = styled.div`
  border-bottom: 1px solid #eae8e3;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 15px 10px;
  margin-bottom: 15px;
  font-size: 22px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainHeadBox = styled.div`
  display: flex;
  flex-direction: row;
`;
const SelectBox = styled(Select)`
  height: 35px;
  width: 130px;
  border-radius: 18px;
  border: 1px solid #dcd7cc;
`;
const SearchInput = styled(Input)`
  height: 35px;
  width: 150px;
  border-radius: 18px;
  border: 1px solid #dcd7cc;
  :hover {
    border: 1px solid #dcd7cc;
  }
`;
const MidBox = styled(ColumnFlex)`
  justify-content: space-between;
  padding: 10px;
  width: 600px;
  @media (max-width: 1400px) {
    width: 450px;
  }
  @media (max-width: 1280px) {
    width: 400px;
  }
`;
const TimeSpan = styled.span`
  @media (max-width: 1400px) {
    display: none;
  }
`;
const ContentWrapper = styled.div`
  // height: 90%;
  height: 85%;
  overflow-y: scroll;
`;
const ContentBox = styled(RowFlex)`
  width: 100%;
  height: 40%;
  max-height: 225px;
  border: 1px solid #dcd7cc;
  border-radius: 10px;
  justify-content: space-between;
  margin: 10px 0;
  padding: 15px;
`;

const ThumbNail = styled.img`
  max-height: 170px;
  margin: 10px;
  border: 1px solid #968489;
  max-width: 180px;
`;

const AssignedIcon = styled(ScheduleTwoTone)`
  padding: 5px;
  background: rgba(255, 255, 255, 0.57);
  font-size: 25px;
  height: 25px;
  border-radius: 5px;
  margin-top: 145px;
  margin-left: -50px;
  z-index: 111;
`;

const ContentText = styled.div`
  color: #595959;
  font-size: 16px;
  padding: 0 5px 15px;
  @media (max-width: 1280px) {
    font-size: 16px;
  }
`;
const GridItem = styled.div`
  text-align: center;
  padding: 8px;
  width: ${(props) => (props.time ? '150px' : '120px')};
  font-size: 15px;
  background-color: ${(props) => (props.isHeader ? '#edf2fa' : 'transparent')};
  color: ${(props) => (props.color ? props.color : 'black')};
  border: 1px solid #b4c7e7;
  border-bottom: ${(props) => props.isHeader && 'none'};
  @media (max-width: 1400px) {
    font-size: 12px;
    /* width: 110px; */
    width: ${(props) => (props.time ? '130px' : '120px')};
  }
  @media (max-width: 1280px) {
    font-size: 12px;
    width: ${(props) => (props.time ? '120px' : '110px')};
    /* width: 100px; */
  }
  @media (max-width: 1150px) {
    font-size: 12px;
    width: ${(props) => (props.time ? '120px' : '85px')};
    /* width: 85px; */
  }
`;
const IconImg = styled.img`
  height: ${(props) => (props.isNoReport ? '100px' : '60px')};
  max-height: ${(props) => (props.isNoReport ? '100px' : '60px')};
  width: ${(props) => (props.isNoReport ? '100px' : '60px')};
  max-width: ${(props) => (props.isNoReport ? '100px' : '60px')};
  cursor: ${(props) => !props.isDone && 'pointer'};
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
`;
const IconText = styled.div`
  color: '#333366';
  /* font-size: 15px; */
  font-size: ${(props) => (props.isNoReport ? '20px' : '14px')};
`;
const useCheckScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return [width > 1600 ? 1 : width / 1600, height > 900 ? 1 : height / 900];
};
export default function LevelUpPortfolio() {
  let width = useCheckScreen()[0];
  let height = useCheckScreen()[1];
  const { student_idx, name, studentid, month } = queryString.parse(window.location.search);
  const history = useHistory();
  const [selectValue, setSelectValue] = useState('all');
  const [searchValue, setSearchValue] = useState('');
  const [recordingTitle, setRecordingTitle] = useState('Recording');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [currentUserName, setCurrentUserName] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');
  const [recordingContent, setRecordingContent] = useState('');
  const companyName = useSelector(classStoreData);
  useEffect(() => {
    if (month) {
      setSelectValue(month);
    }
  }, [month]);
  const isSelfBookReport = useMemo(() => {
    let etcData = userInfoVar()?.campus?.etc
      ? JSON.parse(userInfoVar()?.campus?.etc)
      : {
          isSelfBookReport: false,
        };

    return etcData?.isSelfBookReport === false ? false : true;
  }, []);

  const isLevelupRecording = useMemo(() => {
    let etcData = userInfoVar()?.campus?.etc
      ? JSON.parse(userInfoVar()?.campus?.etc)
      : {
          isLevelupRecording: true,
        };

    return etcData?.isLevelupRecording === false ? false : true;
  }, []);

  const handleSelectChange = (v) => {
    setSelectValue(v);
  };

  const user_idx = userInfoVar()?.idx || null;
  const { data, loading } = useQuery(EXTERNAL_USER_STUDY_LEVELUP, {
    variables: {
      user_idx: student_idx ? parseInt(student_idx) : parseInt(user_idx),
      ym: selectValue === 'all' ? null : selectValue,
      type: 'levelup',
      status_gte: 2,
    },
    fetchPolicy: 'no-cache',
    skip: !user_idx,
  });

  const studyData = useMemo(() => {
    if (data?.getExternalStuiesList) {
      return data?.getExternalStuiesList
        .sort((a, b) => {
          if (moment(a.study_date).isSameOrAfter(b.study_date)) {
            return -1;
          }
          return 1;
        })
        .map((item, key) => {
          const externalStudy =
            item?.external_study_data?.length === 0
              ? JSON.parse(item?.article_data)
              : JSON.parse(item?.external_study_data?.external_study_data);
          const recording_data = item.recording_data;
          const {
            study_date,
            article_data,
            exam_total,
            exam_correct,
            wpm,
            external_study_idx,
            data,
            last_study_timestamp,
            assigned_idx,
            external_study_stage,
            user,
          } = item;
          // const dataparse = JSON.parse(data);
          // const score = dataparse?.score_percent || '';
          const { id, name } = user;
          let quizStage = external_study_stage.filter((item) => item.stage_no === 3) || [];
          quizStage = quizStage[0] || {};

          let activitiesStage = external_study_stage.filter((item) => [1, 2, 4, 5].includes(item.stage_no)) || [];
          const {
            lexile_val,
            issue_date,
            title,
            mx_level: level,
            pages,
            code,
            mxObj,
            genre__1,
            author,
            publisher,
            is_voca,
          } = JSON.parse(
            article_data || {
              lexileScore: '',
              issue_date: '',
              title: '',
              level: '',
              image: '',
              pages: '',
              code: '',
              mxObj: '',
              publisher: '',
              author: '',
              is_voca: false,
            },
          );
          const { bookId, gamePlayResults, lastPageVisited, startedAt, timeSpent } = JSON.parse(data) || {
            bookId: 0,
            gamePlayResults: [],
            lastPageVisited: 0,
            pagesVisited: [],
            startedAt: '',
            timeSpent: 0,
          };
          const date = moment(study_date).format('M월D일');
          const articleData = {
            lexile: lexile_val,
            issue_date: moment(study_date).format('YYYY-MM-DD HH:mm:SS'),
            image: `https://cdn.cloubot.com/LEVELUP/covers/${code}.png`,
            level,
            title,
            assigned_idx,
            is_voca,
            code,
          };
          const achievement = gamePlayResults
            ? gamePlayResults.reduce(
                (acc, cur) => {
                  if (cur.numberOfAttempts) {
                    acc.score += cur.numberOfAttempts;
                    acc.total += cur.numberOfElements;
                  } else if (cur.numberOfCorrectAttempts) {
                    acc.score += cur.numberOfCorrectAttempts;
                    acc.total += cur.numberOfElements;
                  } else if (cur.status == 1) {
                    acc.score += 1;
                    acc.total += 1;
                  }
                  return { ...acc };
                },
                { score: 0, total: 0 },
              )
            : {
                score: 0,
                total: 0,
              };
          const time =
            (parseInt(timeSpent / 3600) > 0 ? parseInt(timeSpent / 3600) + '시간 ' : '') +
            (parseInt((timeSpent % 3600) / 60) > 0 ? parseInt((timeSpent % 3600) / 60) + '분 ' : '') +
            (parseInt(timeSpent % 60) + '초');
          return {
            key: `levelup-list-${key}`,
            date,
            id,
            name,
            lexileScore: lexile_val ? lexile_val : '-',
            issue_date,
            title,
            level,
            author,
            publisher,
            mx_level: level,
            lexile: lexile_val,
            pages,
            grl: genre__1 || '',
            image: `https://cdn.cloubot.com/LEVELUP/covers/${code}.png`,
            accuracy: recording_data ? `${Math.round((100 * exam_correct) / exam_total)}%` : '',
            wpm,
            recording_data,
            idx: item?.idx,
            assigned_idx,
            quiz: quizStage?.exam_total ? `${Math.round((100 * quizStage?.exam_correct) / quizStage?.exam_total)}%` : '',
            activities: activitiesStage?.length > 3 || !is_voca ? 'O' : 'X',
            // exam_total,
            // exam_correct,
            achievement: achievement.score > 0 ? `${Math.round((achievement.score * 100) / achievement.total)}%` : '0%',
            book_id: external_study_idx || bookId,
            gamePlayResults,
            last_study_timestamp: last_study_timestamp ? `${last_study_timestamp.slice(0, 10)} ${last_study_timestamp.slice(11, 19)}` : '-',
            lastPageVisited,
            time: timeSpent > 0 ? time : '-',
            startedAt: startedAt ? `${startedAt.slice(0, 10)} ${startedAt.slice(11, 19)}` : '-',
            timeSpent: Math.round(timeSpent),
            articleData,
            selfStudy: assigned_idx === null,
            externalStudy,
            data: item?.data ? JSON?.parse(item.data) : {},
            is_voca,
          };
        });
    }
    return [];
  }, [data]);

  const books = useMemo(() => {
    if (studyData.length > 0) {
      return searchValue !== '' ? studyData.filter((item) => item.title.toLowerCase().includes(searchValue.toLowerCase())) : studyData;
    }
    return studyData;
  }, [studyData, searchValue]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleSearch = (e) => {
    console.log(searchValue);
  };
  const openRecordingDataModal = (data, name, id, title) => {
    setIsRecordingVisible(true);
    setRecordingContent(data);
    setCurrentUserName(name);
    setCurrentUserId(id);
    setRecordingTitle(title);
  };
  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }
  const getActivities = (e) => {
    return (
      <ColumnFlex>
        {e.articleData?.is_voca ? (
          <>
            <RowFlex>
              {e?.externalStudy?.bookreport && e?.externalStudy?.bookreport !== undefined
                ? ['Activities', 'Quiz', isPturn(companyName)?'WPM/Accuracy':'WPM', 'Book Report', 'Time'].map((item) => (
                    <GridItem key={item} time={item === 'Time'} isHeader>
                      {item}
                    </GridItem>
                  ))
                : ['Activities', 'Quiz', isPturn(companyName)?'WPM/Accuracy':'WPM', 'Time'].map((item) => (
                    <GridItem key={item} time={item === 'Time'} isHeader>
                      {item}
                    </GridItem>
                  ))}
            </RowFlex>
            <RowFlex>
              <GridItem color={'#548235'}>{e.activities}</GridItem>
              <GridItem color={'#ff002a'}>{e.quiz}</GridItem>
              <GridItem
                onClick={() => e.wpm && e.accuracy && openRecordingDataModal(e, e.name, e.id, 'Recording')}
                color={'#4472c4'}
                style={{ cursor: e.wpm ? 'pointer' : 'default' }}
              >
                {/* {e.wpm}/{e.accuracy} */}
                {(e?.wpm === null || e?.wpm === '') && (e?.accuracy === null || e?.accuracy === '') ? '-' : `${e.wpm}/${e.accuracy}`}
              </GridItem>
              {e?.externalStudy?.bookreport && e?.externalStudy?.bookreport !== undefined ? (
                e?.data?.rubric_data && e?.externalStudy?.bookreport !== undefined ? (
                  <GridItem color={'#548235'}>{JSON.parse(e?.data?.rubric_data)?.score}</GridItem>
                ) : (
                  e?.externalStudy?.bookreport &&
                  e?.externalStudy?.bookreport !== undefined && <GridItem color={'#548235'}>{e.book_report && 'Done'}</GridItem>
                )
              ) : undefined}
              <GridItem time={true} color={'#4472c4'}>
                <TimeSpan>{moment(e.last_study_timestamp).format('YY-')}</TimeSpan>
                {moment(e.last_study_timestamp).format('MM-DD HH:mm')}

                {/* {Math.floor(e.spent_time / 60)}:{String(e.spent_time % 60).padStart(2, '0')} */}
              </GridItem>
            </RowFlex>
          </>
        ) : (
          <>
            <RowFlex>
              {['Activities', 'Time'].map((item) => (
                <GridItem key={item} time={item === 'Time'} isHeader>
                  {item}
                </GridItem>
              ))}
            </RowFlex>
            <RowFlex>
              <GridItem color={'#548235'}>{e.activities}</GridItem>
              <GridItem time={true} color={'#4472c4'}>
                {moment(e.last_study_timestamp).format('YY-MM-DD HH:mm')}

                {/* {Math.floor(e.spent_time / 60)}:{String(e.spent_time % 60).padStart(2, '0')} */}
              </GridItem>
            </RowFlex>
          </>
        )}
      </ColumnFlex>
    );
  };

  const handleImgClick = (code, exStudyIdx, data, fromRecording, fullData) => {
    if (data?.status === 2) {
      if (fromRecording) {
        let recordingData = data?.recording_data ? JSON.parse(data.recording_data) : {};
        recordingData = {
          ...recordingData,
          accuracy: recordingData?.accuracy || `${recordingData?.score}%`,
          wpm: recordingData?.wpm || recordingData?.speed,
        };
        openRecordingDataModal(recordingData, data?.user?.name, data?.user?.id, 'Presentation');
      } else {
        const rubricData = data?.rubric_data ? JSON.parse(data.rubric_data) : {};

        if (rubricData?.pdf_url === undefined || rubricData?.pdf_url === null || rubricData?.pdf_url === '') {
          alert("Please wait for teacher's feedback");
        } else {
          if(window.confirm("완료한 학습입니다. Report Page로 이동시겠습니까?")){
            window.open(rubricData?.pdf_url);
          }else{
            return;
          }
          
        }
      }
    } else {
      history.push(`/level-up/bookreport?code=${code}&external_study_idx=${exStudyIdx}`);
    }
  };

  return (
    <>
      <Wrapper>
        <MainWrapper className="test123">
          <LevelUpLibraryMenuGroup currentMenu={'portfolio'} student_idx={student_idx} />
          <Main>
            <MainHead style={{ marginBottom: height < 0.8 && 0 }}>
              <MainHeadBox>총 {books.length}권</MainHeadBox>
              <MainHeadBox style={{ fontSize: '15px', lineHeight: '30px' }}>
                {/* * 녹음된 음원은 30일 동안 저장됩니다.&nbsp; */}
                {name && studentid && (
                  <span style={{ marginRight: 10 }}>
                    {name} (ID : {studentid})
                  </span>
                )}
                <SelectBox bordered={false} value={selectValue} onChange={handleSelectChange}>
                  <Option key={0} value="all">
                    전체
                  </Option>
                  {[
                    moment().format('YYYY-MM'),
                    moment().subtract(1, 'months').format('YYYY-MM'),
                    moment().subtract(2, 'months').format('YYYY-MM'),
                  ].map((item, key) => (
                    <Option key={key + 1} value={item}>
                      {item}
                    </Option>
                  ))}
                </SelectBox>
                &nbsp;
                <SearchInput
                  onKeyUp={handleInputChange}
                  allowClear
                  bordered={false}
                  placeholder="Search"
                  suffix={<SearchOutlined onClick={handleSearch} style={{ border: 'none' }} />}
                />
                {student_idx && (
                  <>
                    &nbsp;
                    <BackButton />
                  </>
                )}
              </MainHeadBox>
            </MainHead>
            <ContentWrapper className="cust-portfolio-wrap">
              {books.map((e, i) => (
                <ContentBox key={i} className="cust-portfolio">
                  <RowFlex style={{ width: '180px', justifyContent: 'center' }}>
                    <ThumbNail preview={false} src={e.image} />
                    {!e.selfStudy && <AssignedIcon />}
                  </RowFlex>

                  <MidBox>
                    <ContentText>{`Author : ${e.author}`}</ContentText>
                    {/* <RowFlex style={{ justifyContent: 'space-between' }}> */}
                    <ContentText>{`Publisher : ${e.publisher}`}</ContentText>
                    <ContentText>{`Page : ${e.pages}`}</ContentText>
                    {/* </RowFlex> */}
                    {getActivities(e)}
                  </MidBox>
                  <div style={{ width: '130px', fontSize: '14px' }}>
                    <ColumnFlex style={{ padding: '10px' }}>
                      <ContentText>{`mx Level : ${e.mx_level}`}</ContentText>
                      <ContentText>{`Lexile : ${e?.lexileScore < 0 ? `BR${Math.abs(e?.lexileScore)}` : e?.lexileScore}`}</ContentText>
                      <ContentText>{`GRL : ${e.grl}`}</ContentText>
                    </ColumnFlex>
                  </div>

                  {(e?.externalStudy?.bookreport && e?.externalStudy?.bookreport !== undefined) || (isSelfBookReport && e.is_voca) ? (
                    <ColumnFlex>
                      <FluencyButton book={e} done={!!e.wpm} isNoReport={false} />
                      <RowFlex style={{ alignItems: 'center' }}>
                        <ColumnFlex
                          style={{ justifyContent: 'end', alignItems: 'center' }}
                          onClick={() => handleImgClick(e?.externalStudy?.code, e?.idx, e?.data, false, e)}
                        >
                          <IconImg isDone={e?.data?.rubric_data} src="/images/book/portfolio/bookreport.jpg" isNoReport={false} />
                          <IconText isNoReport={false}>Book Report</IconText>
                        </ColumnFlex>
                        {isLevelupRecording && (
                          <>
                            <div style={{ fontSize: '20px' }}>
                              <ArrowRightOutlined />
                            </div>
                            <ColumnFlex
                              style={{ justifyContent: 'end', alignItems: 'center' }}
                              onClick={() => handleImgClick(e?.externalStudy?.code, e?.idx, e?.data, true, e)}
                            >
                              <IconImg isDone={false} src="/images/book/portfolio/presentation.jpg" isNoReport={false} />
                              <IconText isNoReport={false}>Presentation</IconText>
                            </ColumnFlex>
                          </>
                        )}
                      </RowFlex>
                    </ColumnFlex>
                  ) : (
                    <ColumnFlex>
                      <FluencyButton book={e} done={!!e.wpm} isNoReport={true} />
                    </ColumnFlex>
                  )}
                </ContentBox>
              ))}
            </ContentWrapper>
          </Main>
        </MainWrapper>
      </Wrapper>
      <RecordingDataModal
        data={{ ...recordingContent, currentUserName, currentUserId }}
        isShow={isRecordingVisible}
        onOk={handleOkForRecordingData}
        title={recordingTitle}
      />
    </>
  );
}

const FluencyButton = ({ book, done, isNoReport }) => {
  const [hasData, setHasData] = useState(false);
  const history = useHistory();

  useEffect(() => {
    Promise.allSettled([axios.get(`https://cdn.cloubot.com/LEVELUP/texts/${book.book_id}.json`)])
      .then((responses) => {
        responses.forEach((res, idx) => {
          setHasData(res.status !== 'rejected');
        });
      })
      .catch((err) => console.log(err));
  }, [book]);

  const onHandleClick = () => {
    if (!hasData) {
      return;
    } else {
      if (done) {
        if(window.confirm("완료한 학습입니다. 다시하겠습니까?")){
          history.push({
            pathname: `/level-up/recording/${book?.book_id}`,
            state: { articleData: book?.articleData, assigned_idx: book?.assigned_idx, idx: book?.idx },
          });
        }else{
          return;
        }
      } else {
        history.push({
          pathname: `/level-up/recording/${book?.book_id}`,
          state: { articleData: book?.articleData, assigned_idx: book?.assigned_idx, idx: book?.idx },
        });
      }
    }
  };

  return (
    <ColumnFlex style={{ justifyContent: 'end', alignItems: 'center' }} onClick={onHandleClick}>
      <IconImg isNoReport={isNoReport} isDone={!hasData || done} src="/images/book/portfolio/fluency.jpg" />
      <IconText isNoReport={isNoReport}>Fluency</IconText>
    </ColumnFlex>
  );
};

const RecordingDataModal = ({ data, isShow, onOk, title }) => {
  return (
    <Modal
      title={title || 'Recording'}
      width="60%"
      visible={isShow}
      onOk={onOk}
      onCancel={onOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button type="primary" key="2" onClick={onOk}>
          OK
        </Button>,
      ]}
    >
      <div className="show-print" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '10px',
          }}
        >
          {/* <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" /> */}
          <Title level={3} style={{ padding: '0 10px' }}>
            Recording Result
          </Title>
        </div>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px',
          }}
        >
          <Title level={3} style={{ padding: '10px' }}>
            Student Name: {data?.currentUserName}
          </Title>
          <Title level={3} style={{ padding: '10px', margin: 0 }}>
            Class: {data?.className}
          </Title>
        </div>
        <Title level={4} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
          Recording
        </Title>
        <div style={{ padding: 20, lineHeight: '60px' }} dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
        <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
          <span>accuracy: {data?.accuracy ? data?.accuracy : '-'}</span>
          <span>wpm: {data?.wpm ? data.wpm : 0}</span>
        </div>
      </div>
      <div className="hide-print" dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
      <div className="hide-print" style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
        <span>accuracy: {data?.accuracy ? data?.accuracy : '-'}</span>
        <span>wpm: {data?.wpm ? data.wpm : 0}</span>
      </div>
    </Modal>
  );
};
