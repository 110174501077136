import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import PageNum from './PageNum';
import _ from 'lodash';
import { isVowel,alphabetBoxTypeChecker } from '../../utils/pm/util';
import { useParams } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
const greatSounds = [
  'https://cdn.cloubot.com/PM/audio/sound-effect/excellent.mp3',
  'https://cdn.cloubot.com/PM/audio/sound-effect/very-good.mp3',
  'https://cdn.cloubot.com/PM/audio/sound-effect/great.mp3',
];
let bookNameUnitStage = undefined;
const MultiWordDnd = ({ problems, steps, setSteps }) => {
  const [words, setWords] = useState(undefined);
  const [images, setImages] = useState(undefined);
  const [dropSound, setDropSound] = useState(undefined);
  const [wordSound, setWordSound] = useState(undefined);
  const [greatSound, setGreatSound] = useState(undefined);
  const [isCorrect, setIsCorrect] = useState(undefined);
  const [currentDroppedWord, setCurrentDroppedWord] = useState(undefined);
  const [finishCurrentStep, setFinishCurrentStep] = useState(false);
  const [stageEnd, setStageEnd] = useState(false);

  const [playGreat, setPlayGreat] = useState(false);

  const { bookName, unit, stage } = useParams();

  if (bookName == '2a' && unit == 3 && stage == 2) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 3 && stage == 3) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 3 && stage == 5) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 3 && stage == 6) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 3 && stage == 7) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 3 && stage == 9) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 3 && stage == 11) {
    bookNameUnitStage = true;
  }

  if (bookName == '2a' && unit == 4 && stage == 2) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 4 && stage == 3) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 4 && stage == 5) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 4 && stage == 6) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 4 && stage == 7) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 4 && stage == 9) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 4 && stage == 11) {
    bookNameUnitStage = true;
  }

  if (bookName == '2b' && unit == 6 && stage == 6) {
    bookNameUnitStage = true;
  }
  if (bookName == '2a' && unit == 2 && stage == 6) {
    bookNameUnitStage = true;
  }
  if (bookName == '2b' && unit == 7 && stage == 6) {
    bookNameUnitStage = true;
  }
  if (bookName == '2b' && unit == 8 && stage == 6) {
    bookNameUnitStage = true;
  }
  if (bookName == '2b' && unit == 9 && stage == 6) {
    bookNameUnitStage = true;
  }

  const handleDragEnd = (result) => {
    const { destination, draggableId } = result;
    if (_.isNil(destination) || destination.droppableId.startsWith('under')) {
      setDropSound('wrong_sound1');
      return;
    }
    setDropSound('mouse-click');
    const copyWords = _.cloneDeep(words);
    const copy = copyWords.map((value) => value.name);
    const findIndex = copy.indexOf(draggableId.substring(0, draggableId.length - 1));
    const findImageIndex = _.map(images, (item) => item.word).indexOf(draggableId.substring(0, draggableId.length - 1));
    if (findIndex !== -1) {
      //copy.splice(findIndex, 1);
      copyWords[findIndex].dropped = true;
      //copyWords.splice(findIndex, 1);
      setCurrentDroppedWord(copyWords[findIndex].name);
      setWords(copyWords);
      let correctCopy = _.cloneDeep(isCorrect);
      correctCopy[findImageIndex] = true;
      setIsCorrect(correctCopy);
    }
  };
  const handleDropSoundEnd = () => {
    if (dropSound === 'mouse-click') {
      setWordSound(currentDroppedWord);
    }
    setDropSound(undefined);
  };
  const handleWordSoundEnd = () => {
    if (finishCurrentStep) {
      setGreatSound(_.sample(greatSounds));
      setFinishCurrentStep(false);
    }

    if (stageEnd) {
      setSteps((state) => state + 1); //여기서 끝난다.
    }
    setWordSound(undefined);
  };
  const handleGreatSoundEnd = () => {
    setGreatSound(undefined);
    setSteps((state) => state + 1);
  };
  useEffect(() => {
    if (!_.isNil(problems[steps])) {
      setWords(_.shuffle(problems[steps].words).map((item) => ({ name: item, dropped: false })));
      setImages(_.shuffle(problems[steps].images));
    }
  }, [steps, problems]);

  useEffect(() => {
    if (!_.isNil(problems[steps]) && !_.isNil(images)) {
      setIsCorrect(new Array(images.length).fill(false));
    }
    // eslint-disable-next-line
  }, [images]);

  useEffect(() => {
    if (!_.isNil(words) && words.every((item) => item.dropped === true)) {
      if (problems.length - 1 > steps) {
        //setGreatSound(_.sample(greatSounds));
        setFinishCurrentStep(true);
      } else {
        //마지막 스텝인 경우임
        setStageEnd(true);
        //setSteps(state => state + 1);
      }
    }
    // eslint-disable-next-line
  }, [words]);

  useEffect(() => {
    //자동으로 소리를 재생하려면 useEffect에서 state 변경해서 랜더링을 하면 재생이 된다.
    setPlayGreat(!_.isNil(greatSound));
  }, [greatSound]);

  return (
    <>
      <S.ColumnFlex id="lv2_stage06_content" className="main_content  row2_arrow">
        <DragDropContext onDragEnd={handleDragEnd}>
          <S.ImgDndBox className="top_box" style={{ position: 'relative', flex: '7 1', marginBottom: '1.2rem', padding: '2.2rem 1.6rem' }}>
            <PageNum
              num={steps + 1}
              total={problems && problems.length}
              style={{ top: '20px', position: 'absolute', right: '1.8rem', fontSize: '1.3rem', fontWeight: 'bold' }}
            />
            <ul style={{ display: 'flex', listStyle: 'none', padding: '20px 0', justifyContent: 'space-between', margin:0 }} className="img_box">
              {images &&
                images.map((item, index) => (
                  <S.MultiDndBtnLi key={`image-box${index}`}>
                    <img style={{ height: '20vh' }} src={item.url} alt="보기 이미지" />
                  </S.MultiDndBtnLi>
                ))}
            </ul>
            <S.MultiWordAlphabetWrap style={{ margin: '15px 0' }} className="alphabet_wrap row5_wrap">
              {images &&
                images.map((item, index) => (
                  <DroppableWordBox key={`droppable-word-${index}`} item={item} index={index} isCorrect={isCorrect} />
                ))}
            </S.MultiWordAlphabetWrap>
          </S.ImgDndBox>
          <S.Dnd4BottomBox className="bottom_box">
            <S.MultiWordAlphabetWrap style={{ margin: '20px 0' }} className="alphabet_wrap row5_wrap">
              {words && words.map((item, index) => <DraggableWord key={`draggable-word${index}`} item={item} index={index} />)}
            </S.MultiWordAlphabetWrap>
          </S.Dnd4BottomBox>
        </DragDropContext>
      </S.ColumnFlex>

      {!_.isNil(dropSound) && (
        <SoundEffectPM url={`https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3`} onEnd={handleDropSoundEnd} />
      )}
      {!_.isNil(wordSound) && <SoundEffectPM url={`https://cdn.cloubot.com/PM/audio/sounds/${wordSound}.mp3`} onEnd={handleWordSoundEnd} />}
      {playGreat && <SoundEffectPM url={greatSound} onEnd={handleGreatSoundEnd} />}
    </>
  );
};

export default MultiWordDnd;

const DroppableWordBox = ({ item, index, isCorrect }) => {
  return (
    <li style={{ display: 'flex', justifyContent: 'center', flex: '1 1' }}>
      <Droppable droppableId={`upper${item.word}`} type={item.word}>
        {(provided, snapshot) => (
          <>
            <S.AlphabetBox
              ref={provided.innerRef}
              {...provided.droppableProps}
              row={alphabetBoxTypeChecker(5)}
              gray={!(isCorrect && isCorrect[index])}
              style={{ backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined }}
              className={isCorrect && isCorrect[index] ? 'alphabet_box row5 green' : 'alphabet_box row5 gray'}
            >
              {isCorrect && isCorrect[index]
                ? bookNameUnitStage
                  ? _.map(item.word, (word, idx) => (
                      <span
                        style={{ color: idx !== 0 ? '#f368e0' : '#2e86de' }}
                        key={`word-${word}-${idx}`}
                        className={idx == 0 ? 'font_blue' : 'font_pink'}
                      >
                        {word}
                      </span>
                    ))
                  : _.map(item.word, (word, idx) => (
                      <span
                        style={{ color: isVowel(word) ? '#f368e0' : '#2e86de' }}
                        key={`word-${word}-${idx}`}
                        className={isVowel(word) ? 'font_pink' : 'font_blue'}
                      >
                        {word}
                      </span>
                    ))
                : undefined}
            </S.AlphabetBox>
          </>
        )}
      </Droppable>
    </li>
  );
};

const DraggableWord = ({ item, index }) => {
  return (
    <Droppable droppableId={`under${item.name + index}`} type={item.name} isDropDisabled={true}>
      {(provided) =>
        item.dropped ? (
          <li style={{ visibility: 'hidden' }}>
            <S.AlphabetBox row={alphabetBoxTypeChecker(5)} className="alphabet_box row5 green">
              <span className="font_blue">&nbsp;</span>
            </S.AlphabetBox>
          </li>
        ) : (
          <li style={{ display: 'flex', justifyContent: 'center', flex: '1 1' }} ref={provided.innerRef}>
            <Draggable draggableId={item.name + index} index={index} type={item.name}>
              {(provided) => (
                <S.AlphabetBox
                  row={alphabetBoxTypeChecker(5)}
                  className="alphabet_box row5 green"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {bookNameUnitStage
                    ? _.map(item.name, (letter, index) => (
                        <span
                          data="first"
                          key={letter + index}
                          style={{ color: index == 0 ?  '#2e86de':'#f368e0' }}
                          className={index == 0 ? 'font_blue' : 'font_pink'}
                        >
                          {letter}
                        </span>
                      ))
                    : _.map(item.name, (letter, index) => (
                        <span
                          data="second"
                          key={letter + index}
                          style={{ color: isVowel(letter) ? '#f368e0' : '#2e86de' }}
                          className={isVowel(letter) ? 'font_pink' : 'font_blue'}
                        >
                          {letter}
                        </span>
                      ))}
                </S.AlphabetBox>
              )}
            </Draggable>
            {provided.placeholder}
          </li>
        )
      }
    </Droppable>
  );
};
