import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { isVowel } from '../../utils/pm/util';
import SoundEffect from 'src/components/common/SoundEffect';
import { useParams } from 'react-router-dom';

let bookNameUnitStage = undefined;
const AlphabetDnD2 = ({ upperWord, question, setSteps, customData }) => {
  const [isDropped, setIsDropped] = useState(false);
  const [droppedAlphabet, setDroppedAlphabet] = useState('');
  const [dropSound, setDropSound] = useState(undefined);
  const [soundName, setSoundName] = useState(undefined);
  const [alphabets, setAlphabets] = useState([]);
  const [isShowAnswer, setIsShowAnswer] = useState(false);
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const { bookName, unit, stage } = useParams();

  if (bookName == '3a' && (unit == 1 || 2) && (stage == 2 || 4 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  if (bookName == '3b' && (unit == 6 || 7 || 8 || 9) && (stage == 2 || 4 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  const handleDropSoundEnd = () => {
    if (dropSound === 'mouse-click') {
      const word = droppedAlphabet + question;
      if (word === 'june') {
        setSoundName('J_une_June');
      } else {
        setSoundName(`${droppedAlphabet}_${question}_${droppedAlphabet + question}`);
      }

      setTimeout(() => {
        setIsShowAnswer(true);
      }, 2500);
    }
    setDropSound(undefined);
  };

  const handleSoundEnd = () => {
    setSoundName(undefined);
    setIsDropped(false);
    setIsDragDisabled(false);
    setIsShowAnswer(false);
  };

  const handleDragEnd = useCallback(
    (result) => {
      const { destination, source } = result;

      if (_.isNil(destination)) {
        setDropSound('wrong_sound1');
        return;
      }

      if (destination.droppableId === source.droppableId) {
        return;
      }

      setDropSound('mouse-click');
      const dropped = result.draggableId.slice(-1);
      setIsDropped(true);
      setIsDragDisabled(true);
      if (!_.isNil(dropped)) {
        setDroppedAlphabet(dropped);
        const copy = _.cloneDeep(alphabets);
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].name === dropped) {
            copy[i].isDropped = true;
          }
        }
        setAlphabets(copy);
      }
    },
    [alphabets],
  );

  useEffect(() => {
    if (!_.isNil(upperWord) && upperWord.length > 0) {
      setAlphabets(
        upperWord.map((item) => {
          return { isDropped: false, name: item };
        }),
      );
    }
  }, [upperWord]);

  useEffect(() => {
    if (alphabets.every((item) => item.isDropped)) {
      if (isDropped === false) {
        setSteps((prev) => prev + 1);
      }
    }
    // eslint-disable-next-line
  }, [alphabets, isDropped]);

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="main_content  stage2-2_content  row2_arrow">
          <div className="top_box">
            <ul className="alphabet_wrap">
              <AlphabetDropZone isDropped={isDropped} droppedAlphabet={droppedAlphabet} />
              <li>
                <div className="sign">+</div>
              </li>
              <li>
                {bookNameUnitStage
                  ? _.map(question, (item) => (
                      <span key={item} className={`font_${isVowel(item) ? 'pink' : 'pink'}`}>
                        {item}
                      </span>
                    ))
                  : _.map(question, (item) => (
                      <span key={item} className={`font_${isVowel(item) ? 'blue' : 'blue'}`}>
                        {item}
                      </span>
                    ))}
              </li>
              <li>
                <div className="sign">=</div>
              </li>
              <AnswerWordBox isDropped={isDropped} answerWord={droppedAlphabet + question} isShowAnswer={isShowAnswer} />
            </ul>
          </div>
          <img src="/images/pm/i_arrow_top.svg" alt="위로 향하는 화살표" />
          <div className="bottom_box">
            <AlphabetDraggableZone alphabets={alphabets} isDragDisabled={isDragDisabled} />
          </div>
        </div>
      </DragDropContext>
      <SoundEffect url={!_.isNil(dropSound) ? `/sound-effect/${dropSound}.mp3` : undefined} onEnd={handleDropSoundEnd} />
      <SoundEffect
        url={
          !_.isNil(soundName) ? `${!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : '/sounds/'}${soundName}.mp3` : undefined
        }
        onEnd={handleSoundEnd}
      />
    </>
  );
};

export default AlphabetDnD2;

export const AlphabetDropZone = ({ isDropped, droppedAlphabet }) => {
  return (
    <Droppable droppableId={'change'}>
      {(provided, snapshot) => (
        <li ref={provided.innerRef}>
          <div
            className={`alphabet_box row1 ${isDropped ? 'green' : 'gray'}`}
            style={{
              backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined,
            }}
          >
            {bookNameUnitStage ? (
              <span className="font_blue">{isDropped ? droppedAlphabet : ''}</span>
            ) : (
              <span className="font_pink">{isDropped ? droppedAlphabet : ''}</span>
            )}
          </div>
          <span style={{ display: 'none' }}>{provided.placeholder}</span>
        </li>
      )}
    </Droppable>
  );
};

export const AlphabetDraggableZone = ({ alphabets, isDragDisabled }) => {
  return (
    <ul className="alphabet_wrap row1_wrap">
      {_.map(alphabets, (item, index) => (
        <Droppable key={item.name} droppableId={item.name + 'drop'} isDropDisabled={true}>
          {(provided) => (
            <li ref={provided.innerRef}>
              <Draggable key={index} draggableId={item.name} index={index} isDragDisabled={isDragDisabled}>
                {(provided, snapshot) => (
                  <div
                    className={`alphabet_box row1 green ${item.isDropped ? 'area_hide' : ''}`}
                    key={index}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <span className="font_blue">{item.name}</span>
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
            </li>
          )}
        </Droppable>
      ))}
    </ul>
  );
};

export const AnswerWordBox = ({ isDropped, answerWord, isShowAnswer }) => {
  return (
    <li>
      <div className={`alphabet_box row3 ${isDropped && isShowAnswer ? 'green' : 'gray'}`}>
        {isDropped &&
          isShowAnswer &&
          (bookNameUnitStage
            ? _.map(answerWord, (item, key) => (
                <span key={key} className={`font_${key == 0 ? 'blue' : 'pink'}`}>
                  {item}
                </span>
              ))
            : _.map(answerWord, (item, key) => (
                <span key={key} className={`font_${key == 0 ? 'pink' : 'blue'}`}>
                  {item}
                </span>
              )))}
      </div>
    </li>
  );
};
