import React, { useState } from 'react';
import { Col, Row, Button, Tabs } from 'antd';
import { Link, Redirect } from 'react-router-dom';

//import { HeaderTitle } from 'src/components/common/Styles';
import styled from 'styled-components';
import { userInfoVar } from 'src/apollo/cache';

const TabNaviWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

const TitleBg = styled.div``;

const { TabPane } = Tabs;
const KIDS_TAP_BOOK_COVER_BASE_URL = `https://lms-v2.talkingsam.com/data/img/cover/kidstap_phonics/`;
const BOOK_COVER_BASE_URL = `https://cdn.cloubot.com/AE/cover/`;
const BOOK_WN_BASE_URL = `https://cdn.cloubot.com/AE/WN/`;
const MP3_DATA_BASE_URL = `https://cdn.cloubot.com/AE/MP3_DATA/`;
const PPT_DATA_BASE_URL = `https://cdn.cloubot.com/AE/PPT/`;
//const EBOOK_BASE_URL = `http://culp.cloubot.com/culp-demo/textbook/index.html?lesson=`;
//const EBOOK_BASE_URL_V1 = `http://lms-v2.talkingsam.com/data/ebook/`;
const levels = [
  { name: 'Phonics', no: 0, color: '#ff0000' },
  { name: 'Awesome 1', no: 1, color: '#ffc000' },
  { name: 'Awesome 2', no: 2, color: '#ed7d31' },
  { name: 'Awesome 3', no: 3, color: '#70ad47' },
  { name: 'Awesome 4', no: 4, color: '#44bbc4' },
  { name: 'Awesome 5', no: 5, color: '#4472c4' },
  { name: 'Awesome 6', no: 6, color: '#7030a0' },
];
const volumes = [1, 2, 3, 4, 5, 6];

const colStyle = {
  textAlign: 'center',
  paddingBottom: '10px',
};

const Awesome = () => {
  const [tabKey, setTabKey] = useState('1');
  const type = userInfoVar()?.type;
  //학생은 접근 안 되게 막음..
  if (type < 1) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <div className="ebook-ae-overflow">
        <TabNaviWrapper className="ebook-ae-a">
          {levels.map((item, key) => (
            <Button
              key={`level-navi-key${key}`}
              style={{
                backgroundColor: tabKey === key.toString() ? '#fff' : item.color,
                color: tabKey === key.toString() ? item.color : '#fff',
              }}
              onClick={() => {
                setTabKey(key.toString());
              }}
            >
              {item.name}
            </Button>
          ))}
        </TabNaviWrapper>
        <Tabs
          type="card"
          activeKey={tabKey}
          onChange={setTabKey}
          className="awesome-ebook-tab-nav-list"
          style={{ height: '80vh', overflowY: 'scroll' }}
        >
          {levels.map((item, key_level) => (
            <TabPane tab={item.name} key={key_level} style={{backgroundColor:'#fff'}}>
              {key_level === 0 ? (
                <Row gutter={[16, 16]} justify="center">
                  <Col span={3}></Col>
                  <Col span={4} style={colStyle}>
                    교재
                  </Col>
                  <Col span={4} style={colStyle}>
                    Teacher's Guide
                  </Col>
                  <Col span={4} style={colStyle}></Col>
                  <Col span={3} style={colStyle}></Col>
                  <Col span={3} style={colStyle}></Col>
                  <Col span={3} style={colStyle}></Col>
                </Row>
              ) : (
                <Row gutter={[16, 16]} justify="center">
                  <Col span={4}></Col>
                  <Col span={3} style={colStyle}>
                    Student Book
                  </Col>
                  <Col span={3} style={colStyle}>
                    Workbook
                  </Col>
                  <Col span={3} style={colStyle}>
                    Writing Note
                  </Col>
                  <Col span={4} style={colStyle}>
                    강의용 PPT
                  </Col>
                  <Col span={4} style={colStyle}>
                    E-Learning
                  </Col>
                  <Col span={3} style={colStyle}>
                    MP3
                  </Col>
                </Row>
              )}

              {volumes.map((volume, key) => (
                <>
                  {key_level === 0 ? (
                    <KidsTapPhonicsVolumeRow volume={volume} />
                  ) : (
                    <AwesomeVolumeRow level={item.no} volume={volume} key={`awesome-vol-key${key}`} />
                  )}
                </>
              ))}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default Awesome;

const BookCoverImage = ({ src, href }) => {
  return (
    <section
      style={{
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        textAlign: 'center',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
    >
      <img src={src} alt="" style={{ width: '100%', maxWidth: '188px' }} />
    </section>
  );
};

const AwesomeVolumeRow = ({ level, volume }) => {
  return (
    <Row gutter={[16, 16]} justify="center" style={{ paddingBottom: '10px' }}>
      <Col span={4} style={{ textAlign: 'center', padding: '0' }}>
        <div
          style={{
            color: '#fff',
            fontSize: '1.5em',
            witdh: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: levels[level].color,
            borderRadius: '50%',
            marginLeft: '10%',
            marginRight: '10%',
          }}
        >
          Awesome
          <br />
          {`${level}-${volume}`}
        </div>
      </Col>
      <Col span={3}>
        <Link to={{ pathname: `https://lms-v2.talkingsam.com/data/ebook/AE-L${level}V${volume}/` }} target="_blank">
          <BookCoverImage src={`${BOOK_COVER_BASE_URL}SB/L${level}V${volume}.jpg`} />
        </Link>
      </Col>
      <Col span={3}>
        <Link to={{ pathname: `https://lms-v2.talkingsam.com/data/wb/Awesome_Level${level}_WB${volume}_AnswerKey.pdf` }} target="_blank">
          <BookCoverImage src={`${BOOK_COVER_BASE_URL}WB/L${level}V${volume}.jpg`} />
        </Link>
      </Col>
      <Col span={3}>
        {volume === 1 ? (
          <Link to={{ pathname: `${BOOK_WN_BASE_URL}Awesome_Level${level}_V${volume}_WN_Unit1.pdf` }} target="_blank">
            <BookCoverImage src={`${BOOK_COVER_BASE_URL}WN/L${level}V${volume}.jpg`} />
          </Link>
        ) : (
          <BookCoverImage src={`${BOOK_COVER_BASE_URL}WN/L${level}V${volume}.jpg`} />
        )}
      </Col>

      <Col span={4}>
        <Link to={{ pathname: `${PPT_DATA_BASE_URL}Awesome_PPT_L${level}V${volume}.zip` }} target="_blank">
          <BookCoverImage src={`${BOOK_COVER_BASE_URL}PPT/L${level}V${volume}.png`} />
        </Link>
      </Col>
      <Col span={4}>
        <BookCoverImage src={`${BOOK_COVER_BASE_URL}TL/L${level}V${volume}.png`} />
      </Col>
      <Col span={3} style={{ textAlign: 'center' }}>
        <div style={{ position: 'relative', top: '40%' }}>
          <Link to={{ pathname: `${MP3_DATA_BASE_URL}L${level}V${volume}.zip` }} target="_blank">
            <Button style={{ color: '#fff', backgroundColor: levels[level].color }}>Download</Button>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

const KidsTapPhonicsVolumeRow = ({ volume }) => {
  return (
    <Row gutter={[16, 16]} justify="center" style={{ paddingBottom: '10px' }}>
      <Col span={3} style={{ textAlign: 'center' }}>
        <div
          style={{
            color: '#fff',
            fontSize: '1.5em',
            witdh: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f00',
            borderRadius: '50%',
            marginLeft: '10%',
            marginRight: '10%',
          }}
        >
          Kids Tap Phonics
          <br />
          {`${volume}`}
        </div>
      </Col>
      <Col span={4}>
        <Link to={{ pathname: `http://admin.kidstapenglish.com/flipBook/Phonics/PH_${volume}/` }} target="_blank">
          <BookCoverImage src={`${KIDS_TAP_BOOK_COVER_BASE_URL}V${volume}.jpg`} />
        </Link>
      </Col>
      <Col span={4}>
        <Link to={{ pathname: `http://admin.kidstapenglish.com/TG/Phonics/PH_${volume}/` }} target="_blank">
          <BookCoverImage src={`${KIDS_TAP_BOOK_COVER_BASE_URL}V${volume}.jpg`} />
        </Link>
      </Col>
      <Col span={4}></Col>
      <Col span={3}></Col>
      <Col span={3}></Col>
      <Col span={3}></Col>
    </Row>
  );
};
