import React from "react";
import _ from "lodash";
import { isVowel } from '../../utils/pm/util';
import { useParams } from "react-router-dom";

let bookNameUnitStage = undefined;
const ExWordBox = ({word, direction}) => {

	const { bookName,unit,stage } = useParams();

	if(bookName == '2a' && (unit == 3 || 4 || 5) && (stage == 2 || 3 || 5 || 6 || 7 || 9 || 11)){
		bookNameUnitStage = true;
	}

	return (
		<p className="ex_txt" style={{margin:0,height: "20vh", lineHeight: "20vh", fontSize: "6rem", fontWeight: "bold", whiteSpace: "nowrap"}}>
			{["left", "both"].indexOf(direction) !== -1 && <span className="font_black">-</span>}
			{word &&
				(bookNameUnitStage ? 
				(
					_.map(word, (item, key) => (
						<span key={`qword-key${key}`} className={`${isVowel(item) ? "font_pink" : "font_pink"}`}>
							{item}
						</span>
					))
				) : 
				(
					_.map(word, (item, key) => (
						<span key={`qword-key${key}`} className={`${isVowel(item) ? "font_black" : "font_black"}`}>
							{item}
						</span>
					))
				))
				}
			{["right", "both"].indexOf(direction) !== -1 && <span className="font_black">-</span>}
		</p>
	);
};

export default ExWordBox;
