import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
// const Wrapper = styled(ColumnFlex)`
//   width: 100%;
//   height: 95%;
//   background-color: #453ec8;
//   justify-content: space-between;
//   align-items: center;
//   min-height: 660px;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-use-select: none;
//   user-select: none;
// `;
// const TitleSpan = styled.span`
//   color: ${(props) => (props.color ? props.color : '#000000')};
//   font-size: ${(props) => (props.color ? '30px' : '25px')};
//   font-weight: 600;
//   padding: 0 20px;
//   @media (max-width: 1280px) {
//     font-size: ${(props) => (props.color ? '25px' : '22px')};
//   }
// `;
// const Title = styled.div`
//   font-size: 33px;
//   font-weight: 600;
//   width: 100%;
//   text-align: left;
//   color: #433fc8;
//   background-color: #fef189;
//   padding: 12px;
//   @media (max-width: 1280px) {
//     font-size: 27px;
//   }
// `;
// const MainWrapper = styled(ColumnFlex)`
//   height: calc(100vh - 245px);
//   padding: 0 45px 15px;
//   align-items: center;
//   width: 80%;
//   max-width: 1500px;
//   min-width: 1250px;
//   margin: 0 auto;
//   @media (max-width: 1280px) {
//     width: 95%;
//     min-width: 800px;
//   }
// `;
// const Main = styled.div`
//   height: 100%;
//   width: 80%;
//   border-radius: 0 0 13px 13px;
//   background-color: #ffffff;
//   max-width: 1500px;
//   min-width: 1250px;
//   min-height: 550px;
//   min-height: 650px;
//   @media (max-width: 1280px) {
//     width: 95%;
//     min-width: 800px;
//   }
// `;
const Image = styled.img`
  object-fit: scale-down;
  width: 100%;
  height: 100%;
  /* border-radius: 15px; */
`;
// const NumberText = styled(RowFlex)`
//   color: #595758;
//   font-weight: 600;
//   font-size: 15px;
//   align-items: center;
// `;
// const QuizNumber = styled.div`
//   color: #717171;
//   border: 1px solid #b5b5b7;
//   border-radius: 3px;
//   padding: 5px;
//   margin: 0 5px;
// `;
// const StageTitle = styled.div`
//   font-size: 24px;
//   font-weight: 600;
//   color: #000;
//   padding: 30px 0;
//   @media (max-width: 1280px) {
//     font-size: 18px;
//   }
// `;
const Body = styled(ColumnFlex)`
  width: 90%;
  padding: 0 30px;
  height: 80%;
  align-items: center;
  /* justify-content: space-between; */
`;
// const ImgButton = styled.div`
//   cursor: pointer;
//   width: 50px;
//   min-width: 50px;
//   height: 50px;
//   min-height: 50px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 35px;
//   background-color: #443ec8;
//   color: #fff;
//   font-size: 25px;
//   margin: 0 15px;
//   box-shadow: 0 2px 1px 2px #090989;
// `;
const QuestionBox = styled(RowFlex)`
  font-size: ${(props) => (props.isLong ? '20px' : '25px')};
  color: #000000;
  align-items: center;
  padding: 0 40px;
  /* line-height: 75px; */
  height: 75px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #a1cec9;
  background-color: #daedeb;
  @media (max-height: 960px) {
    font-size: ${(props) => (props.isLong ? '18px' : '23px')};
    /* line-height: 65px; */
    height: 65px;
    border-radius: 10px;
  }
`;
const ChoiceBox = styled(RowFlex)`
  padding: 15px;
  width: 50%;
  height: 350px;
  @media (max-height: 960px) {
    height: 260px;
  }
`;
const ChoiceOption = styled.div`
  color: #000000;
  background-color: ${(props) => (props.bgc ? props.bgc : '#eff5fb')};
  border: 1px solid #a09de2;
  /* border-left: none; */
  border-radius: 30px;
  height: 300px;
  font-size: ${(props) => (props.isLong ? '20px' : '23px')};
  width: 100%;
  /* line-height: 60px; */
  padding: 0 60px 0 70px;
  cursor: pointer;
  @media (max-height: 960px) {
    font-size: ${(props) => (props.isLong ? '18px' : '21px')};
    /* line-height: 50px; */
    height: 250px;
    border-radius: 25px;
  }
`;
const ChoiceNumber = styled.div`
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  color: #453ec8;
  background: #fff;
  border: 2px solid #453ec8;
  border-radius: 30px;
  text-align: center;
  margin-right: -60px;
  z-index: 1;
  @media (max-height: 960px) {
    margin-right: -50px;
    font-size: 25px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
`;

const ChoiceWrapper = styled.div`
  display: flex;
  padding: 20px 0;
  width: 100%;
  flex-wrap: wrap;
`;
// const StageButton = styled.div`
//   width: 72px;
//   min-width: 72px;
//   height: 50px;
//   min-height: 50px;
//   border-radius: 15px;
//   /* border: 1px red solid; */
//   background-image: url(${(props) => props.bg});
//   background-size: 72px 50px;
//   background-repeat: no-repeat;
//   /* background-color: ${(props) => (props.active ? 'red' : 'orange')}; */
//   filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
//   cursor: pointer;
// `;
// const StageButtonWrapper = styled(RowFlex)`
//   background-color: #fef189;
//   height: 70px;
//   width: 60%;
//   align-items: center;
//   justify-content: space-between;
//   max-width: 800px;
//   border-radius: 20px 20px 0 0;
// `;
// const StageButtonText = styled(ColumnFlex)`
//   align-items: center;
//   font-size: 12px;
//   height: 100%;
//   color: #453ec8;
//   font-weight: 500;
//   background-color: ${(props) => props.active && '#b8d9f8'};
//   padding: 5px;
// `;
// const ArrowIcon = styled.div`
//   /* font-size: 40px; */
//   width: 52px;
//   min-width: 52px;
//   height: 45px;
//   min-height: 45px;
//   /* color: #453ec8; */
//   /* font-weight: bolder; */
//   margin: 0 35px;
//   background-image: url(${(props) => props.src});
//   background-size: 52px 45px;
//   background-repeat: no-repeat;
//   cursor: pointer;
// `;
const dummydata = {
  question: 'Where do you sleep?',
  url: 'https://images.costco-static.com/ImageDelivery/imageService?profileId=12026540&itemId=1570087-847&recipeName=680',
  option: [
    { number: 1, text: 'floor' },
    { number: 2, text: 'table' },
  ],
};
export default function LevelUpQuiz2({ checking, setChecking, currentQuiz, currentQuizOptions, questionNumber, handleTryCount }) {
  const [bgc, setBgc] = useState(new Array(currentQuizOptions.length || 2).fill(null));
  const handleClick = (answer, i) => {
    if (!checking) {
      setChecking(true);
      const tmp = [...bgc];
      if (answer) {
        tmp[i] = '#53f292';
        setBgc(tmp);
      } else {
        tmp[i] = '#ff4568';
        setBgc(tmp);
      }
      handleTryCount(answer, i);
    } else {
      // alert('잠시 뒤에 시도하세요.');
      return;
    }
  };
  useEffect(() => {
    setBgc(new Array(currentQuizOptions.length || 2).fill(null));
  }, [questionNumber, currentQuiz]);
  return (
    <>
      <Body>
        <RowFlex style={{ alignItems: 'center', width: '100%' }}>
          <QuestionBox
            isLong={currentQuiz?.question.length > 100}
            dangerouslySetInnerHTML={{ __html: `<span>${currentQuiz?.question}</span>` }}
          />
        </RowFlex>
        <ChoiceWrapper>
          {currentQuizOptions.map((e, i) => (
                <ChoiceBox onClick={() => handleClick(e?.Correct === 'True', i)}>
                  <ChoiceNumber>{i + 1}</ChoiceNumber>
                  <ChoiceOption bgc={bgc[i]}>
                    {''}
                    <Image src={e.Quiz_Image} />
                  </ChoiceOption>
                </ChoiceBox>
              ))}
        </ChoiceWrapper>
      </Body>
    </>
  );
}
