import React, { useState, useRef, useEffect, useMemo } from 'react';
// import {useSelector} from "react-redux";
import axios from 'axios';
import _ from 'lodash';
// import PageNav from "../components/common/PageNav";
// 일단은 db나 그런거 없으니까 이거 가져와서 하고, 나중에는 fetch 받아다 어딘가에 보관해두고 쓰든지 그렇게 바꾸면 될듯..
import bookInfo from 'src/utils/pm/bookInfo';
import UnitLayout from 'src/components/pm/UnitLayout';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useHistory, useParams } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import { SoundFilled, MutedFilled, HomeFilled } from '@ant-design/icons';
import { useUserStudyCompleteList } from 'src/utils/pm/api';
import queryString from 'query-string';
import { parseLessonCode } from 'src/utils';
import { Modal } from 'antd';

const StageList = ({ match, location }) => {
  const params = queryString.parse(window.location.search);
  const { level, unit } = useParams();

  const { data: complteStageList, loading: loadingUserStudy } = useUserStudyCompleteList();
  const [isMute, setIsMute] = useState(false);
  useEffect(() => {
    const pmMute = window.localStorage.getItem('pm_Mute');
    if (pmMute) {
      setIsMute(true);
    }
  }, []);
  useEffect(() => {
    if (isMute) {
      window.localStorage.setItem('pm_Mute', true);
    } else {
      window.localStorage.removeItem('pm_Mute', true);
    }
  }, [isMute]);

  const lesson = useMemo(() => {
    if (params.lesson_code) {
      let { day } = parseLessonCode(params.lesson_code);
      window.sessionStorage.setItem('lesson_url', day);
      return day == 2 ? true : false;
    } else if (window.sessionStorage.getItem('lesson_url') && window.sessionStorage.getItem('lesson_url') == 2) {
      return true;
    } else {
      return location.state.lesson;
    }
  }, [location.state, params.lesson_code]);

  const assignedLesson = useMemo(() => {
    if (params.lesson_code && params.user_lesson_idx) {
      window.sessionStorage.setItem('lesson_code', params.lesson_code);
      window.sessionStorage.setItem('user_lesson_idx', params.user_lesson_idx);
      return [params.user_lesson_idx, params.lesson_code];
    }

    if (window.sessionStorage.getItem('lesson_code') && window.sessionStorage.getItem('user_lesson_idx')) {
      return [window.sessionStorage.getItem('user_lesson_idx'), window.sessionStorage.getItem('lesson_code')];
    }

    return null;
  }, [params.lesson_code, params.user_lesson_idx]);

  const stageData = useMemo(() => {
    let find = bookInfo.find((item) => item.bookName === level);
    return find;
  }, [level]);

  const unitInfo = useMemo(() => {
    // return _.each(Object.keys(stageData.unit), (item) => (item === unit ? (unitInfo = stageData.unit[item]) : undefined));

    let unitInfo = stageData.unit[unit] ? stageData.unit[unit] : undefined;
    return unitInfo;
  }, [stageData, unit]);

  const [clickSound, setClickSound] = useState('');
  const clickedUrlRef = useRef(undefined);
  const history = useHistory();

  // const {isMute} = useSelector(state => state.commonStore, []);

  const handleClick = (e, path) => {
    e.preventDefault();
    clickedUrlRef.current = path;
    setClickSound('https://cdn.cloubot.com/PM/audio/sound-effect/game-choose.mp3');
  };

  const handleSoundEffectEnd = () => {
    history.push(clickedUrlRef.current);
  };

  const handleExit = () => {
    Modal.confirm({
      title: 'Exit',
      content: 'Do you want to exit?',
      onOk: () => {
        window.sessionStorage.removeItem('lesson_url');
        window.sessionStorage.removeItem('lesson_code');
        window.sessionStorage.removeItem('user_lesson_idx');
        history.push('/');
      },
    });
  };

  return (
    <>
      <S.fontStyle />
      <S.Root>
        <S.RowFlex style={{ width: '85vw', justifyContent: 'space-between', paddingBottom: 10 }}>
          <S.HeadUnitTitle>
            Unit {unit} {unitInfo.title}
          </S.HeadUnitTitle>

          <S.RowFlex>
            {assignedLesson && unit !== 'R' && (
              <S.UnitTD>
                <p className="button_game" style={{ marginBottom: 0 }}>
                  {
                    <a
                      href={`https://epublic-phonics.cloubot.com/game/phonics_game${
                        parseInt(unit) % 5 || 1
                      }/mobile/index.html?book=${level}&unit=${unit}`}
                      target="_blank"
                      className="btn_type2"
                      rel="noopener noreferrer"
                    >
                      <S.Button2>
                        <img style={{ width: '80%' }} src="/images/pm/i_game.svg" alt="GAME!" />
                      </S.Button2>
                    </a>
                  }
                </p>
              </S.UnitTD>
            )}
            {!assignedLesson && (
              <S.Button1
                style={{ marginRight: 10, width: 50, height: 50 }}
                onClick={() => {
                  window.sessionStorage.removeItem('lesson_url');
                  window.sessionStorage.removeItem('lesson_code');
                  window.sessionStorage.removeItem('user_lesson_idx');
                  history.goBack();
                }}
              >
                <img src="/images/pm/i_back.svg" alt="back" />
              </S.Button1>
            )}
            <S.Button1 onClick={() => setIsMute((prev) => !prev)} style={{ marginRight: 10, width: 50, height: 50 }}>
              {isMute ? (
                <MutedFilled style={{ fontSize: '1.5rem', color: '#fff' }} />
              ) : (
                <SoundFilled style={{ fontSize: '1.5rem', color: '#fff' }} />
              )}
            </S.Button1>
            {assignedLesson && (
              <S.Button1 style={{ marginRight: 10, width: 50, height: 50 }} onClick={handleExit}>
                <img src="/images/pm/i_exit.svg" alt="exit" />
              </S.Button1>
            )}
            {!assignedLesson && (
              <S.Button1
                style={{ width: 50, height: 50 }}
                onClick={() => {
                  history.push('/pm');
                }}
              >
                <HomeFilled style={{ fontSize: '1.4rem', color: '#fff' }} />
              </S.Button1>
            )}
          </S.RowFlex>
        </S.RowFlex>
        <UnitLayout unit={unit} unitInfo={unitInfo} lesson={lesson} handleClick={handleClick} complteStageList={complteStageList} />
        <SoundEffectPM url={clickSound} onEnd={handleSoundEffectEnd} />
        {!isMute && <SoundEffectPM url="https://cdn.cloubot.com/PM/audio/sound-effect/just-joking-fun.mp3" replayCnt={0} />}
      </S.Root>
    </>
  );
};

export default StageList;
