import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Button, Radio, Progress, Statistic, Input, Modal, Switch } from 'antd';
import {
  RedoOutlined,
  CheckOutlined,
  ZoomOutOutlined,
  ZoomInOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import clip from 'src/sounds/clip-sound.mp3';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ASSIGNED_TEST_USER_SINGLE } from 'src//operations/queries/getTests';
import { SAVE_TEST_ANSWERS } from 'src/operations/mutations/createTests';
import { useQuery, useMutation } from '@apollo/client';
import File from 'src/components/common/File';
import moment from 'moment';
import useSound from 'use-sound';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { storage } from 'src/utils/hooks';
import { useApolloClient } from '@apollo/client';
import BackButton from 'src/components/common/BackButton';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { Title } = Typography;

// const onHandleRowClick = (row, index, user) => {
//   if (window.Android && row.isMeeting) {
//     if (user.type == 1) {
//       window.Android.joinZoomClass(JSON.stringify(user));
//     }else{
//       window.Android.startZoomClass(JSON.stringify(user));
//     }
//   }
// }

const TestBlock = styled.div`
  margin-top: 12px;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const QuestionBlock = styled.div`
  padding: 2px;
  background: #eaecf1f2;
`;

const options = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];

const { Countdown } = Statistic;

const questionsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const questions = questionsList.map((e) => {
  return { id: e, options: options };
});

const New = (props) => {
  const [testUser, setTestUser] = useState({ assigned_tests: { tests: { test_answers: [] } } });
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [deadline, setDeadline] = useState(0);
  const [currentDoneRetries, setcurrentDoneRetries] = useState(0);
  const [previousRetriesAnswers, setPreviousRetriesAnswers] = useState([]);
  const [start_time, setStartTime] = useState(null);
  const [values, setValues] = useState([]);
  const [sures, setSures] = useState([]);
  const [pageWidth, setPageWidth] = useState(400);
  const [fullScreen, setFullScreen] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);
  const [answersList, setAnswersList] = useState([]);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [currentPdf, setCurrentPdf] = useState('');
  const [lastScore, setLastScore] = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [play] = useSound(clip);

  const client = useApolloClient();

  let myDocument = React.createRef();

  const [saveTestAnswers, { loading }] = useMutation(SAVE_TEST_ANSWERS);

  const { data } = useQuery(ASSIGNED_TEST_USER_SINGLE, {
    variables: { idx: parseInt(props.id) },
  });

  useEffect(() => {
    if (myDocument && myDocument.current) {
      setPageWidth(myDocument.current.offsetWidth);
    }

    if (data?.assignedTestUserSingle) {
      setTestUser(data?.assignedTestUserSingle);
    }
  }, [myDocument, data]);

  useEffect(() => {
    if (testUser?.assigned_tests?.limit_time) {
      setDeadline(
        testUser.assigned_tests.limit_time ? Date.now() + 1000 * 60 * testUser.assigned_tests.limit_time : Date.now() + 1000 * 60 * 0,
      );
      setStartTime(moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'));
    }
    setCurrentQuestions(testUser.assigned_tests.tests.test_answers);
    setSures(Array(testUser.assigned_tests.tests.test_answers.length).fill(true));
    setCurrentPdf(testUser.assigned_tests.tests.main_pdf ? testUser.assigned_tests.tests.main_pdf.path : '');
  }, [testUser, setDeadline, setStartTime]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onChange(e, idx) {
    values[idx] = e.target.value;
    // play();
    setValues(JSON.parse(JSON.stringify(values)));
  }

  function onChangeText(e, idx, num) {
    values[idx] = values[idx] ? values[idx] : [];
    values[idx][num] = e.target.value;
    // play();
    setValues(JSON.parse(JSON.stringify(values)));
  }

  const renderQuestions = (question, idx, span) => {
    const answers = question.answer.split('|');
    if (answers[0] === '') {
      return (
        <Col span={span} style={{ paddingRight: 40, paddingLeft: 0 }}>
          <Input.TextArea
            onChange={(e) => onChange(e, idx)}
            value={values[idx]}
            key={idx}
            placeholder="Enter Answer"
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </Col>
      );
    } else if (question.condition === 'OR') {
      if (question.option_count) {
        let options = new Array(question.option_count).fill({});
        options = options.map((e, i) => ({ label: i + 1, value: i + 1 }));
        return (
          <Col span={span} style={{ padding: 0 }}>
            <Radio.Group
              options={options}
              onChange={(e) => onChange(e, idx)}
              value={values[idx]}
              optionType="button"
              buttonStyle="solid"
              style={{ background: '#eaecf1f2' }}
            />
          </Col>
        );
      } else {
        return (
          <Col span={span} style={{ paddingRight: 40, paddingLeft: 0 }}>
            <Input onChange={(e) => onChange(e, idx)} value={values[idx]} placeholder="Enter Answer" />
          </Col>
        );
      }
    } else {
      if (question.option_count) {
        let options = new Array(question.option_count).fill({});
        options = options.map((e, i) => ({ label: i + 1, value: i + 1 }));
        return (
          <Col span={span} style={{ padding: 0 }}>
            {answers.map((a, aix) => (
              <Radio.Group
                options={options}
                onChange={(e) => onChangeText(e, idx, aix)}
                value={values[idx] ? values[idx][aix] : ''}
                optionType="button"
                buttonStyle="solid"
                style={{ background: '#eaecf1f2' }}
              />
            ))}
          </Col>
        );
      } else {
        return (
          <Col span={span} style={{ paddingRight: 40, paddingLeft: 0 }}>
            {answers.map((a, aix) => (
              <Input
                onChange={(e) => onChangeText(e, idx, aix)}
                value={values[idx] ? values[idx][aix] : ''}
                key={a}
                name={`${a}-a`}
                placeholder="Enter Answer"
              />
            ))}
          </Col>
        );
      }
    }
  };

  // const deadline = Date.now();

  const retakeWrongAnswers = (curQuestions) => {
    setValues([]);
    setSures(Array(curQuestions.length).fill(true));
    setCurrentQuestions(curQuestions);
    // let questions = curQuestions.map((e) => e.questions);
    // let groupQuestioinList = {};

    // let sortedQuestionList = [];

    // questions.forEach((question) => {
    //   if (question['questions']) {
    //     if (groupQuestioinList[question['questions']['question']] === undefined) {
    //       groupQuestioinList[question['questions']['question']] = [];
    //     }
    //     groupQuestioinList[question['questions']['question']].push(question);
    //   } else {
    //     sortedQuestionList[question['number']] = { questions: [question], group_name: null, group_context: null };
    //   }
    // });

    // Object.keys(groupQuestioinList).forEach((group_name) => {
    //   let sortedGroupQuestionList = groupQuestioinList[group_name].sort((a, b) => {
    //     return a['number'] - b['number'];
    //   });
    //   sortedQuestionList[sortedGroupQuestionList[0]['number']] = {
    //     questions: sortedGroupQuestionList,
    //     group_name: group_name,
    //     group_context: sortedGroupQuestionList[0]['question_direction'],
    //   };
    // });

    // setQuestionList(Object.values(sortedQuestionList));
  };

  const submitAnswer = async () => {
    if (window.confirm('답안지를 제출하시겠습니까') === false) {
      return false;
    }
    const end_time = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

    let score = 0;
    let wrongAnswers = [];

    const answers = currentQuestions.map((t, i) => {
      let answer = Array.isArray(values[i]) ? values[i].join('|') : values[i] ? values[i] + '' : '';
      let sanitizeAnswer = answer.replaceAll('.', '');
      let corAns = t.answer
        .replaceAll('.', '')
        .split('|')
        .map((p) => p.trim())
        .join('|');
      const splitAns = corAns.split('|').map((p) => p.trim());
      let is_correct = false;

      if (t.condition === 'OR' && splitAns.includes(sanitizeAnswer)) {
        score += 1;
        is_correct = true;
      } else if (corAns === sanitizeAnswer) {
        score += 1;
        is_correct = true;
      }
      if (!is_correct) {
        wrongAnswers.push(t);
      }

      return { answer, code: t.code, is_correct, sure: sures[i] ? 100 : 50 };
    });

    if (wrongAnswers.length) {
      //레벨테스트이면 안 물어보고 바로 저장한다.
      if (userInfoVar()?.level_test === 1 || testUser?.assigned_tests.retries_count <= currentDoneRetries) {
        setAnswersList();
      } else {
        console.log(currentDoneRetries);
        if (window.confirm(`retake wrong for ${wrongAnswers.map((p) => p.code)}`) === true) {
          if (currentDoneRetries > 0) {
            setPreviousRetriesAnswers((prev) => prev.concat(answers.map((p) => ({ ...p, retries: currentDoneRetries }))));
          } else {
            setLastScore(score);
            setAnswersList(answers);
          }
          retakeWrongAnswers(wrongAnswers);
          setcurrentDoneRetries(currentDoneRetries + 1);
          return false;
        } else {
          setAnswersList();
        }
      }
    }

    let data = {
      start_time,
      end_time,
      assigned_test_user_idx: testUser.idx,
      score: lastScore === null ? score : lastScore,
      answers: answersList.length ? answersList : answers,
      answer_retakes: answersList.length
        ? previousRetriesAnswers.concat(answers.map((p) => ({ ...p, retries: currentDoneRetries })))
        : null,
    };

    try {
      await saveTestAnswers({ variables: data });
      if (userInfoVar()?.level_test === 1) {
        //alert('제출완료');
        Modal.info({
          title: '제출완료!',
          onOk: () => {
            isLogedIn(false);
            userInfoVar(null);
            client.clearStore();
            client.cache.gc();
            storage.removeItem('culp_token');
            props.history.push('/level-test');
          },
        });
      } else {
        if (props?.history?.location?.state?.fromList) {
          props.history.push('/test/manage/list?refresh=true');
        } else {
          props.history.goBack();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // remove for now

  // {questionList && questionList.length && answersList && answersList.length ? (
  //   <>
  //     {questionList.map((questionGroup, idx) => (
  //       <div key={idx} style={{ marginBottom: '10px', padding: '10px' }}>
  //         <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
  //           <div style={{ width: '100%', verticalAlign: 'middle', textAlign: 'left' }}>
  //             {questionGroup.group_name ? (
  //               <div
  //                 style={{
  //                   display: 'flex',
  //                   backgroundColor: '#ebebeb',
  //                   marginBottom: '10px',
  //                   padding: '5px',
  //                   flexDirection: 'column',
  //                 }}
  //               >
  //                 <div
  //                   style={{
  //                     display: 'flex',
  //                     backgroundColor: '#ebebeb',
  //                     marginBottom: '10px',
  //                     padding: '5px',
  //                     width: '100%',
  //                   }}
  //                 >
  //                   <p style={{ display: 'inline-block' }}>{questionGroup.group_context}</p>
  //                 </div>
  //                 <div style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: questionGroup.group_name }}></div>
  //               </div>
  //             ) : null}
  //             <div style={questionGroup.group_name ? { paddingLeft: '20px' } : {}}>
  //               {questionGroup.questions.map((qnsx, idxs) => (
  //                 <div key={`${idx}-${idxs}`} data-qns={qnsx['number']} style={{ marginTop: '10px' }}>
  //                   <div style={{ display: 'flex' }}>
  //                     <h3 style={{ marginRight: '10px' }}> Question:{qnsx['number']}</h3>
  //                     <div style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: qnsx['question'] }}></div>
  //                   </div>
  //                   {qnsx['information_text'] && qnsx['information_text'] != '<br>' ? (
  //                     <div
  //                       style={{
  //                         display: 'flex',
  //                         marginTop: '10px',
  //                         justifyContent: 'center',
  //                         alignItems: 'center',
  //                         marginBottom: '10px',
  //                         border: '1px solid #ebebeb',
  //                       }}
  //                     >
  //                       <div
  //                         style={{ display: 'inline-block', flex: 1 }}
  //                         dangerouslySetInnerHTML={{ __html: qnsx['information_text'] }}
  //                       ></div>
  //                     </div>
  //                   ) : null}

  //                   {qnsx['options'] && qnsx['options'].length && (
  //                     <div style={{ paddingLeft: '20px', marginTop: '10px' }}>
  //                       <b style={{ marginRight: '10px' }}>Options:</b>
  //                       {qnsx['options'].split('|').map((ansx, idxsa) => (
  //                         <div key={`${idx}-${idxs}-${idxsa}`} style={{ marginTop: '10px', display: 'flex' }}>
  //                           {ansx}
  //                         </div>
  //                       ))}
  //                     </div>
  //                   )}

  //                   {idxs + 1 < questionGroup.questions.length && (
  //                     <hr style={{ marginTop: 10, borderTop: '1px solid blue' }} />
  //                   )}
  //                 </div>
  //               ))}
  //             </div>
  //           </div>
  //         </div>
  //         <hr />
  //       </div>
  //     ))}
  //   </>
  // ) : (

  return (
    <>
      <Col className="testpreview-wrapper" span={24} style={{ margin: 0 }}>
        <TestBlock>
          <Row gutter={[24, 16]}>
            <Col className={`pdffullscreen ${mobileActive ? 'active' : 'inactive'}`} span={fullScreen ? 24 : 17}>
              <div className="openboxlayout">
                <Button
                  type="primary"
                  onClick={() => setMobileActive(!mobileActive)}
                  icon={mobileActive ? <UpOutlined /> : <DownOutlined />}
                >
                  Open
                </Button>
              </div>

              <TestSectionBlock className="pdffullscreen-scroll" ref={myDocument}>
                <>
                  <Document file={currentPdf} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.apply(null, Array(numPages))
                      .map((x, i) => i + 1)
                      .map((page) => (
                        <Page
                          key={page}
                          pageNumber={page}
                          scale={scale}
                          renderAnnotationLayer={true}
                          renderInteractiveForms={true}
                          renderTextLayer={true}
                          width={pageWidth}
                        />
                      ))}
                  </Document>
                  <Button
                    type="primary"
                    onClick={() => setScale(scale - 0.2)}
                    shape="circle"
                    style={{
                      background: '#3b99ff',
                      position: 'absolute',
                      bottom: '20px',
                      right: '60px',
                    }}
                    icon={<ZoomOutOutlined />}
                  />
                  <Button
                    type="primary"
                    onClick={() => setScale(scale + 0.2)}
                    shape="circle"
                    style={{
                      background: '#3b99ff',
                      position: 'absolute',
                      bottom: '20px',
                      right: '100px',
                    }}
                    icon={<ZoomInOutlined />}
                  />
                  <Button
                    type="primary"
                    onClick={() => {
                      setPageWidth(400);
                      setFullScreen(!fullScreen);
                    }}
                    shape="circle"
                    style={{
                      background: '#3b99ff',
                      position: 'absolute',
                      bottom: '60px',
                      right: '60px',
                    }}
                    icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                  />
                </>
              </TestSectionBlock>
            </Col>

            {!fullScreen ? (
              <Col span={7} className={`pdffullscreen-box `}>
                <BackButton />
                <TestSectionBlock className="pdffullscreen-box-scroll" style={{ background: '#eaecf1f2', padding: 5 }}>
                  <Row gutter={[24, 16]} style={{ background: '#fff', padding: 10 }}>
                    <Title
                      level={4}
                      style={{
                        textAlign: 'center',
                        color: '#3b99ff',
                        width: '100%',
                      }}
                    >
                      {testUser.assigned_tests ? testUser.assigned_tests.title : ''}
                    </Title>
                  </Row>
                  <Row gutter={[24, 16]} style={{ background: '#fff', padding: 10 }}>
                    <Countdown
                      style={{
                        textAlign: 'center',
                        color: '#3cc533',
                        width: '100%',
                      }}
                      title="Time"
                      value={deadline}
                    />
                  </Row>

                  {testUser.assigned_tests.tests.extra_pdf ? (
                    <Row gutter={[24, 16]} style={{ background: '#fff', padding: 0 }}>
                      <Col span={14}>
                        <Title level={5}>Explanation PDF</Title>
                      </Col>
                      <Col span={10}>
                        <File
                          file={testUser.assigned_tests.tests.extra_pdf}
                          thumbStyle={{ fontSize: 15, width: '100%', color: '#887053' }}
                          textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
                        />
                      </Col>
                    </Row>
                  ) : null}

                  {testUser.assigned_tests.tests.extra_audio ? (
                    <Row gutter={[24, 16]} style={{ background: '#fff', padding: 0 }}>
                      <Col span={24}>
                        <File
                          file={testUser.assigned_tests.tests.extra_audio}
                          thumbStyle={{ fontSize: 15, width: '100%', color: '#887053', flatAudio: true }}
                          textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {testUser.assigned_tests.tests.extra_video ? (
                    <Row gutter={[24, 16]} style={{ background: '#fff', padding: 0 }}>
                      <Col offset={2} span={12}>
                        <Title level={5}>Video</Title>
                      </Col>
                      <Col span={8}>
                        <File
                          file={testUser.assigned_tests.tests.extra_video}
                          thumbStyle={{ fontSize: 40, width: '100%', color: '#887053' }}
                          textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
                        />
                      </Col>
                    </Row>
                  ) : null}

                  {currentQuestions.map((e, idx) => {
                    return (
                      <QuestionBlock key={idx}>
                        <Row gutter={[24, 16]}>
                          <Col span={4} style={{ paddingLeft: 20 }}>
                            <Title level={5}>{e.code}.</Title>
                          </Col>
                          {renderQuestions(e, idx, 15)}
                          <Col span={4} style={{ padding: 0 }}>
                            <Switch
                              checked={sures[idx]}
                              onChange={(checked) => {
                                const newSures = [...sures];
                                newSures[idx] = checked;
                                setSures(newSures);
                              }}
                              checkedChildren="100%"
                              unCheckedChildren="Not"
                            />
                          </Col>
                        </Row>
                      </QuestionBlock>
                    );
                  })}
                  <div
                    style={{
                      background: '#eaecf1f2',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      padding: 10,
                    }}
                  >
                    <>
                      <Button
                        size="large"
                        type="primary"
                        shape="circle"
                        style={{
                          background: '#1890ff',
                        }}
                        onClick={() => setValues([])}
                        icon={<RedoOutlined />}
                      />
                      <Button
                        size="large"
                        style={{
                          background: '#1890ff',
                          color: '#fff',
                          borderRadius: 5,
                        }}
                        onClick={submitAnswer}
                      >
                        Done
                        <CheckOutlined />
                      </Button>
                    </>
                  </div>
                </TestSectionBlock>
              </Col>
            ) : null}
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

export default withRouter(New);
