import React, { useState, useMemo, useEffect } from 'react';
import { Col, Typography, Space, Row, Button, Input, DatePicker, Tooltip } from 'antd';
import { withRouter, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { AI_TOPIC_USER_ASSIGNMENT_LIST } from 'src/operations/queries/aiTopic';
import { UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox from 'src/components/common/ClassSelectBox';
import WritingReportCoachMenuGroup from 'src/components/common/WritingReportCoachMenuGroup';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import BookReportAnswerModal from 'src/components/Modal/BookReportAnswerModal';
import GeneratePdfButton from 'src/components/common/GeneratePdfButton';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const CampusResults = (props) => {
  const query = new URLSearchParams(props.location.search);
  const isRefresh = query.get('refresh');
  const [ai_topic_user_assignments, setAssignedAITopics] = useState([]);
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [total, setTotal] = useState(0);
  const [topic, setTopic] = useState();
  const [currentChatList, setCurrentChatList] = useState([]);
  const [userTopicId, setUserTopicId] = useState();
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [topicAnswer, setTopicAnswer] = useState('');
  const [recordingData, setRecordingData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [localState, setLocalState] = UseHistoryState(
    {
      class_idx: 0,
      currentPage: 0,
      pageSize: 10,
      class_type: 0, //class_type - 0: 진행, 1: 종료
      start_date: dayjs().subtract(3, 'M').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      searchValue: '',
    },
    'ai_topic_result',
  );
  dayjs.extend(customParseFormat);
  dayjs.extend(advancedFormat);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(weekOfYear);
  dayjs.extend(weekYear);
  useEffect(() => {
    setLocalState((prev) => {
      return {
        ...prev,
        currentPage: 0,
      };
    });
  }, [localState.class_idx]);
  const [updateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });

  const { data, loading, refetch } = useQuery(AI_TOPIC_USER_ASSIGNMENT_LIST, {
    // variables: { class_idx, page, take },
    variables: {
      class_idx: localState.class_idx,
      class_type: localState.class_type,
      type: 'book-report',
      start_date: localState.start_date ? localState.start_date : undefined,
      end_date: localState.end_date ? localState.end_date : undefined,
    },
    fetchPolicy: 'no-cache',
  });

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });
  const ownClass = useMemo(() => {
    return classQuery?.data?.classList.map((e) => e.idx);
  }, [classQuery]);
  useEffect(() => {
    if (data?.aiTopicUserAssignmentList) {
      setAssignedAITopics(
        data?.aiTopicUserAssignmentList.ai_topic_user_assignments
          .filter((e) => ownClass?.includes(e.class_idx))
          .sort((a, b) => new Date(b.ai_topic_date_assignment.start_date) - new Date(a.ai_topic_date_assignment.start_date))
          .map((item, index) => {
            let ai_topic_date_assignmentData = JSON.parse(item?.ai_topic_date_assignment?.data || '{}');
            return {
              ...item,
              ai_topic_date_assignment: {
                ...item.ai_topic_date_assignment,
                data: ai_topic_date_assignmentData,
              },
              ai_topic: {
                ...item.ai_topic,
                data: JSON.parse(item.ai_topic.data || '{}'),
                topic: ai_topic_date_assignmentData?.revisedPrompt || item.ai_topic.topic,
              },
              no: index + 1,
              start_date: data?.aiTopicDateAssignmentDetails?.start_date,
              rubric_data: JSON.parse(item.rubric_data),
              ai_request_responses: item.ai_request_responses.map((item) => {
                return {
                  ...item,
                  promt: item.prompt_text,
                  content: item.completion_text,
                  data: JSON.parse(item.data),
                  created: item.idate,
                };
              }),
            };
          }),
      );

      setTotal(data?.aiTopicUserAssignmentList.total);
    }

    if (isRefresh) {
      refetch();
      props.history.replace({ search: '' });
    }
  }, [data, isRefresh, ownClass]);

  const filteredData = useMemo(() => {
    if (ai_topic_user_assignments) {
      let filtered = ai_topic_user_assignments;
      if (localState.searchValue) {
        filtered = ai_topic_user_assignments.filter((e) => e.user.name.toLowerCase().includes(localState.searchValue.toLowerCase()));
      }
      setTotal(filtered.length);
      return filtered;
    }
  }, [localState.searchValue, ai_topic_user_assignments]);

  const handleAITopicAssignDelete = (item) => {
    const idxs = [item.idx];
    props.onAITopicAssignDelete({ idxs });
    setTimeout(() => {
      refetch();
      classQuery.refetch();
    }, 1000);
  };
  const onChangeStartDate = (a, date) => {
    let clearCheck = a === null;
    if (clearCheck) {
      setLocalState((prev) => {
        return {
          ...prev,
          start_date: undefined,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          start_date: dayjs(date).format('YYYY-MM-DD'),
        };
      });
    }
  };
  const onChangeEndDate = (a, date) => {
    let clearCheck = a === null;
    if (clearCheck) {
      setLocalState((prev) => {
        return {
          ...prev,
          end_date: undefined,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          end_date: dayjs(date).format('YYYY-MM-DD'),
        };
      });
    }
  };
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
    },
    {
      title: 'A. Date',
      dataIndex: 'ai_topic_date_assignment',
      align: 'center',
      key: 'date',
      width: 100,
      sorter: (a, b) =>
        a.ai_topic_date_assignment.start_date && a.ai_topic_date_assignment.start_date.localeCompare(b.ai_topic_date_assignment.start_date),
      render: (text, record) => {
        return (
          <Space size="middle">
            <span style={{ fontWeight: 'bold' }}>{record.ai_topic_date_assignment.start_date.substr(0, 10)}</span>
          </Space>
        );
      },
    },
    {
      title: 'Class',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return (
          <Space size="middle">
            {' '}
            <span style={{ color: 'blue', fontWeight: 'bold' }}>{record?.class?.name}</span>
          </Space>
        );
      },
    },
    {
      title: 'Student',
      dataIndex: 'user',
      key: 'user',
      width: 200,
      align: 'center',
      render: (text, record) => {
        return (
          <Space size="middle">
            <span style={{ color: 'blue', fontWeight: 'bold' }}>{record.user.name + '(' + record.user.id + ')'}</span>
          </Space>
        );
      },
    },
    {
      title: 'Topic/Prompt',
      dataIndex: 'topic',
      key: 'topic',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placement="topLeft" color="#fff" title={record?.ai_topic.topic} size="middle">
            {record?.ai_topic.topic}
          </Tooltip>
        );
      },
    },

    {
      title: 'Rubric',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status < 1) {
          return <Space size="middle">-</Space>;
        }

        if (record.rubric_data) {
          return <Space size="middle">{record.rubric_data?.score}</Space>;
        }
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                props.history.push('/aicoach/results/rubric/' + record.idx);
              }}
            >
              Edit
            </Button>
          </Space>
        );
      },
    },
    {
      title: 'TOEFL',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record?.rubric_data?.toefl) {
          return <Space size="middle">{record?.rubric_data?.toefl}</Space>;
        } else return <Space size="middle">-</Space>;
      },
    },
    {
      title: 'Original',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  let filtered = record.ai_request_responses.filter((item) => item?.data?.type !== 'word');
                  if (filtered.length > 0) {
                    setCurrentChatList([
                      {
                        ...filtered[0],
                        content: filtered[0]?.data?.currentText || '',
                      },
                    ]);
                  }

                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Check',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  setCurrentChatList(record.ai_request_responses.filter((item) => item?.data?.type === 'check'));
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Comment',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  setCurrentChatList(record.ai_request_responses.filter((item) => item?.data?.type === 'comment'));
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Rewrite',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  setCurrentChatList([
                    {
                      content: record.answer,
                      prompt: record.answer,
                      idate: record.idate,
                      data: '{}',
                    },
                  ]);
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'ACC/WPM',
      key: 'wpm',
      dataIndex: 'wpm',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status < 2) {
          return <span>-</span>;
        } else {
          return (
            <Space size="middle">
              <Button onClick={() => openRecordingDataModal({ ...record, accuracy: `${record.score}%` }, record?.user?.name)}>
                {record.score}/{text}
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Std.PDF',
      key: 'user',
      dataIndex: 'user',
      width: 90,
      align: 'center',
      render: (text, record) => {
        if (record.status < 1) {
          return <span>-</span>;
        } else {
          return (
            <Space size="middle">
              <GeneratePdfButton
                saveData={async (url) => {
                  await handleGeneratePdf(record.idx, { ...record.rubric_data, pdf_url: url });
                }}
                url={`https://culp.cloubot.com/aicoach/report/generate/${record.idx}?pdfType=student`}
                pdfUrl={record.rubric_data?.pdf_url}
                filename={`-writing-coach-report-result-${record.idx}`}
                isEditUrl={`/aicoach/report/generate/${record.idx}?pdfType=student`}
              />
            </Space>
          );
        }
      },
    },
    {
      title: 'T.PDF',
      key: 'user',
      dataIndex: 'user',
      width: 90,
      align: 'center',
      render: (text, record) => {
        if (record.status < 1) {
          return <span>-</span>;
        } else {
          let commentFull = record.ai_request_responses.filter((item) => item?.data?.type === 'comment-full');
          return (
            <Space size="middle">
              <GeneratePdfButton
                saveData={async (url) => {
                  await handleGeneratePdf(record.idx, { ...record.rubric_data, pdf_url_teacher: url });
                }}
                url={`https://culp.cloubot.com/aicoach/report/generate/${record.idx}?pdfType=teacher&type=${
                  commentFull.length > 0 ? 'comment-full' : 'comment'
                }`}
                pdfUrl={record.rubric_data?.pdf_url_teacher}
                filename={`-writing-coach-report-result-full-${record.idx}`}
                isEditUrl={`/aicoach/report/generate/${record.idx}?pdfType=teacher&type=${
                  commentFull.length > 0 ? 'comment-full' : 'comment'
                }`}
              />
            </Space>
          );
        }
      },
    },
  ];

  const handleGeneratePdf = async (idx, data) => {
    await updateAITopicUserAssignment({
      variables: {
        idx,
        rubric_data: JSON.stringify(data),
      },
    });
    refetch();
  };

  function handleTableChange(pagination, filters) {
    setLocalState((prev) => {
      return {
        ...prev,
        currentPage: pagination.current - 1,
        pageSize: pagination.pageSize,
      };
    });
  }

  function openRecordingDataModal(info, nameStr) {
    setIsRecordingVisible(true);
    setRecordingData(info);
    setCurrentUserName(nameStr);
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }
  const onSeachChange = (val) => {
    if (val.target.value) {
      setLocalState((prev) => {
        return {
          ...prev,
          searchValue: val.target.value,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          searchValue: '',
        };
      });
    }
  };
  return (
    <>
      <WritingReportCoachMenuGroup currentMenu="result" />
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col className="contenttest-title-result" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>Writing Coach Result</Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <ClassSelectBox
                style={{ width: 150, color: '#065aa9', marginRight: 10 }}
                // placeholder="Select a Class"
                selectedClassIdx={localState?.selectedClassIdx}
                campus_idx={dataUser?.getUserData?.campus_idx}
                onChange={(val) =>
                  setLocalState((prev) => {
                    return {
                      ...prev,
                      selectedClassIdx: parseInt(val),
                      class_idx: parseInt(val),
                    };
                  })
                }
              />
              &nbsp; &nbsp;
              <Input.Search
                className="inputsearch-classroom inputsearch-classroom-wrap"
                placeholder="Search Student"
                allowClear
                style={{ width: 250 }}
                onChange={onSeachChange}
                enterButton
              />
              &nbsp;
              <DatePicker
                style={{ marginRight: 10 }}
                onChange={onChangeStartDate}
                value={dayjs(localState.start_date)}
                allowClear={false}
              />
              ~
              <DatePicker style={{ margin: '0 10px' }} onChange={onChangeEndDate} value={dayjs(localState.end_date)} allowClear={false} />
              &nbsp;
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            loading={loading}
            dataSource={filteredData}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            //scroll={{ y: 'calc(100vh - 254px) !important' }}
            //pagination={{ pageSize: take, total }}
            pagination={{ pageSize: localState.pageSize, current: localState.currentPage + 1, total: total, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            //onChange={(a) => setPage(a.current - 1)}
          />
        </MainBlock>
        {viewAnswerModal && (
          <BookReportAnswerModal
            visible={viewAnswerModal}
            chatList={currentChatList}
            handleCancel={() => {
              setViewAnswerModal(false);
            }}
            topic={topic}
          />
        )}
        <RecordingDataModal
          data={{ ...recordingData, currentUserName, className: '' }}
          isShow={isRecordingVisible}
          onPrint={null}
          componentRef={null}
          onOk={handleOkForRecordingData}
          hidePrint={true}
        />
      </Col>
    </>
  );
};

export default withRouter(CampusResults);
