import React, { useEffect, useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { isVowel } from '../../utils/pm/util';
import SoundButton from './SoundButton';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useParams } from 'react-router-dom';

let bookNameUnitStage = undefined;
let bookNameUnitStageAdd = undefined;
let bookNameUnitStageExcep = undefined;
const isConsonantExist = (c, arr) => {
  const consonantArr = arr.map((v) => v.substr(0, 1));

  return consonantArr.indexOf(c) !== -1;
};

const AlphabetDnD3 = ({ upperWord, directionEnd, stages, setSteps }) => {
  const [vowel, setVowel] = useState(undefined);
  const [consonant, setConsonant] = useState(undefined);
  const [isCorrect, setIsCorrect] = useState([false, false]);
  const [wordIndex, setWordIndex] = useState(0);

  const [dropSound, setDropSound] = useState(undefined);
  const [eachSound, setEachSound] = useState(undefined);
  const { bookName, unit, stage } = useParams();

  if (bookName == '3b' && unit == 9 && stage == 2) {
    bookNameUnitStage = true;
    bookNameUnitStageAdd = true;
    bookNameUnitStageExcep = { e: true };
  }

  const handleDragEnd = useCallback(
    (result) => {
      console.log('handleDragEnd', result);
      const { destination, source } = result;
      if (_.isNil(destination)) {
        setDropSound('wrong_sound1');
        return;
      }

      if (destination.droppableId === source.droppableId) {
        return;
      }

      setDropSound('mouse-click');
      const copy = _.cloneDeep(isCorrect);

      if (destination.droppableId === 'consonant') {
        copy[0] = true;
      } else {
        copy[1] = true;
      }

      if (copy.every((v) => v)) {
        const copyVowel = _.cloneDeep(vowel);
        copyVowel.splice(copyVowel.indexOf(upperWord[wordIndex].substr(1)), 1);
        setVowel(copyVowel);
        if (!isConsonantExist(upperWord[wordIndex].substr(0, 1), upperWord.slice(wordIndex + 1))) {
          const copyConsonant = _.cloneDeep(consonant);
          copyConsonant.splice(copyConsonant.indexOf(upperWord[wordIndex].substr(0, 1)), 1);
          setConsonant(copyConsonant);
        }
      }
      setIsCorrect(copy);
    },
    [isCorrect],
  );

  const handleDropSoundEnd = () => {
    if (dropSound === 'mouse-click') {
      console.log('handleDropSoundEnd', isCorrect);
      if (isCorrect.every((v) => v)) {
        setEachSound(upperWord[wordIndex]);
      }
    }
    setDropSound(undefined);
  };
  const handleEachSoundEnd = () => {
    if (isCorrect.every((v) => v)) {
      if (wordIndex < upperWord.length - 1) {
        setWordIndex(wordIndex + 1);
      } else {
        setSteps((prev) => prev + 1);
      }
    }
    setEachSound(undefined);
  };

  useEffect(() => {
    const aWord = [];
    const aVowel = [];
    _.each(upperWord, (item) => {
      // 중복된 것들이 들어가지 않도록 거르기... 중복된 key값이 들어가는 경우에는 drag에서 문제가 생김
      if (aWord.indexOf(item.substring(0, 1)) === -1) aWord.push(item.substring(0, 1));
      aVowel.push(item.substring(1));
    });
    setConsonant(aWord);
    setVowel(aVowel);
  }, [upperWord]);

  useEffect(() => {
    if (upperWord[wordIndex]) {
      setIsCorrect([false, false]);

      setEachSound(upperWord[wordIndex]);
    }
  }, [wordIndex, upperWord]);

  return (
    // <DragDropContext onDragEnd={dragEnd}>
    // 	<div className="contents_hrbox hrbox_arrt">
    // 		<SoundButton src={consonant && vowel && `${consonant[0] + vowel[0]}.mp3`} style={style} />
    // 		<div className="block_hrbox_t">
    // 			<ul className="block_align">
    // 				<Droppable droppableId="consonant" type={consonant && consonant[0]}>
    // 					{(provided, snapshot) => (
    // 						<li
    // 							ref={provided.innerRef}
    // 							style={{
    // 								backgroundColor: snapshot.isDraggingOver ? "#00b3e6" : undefined,
    // 							}}
    // 							className={isCorrect[0] ? "block_green" : "block_default"}
    // 						>
    // 							<span className={isCorrect[0] ? "text_blue" : "text_gray"}>{isCorrect[0] ? consonant[0] : undefined}</span>
    // 							<ul style={{display: "none"}}>{provided.placeholder}</ul>
    // 						</li>
    // 					)}
    // 				</Droppable>
    // 				<li className="block_none">
    // 					<span className="i_plus" />
    // 				</li>
    // 				<Droppable droppableId="vowel" type={vowel && vowel[0]}>
    // 					{(provided, snapshot) => (
    // 						<li
    // 							ref={provided.innerRef}
    // 							style={{
    // 								backgroundColor: snapshot.isDraggingOver ? "#00b3e6" : undefined,
    // 							}}
    // 							className={isCorrect[1] ? "block_blue block_3" : "block_default block_3"}
    // 						>
    // 							{_.map(vowel && vowel[0], item => (
    // 								<span key={item} className={isCorrect[1] ? `text_${isVowel(item) ? "pink" : "blue"}` : undefined}>
    // 									{isCorrect[1] ? item : undefined}
    // 								</span>
    // 							))}
    // 							<ul style={{display: "none"}}>{provided.placeholder}</ul>
    // 						</li>
    // 					)}
    // 				</Droppable>
    // 				<li className="block_none">
    // 					<span className="i_equals" />
    // 				</li>
    // 				<li className={isCorrect[2] ? "block_yellow block_3" : "block_default block_3"}>
    // 					{_.map(upperWord[0], item => (
    // 						<span key={item} className={isVowel(item) ? "text_pink" : "text_blue"}>
    // 							{isCorrect[2] ? item : undefined}
    // 						</span>
    // 					))}
    // 				</li>
    // 			</ul>
    // 		</div>
    // 	</div>
    // 	<p className="arrow arrow_top" />
    // 	<div className="contents_hrbox hrbox_arrb">
    // 		<div className="block_box_b inline_container">
    // 			<ul className="block_align block_align_inline">
    // 				{_.map(consonant, (item, index) => (
    // 					<Droppable key={item + index} droppableId={item + "drop"} isDropDisabled={true} type={consonant && consonant[index]}>
    // 						{provided => (
    // 							<span ref={provided.innerRef}>
    // 								<Draggable key={item} draggableId={item} index={index}>
    // 									{provided => (
    // 										<li key={item} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="block_green">
    // 											<span className="text_blue">{item}</span>
    // 										</li>
    // 									)}
    // 								</Draggable>
    // 								{provided.placeholder}
    // 							</span>
    // 						)}
    // 					</Droppable>
    // 				))}
    // 			</ul>
    // 			<ul className="block_align block_align_inline">
    // 				{_.map(vowel, (item, index) => (
    // 					<Droppable key={item} droppableId={item + "drop"} isDropDisabled={true} type={vowel && vowel[index]}>
    // 						{provided => (
    // 							<span ref={provided.innerRef}>
    // 								<Draggable key={index + item} draggableId={item} index={index}>
    // 									{provided => (
    // 										<li key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="block_blue block_3">
    // 											{_.map(item, word => (
    // 												<span key={word} className={isVowel(word) ? "text_pink" : "text_blue"}>
    // 													{word}
    // 												</span>
    // 											))}
    // 										</li>
    // 									)}
    // 								</Draggable>
    // 								{provided.placeholder}
    // 							</span>
    // 						)}
    // 					</Droppable>
    // 				))}
    // 			</ul>
    // 		</div>
    // 	</div>
    // </DragDropContext>
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="main_content  stage2-2_content  row2_arrow">
          <div className="top_box" style={{ position: 'relative' }}>
            <SoundButton src={`${upperWord[wordIndex]}.mp3`} style={{ position: 'absolute', top: '10px', right: '10px' }} />
            <ul className="alphabet_wrap">
              <Droppable droppableId="consonant" type={!_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(0, 1)}>
                {(provided, snapshot) => (
                  <li ref={provided.innerRef}>
                    <div
                      className={`alphabet_box row1 ${isCorrect[0] ? 'green' : 'gray'}`}
                      style={{
                        backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined,
                      }}
                    >
                      {bookNameUnitStage && bookNameUnitStageAdd ? (
                        <span className={`font_${bookNameUnitStageExcep[upperWord[wordIndex].substr(0, 1)] == true ? 'pink' : 'blue'}`}>
                          {isCorrect[0] ? !_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(0, 1) : undefined}
                        </span>
                      ) : (
                        <span className="font_blue">
                          {isCorrect[0] ? !_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(0, 1) : undefined}
                        </span>
                      )}
                    </div>
                    <ul style={{ display: 'none' }}>{provided.placeholder}</ul>
                  </li>
                )}
              </Droppable>

              <li>
                <div className="sign">+</div>
              </li>

              <Droppable droppableId="vowel" type={!_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(1)}>
                {(provided, snapshot) => (
                  <li ref={provided.innerRef}>
                    <div
                      className={`alphabet_box row3 ${isCorrect[1] ? 'blue' : 'gray'}`}
                      style={{
                        backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined,
                      }}
                    >
                      {bookNameUnitStage && bookNameUnitStageAdd
                        ? _.map(!_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(1), (item, itemkey) => (
                            <span
                              key={item}
                              className={isCorrect[1] ? `font_${bookNameUnitStageExcep[item] == true ? 'pink' : 'blue'}` : undefined}
                            >
                              {isCorrect[1] ? item : undefined}
                            </span>
                          ))
                        : _.map(!_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(1), (item) => (
                            <span key={item} className={isCorrect[1] ? `font_${isVowel(item) ? 'pink' : 'blue'}` : undefined}>
                              {isCorrect[1] ? item : undefined}
                            </span>
                          ))}
                    </div>
                    <ul style={{ display: 'none' }}>{provided.placeholder}</ul>
                  </li>
                )}
              </Droppable>

              <li>
                <div className="sign">=</div>
              </li>

              <li>
                <div className={`alphabet_box row3 ${isCorrect.every((v) => v) ? 'yellow' : 'gray'}`}>
                  {bookNameUnitStage && bookNameUnitStageAdd
                    ? _.map(upperWord[wordIndex], (item, itemkey) => (
                        <span data="item1" key={item} className={bookNameUnitStageExcep[item] == true ? 'font_pink' : 'font_blue'}>
                          {isCorrect.every((v) => v) ? item : undefined}
                        </span>
                      ))
                    : _.map(upperWord[wordIndex], (item) => (
                        <span data="item2" key={item} className={isVowel(item) ? 'font_pink' : 'font_blue'}>
                          {isCorrect.every((v) => v) ? item : undefined}
                        </span>
                      ))}
                </div>
              </li>
            </ul>
          </div>
          <img src="/images/pm/i_arrow_top.svg" alt="위로 향하는 화살표" />
          <div className="bottom_box" style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <ul className="alphabet_wrap row1_wrap" style={{ width: '30%' }}>
              {_.map(consonant, (item, index) => (
                <Droppable key={item + index} droppableId={item + 'drop'} isDropDisabled={true} type={consonant && consonant[index]}>
                  {(provided) => (
                    <li ref={provided.innerRef}>
                      <Draggable key={item} draggableId={item} index={index}>
                        {(provided) => (
                          <div
                            key={item}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="alphabet_box row1 green"
                          >
                            {bookNameUnitStage && bookNameUnitStageAdd ? (
                              <span className={`font_${bookNameUnitStageExcep[item] == true ? 'pink' : 'blue'}`}>{item}</span>
                            ) : (
                              <span className="font_blue">{item}</span>
                            )}
                          </div>
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </li>
                  )}
                </Droppable>
              ))}
            </ul>

            <ul className="alphabet_wrap row1_wrap" style={{ width: '70%' }}>
              {_.map(vowel, (item, index) => (
                <Droppable key={item} droppableId={item + 'drop'} isDropDisabled={true} type={vowel && vowel[index]}>
                  {(provided) => (
                    <li ref={provided.innerRef}>
                      <Draggable key={index + item} draggableId={item} index={index}>
                        {(provided) => (
                          <div
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="alphabet_box row1 long-width blue"
                          >
                            {bookNameUnitStage && bookNameUnitStageAdd
                              ? _.map(item, (word) => (
                                  <span
                                    data="item5"
                                    data1={item}
                                    data2={word}
                                    key={word}
                                    className={`font_${bookNameUnitStageExcep[word] == true ? 'pink' : 'blue'}`}
                                  >
                                    {word}
                                  </span>
                                ))
                              : _.map(item, (word) => (
                                  <span data="item6" key={word} className={isVowel(word) ? 'font_pink' : 'font_blue'}>
                                    {word}
                                  </span>
                                ))}
                          </div>
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </li>
                  )}
                </Droppable>
              ))}
            </ul>
          </div>
        </div>
      </DragDropContext>

      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
      {directionEnd && eachSound && (
        <SoundEffectPM
          url={
            !_.isNil(eachSound)
              ? `${
                  !_.isNil(stages.customData.soundBaseUrl) ? stages.customData.soundBaseUrl : 'https://cdn.cloubot.com/PM/audio/sounds/'
                }${eachSound}.mp3`
              : undefined
          }
          onEnd={handleEachSoundEnd}
        />
      )}
    </>
  );
};

export default AlphabetDnD3;
