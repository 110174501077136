import React, {useState} from "react";
import _ from "lodash";
import {ImagePopup} from "./PopUp";
import AlphabetTopArea from "./AlphabetTopArea";
import AlphabetDnDContextArea from "./AlphabetDnDContextArea";

const AlphabetDnD5 = ({customData, steps, setSteps}) => {
	const {problem} = customData;
	const [isShowPopup, setIsShowPopup] = useState(false);
	const [wordIndex, setWordIndex] = useState(0);
	const handleDragFinish = () => {
		//setSteps(steps + 1);
		setIsShowPopup(true);
	};
	const handleNext = () => {
		if (wordIndex < problem[steps].words.length - 1) {
			setWordIndex(wordIndex + 1);
		} else {
			setSteps(steps + 1);
			setIsShowPopup(false);
			setWordIndex(0);
		}
	};
	return (
		<>
			<div className="main_content  stage2_content  col2">
				<div className="">
					<AlphabetTopArea title="Double Letters" />
					<AlphabetDnDContextArea
						letters={problem[steps].double}
						soundBaseUrl={!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : "/sounds/"}
						onDragFinish={handleDragFinish}
					/>
				</div>
			</div>
			{isShowPopup && (
				<ImagePopup
					discImage={problem[steps].discImage}
					wordImage={problem[steps].words[wordIndex].wordImage}
					word={problem[steps].words[wordIndex].word}
					number={wordIndex + 1}
					total={problem[steps].words.length}
					sound={problem[steps].words[wordIndex].sound}
					onNext={handleNext}
				/>
			)}
		</>
	);
};

export default AlphabetDnD5;
