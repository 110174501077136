import React, {useState, useEffect, useRef} from "react";
import Sound from "react-sound";
import _ from "lodash";

const SoundEffect = ({url, onEnd, replayCnt = 1, volume}) => {
	const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
	const count = useRef(replayCnt);
	const handleFinishPlay = () => {
		setPlayStatus(Sound.status.STOPPED);
		if (replayCnt === 0) {
			//무한반복임..
			setPlayStatus(Sound.status.PLAYING);
			return;
		}
		count.current--;
		if (count.current === 0) {
			if (onEnd instanceof Function) {
				onEnd();
			}
		} else setPlayStatus(Sound.status.PLAYING);
	};
	useEffect(() => {
		if (url) {
			setPlayStatus(Sound.status.PLAYING);
		}
		return () => {
			setPlayStatus(Sound.status.STOPPED);
			count.current = replayCnt;
		};
		// eslint-disable-next-line
	}, [url]);

	return !_.isNil(volume) ? (
		<>{url && <Sound url={url} playStatus={playStatus} onFinishedPlaying={handleFinishPlay} volume={volume} />}</>
	) : (
		<>{url && <Sound url={url} playStatus={playStatus} onFinishedPlaying={handleFinishPlay} />}</>
	);
};

export default SoundEffect;

export const SoundEffectMulti = ({urls, onEnd}) => {
	const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
	const [urlIndex, setUrlIndex] = useState(0);
	const [url, setUrl] = useState("");
	const handleFinishPlay = () => {
		setPlayStatus(Sound.status.STOPPED);
		console.log("handleFinishPlay", urlIndex, urls.length - 1);
		if (urlIndex >= urls.length - 1) {
			onEnd();
			return;
		}
		setUrlIndex(urlIndex + 1);
	};
	useEffect(() => {
		if (url) {
			setPlayStatus(Sound.status.PLAYING);
		}
		return () => {
			setPlayStatus(Sound.status.STOPPED);
		};
		// eslint-disable-next-line
	}, [url]);

	useEffect(() => {
		if (urls instanceof Array) {
			setUrl(urls[urlIndex]);
		}
	}, [urls, urlIndex]);
	return <>{url && <Sound url={url} playStatus={playStatus} onFinishedPlaying={handleFinishPlay} />}</>;
};
