import { gql } from '@apollo/client';

export const CLASS_LESSON_LIST = gql`
  query classLessonList($class_idx: Int!, $start: DateTime, $end: DateTime) {
    classLessonList(class_idx: $class_idx, start: $start, end: $end) {
      idx
      lesson_code
      study_date
      begin_time
      end_time
      class {
        idx
        name
      }

      book_lesson {
        code
        title
        book_code
        unit_no
        day_no
        stage_group_code
      }
      zoom_schedule {
        idx
        meeting_data
      }
      homework_assigned_student
      homework_completed_student
    }
  }
`;

export const CLASS_LESSON_DETAIL_LIST = gql`
  query classLessonDetailList($campus_idx: Int, $day: DateTime) {
    classLessonDetailList(campus_idx: $campus_idx, day: $day) {
      class {
        idx
        name
      }
      idx
      lesson_code
      study_date
      begin_time
      end_time
      book_lesson {
        unit_no
        day_no
        title
        stage_group_code
        stage {
          is_complete
        }
        book {
          book_sub {
            title
          }
        }
      }
      zoom_schedule {
        idx
        meeting_data
      }
      homework_assigned_student
      homework_completed_student
    }
  }
`;

export const GET_TYPE_CLASS = gql`
  query classList($campus_idx: Int, $start: DateTime, $end: DateTime) {
    classList(campus_idx: $campus_idx, start: $start, end: $end) {
      idx
      name
      book_code
      book_title
      day_of_week
      day_of_week_view
      begin_date
      end_date
      begin_time
      end_time
      room
      idate
      lexile_level
      levelup_level
      campus {
        code
        name
        company {
          name
        }
      }
      class_teacher {
        user {
          idx
          id
          name
          campus_idx
        }
      }
      class_student {
        idx
        user_idx
        stat
      }
      book {
        level_no
        volume_no
        book_sub {
          title
          book_category {
            no
          }
          no
          cover_path
        }
      }
      class_book {
        idx
        book_code
        begin_date
        book {
          book_sub {
            title
          }
        }
      }
    }
  }
`;

export const GET_CLASS = gql`
  query classList {
    classList {
      idx
      name
      book_code
      day_of_week
      begin_date
      end_date
      begin_time
      end_time
      room
      idate
      campus {
        code
        name
        company {
          name
        }
      }
      class_teacher {
        user {
          id
          idx
          name
          campus_idx
        }
      }
    }
  }
`;

export const CLASS_STUDENT_LIST = gql`
  query classStudentList($class_idx: Int!) {
    classStudentList(class_idx: $class_idx) {
      idx
      user {
        idx
        id
        name
        english_name
        school_name
        school_grade
        email
        phone
        memo
        birthday
        parent_name
        parent_phone
        address
        type
        idate
      }
    }
  }
`;

export const CLASS_STUDENT_LIST_FOR_SMS = gql`
  query classStudentList($class_idx: Int!) {
    classStudentList(class_idx: $class_idx) {
      user {
        idx
        id
        name
        phone
        parent_phone
      }
    }
  }
`;

export const CLASS_STUDENT_LIST_FOR_REPORT_CARD = gql`
  query classStudentList($class_idx: Int!, $hasReportCard: Boolean!) {
    classStudentList(class_idx: $class_idx) {
      idx
      user {
        idx
        id
        name
      }
    }
    culpReportCardList(class_idx: $class_idx) @include(if: $hasReportCard) {
      idx
      idate
      user_idx
      comment_ko
      comment_en
      study_status_result
      score_result
    }
  }
`;

export const TEACHER_CLASS_LIST = gql`
  query classTeachers($teacher_idx: Int!) {
    classTeachers(teacher_idx: $teacher_idx) {
      idx
      class {
        idx
        name
        day_of_week
        begin_date
        end_date
        begin_time
        end_time
        book_code
      }
    }
  }
`;

export const TEACHER_SEARCH_CLASS_LIST = gql`
  query teacherClassList($teacher_idx: Int!, $start: DateTime, $end: DateTime) {
    teacherClassList(teacher_idx: $teacher_idx, start: $start, end: $end) {
      idx
      class {
        idx
        name
        day_of_week
        begin_date
        end_date
        begin_time
        end_time
        book_code
      }
    }
  }
`;

export const GET_CLASS_LIST_FOR_SELECT_BOX = gql`
  query classList($campus_idx: Int) {
    classList(campus_idx: $campus_idx) {
      idx
      name
      book_code
      begin_date
      end_date
      class_teacher {
        user_idx
      }
      book {
        book_sub {
          short_title
          title
        }
      }
      class_book {
        idx
        book_code
        begin_date
        book {
          book_sub {
            title
            short_title
          }
        }
      }
    }
  }
`;

export const GET_DAILY_STUDY_SCHEDULE = gql`
  query dailyStudySchedule($user_idx: Int!, $date_string: String!) {
    dailyStudySchedule(user_idx: $user_idx, date_string: $date_string) {
      idx
      lesson_code
      study_date
      begin_time
      stat
      book_lesson {
        unit_no
        unit_name
        day_no
        title
        stage_group_code
        stage {
          is_complete
          user_lesson_ids
        }
        book {
          book_sub {
            title
            short_title
          }
        }
      }
    }
  }
`;

export const GET_DAILY_TEST_SCHEDULE = gql`
  query dailyTestSchedule($user_idx: Int!, $date_string: String!) {
    dailyTestSchedule(user_idx: $user_idx, date_string: $date_string) {
      idx
      DateOnly
      start_time
      title
      class {
        idx
      }
      user {
        idx
        name
      }
      assigned_test_users {
        idx
        user_idx
        status
      }
    }
  }
`;

export const GET_DAILY_AI_TOPIC_SCHEDULE = gql`
  query dailyAiTopicSchedule($date_string: String!) {
    dailyAiTopicSchedule(date_string: $date_string) {
      idx
      start_date
      limit_time
      type
      data
      ai_topic_user_assignments {
        idx
        answer
        status
        ai_topic {
          idx
          topic
          data
        }
        class {
          idx
          name
        }
        user {
          idx
          name
        }
      }
    }
  }
`;

export const GET_DAILY_AI_TOPIC_STUDENT_SCHEDULE = gql`
  query dailyAiTopicStudentSchedule($date_string: String!) {
    dailyAiTopicStudentSchedule(date_string: $date_string) {
      idx
      topic_idx
      user_idx
      class_idx
      answer
      status
      type
      ai_topic {
        idx
        topic
        data
      }
      class {
        idx
        name
      }
      user {
        idx
        name
      }
      ai_topic_date_assignment {
        idx
        start_date
        limit_time
        data
      }
    }
  }
`;

export const GET_DAILY_EXTERNAL_STUDY_SCHEDULE = gql`
  query dailyExternalStudySchedule($user_idx: Int!, $date_string: String!, $type: String) {
    dailyExternalStudySchedule(user_idx: $user_idx, date_string: $date_string, type: $type) {
      idx
      start_date
      type
      limit_time
      external_study_data
      external_study_idx
      DateOnly
      is_done
    }
  }
`;

export const GET_DAILY_EXTERNAL_STUDY_SCHEDULE_DUE = gql`
  query dailyExternalStudySchedule($user_idx: Int!, $date_string: String!, $type: String, $check_due: Boolean) {
    dailyExternalStudySchedule(user_idx: $user_idx, date_string: $date_string, type: $type, check_due: $check_due) {
      idx
      start_date
      type
      limit_time
      external_study_data
      external_study_idx
      DateOnly
      is_done
    }
  }
`;

export const GET_DAILY_BOOK_SCHEDULE = gql`
  query dailyBookSchedule($user_idx: Int!, $date_string: String!) {
    dailyBookSchedule(user_idx: $user_idx, date_string: $date_string) {
      idx
      start_date
      limit_time
      book_lesson {
        title
        code
      }
      DateOnly
      is_done
    }
  }
`;
export const GET_DAILY_PHONICS_SCHEDULE = gql`
  query AssignedPhonicsDailySchedule($user_idx: Int!, $date_string: String!) {
    AssignedPhonicsDailySchedule(user_idx: $user_idx, date_string: $date_string) {
      idx
      user_idx
      phonics_start_date
      book_idx
      unit_title
      phonics_stages
    }
  }
`;
export const GET_ALL_PHONICS_SCHEDULE = gql`
  query AssignedPhonicsAllSchedule($start: String, $end: String) {
    AssignedPhonicsAllSchedule(start: $start, end: $end) {
      user_idx
      phonics_start_date
      unit_title
      phonics_stages
      count
      done
    }
  }
`;
export const GET_DAILY_ZOOM_SCHEDULE = gql`
  query dailyZoomSchedule($user_idx: Int!, $date_string: String!) {
    dailyZoomSchedule(user_idx: $user_idx, date_string: $date_string) {
      DateOnly
      start_time
      title
      meeting_data
      class {
        idx
      }
    }
  }
`;

export const GET_CLASS_LESSONS = gql`
  query classList($campus_idx: Int) {
    classList(campus_idx: $campus_idx) {
      idx
      name
      class_lesson {
        study_date
        lesson_code
        book_lesson {
          title
          stage_group_code
        }
      }
      user_lesson {
        idate
        user {
          name
        }
        class_idx
        user_idx
        lesson_code
        stat

        stage {
          is_complete
        }

        book_lesson {
          unit_no
          day_no
          title
          stage_group_code
          stage {
            is_complete
          }
        }
      }
    }
  }
`;

export const GET_USER_LESSONS = gql`
  query getUserLesson($class_idx: Int!, $lesson_code: String!) {
    getUserLesson(class_idx: $class_idx, lesson_code: $lesson_code) {
      study_date
      user {
        id
        name
      }
      user_study {
        user_idx
        stage_no
        study_date
        exam_total
        exam_correct
        wpm
        recording_data
        user_lesson_idx
        essay {
          idx
          content
          title
        }
        book_lesson {
          stage {
            no
            name
          }
        }
      }
      book_lesson {
        stage_group_code
      }
    }
  }
`;

export const GET_TEACHER_DAILY_LESSONS = gql`
  query teacherDailyClassLesson($teacher_idx: Int!, $date: String!) {
    teacherDailyClassLesson(teacher_idx: $teacher_idx, date: $date) {
      class {
        name
      }
      lesson_code
      book_lesson {
        title
        stage_group_code
      }
    }
  }
`;

export const GET_CAMPUS_DAILY_LESSONS = gql`
  query campusDailyClassLesson($campus_idx: Int!, $date: String!) {
    campusDailyClassLesson(campus_idx: $campus_idx, date: $date) {
      class {
        name
      }
      lesson_code
      book_lesson {
        title
        stage_group_code
      }
    }
  }
`;

export const DASHBOARD_CLASS_LESSON_LIST = gql`
  query dashBoardClassLessonList($start: DateTime, $end: DateTime) {
    dashBoardClassLessonList(start: $start, end: $end) {
      study_date
      lesson_cnt
    }
  }
`;

export const DASHBOARD_MANAGER_TEST_LIST = gql`
  query dashBoardTestList($start: DateTime, $end: DateTime) {
    dashBoardTestList(start: $start, end: $end) {
      start_time
      DateOnly
      class_test_cnt
    }
  }
`;

export const DASHBOARD_MANAGER_ZOOM_LIST = gql`
  query dashBoardZoomList($start: DateTime, $end: DateTime) {
    dashBoardZoomList(start: $start, end: $end) {
      start_time
      DateOnly
      class_zoom_cnt
    }
  }
`;

export const DASHBOARD_TEACHER_TEST_LIST = gql`
  query dashBoardTeacherTestList($start: DateTime, $end: DateTime) {
    dashBoardTestList(start: $start, end: $end) {
      start_time
      DateOnly
      class_test_cnt
    }
  }
`;

export const DASHBOARD_TEACHER_ZOOM_LIST = gql`
  query dashBoardTeacherZoomList($start: DateTime, $end: DateTime) {
    dashBoardZoomList(start: $start, end: $end) {
      start_time
      DateOnly
      class_zoom_cnt
    }
  }
`;

export const DASHBOARD_TEACHER_AI_TOPIC_LIST = gql`
  query dashBoardAiTopicList($start: DateTime!, $end: DateTime!) {
    dashBoardAiTopicList(start: $start, end: $end) {
      assigned_time
      DateOnly
      ai_topic_cnt
    }
  }
`;

export const DASHBOARD_TEACHER_BOOK_REPORT_TOPIC_LIST = gql`
  query dashBoardBookReportTopicList($start: DateTime!, $end: DateTime!) {
    dashBoardBookReportTopicList(start: $start, end: $end) {
      assigned_time
      DateOnly
      ai_topic_cnt
    }
  }
`;
export const DASHBOARD_TEACHER_AI_TALKING_REPORT_TOPIC_LIST = gql`
  query dashBoardAiTalkingTopicList($start: DateTime!, $end: DateTime!) {
    dashBoardAiTalkingTopicList(start: $start, end: $end) {
      assigned_time
      DateOnly
      ai_topic_cnt
    }
  }
`;

export const DASHBOARD_STUDENT_LESSON_LIST = gql`
  query dashBoardStudentClassLessonList($start: DateTime, $end: DateTime) {
    dashBoardStudentClassLessonList(start: $start, end: $end) {
      study_date
      lesson_cnt
      lesson_done
    }
  }
`;

export const DASHBOARD_STUDENT_TEST_LIST = gql`
  query dashBoardStudentTestList($start: DateTime, $end: DateTime) {
    dashBoardStudentTestList(start: $start, end: $end) {
      DateOnly
      test_cnt
      test_done
    }
  }
`;

export const DASHBOARD_STUDENT_AI_TOPIC_LIST = gql`
  query dashBoardStudentAiTopicList($start: DateTime!, $end: DateTime!) {
    dashBoardStudentAiTopicList(start: $start, end: $end) {
      DateOnly
      ai_topic_cnt
      assigned_time
    }
  }
`;

export const DASHBOARD_STUDENT_BOOK_REPORT_TOPIC_LIST = gql`
  query dashBoardStudentBookReportTopicList($start: DateTime!, $end: DateTime!) {
    dashBoardStudentBookReportTopicList(start: $start, end: $end) {
      DateOnly
      ai_topic_cnt
      assigned_time
    }
  }
`;

export const DASHBOARD_STUDENT_BOOK_LIST = gql`
  query dashBoardStudentBookList($start: DateTime, $end: DateTime) {
    dashBoardStudentBookList(start: $start, end: $end) {
      start_date
      idate
      limit_time
      book_idx
      elibrary_cnt
    }
  }
`;

export const DASHBOARD_STUDENT_EXTERNAL_STUDY_LIST = gql`
  query dashBoardStudentExternalStudyList($start: DateTime, $end: DateTime) {
    dashBoardStudentExternalStudyList(start: $start, end: $end) {
      start_date
      idate
      limit_time
      type
      external_study_idx
      is_done
    }
  }
`;

export const DASHBOARD_STUDENT_ZOOM_LIST = gql`
  query dashBoardStudentZoomList($start: DateTime, $end: DateTime) {
    dashBoardStudentZoomList(start: $start, end: $end) {
      DateOnly
      zoom_cnt
    }
  }
`;

export const GET_DAILY_CLASS_TEST_SCHEDULE = gql`
  query dailyClassTestSchedule($campus_idx: Int!, $date_string: String!) {
    dailyClassTestSchedule(campus_idx: $campus_idx, date_string: $date_string) {
      idx
      DateOnly
      start_time
      title
      class {
        idx
        name
      }
      user {
        name
      }
      test_group_assigned {
        idx
      }
    }
  }
`;

export const GET_DAILY_CLASS_TEST_GROUP_SCHEDULE = gql`
  query dailyClassTestGroupSchedule($campus_idx: Int!, $date_string: String!) {
    dailyClassTestGroupSchedule(campus_idx: $campus_idx, date_string: $date_string) {
      idx
      DateOnly
      start_time
      class {
        idx
        name
      }
      user {
        name
      }
      daily_test_group_assigned {
        idx
      }
      test_group_name
    }
  }
`;

export const GET_DAILY_CLASS_ZOOM_SCHEDULE = gql`
  query dailyClassZoomSchedule($campus_idx: Int!, $date_string: String!) {
    dailyClassZoomSchedule(campus_idx: $campus_idx, date_string: $date_string) {
      DateOnly
      start_time
      meeting_data
      title
      class {
        idx
        name
      }
    }
  }
`;

export const CLASS_LESSON_ITEM = gql`
  query classLessonItem($class_idx: Int!, $lesson_code: String!) {
    classLessonItem(class_idx: $class_idx, lesson_code: $lesson_code) {
      idx
      lesson_code
      study_date
      begin_time
      end_time
      class {
        name
      }
    }
  }
`;

export const GET_CLASSS_LESSONS_FOR_HOMEWORK = gql`
  query classLessonsForHomworkResult(
    $campus_idx: Int!
    $page: Int
    $take: Int
    $class_idx: Int
    $search_text: String
    $searchDate: String
    $state: String
  ) {
    classLessonsForHomworkResult(
      campus_idx: $campus_idx
      page: $page
      take: $take
      class_idx: $class_idx
      search_text: $search_text
      searchDate: $searchDate
      state: $state
    ) {
      page
      take
      total
      class_lessons {
        idx
        lesson_code
        study_date
        book_lesson {
          title
          book_code
          stage_group_code
          book {
            book_sub {
              title
            }
          }
        }
        class {
          idx
          name
          class_student {
            user_idx
          }
          user_lesson {
            user_idx
            lesson_code
            stat
          }
        }
      }
    }
  }
`;

export const GET_USER_LESSONS_FOR_HOMEWORK = gql`
  query getUserLessonsCampus(
    $campus_idx: Int!
    $page: Int
    $take: Int
    $class_idx: Int
    $search_text: String
    $search_type: Int
    $sort_field: String
    $sort_order: String
    $class_type: Int
  ) {
    getUserLessonsCampus(
      campus_idx: $campus_idx
      page: $page
      take: $take
      class_idx: $class_idx
      search_text: $search_text
      search_type: $search_type
      sort_field: $sort_field
      sort_order: $sort_order
      class_type: $class_type
    ) {
      page
      take
      total
      user_lessons {
        class {
          idx
          name
          book_code
          end_date
          book {
            book_sub {
              title
            }
          }
          class_lesson {
            study_date
          }
        }
        user {
          idx
          name
          id
        }
        lesson_count
        homework_result
      }
    }
  }
`;

export const GET_USER_LESSONS_FOR_PHONICS = gql`
  query getUserLessonsPhonics(
    $page: Int
    $take: Int
    $class_idx: Int
    $search_text: String
    $search_type: Int
    $sort_field: String
    $sort_order: String
  ) {
    getUserLessonsPhonics(
      page: $page
      take: $take
      class_idx: $class_idx
      search_text: $search_text
      search_type: $search_type
      sort_field: $sort_field
      sort_order: $sort_order
    ) {
      page
      take
      total
      user_lessons {
        class_name
        class_idx
        user_idx
        student_name
        unit_count
        homework_result
      }
    }
  }
`;

export const GET_CLASSS_INFO = gql`
  query classInfo($class_idx: Int!) {
    classInfo(class_idx: $class_idx) {
      name
      class_teacher {
        user {
          name
        }
      }
      book_code
      book {
        book_sub {
          title
        }
      }
    }
  }
`;

export const GET_CLASSS_INFO_FOR_REPPORT = gql`
  query classInfo($class_idx: Int!) {
    classInfo(class_idx: $class_idx) {
      name
      begin_time
      class_teacher {
        user {
          name
        }
      }
      book_code
      book {
        level_no
        book_sub {
          title
        }
      }
      class_book {
        idx
        book_code
        begin_date
        book {
          book_sub {
            title
            short_title
          }
        }
      }
      campus {
        name
      }
    }
  }
`;

export const MULTI_CLASSS_STUEDNT_LIST = gql`
  query multiClassStudentList($class_idx: [Int!]!) {
    multiClassStudentList(class_idx: $class_idx) {
      user {
        idx
        name
      }
    }
  }
`;

export const DASHBOARD_CAMPUS_PHONICS_SCHEDULE_GRID = gql`
  query CamppusPhonicsDailyScheduleGrid($inputuser: Int!, $inputdate: String!) {
    CamppusPhonicsDailyScheduleGrid(inputuser: $inputuser, inputdate: $inputdate) {
      start_date
      book_idx
      class_idx
      name
      phonics_stages
    }
  }
`;

export const DASHBOARD_CAMPUS_OFFLINE_SCHEDULE_GRID = gql`
  query CamppusOfflineDailyScheduleGrid($inputuser: Int!, $inputdate: String!) {
    CamppusOfflineDailyScheduleGrid(inputuser: $inputuser, inputdate: $inputdate) {
      start_date
      book_idx
      class_idx
      name
      offline_stages
      idx
      level
      plantopic
      planday
      planlearningobj
      days_lesson
    }
  }
`;

export const GET_ALL_OFFLINE_SCHEDULE_STUDENT = gql`
  query AssignedOfflineStudentSchedule($start: String, $end: String) {
    AssignedOfflineStudentSchedule(start: $start, end: $end) {
      user_idx
      offline_start_date
      booktitle
      offline_stages
      count
      offline_syllabus_add_syllabus_idx
    }
  }
`;

export const GET_DAILY_OFFLINE_SCHEDULE = gql`
  query AssignedOfflineDailySchedule($user_idx: Int!, $date_string: String!) {
    AssignedOfflineDailySchedule(user_idx: $user_idx, date_string: $date_string) {
      user_idx
      offline_start_date
      book_idx
      booktitle
      offline_stages
      offline_syllabus_add_syllabus_idx
    }
  }
`;

export const GET_DAILY_OFFLINE_HOMEWORK_SCHEDULE = gql`
  query AssignedOfflineDailyHomeWorkSchedule($std_bookidx: String!, $user_idx: Int!, $date_string: String!, $std_syllabus_idx: String!) {
    AssignedOfflineDailyHomeWorkSchedule(
      std_bookidx: $std_bookidx
      user_idx: $user_idx
      date_string: $date_string
      std_syllabus_idx: $std_syllabus_idx
    ) {
      homeworkcontent
      extra_homework
    }
  }
`;

export const GET_MY_CLASS_BOOKS = gql`
  query classList($campus_idx: Int) {
    classList(campus_idx: $campus_idx) {
      book_code
      book {
        book_sub {
          title
        }
      }
    }
  }
`;

export const DASHBOARD_MANAGER_BOOK_LIST = gql`
  query dashBoardBookList($start: DateTime, $end: DateTime) {
    dashBoardBookList(start: $start, end: $end) {
      start_date
      DateOnly
      class_elibrary_cnt
    }
  }
`;

export const GET_DAILY_CLASS_BOOK_SCHEDULE = gql`
  query dailyClassBookSchedule($campus_idx: Int!, $date_string: String!) {
    dailyClassBookSchedule(campus_idx: $campus_idx, date_string: $date_string) {
      idx
      DateOnly
      start_date
      book_lesson {
        title
      }
      assigned_elibrary_group {
        idx
        class {
          idx
          name
        }
        user {
          name
        }
      }
      assigned_elibrary_users {
        user {
          idx
          id
          name
        }
        status
      }
      user_study {
        idx
        stage_no
        exam_total
        exam_correct
        study_date
        recording_data
        wpm
        recording_filepath
        user {
          idx
          name
        }
      }
    }
  }
`;

export const GET_DAILY_CLASS_EXTERNAL_STUDY_SCHEDULE = gql`
  query dailyClassExternalStudySchedule($campus_idx: Int!, $date_string: String!) {
    dailyClassExternalStudySchedule(campus_idx: $campus_idx, date_string: $date_string) {
      idx
      DateOnly
      start_date
      type
      external_study_data
      external_study_idx
      assigned_external_study_group {
        idx
        class {
          idx
          name
        }
        user {
          name
        }
      }
      external_user_study {
        user {
          idx
          id
          name
        }
        status
      }
      assigned_external_study_user {
        idx
        user_idx
        study_date
        assigned_ext_study_idx
      }
    }
  }
`;

export const DASHBOARD_MANAGER_EXTERNAL_STUDY_LIST = gql`
  query dashBoardExternalStudyList($start: DateTime, $end: DateTime) {
    dashBoardExternalStudyList(start: $start, end: $end) {
      start_date
      DateOnly
      type
    }
  }
`;
export const GET_DAILY_CLASS_EXTERNAL_STUDY_SCHEDULE_FOR_STUDENT = gql`
  query dailyClassExternalStudyScheduleForStudent($start: String!, $end: String!) {
    dailyClassExternalStudyScheduleForStudent(start: $start, end: $end) {
      idx
      user_idx
      study_date
      assigned_ext_study_idx
    }
  }
`;
export const GET_DAILY_EXTERNAL_STUDY_SCHEDULE_FOR_STUDENT = gql`
  query dailyExternalStudyScheduleForStudent($user_idx: Int!, $date_string: String!) {
    dailyExternalStudyScheduleForStudent(user_idx: $user_idx, date_string: $date_string) {
      idx
      start_date
      type
      limit_time
      external_study_data
      external_study_idx
      DateOnly
      is_done
    }
  }
`;
export const GET_DAILY_TEST_GROUP_SCHEDULE = gql`
  query dailyTestGrpupScheduleList($user_idx: Int!, $date_string: String!) {
    dailyTestGrpupScheduleList(user_idx: $user_idx, date_string: $date_string) {
      idx
      cut_line
      no_of_retake
      class_idx
      create_user_idx
      daily_test_group {
        idx
        name
        code
      }
      done_status
    }
  }
`;
export const DAILY_DASHBOARD_STUDENT_TEST_LIST = gql`
  query dailyDashBoardStudentTestList($start: DateTime, $end: DateTime) {
    dailyDashBoardStudentTestList(start: $start, end: $end) {
      DateOnly
      test_cnt
      test_done
      done_status
    }
  }
`;
export const DASHBOARD_MANAGER_TEST_GROUP_LIST = gql`
  query dashBoardTestGroupList($start: DateTime, $end: DateTime) {
    dashBoardTestGroupList(start: $start, end: $end) {
      start_time
      DateOnly
      class_test_cnt
    }
  }
`;

export const GET_SKILL = gql`
  query getSkill($class_idx: Int, $start_date: String!, $end_date: String!, $user_idx: Int) {
    getSkill(class_idx: $class_idx, start_date: $start_date, end_date: $end_date, user_idx: $user_idx)
  }
`;

export const DASHBOARD_STUDENT_TALKING_TUTOR_TOPIC_LIST = gql`
  query dashBoardStudentAiTalkingTopicList($start: DateTime!, $end: DateTime!) {
    dashBoardStudentAiTalkingTopicList(start: $start, end: $end) {
      DateOnly
      ai_topic_cnt
      assigned_time
    }
  }
`;
