import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { LEVELUP_AI_TOPIC_USER_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import { UPDATE_AI_REQUEST_RESPONSE, LEVELUP_UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Space, Table, Modal } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditOutlined } from '@ant-design/icons';
import GeneratePdfButton from 'src/components/common/GeneratePdfButton';
import { BOOK_REPORT_LEVEL_LIST } from 'src/utils';

const InnerWrap = styled.div`
  width: 94%;
  max-width: 2000px;
  padding: 0rem 0rem;
  margin: 0rem auto;
  background-color: #fff;
  min-height: 100vh;
  flex-direction: column;
`;

const StageTitle = styled.div`
  /* border-bottom: 1px solid #4472c4; */
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  font-weight: 500;
  background-color: #f3db4c;
  font-size: 14px;
`;

const StageTitleSpan = styled.div`
  color: #453dd0;
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const PrintWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media print {
    page-break-before: always;
  }
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColFlex = styled.div`
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: 55px;
  padding: 15px 65px;
  background-color: #ffa700;
  background-image: url('/images/cloud.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  margin-left: -3%;
  margin-right: -3%;
`;

const HeadBox = styled.div`
  font-size: 14px;
  border-radius: 5px;
  background-color: ${(props) => (props.background ? props.background : '#fff')};
  color: ${(props) => props.color};
  padding: 2px 7px;
  margin: 0 5px;
  min-width: 80px;
  text-align: center;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 800;
  color: #453dd0;
  @media (max-width: 1280px) {
    font-size: 16px;
  }
`;

const NoBreakContent = styled.div``;

const ContentBox = styled.div`
  width: 100%;
  border: ${(props) => (props.border ? props.border : '1px solid #00adff')};
  padding: 10px;
  margin: 10px 0;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  line-height: 22px;

  & .ant-table-cell {
    padding: 4px !important;
    font-size: 10px;
  }

  & .main-score .ant-table-cell {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  & .ant-table-thead > tr > th {
    color: #453dd0;
    font-weight: bold;
  }

  & .table-score .ant-table-thead > tr > th:last-child {
    color: #ffa700;
  }

  & .rubric-score .ant-table-tbody > tr:last-child {
    background-color: #e7e7e3;
  }

  & p {
    margin: 5px;
  }
`;
const HideInPrint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media print {
    display: none;
  }
`;

const LevelUpReportGenerate = () => {
  const { id } = useParams();
  const history = useHistory();
  const params = queryString.parse(window.location.search);
  const commentType = params.type ? params.type : 'comment';
  const pdfType = params.pdfType ? params.pdfType : 'student';

  // const [assignedAiTopic, setAssignedAITopics] = useState(null);
  const [ai_topic_user_assignments_list, setAssignedAITopicsList] = useState(null);

  const [chatList, setChatList] = useState([]);
  const [totalScore, setTotalScore] = useState({});
  const [startDate, setStartDate] = useState('');
  const [rubricData, setRubricData] = useState();

  const botChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type !== 'word');
  const wordChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type === 'word');
  const botCheckChat = botChats.filter((chat) => chat.data.type === 'check');
  const botReviseChat = botChats.filter((chat) => chat.data.type === 'revise');
  const botCommentChat = botChats.filter((chat) => chat.data.type === commentType);

  const total = Object.values(totalScore).reduce((a, b) => a + b.point, 0);
  const comment = Object.values(totalScore).reduce((a, b) => a + `${b.desc}\n`, '');
  const [text, setText] = useState();
  const [editorData, setEditorData] = useState();
  const [editorModal, setEditorModal] = useState(false);
  const [editorId, setEditorId] = useState(null);
  const [editorType, setEditorType] = useState(1);

  const {
    data: aiTopicDataList,
    loading: loadingAiTopic,
    refetch,
  } = useQuery(LEVELUP_AI_TOPIC_USER_ASSIGNMENT_DETAILS, {
    variables: {
      external_study_idx: parseInt(id),
    },
    fetchPolicy: 'no-cache',
  });

  const [updateAiRequestResponse, { loading }] = useMutation(UPDATE_AI_REQUEST_RESPONSE, {
    onCompleted: (data) => {
      console.log('updateAiRequestResponse', data);
      refetch();
    },
    onError: (error) => {
      console.log('updateAiRequestResponse', error);
    },
  });

  const [levelUpUpdateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(
    LEVELUP_UPDATE_AI_TOPIC_USER_ASSIGNMENT,
    {
      onError(error) {
        console.log('error', error);
      },
    },
  );

  useEffect(() => {
    if (aiTopicDataList?.levelUpAiTopicUserAssignmentDetails) {
      setAssignedAITopicsList({
        ...aiTopicDataList?.levelUpAiTopicUserAssignmentDetails,
        aiTopic: {
          ...aiTopicDataList?.levelUpAiTopicUserAssignmentDetails.aiTopic[0],
          data: JSON.parse(aiTopicDataList?.levelUpAiTopicUserAssignmentDetails.aiTopic[0]?.data),
        },
        ai_request_response: aiTopicDataList?.levelUpAiTopicUserAssignmentDetails?.ai_request_response.map((item) => {
          return { ...item, promt: item.prompt_text, content: item.completion_text, data: JSON.parse(item.data), created: item.idate };
        }),
        data: JSON.parse(aiTopicDataList?.levelUpAiTopicUserAssignmentDetails?.data),
        class_detail: aiTopicDataList?.levelUpAiTopicUserAssignmentDetails?.class_detail[0],
        external_study_data: {
          ...aiTopicDataList?.levelUpAiTopicUserAssignmentDetails?.external_study_data,
          external_study_data: JSON.parse(
            aiTopicDataList?.levelUpAiTopicUserAssignmentDetails?.external_study_data?.external_study_data || '{}',
          ),
        },
      });
      setStartDate(moment(aiTopicDataList?.levelUpAiTopicUserAssignmentDetails?.study_date).format('YYYY-MM-DD'));
    }
  }, [aiTopicDataList]);

  useEffect(() => {
    if (ai_topic_user_assignments_list?.ai_request_response.length > 0) {
      let resChats = [];
      ai_topic_user_assignments_list?.ai_request_response.forEach((item) => {
        resChats.push({
          user: ai_topic_user_assignments_list?.user?.id,
          chat_user_name: ai_topic_user_assignments_list?.user?.name,
          content: item.prompt_text,
          urls: [],
          created: item.idate,
          data: item?.data,
          idx: item.idx,
        });
        resChats.push({
          user: 'bot',
          chat_user_name: 'AI봇',
          content: item.completion_text,
          urls: item.image_urls ? JSON.parse(item.image_urls) : [],
          created: item.idate,
          data: item?.data,
          idx: item.idx,
        });
      });
      setChatList(resChats);
    }
  }, [ai_topic_user_assignments_list]);
  useEffect(() => {
    if (ai_topic_user_assignments_list?.data?.rubric_data) {
      setRubricData(JSON.parse(ai_topic_user_assignments_list?.data?.rubric_data || '{}'));
    }
  }, [ai_topic_user_assignments_list]);

  const rubricTotalScore = ai_topic_user_assignments_list?.data?.rubric_data
    ? JSON.parse(ai_topic_user_assignments_list?.data?.rubric_data)?.totalScore || null
    : null;

  const openEditorModal = (html, id, type, data) => {
    let htmlData = html.split('\n').filter((e) => e !== ' ');
    setText(htmlData.join('<br />'));
    setEditorModal(true);
    setEditorId(id);
    setEditorType(type);
    setEditorData(data);
  };

  const closeEditorModal = () => {
    setEditorModal(false);
    setText('');
  };

  const handleEditorChange = (editorState) => {
    setText(editorState);
  };

  const handleGeneratePdf = async (idx, data) => {
    await levelUpUpdateAITopicUserAssignment({
      variables: {
        idx,
        rubric_data: JSON.stringify(data),
      },
    });
    history.goBack();
    refetch();
  };

  const saveEditor = async () => {
    const tempText = text.trim('\n');
    if (editorType === 1) {
      await levelUpUpdateAITopicUserAssignment({
        variables: {
          idx: editorId,
          rubric_data: JSON.stringify({
            ...rubricData,
            revisedData: { text: tempText },
          }),
        },
      });
    } else if (editorType === 4) {
      await levelUpUpdateAITopicUserAssignment({
        variables: {
          idx: editorId,
          data: JSON.stringify({ ...editorData?.data, answer: tempText }),
        },
      });
    } else if (editorType === 3) {
      await updateAiRequestResponse({
        variables: {
          idx: editorId,
          data: JSON.stringify({ ...editorData?.data, currentText: tempText }),
          completion_text: editorData?.content,
        },
      });
    } else {
      await updateAiRequestResponse({
        variables: {
          idx: editorId,
          completion_text: tempText,
        },
      });
    }
    refetch();
    closeEditorModal();
  };

  const getPercentage = (score, total) => {
    return (score / total) * 100;
  };

  return (
    <>
      <div
        className="meta-report-con allPage"
        style={{
          backgroundColor: '#453dd0',
        }}
      >
        <PrintWrapper>
          <InnerWrap>
            <Header>
              <div>
                <img src="/images/level_up_logo.svg" style={{ height: '35px' }} alt="logo" />
              </div>
            </Header>
            <StageTitle>
              <StageTitleSpan>Writing Coach Report</StageTitleSpan>
              <RowFlex style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                <RowFlex style={{ justifyContent: 'center', alignItems: 'center' }}>
                  Date: <HeadBox>{startDate}</HeadBox>
                </RowFlex>
                <RowFlex style={{ justifyContent: 'center', alignItems: 'center' }}>
                  Name: <HeadBox>{ai_topic_user_assignments_list?.user?.name}</HeadBox>
                  Id: <HeadBox>{ai_topic_user_assignments_list?.user?.id}</HeadBox>
                </RowFlex>
              </RowFlex>
            </StageTitle>
            <ColFlex style={{ justifyContent: 'space-between', marginTop: '10px', padding: '0 15px' }}>
              <Title style={{ margin: '5px 0 5px 0' }}>Student Info.</Title>
              <ContentBox>
                <Table
                  className="main-score"
                  columns={[
                    {
                      title: 'Campus',
                      dataIndex: 'campus',
                      key: 'campus',
                      align: 'center',
                    },
                    {
                      title: 'Class',
                      dataIndex: 'class',
                      key: 'class',
                      align: 'center',
                    },
                    {
                      title: 'Grade',
                      dataIndex: 'grade',
                      key: 'grade',
                      width: 60,
                      align: 'center',
                    },
                    {
                      title: 'Level',
                      dataIndex: 'level',
                      key: 'level',
                      width: 50,
                      align: 'center',
                    },
                    {
                      title: 'Assigned Date',
                      dataIndex: 'assignedDate',
                      key: 'assignedDate',
                      width: 100,
                      align: 'center',
                    },
                    {
                      title: 'Upload Date',
                      dataIndex: 'uploadDate',
                      key: 'uploadDate',
                      width: 100,
                      align: 'center',
                    },
                    {
                      title: 'Duration',
                      dataIndex: 'durationTime',
                      key: 'durationTime',
                      width: 60,
                      align: 'center',
                    },
                    {
                      title: 'TOEFL',
                      dataIndex: 'toefl',
                      key: 'toefl',
                      width: 60,
                      align: 'center',
                    },
                    {
                      title: 'ACC/WPM',
                      dataIndex: 'wpm',
                      key: 'wpm',
                      width: 80,
                      align: 'center',
                      render: (text, record) => {
                        if (record.wpm === null) {
                          return (
                            <Space size="middle">
                              <span>
                                <span style={{ color: '#ff0000', fontWeight: 'bold' }}>0</span>/0
                              </span>
                            </Space>
                          );
                        }

                        return (
                          <Space size="middle">
                            <span>
                              <span style={{ color: '#e14d4d', fontWeight: 'bold' }}>{record.acc}</span>%/
                              <span style={{ color: '#39cb4c', fontWeight: 'bold' }}>{record.wpm}</span>
                            </span>
                          </Space>
                        );
                      },
                    },
                    {
                      title: 'Rubric Score',
                      dataIndex: 'score',
                      key: 'score',
                      width: 80,
                      align: 'center',
                      render: (text) => {
                        if (parseInt(text) < 80) {
                          return (
                            <Space size="middle">
                              <span>
                                <span style={{ color: '#ff0000', fontWeight: 'bold' }}>{text}</span>%
                              </span>
                            </Space>
                          );
                        }
                        return (
                          <Space size="middle">
                            <span>
                              <span style={{ color: '#00adff', fontWeight: 'bold' }}>{text}</span>%
                            </span>
                          </Space>
                        );
                      },
                    },
                  ]}
                  dataSource={
                    ai_topic_user_assignments_list
                      ? [
                          {
                            campus: ai_topic_user_assignments_list?.user?.campus?.name || '',
                            class: ai_topic_user_assignments_list?.class_detail?.name || '',
                            grade: ai_topic_user_assignments_list?.aiTopic?.data?.book?.level || '',
                            level: ai_topic_user_assignments_list?.external_study_data?.data?.level
                              ? BOOK_REPORT_LEVEL_LIST[ai_topic_user_assignments_list?.external_study_data?.data?.level]
                              : ai_topic_user_assignments_list?.aiTopic?.data?.book?.level || '',
                            assignedDate: startDate,
                            uploadDate: moment(ai_topic_user_assignments_list?.data?.end_time?.set).format('YYYY-MM-DD'),
                            toefl: rubricData?.toefl || '',
                            durationTime: `${String(
                              Math.floor(
                                moment(ai_topic_user_assignments_list?.data?.end_time?.set).diff(
                                  moment(ai_topic_user_assignments_list?.data?.start_time?.set),
                                  'seconds',
                                ) / 60,
                              ),
                            ).padStart(2, '0')}:${String(
                              moment(ai_topic_user_assignments_list?.data?.end_time?.set).diff(
                                moment(ai_topic_user_assignments_list?.data?.start_time?.set),
                                'seconds',
                              ) % 60,
                            ).padStart(2, '0')}`,
                            score: rubricData?.score || 0,
                            acc: ai_topic_user_assignments_list?.data?.recording_data
                              ? JSON.parse(ai_topic_user_assignments_list?.data?.recording_data)?.score
                              : '-',
                            wpm: ai_topic_user_assignments_list?.data?.recording_data
                              ? JSON.parse(ai_topic_user_assignments_list?.data?.recording_data)?.wpm
                              : '-',
                          },
                        ]
                      : []
                  }
                  tableLayout="fixed"
                  pagination={false}
                  bordered={true}
                  size="small"
                />
              </ContentBox>
            </ColFlex>
            <ColFlex style={{ justifyContent: 'space-between', marginTop: '10px', padding: '0 15px' }}>
              <Title style={{ margin: '5px 0 5px 0' }}>Writing Topic/Prompt</Title>
              <ContentBox>
                <Table
                  className="table-topic"
                  columns={[
                    {
                      title: 'Subject / Title',
                      dataIndex: 'subject',
                      key: 'subject',
                      align: 'center',
                    },
                    {
                      title: 'Topic / Prompt',
                      dataIndex: 'topic',
                      key: 'topic',
                      align: 'center',
                    },
                  ]}
                  dataSource={
                    ai_topic_user_assignments_list
                      ? [
                          {
                            subject: ai_topic_user_assignments_list?.aiTopic?.data?.book?.topic || '',
                            topic:
                              ai_topic_user_assignments_list?.external_study_data?.external_study_data?.ai_topic?.topic ||
                              ai_topic_user_assignments_list?.aiTopic?.topic ||
                              '',
                          },
                        ]
                      : []
                  }
                  tableLayout="fixed"
                  pagination={false}
                  bordered={true}
                  size="small"
                />
                {ai_topic_user_assignments_list?.aiTopic?.data?.book?.cover && (
                  <ColFlex style={{ justifyContent: 'center', marginTop: '10px', padding: '5px', alignItems: 'center' }}>
                    <img src="https://placehold.co/400x200" style={{ width: '40%' }} alt="img" />
                  </ColFlex>
                )}
              </ContentBox>
            </ColFlex>
            <ColFlex style={{ justifyContent: 'space-between', marginTop: '10px', padding: '15px' }}>
              <Title style={{ margin: '5px 0 5px 0', color: '#ffa700' }}>
                Final Draft{' '}
                {ai_topic_user_assignments_list?.data?.answer && (
                  <EditOutlined
                    className="edit-icon"
                    onClick={() =>
                      openEditorModal(
                        ai_topic_user_assignments_list?.data?.answer,
                        ai_topic_user_assignments_list?.idx,
                        4,
                        ai_topic_user_assignments_list?.data,
                      )
                    }
                  />
                )}
              </Title>
              <ContentBox border="2px solid #f3db4c">
                <HeadBox
                  background="#f3db4c"
                  color="#453dd0"
                  style={{
                    padding: '10px',
                    margin: '-10px',
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontWeight: 'bold',
                  }}
                >
                  Word Count :{' '}
                  <HeadBox>
                    {ai_topic_user_assignments_list?.data?.answer ? ai_topic_user_assignments_list?.data?.answer.split(' ').length : 0}
                  </HeadBox>
                </HeadBox>
                {ai_topic_user_assignments_list?.data?.answer
                  ?.split('\n')
                  .filter((e) => e !== ' ')
                  .map((line, index) => {
                    return <p key={index} dangerouslySetInnerHTML={{ __html: `${line}}` }}></p>;
                  })}
              </ContentBox>
            </ColFlex>
            <ColFlex style={{ justifyContent: 'space-between', marginTop: '20px', padding: '0 15px' }}>
              <Title style={{ margin: '5px 0 5px 0', paddingTop: '20px' }}>
                Original First Draft{' '}
                {botCheckChat?.length && (
                  <EditOutlined
                    className="edit-icon"
                    onClick={() =>
                      openEditorModal(
                        botCheckChat[botCheckChat?.length - 1]?.data?.currentText,
                        botCheckChat[botCheckChat?.length - 1]?.idx,
                        3,
                        botCheckChat[botCheckChat?.length - 1],
                      )
                    }
                  />
                )}
              </Title>
              <ContentBox>
                {botCheckChat[botCheckChat?.length - 1]?.data?.currentText
                  .split('\n')
                  .filter((e) => e !== ' ')
                  .map((line, index) => {
                    return <p key={index} dangerouslySetInnerHTML={{ __html: `${line}` }}></p>;
                  }) || ''}
              </ContentBox>
            </ColFlex>
            <ColFlex style={{ justifyContent: 'space-between', marginTop: '10px', padding: '0 15px' }}>
              <Title style={{ margin: '5px 0 5px 0' }}>
                {' '}
                Corrections{' '}
                {botCheckChat?.length && (
                  <EditOutlined
                    className="edit-icon"
                    onClick={() =>
                      openEditorModal(botCheckChat[botCheckChat?.length - 1]?.content, botCheckChat[botCheckChat?.length - 1]?.idx, 2, null)
                    }
                  />
                )}
              </Title>
              <ContentBox>
                {botCheckChat[botCheckChat?.length - 1]?.content
                  .split('\n')
                  .filter((e) => e !== ' ')
                  .map((line, index) => {
                    return <p key={index} dangerouslySetInnerHTML={{ __html: `${line}}` }}></p>;
                  })}
              </ContentBox>
            </ColFlex>
          </InnerWrap>
        </PrintWrapper>
        <PrintWrapper>
          <InnerWrap>
            {rubricTotalScore && (
              <ColFlex style={{ justifyContent: 'space-between', marginTop: '20px', padding: '0 15px' }}>
                <Title style={{ margin: '5px 0 5px 0', paddingTop: '20px' }}> Rubric Scoring</Title>
                <ContentBox>
                  <Table
                    className="table-score rubric-score"
                    columns={[
                      {
                        title: '',
                        dataIndex: 'title',
                        key: 'title',
                        align: 'center',
                        width: 50,
                      },
                      {
                        title: 'Categories',
                        dataIndex: 'categories',
                        key: 'categories',
                        align: 'center',
                        width: 100,
                      },
                      {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                        align: 'center',
                      },
                      {
                        title: 'Score',
                        dataIndex: 'score',
                        key: 'score',
                        align: 'center',
                        width: 60,
                        render: (text, record) => {
                          if (record.title === '') {
                            return (
                              <Space size="middle">
                                <span style={{ color: '#ffa700', fontWeight: 'bold' }}>{text}/100</span>
                              </Space>
                            );
                          }

                          return (
                            <Space size="middle">
                              <span>
                                <span>{text}</span>/20
                              </span>
                            </Space>
                          );
                        },
                      },
                    ]}
                    dataSource={
                      rubricTotalScore
                        ? [
                            {
                              title: '1',
                              categories: 'Ideas',
                              description: rubricTotalScore['Ideas']?.desc || '',
                              score: rubricTotalScore['Ideas']?.point || 0,
                            },
                            {
                              title: '2',
                              categories: 'Organization',
                              description: rubricTotalScore['Organization']?.desc || '',
                              score: rubricTotalScore['Organization']?.point || 0,
                            },
                            {
                              title: '3',
                              categories: 'Voice',
                              description: rubricTotalScore['Voice']?.desc || '',
                              score: rubricTotalScore['Voice']?.point || 0,
                            },
                            {
                              title: '4',
                              categories: 'Word Choice',
                              description: rubricTotalScore['Word Choice']?.desc || '',
                              score: rubricTotalScore['Word Choice']?.point || 0,
                            },
                            {
                              title: '5',
                              categories: 'Conventions',
                              description: rubricTotalScore['Conventions']?.desc || '',
                              score: rubricTotalScore['Conventions']?.point || 0,
                            },
                            {
                              title: '',
                              categories: '',
                              description: '',
                              score: rubricData?.score || 0,
                            },
                          ]
                        : []
                    }
                    tableLayout="fixed"
                    pagination={false}
                    bordered={true}
                    size="small"
                  />
                  <RowFlex style={{ justifyContent: 'space-between', marginTop: '20px', padding: '0 15px' }}>
                    <ResponsiveContainer width={450} height={200} key={`graph-container`}>
                      <BarChart
                        data={
                          rubricTotalScore
                            ? [
                                { name: 'Ideas', '내 점수': 80, '반 점수': getPercentage(rubricTotalScore['Ideas']?.point || 0, 20) },
                                {
                                  name: 'Organization',
                                  '내 점수': 70,
                                  '반 점수': getPercentage(rubricTotalScore['Organization']?.point || 0, 20),
                                },
                                { name: 'Voice', '내 점수': 60, '반 점수': getPercentage(rubricTotalScore['Voice']?.point || 0, 20) },
                                {
                                  name: 'Word Choice',
                                  '내 점수': 50,
                                  '반 점수': getPercentage(rubricTotalScore['Word Choice']?.point || 0, 20),
                                },
                                {
                                  name: 'Conventions',
                                  '내 점수': 80,
                                  '반 점수': getPercentage(rubricTotalScore['Conventions']?.point || 0, 20),
                                },
                                { name: 'Total', '내 점수': 80, '반 점수': getPercentage(rubricData?.score || 0, 100) },
                              ]
                            : []
                        }
                        margin={{
                          top: 10,
                          right: 10,
                          left: 10,
                          bottom: 5,
                        }}
                        style={{ fontSize: '9px' }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={<CustomizedAxisTick />} height={60} interval={0} />
                        <YAxis type="number" domain={[0, 100]} />
                        <Bar dataKey="내 점수" fill="#ffa700" label={{ position: 'top' }} barSize={20} />
                        <Bar dataKey="반 점수" fill="#00adff" label={{ position: 'top' }} barSize={20} />
                      </BarChart>
                    </ResponsiveContainer>
                    <ColFlex style={{ justifyContent: 'center', alignItems: 'center', marginLeft: '10px' }}>
                      <HeadBox background="#ffa700" color="#fff" style={{ padding: '10px' }} />
                      <h5 style={{ margin: '5px 0 5px 0' }}>Your Average Score</h5>
                      <br />
                      <HeadBox background="#00adff" color="#fff" style={{ padding: '10px' }} />
                      <h5 style={{ margin: '5px 0 5px 0' }}>Your Score</h5>
                    </ColFlex>
                  </RowFlex>
                </ContentBox>
              </ColFlex>
            )}
            <ColFlex style={{ justifyContent: 'space-between', marginTop: '10px', padding: '15px' }}>
              <Title style={{ margin: '5px 0 5px 0' }}>
                Comments{' '}
                {botCommentChat?.length > 0 && (
                  <EditOutlined
                    className="edit-icon"
                    onClick={() =>
                      openEditorModal(
                        botCommentChat[botCommentChat?.length - 1]?.content,
                        botCommentChat[botCommentChat?.length - 1]?.idx,
                        2,
                      )
                    }
                  />
                )}
              </Title>
              {botCommentChat?.length > 0 && botCommentChat[botCommentChat?.length - 1]?.content && (
                <ContentBox border="1px solid #FFCD15">
                  {botCommentChat[botCommentChat?.length - 1]?.content
                    .split('\n')
                    .filter((e) => e !== ' ')
                    .map((line, index) => {
                      return <p key={index} dangerouslySetInnerHTML={{ __html: `${line}}` }}></p>;
                    })}
                </ContentBox>
              )}
              {rubricData?.revisedData && (
                <>
                  <EditOutlined
                    className="edit-icon"
                    onClick={() => openEditorModal(rubricData?.revisedData?.text, ai_topic_user_assignments_list?.idx, 1)}
                  />
                  <ContentBox>
                    {rubricData?.revisedData?.text
                      ?.split('\n')
                      .filter((e) => e !== ' ')
                      .map((line, index) => {
                        return <p key={index} dangerouslySetInnerHTML={{ __html: `${line}}` }}></p>;
                      })}
                  </ContentBox>
                </>
              )}
            </ColFlex>
          </InnerWrap>
        </PrintWrapper>
        <HideInPrint>
          <GeneratePdfButton
            url={`https://culp.cloubot.com/level-up/report/generate/${ai_topic_user_assignments_list?.idx}?type=${commentType}`}
            saveData={async (url) => {
              if (pdfType === 'teacher') {
                await handleGeneratePdf(ai_topic_user_assignments_list?.idx, {
                  ...rubricData,
                  pdf_url_teacher: url,
                });
              } else {
                await handleGeneratePdf(ai_topic_user_assignments_list?.idx, {
                  ...rubricData,
                  pdf_url: url,
                });
              }
            }}
            filename={`-level-up-report-result-${pdfType === 'teacher' ? 'full-' : ''}${ai_topic_user_assignments_list?.idx}`}
            defaultStyle={{ color: '#fff', padding: '10px', backgroundColor: '#00adff', borderRadius: '5px' }}
          />
        </HideInPrint>
        <Modal title="Edit" visible={editorModal} onOk={saveEditor} onCancel={closeEditorModal} width={800} okText="Save">
          <ReactQuill
            theme="snow"
            value={text}
            onChange={handleEditorChange}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                ['blockquote'],

                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ direction: 'rtl' }], // text direction

                [{ size: ['small', false, 'large', 'huge'] }],

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],

                ['clean'], // remove formatting button
              ],
            }}
          />
        </Modal>
      </div>
    </>
  );
};

export default LevelUpReportGenerate;

class CustomizedAxisTick extends React.PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-45)"
          fontSize="10px"
          fontWeight={payload.value === 'Total' ? '800' : ''}
        >
          {payload.value}
        </text>
      </g>
    );
  }
}
