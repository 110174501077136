import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
import VideoStage from './stages/VideoStage';
import DndStage from './stages/DndStage';
import MyScriptStage from './stages/MyScriptStage';
import RecordStage from './stages/RecordStage';
import UnitTestStage from './stages/UnitTestStage';
import ImageSelectStage from './stages/ImageSelectStage';
import CrossWordsStage from './stages/CrossWordsStage';
import LineWordStage from './stages/LineWordStage';
import { useStudyCreate } from '../../utils/pm/api';
import SpellingChangeStage from './stages/SpellingChangeStage';
import PhonemeSelectAndDndStage from './stages/PhonemeSelectAndDndStage';
import HiddenPictureStage from './stages/HiddenPictureStage';
import WordSelectStage from './stages/WordSelectStage';
// import PlaneWordStage from "../PlaneWordStage";
import PhonemeSelectAndWriteStage from './stages/PhonemeSelectAndWriteStage';
import PhonemeAndShowImageStage from './stages/PhonemeAndShowImageStage';
import ImageSoundStage from './stages/ImageSoundStage';
import ColorStage from './stages/ColorStage';
import PageNav, { usePageNav } from './PageNav';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';

const isNotRequredPageNaveBtn = (stage) => {
  switch (stage.type) {
    case 'video':
    case 'record':
    case 'unitTest':
      return true;
    case 'dnd':
      return stage.customData.type === 'imgDnd3' ? true : false;
    default:
      return false;
  }
};

const getStageTitle = (hasStep, isMagic, steps, title, bookInfo) => {
  let finalTitle = '';
  let stepNumber = '';
  if (hasStep) {
    if (isMagic) {
      if (steps < 4) {
        if (bookInfo.bookName === '3b' && bookInfo.unit === '9' && bookInfo.stage === '2') {
          return 'Step1. Drag and drop.';
        } else stepNumber = 'Step1. ';
      } else {
        stepNumber = 'Step2. ';
      }
    } else {
      if (steps < 3) {
        stepNumber = 'Step1. ';
      } else {
        stepNumber = 'Step2. ';
      }
    }
    if (title instanceof Array) {
      if (stepNumber === 'Step1. ') {
        finalTitle = `${stepNumber}${title[0]}`;
      } else {
        finalTitle = `${stepNumber}${title[1]}`;
      }
    } else {
      finalTitle = `${stepNumber}${title}`;
    }
  } else {
    finalTitle = title;
  }
  return finalTitle;
};

const StageLayout = ({ bookInfo, unitInfo, stages, directionEnd, handleSound }) => {
  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {},
  });
  const stage = _.toNumber(bookInfo.stage.split('-')[0]);

  let count = !_.isNil(stages.customData) && !_.isNil(stages.customData.problem) ? stages.customData.problem.length : 0;
  if (count == 0 && stages.problem) {
    count = stages.problem.length;
  }
  const { steps, setSteps, onPrev, onNext } = usePageNav(count);
  const location = useLocation();
  const resvowel = location.pathname.split('/');

  const getDndSound = (param) => {
    handleSound(param);
  };
  const getType = (stages) => {
    switch (stages.type) {
      case 'dnd':
        return (
          <DndStage
            unitInfo={unitInfo}
            bookInfo={bookInfo}
            stages={stages}
            steps={steps}
            setSteps={setSteps}
            directionEnd={directionEnd}
            handleDndSound={getDndSound}
          />
        );
      case 'video':
        return <VideoStage url={stages.url} />;
      case 'write':
        return (
          <MyScriptStage
            unitInfo={unitInfo}
            bookInfo={bookInfo}
            stages={stages}
            steps={steps}
            setSteps={setSteps}
            typeName={stages.customData.type}
            directionEnd={directionEnd}
          />
        );
      case 'record':
        return <RecordStage unitInfo={unitInfo} bookInfo={bookInfo} stages={stages} />;
      case 'unitTest':
        return <UnitTestStage unitInfo={unitInfo} bookInfo={bookInfo} stages={stages} directionEnd={directionEnd} />;
      case 'imgSelect':
        return (
          <ImageSelectStage
            unitInfo={unitInfo}
            bookInfo={bookInfo}
            stages={stages}
            steps={steps}
            setSteps={setSteps}
            directionEnd={directionEnd}
          />
        );
      case 'crossWords':
        const correctWords = ['cave', 'case', 'five', 'hive', 'bite', 'ride', 'dice', 'game'];
        return (
          <CrossWordsStage
            unitInfo={unitInfo}
            bookInfo={bookInfo}
            stages={stages}
            steps={steps}
            setSteps={setSteps}
            correctWords={correctWords}
          />
        );
      // case "planeWord":
      // 	return <PlaneWordStage unitInfo={unitInfo} bookInfo={bookInfo} stages={stages} steps={steps} setSteps={setSteps} correctWords={correctWords} />;
      case 'lineWord':
        return <LineWordStage unitInfo={unitInfo} bookInfo={bookInfo} stages={stages} steps={steps} setSteps={setSteps} />;
      case 'spellingChange':
        return <SpellingChangeStage unitInfo={unitInfo} bookInfo={bookInfo} stages={stages} steps={steps} setSteps={setSteps} />;
      case 'phonemeSelectAndDnd':
        return (
          <PhonemeSelectAndDndStage
            unitInfo={unitInfo}
            bookInfo={bookInfo}
            stages={stages}
            steps={steps}
            setSteps={setSteps}
            directionEnd={directionEnd}
          />
        );
      case 'hidden':
        return <HiddenPictureStage unitInfo={unitInfo} bookInfo={bookInfo} stages={stages} steps={steps} setSteps={setSteps} />;
      case 'wordSelect':
        return <WordSelectStage unitInfo={unitInfo} bookInfo={bookInfo} stages={stages} steps={steps} setSteps={setSteps} />;
      case 'phonemeSelectAndWrite':
        return <PhonemeSelectAndWriteStage unitInfo={unitInfo} bookInfo={bookInfo} stages={stages} steps={steps} setSteps={setSteps} />;
      case 'phonemeImage':
        return (
          <PhonemeAndShowImageStage
            unitInfo={unitInfo}
            bookInfo={bookInfo}
            stages={stages}
            steps={steps}
            setSteps={setSteps}
            directionEnd={directionEnd}
          />
        );
      case 'imageSound':
        return (
          <ImageSoundStage
            unitInfo={unitInfo}
            bookInfo={bookInfo}
            stages={stages}
            steps={steps}
            setSteps={setSteps}
            directionEnd={directionEnd}
          />
        );
      case 'color':
        return (
          <ColorStage
            unitInfo={unitInfo}
            bookInfo={bookInfo}
            stages={stages}
            steps={steps}
            setSteps={setSteps}
            directionEnd={directionEnd}
          />
        );
      default:
        return <div>No Data Type Defined</div>;
    }
  };

  useEffect(() => {
    if (stages.type === 'video') {
      const user_study_input = {
        lesson_code: window.sessionStorage.getItem('lesson_code'),
        stage_no: parseInt(bookInfo.stage),
        user_idx: parseInt(userInfoVar()?.idx),
        user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
      };

      handleCreateStudy(user_study_input);
    }
  }, [stages.type, bookInfo]);
  return (
    <S.Main
      className={
        isMobile
          ? (resvowel[2] === '2a' || resvowel[2] === '2b') && resvowel[6] == '7'
            ? 'mobile-scroll cust-main-vowel'
            : 'mobile-scroll'
          : (resvowel[2] === '1b' || resvowel[2] === '4b') && resvowel[6] == '7'
          ? 'cust-stage-b7'
          : ''
      }
    >
      <div className="container" style={{ width: '95%', margin: '20px 0' }}>
        <S.StageDirection>
          <S.UnitButton>{`Stage${stage < 10 ? `0${stage}` : `${stage}`}`}</S.UnitButton>
          <div>
            {getStageTitle(stages.hasStep, !_.isNil(stages.customData) && stages.customData.isMagic, steps, stages.title, bookInfo)}
          </div>
        </S.StageDirection>
        {/* <div className="stage_tit_wrap">
          <p className="stage_num">{`Stage${stage < 10 ? `0${stage}` : `${stage}`}`}</p>
          <h3 className="stage_tit">
            {getStageTitle(stages.hasStep, !_.isNil(stages.customData) && stages.customData.isMagic, steps, stages.title, bookInfo)}
          </h3>
        </div> */}
        {getType(stages)}
      </div>

      {isNotRequredPageNaveBtn(stages) ? undefined : <PageNav bookInfo={bookInfo} onPrev={onPrev} onNext={onNext} />}
    </S.Main>
  );
};

export default StageLayout;
