import React, { useState, useEffect } from 'react';
// import {DefaultScripter} from "./Scripter";
import _ from 'lodash';
import { isVowel } from '../../utils/pm/util';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useParams } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const correctSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/correct.mp3';
const wrongSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/wrong.mp3';

let bookNameUnitStage = undefined;
let bookNameUnitStageChecklist = undefined;
let bookNameUnitStageAdd = undefined;
const MagicWriteWrapper = ({ problem, steps, isAfter = false, onFinish, setSteps, setIsAfter, onScriptLoaded }) => {
  const number = 2;
  const stepIndex = steps < problem.length ? steps : problem.length - 1;
  const short = problem[stepIndex]['short'];
  const long = problem[stepIndex]['long'];

  const [loadCompletes, setLoadCompletes] = useState(Array(number).fill(false));
  const [correctWrongSound, setCorrectWrongSound] = useState(undefined);
  const [firstReset, setFirstReset] = useState(false);
  const [secondReset, setSecondReset] = useState(false);
  const [userAnswerComplete, setUserAnswerComplete] = useState(Array(number).fill(false));
  const { level, unit, stage } = useParams();

  if (level == '3b' && unit == 9 && stage == 5) {
    bookNameUnitStage = true;
    bookNameUnitStageChecklist = { e: true };
    bookNameUnitStageAdd = true;
  }

  const handleLoaded = (editorIndex) => {
    const copy = _.cloneDeep(loadCompletes);
    copy[editorIndex] = true;
    setLoadCompletes(copy);
  };
  const handleFirstWriteEnd = (userInputWord) => {
    if (_.isNil(userInputWord)) {
      return;
    }
    if (userInputWord.join('') === problem[stepIndex]['short']) {
      setCorrectWrongSound(correctSound);
      const copy = _.cloneDeep(userAnswerComplete);
      copy[0] = true;
      setUserAnswerComplete(copy);
    } else {
      setCorrectWrongSound(wrongSound);
      setFirstReset(true);
    }
  };
  const handleSecondWriteEnd = (userInputWord) => {
    if (_.isNil(userInputWord)) {
      return;
    }
    if (userInputWord.join('') === problem[stepIndex]['long']) {
      setCorrectWrongSound(correctSound);
      const copy = _.cloneDeep(userAnswerComplete);
      copy[1] = true;
      setUserAnswerComplete(copy);
    } else {
      setCorrectWrongSound(wrongSound);
      setSecondReset(true);
    }
  };
  const handleSoundEnd = () => {
    if (correctWrongSound === wrongSound) {
      setFirstReset(false);
      setSecondReset(false);
    } else {
      if (userAnswerComplete.every((item) => item === true)) {
        setFirstReset(true);
        setSecondReset(true);
        setSteps(steps + 1);
      }
    }
    setCorrectWrongSound(undefined);
  };

  useEffect(() => {
    setFirstReset(false);
    setSecondReset(false);
    setIsAfter(false);
    setUserAnswerComplete(Array(number).fill(false));
    // eslint-disable-next-line
  }, [steps]);

  useEffect(() => {
    if (loadCompletes.every((item) => item === true)) {
      onScriptLoaded();
    }
    // eslint-disable-next-line
  }, [loadCompletes]);

  useEffect(() => {
    if (userAnswerComplete.every((item) => item === true)) {
      setIsAfter(true);
    }
    // eslint-disable-next-line
  }, [userAnswerComplete]);

  useEffect(() => {
    if (steps >= problem.length) {
      onFinish();
    }
    // eslint-disable-next-line
  }, [problem, steps]);

  return (
    <>
      <S.RowFlex
        style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }}
        id="lv3_unit9_stage5_content"
        className="main_content col2_arrow  boxstyle_none"
      >
        <S.ColumnFlex style={{ alignSelf: 'stretch', flex: '1 1', height: '100%' }} className="left_box">
          <BorderedArea className="top_area">
            <img
              style={{ height: '19vh', objectFit: 'contain' }}
              src={`https://cdn.cloubot.com/PM/image/level3/image_${problem[stepIndex]['short']}.png`}
              alt={problem[stepIndex]['short']}
            />
            <WordBox className="word_box active">
              {bookNameUnitStage && bookNameUnitStageAdd
                ? _.map(short, (item, itemkey) => (
                    <span
                      style={{ color: bookNameUnitStageChecklist[item] == true ? '#f368e0' : '#2e86de' }}
                      className={bookNameUnitStageChecklist[item] == true ? 'font_pink' : 'font_blue'}
                    >
                      {item}
                    </span>
                  ))
                : _.map(short, (item) => (
                    <span style={{ color: isVowel(item) ? '#f368e0' : '#2e86de' }} className={isVowel(item) ? 'font_pink' : 'font_blue'}>
                      {item}
                    </span>
                  ))}
            </WordBox>
          </BorderedArea>
          <BorderedArea style={{ margin: 0 }} className="bottom_area pen_keyboard_wrap">
            {/* <DefaultScripter
							onLoaded={() => {
								handleLoaded(0);
							}}
							onWriteEnd={handleFirstWriteEnd}
							reset={firstReset}
							onTouchStart={handleFirstWriteEnd}
							onTouchEnd={handleFirstWriteEnd}
							touchaction="true"
							isComplete={userAnswerComplete[0]}
						/> */}
          </BorderedArea>
        </S.ColumnFlex>

        <S.ColumnFlex style={{ alignSelf: 'stretch', flex: '0 1', margin: '0 16px', height: '100%' }} className="center_box">
          <MiddleArea className="top_area">
            <img style={{ height: '40px' }} src="/images/pm/i_arrow_right.svg" alt="오른쪽으로 향하는 화살표" />
          </MiddleArea>
          <MiddleArea style={{ margin: 0 }} className="plus_alphabet">
            <div>
              +<span>e</span>
            </div>
          </MiddleArea>
        </S.ColumnFlex>

        <S.ColumnFlex style={{ alignSelf: 'stretch', flex: '1 1', height: '100%' }} className="right_box">
          <BorderedArea className="top_area">
            <img
              style={{ height: '19vh', objectFit: 'contain' }}
              src={
                isAfter
                  ? `https://cdn.cloubot.com/PM/image/level3/image_${problem[stepIndex]['long']}.png`
                  : 'https://cdn.cloubot.com/PM/image/default_monster_gray.png'
              }
              alt={isAfter ? 'before' : problem[stepIndex]['long']}
            />
            <p className={`word_box ${isAfter ? 'active' : ''}`}>
              {bookNameUnitStage && bookNameUnitStageAdd
                ? _.map(long, (item, itemkey) => (
                    <span className={bookNameUnitStageChecklist[item] == true ? 'font_pink' : 'font_blue'}>{item}</span>
                  ))
                : _.map(long, (item) => <span className={isVowel(item) ? 'font_pink' : 'font_blue'}>{item}</span>)}
            </p>
          </BorderedArea>
          <BorderedArea style={{ margin: 0 }} className="bottom_area pen_keyboard_wrap">
            {/* <DefaultScripter
							onLoaded={() => {
								handleLoaded(1);
							}}
							onWriteEnd={handleSecondWriteEnd}
							onTouchStart={handleSecondWriteEnd}
							onTouchEnd={handleSecondWriteEnd}
							reset={secondReset}
							isComplete={userAnswerComplete[1]}
						/> */}
          </BorderedArea>
        </S.ColumnFlex>
      </S.RowFlex>
      <SoundEffectPM url={correctWrongSound} onEnd={handleSoundEnd} />
    </>
  );
};

export default MagicWriteWrapper;
const BorderedArea = styled(S.BorderColumnBox)`
  padding: 1.2rem;
  justify-content: center;
  flex: 1 1;
  margin-bottom: 1.2rem;
  height: calc(50% - 0.6rem);
`;
const WordBox = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  padding: 0;
`;
const MiddleArea = styled(S.ColumnFlex)`
  justify-content: center;
  align-items: center;
  flex: 1 1;
  margin-bottom: 1.2rem;
  height: calc(50% - 0.6rem);
  font-size: 32px;
  font-weight: 600;
`;
