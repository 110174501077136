import React, { useState, useEffect, useMemo } from 'react';
//import PageNav, {usePageNav} from "../components/common/PageNav";
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { isMobile } from 'react-device-detect';
import * as S from 'src/pages/PhonicsMonster/style';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import StageLayout from 'src/components/pm/StageLayout';
// 일단은 db나 그런거 없으니까 이거 가져와서 하고, 나중에는 fetch 받아다 어딘가에 보관해두고 쓰든지 그렇게 바꾸면 될듯..
import bookInfo from 'src/utils/pm/bookInfo';
import { SoundFilled, MutedFilled, HomeFilled } from '@ant-design/icons';
import queryString from 'query-string';

const Stage = ({ match }) => {
  const params = queryString.parse(window.location.search);
  const history = useHistory();
  const [directionEnd, setDirectionEnd] = useState(false);
  const [soundPlay, setSoundPlay] = useState(false);
  const handleDirectionEnd = () => setDirectionEnd(true);
  const [isMute, setIsMute] = useState(false);
  useEffect(() => {
    const pmMute = window.localStorage.getItem('pm_Mute');
    if (pmMute) {
      setIsMute(true);
    }
  }, []);
  useEffect(() => {
    if (isMute) {
      window.localStorage.setItem('pm_Mute', true);
    } else {
      window.localStorage.removeItem('pm_Mute', true);
    }
  }, [isMute]);
  const { level, unit, stage } = useParams();
  const stageData = bookInfo.filter((value) => value.bookName === level)[0];
  const unitInfo = stageData.unit[unit];
  const stages = unitInfo.stage[stage];
  console.log(stages, 'stages');
  // let count = 0;
  // if (!_.isNil(stages.customData) && !_.isNil(stages.customData.problem)) {
  // 	count = stages.customData.problem.length;
  // }
  //const {steps, setSteps, onPrev, onNext} = usePageNav(count);
  const getHandleSound = (param) => {
    setSoundPlay(param);
  };

  const assignedLesson = useMemo(() => {
    if (params.lesson_code && params.user_lesson_idx) {
      window.sessionStorage.setItem('lesson_code', params.lesson_code);
      window.sessionStorage.setItem('user_lesson_idx', params.user_lesson_idx);
      return [params.user_lesson_idx, params.lesson_code];
    }

    if (window.sessionStorage.getItem('lesson_code') && window.sessionStorage.getItem('user_lesson_idx')) {
      return [window.sessionStorage.getItem('user_lesson_idx'), window.sessionStorage.getItem('lesson_code')];
    }

    return null;
  }, [params.lesson_code, params.user_lesson_idx]);
  return (
    <>
      <S.fontStyle />
      <S.Root>
        <S.RowFlex style={{ width: '85vw', justifyContent: 'space-between', paddingBottom: 10 }}>
          <S.HeadUnitTitle>
            Unit {unit} {unitInfo.title}
          </S.HeadUnitTitle>

          <S.RowFlex>
            <S.Button1
              style={{ marginRight: 10, width: 50, height: 50 }}
              onClick={() => {
                history.goBack();
              }}
            >
              <img src="/images/pm/i_back.svg" alt="back" />
            </S.Button1>
            <S.Button1 onClick={() => setIsMute((prev) => !prev)} style={{ marginRight: 10, width: 50, height: 50 }}>
              {isMute ? (
                <MutedFilled style={{ fontSize: '1.5rem', color: '#fff' }} />
              ) : (
                <SoundFilled style={{ fontSize: '1.5rem', color: '#fff' }} />
              )}
            </S.Button1>

            {!assignedLesson && (
              <S.Button1
                style={{ width: 50, height: 50 }}
                onClick={() => {
                  history.push('/pm');
                }}
              >
                <HomeFilled style={{ fontSize: '1.4rem', color: '#fff' }} />
              </S.Button1>
            )}
          </S.RowFlex>
        </S.RowFlex>
        <StageLayout unitInfo={unitInfo} bookInfo={match.params} stages={stages} directionEnd={directionEnd} handleSound={getHandleSound} />
        {/* {isNotRequredPageNaveBtn(stages) ? undefined : <PageNav bookInfo={match.params} onPrev={onPrev} onNext={onNext} />} */}
        {stages.type !== 'video' && stages.type !== 'record' && !isMute && (
          <SoundEffectPM
            url={isMobile ? '' : 'https://cdn.cloubot.com/PM/audio/sound-effect/more-monkey-island-band.mp3'}
            replayCnt={0}
            volume={20}
          />
        )}
        {!_.isEmpty(stages.directionSound) && <SoundEffectPM url={stages?.directionSound} onEnd={handleDirectionEnd} />}
        {soundPlay == true ? <SoundEffectPM url={stages?.directionSound} onEnd={handleDirectionEnd} /> : ''}
      </S.Root>
    </>
  );
};

export default Stage;
