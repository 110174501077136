import React, { useMemo, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Table, Typography, Col,Spin } from 'antd';
import { useSelector } from 'react-redux';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend } from 'recharts';
import { classStoreData } from 'src/operations/store';
import { USER_CLASS_LESSON_LIST, BOOK_STAGE_LIST_BY_BOOK_CODES, ALL_USER_CLASS_LESSION_WITH_BOOK_LIST } from 'src/operations/queries/study';
import { getStageTableHeader } from 'src/utils/bookStage';
import AchievementGraph from './AchievementGraph';
import _ from 'lodash';
const isCountGrade = (stage_abbr) => {
  if (['w1', 'w2'].includes(stage_abbr)) {
    return false;
  }
  return true;
};

const StudyTable = styled(Table)`
  border-top: 3px solid #a4d0e9;
  border-right: 1px solid #a4d0e9;
  thead[class*='ant-table-thead'] th {
    border-left: 1px solid #a4d0e9;
    border-top: 1px solid #a4d0e9;
    font-size: 0.8em;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background: #9b0000; */
    /* color: #fff; */
  }

  /* tr[class*='ant-table-row'] td[rowspan*='3']:first-child {
    background: #fbe5d6;
  }

  tr[class*='ant-table-row']:last-child td {
    background: #fbe5d6;
  } */

  tbody td {
    /* border-left: 1px solid #c5c5c5b3;
     */
    border-left: 1px solid #a4d0e9;
    font-size: 0.8em;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media print {
    max-width: 700px;
    thead[class*='ant-table-thead'] th {
      padding: 3px;
      font-size: 0.5em;
    }
    tbody td {
      padding: 3px;
      font-size: 0.5em;
    }
  }
`;

// const Title = styled.title`
//   margin-bottom: 0.5em;
//   color: #008080;
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 1.4;
//   border-top: 3px solid #9b0000;
//   display: block;
//   margin-top: 15px;
// `;
const { Title } = Typography;
const getPercentGrade = (stageGroupCode, stages, userStudyList) => {
  if (!userStudyList || userStudyList.length === 0) {
    return -1;
  }
  const findItem = stages.find((stage) => stage.group_code === stageGroupCode);
  if (!findItem) {
    return -1; //여기로 들어오면 안 되는 거임
  }
  const findItem2 = userStudyList.find((studyItem) => studyItem.stage_no === findItem.no);
  if (!findItem2) {
    return -1;
  }
  const { exam_total, exam_correct } = findItem2;
  if (!exam_total) {
    return 100;
  }

  const percentGrade = Math.round((100 * parseInt(exam_correct)) / parseInt(exam_total));
  return percentGrade;
};

const StudyCompleteTableAfterSchool = ({
  // bookCode,
  class_idx,
  user_idx,
  isPhonics,
  book_codes = [],
  classBooks = [],
  study_range = [],
}) => {
  const companyName = useSelector(classStoreData);
  const levels = useRef(new Set());
  const { data: dataStages } = useQuery(BOOK_STAGE_LIST_BY_BOOK_CODES, {
    skip: !book_codes.length,
    variables: { book_codes },
  });
  const { data: dataLessons } = useQuery(USER_CLASS_LESSON_LIST, {
    skip: !class_idx || !user_idx,
    variables: { class_idx: parseInt(class_idx), user_idx: parseInt(user_idx), book_codes, study_range },
    fetchPolicy: 'no-cache',
  });
  const { data: dataAllUsers, loading } = useQuery(ALL_USER_CLASS_LESSION_WITH_BOOK_LIST, {
    variables: { class_idx, book_codes, study_range },
    skip: !class_idx,
  });
  const columns = [
    {
      key: 'subject',
      title: 'SUBJECT(교재)',
      dataIndex: 'subject',
      align: 'center',
    },
    {
      // key: 'score',
      title: 'SCORE(점수)',
      // dataIndex: 'score',
      align: 'center',
      children: [
        {
          key: 'words',
          title: 'Words',
          dataIndex: 'words',
          align: 'center',
          render: (text) => {
            return isNaN(text) ? '--' : text;
          },
        },
        {
          key: 'game',
          title: 'Word Game',
          dataIndex: 'game',
          align: 'center',
          render: (text) => {
            return isNaN(text) ? '--' : text;
          },
        },
        {
          key: 'speak',
          title: 'AI Speaking',
          dataIndex: 'speak',
          align: 'center',
          render: (text) => {
            return isNaN(text) ? '--' : text;
          },
        },
        {
          key: 'sentences',
          title: 'Key Sentences',
          dataIndex: 'sentences',
          align: 'center',
          render: (text) => {
            return isNaN(text) ? '--' : text;
          },
        },
        {
          key: 'comprehension',
          title: 'Comprehension',
          dataIndex: 'comprehension',
          align: 'center',
          render: (text) => {
            return isNaN(text) ? '--' : text;
          },
        },
      ],
    },
    {
      key: 'average',
      title: 'AVERAGE(평균 점수)',
      dataIndex: 'average',
      align: 'center',
    },
    {
      key: 'achievement',
      title: 'ACHIEVEMENT(수행률)',
      dataIndex: 'achievement',
      align: 'center',
      render: (value) => {
        return value + '%';
      },
    },
  ];

  const dataStudyComplete = useMemo(() => {
    if (dataLessons && dataStages) {
      const gradeData = dataLessons.getUserClassLessonList.map((item) => {
        const baseData = {};
        const lessonBookCode = item.book_lesson?.book?.code;
        let stageList = dataStages.bookStageListByBookCodes.filter((stage) => stage.book_code === lessonBookCode);

        for (const stageItem of stageList) {
          const groupCodes = stageItem.book_stage.map((stage) => stage.group_code);

          if (groupCodes instanceof Array && groupCodes.includes(item.book_lesson.stage_group_code)) {
            const grade = getPercentGrade(
              item.book_lesson.stage_group_code,
              stageItem.book_stage,
              item.user_study.filter((study) => study.user_lesson_idx),
            );
            baseData[stageItem.name_abbr] = grade;
          }
        }
        let doneStages = Object.values(baseData).filter((value) => value != -1);
        return {
          ...baseData,
          lesson_code: item.book_lesson.code,
          avg: Math.round((100 * parseInt(doneStages.reduce((a, b) => a + (b || 0), 0))) / parseInt(Object.keys(baseData).length * 100)),
          completion: Math.round((100 * parseInt(doneStages.length)) / parseInt(Object.keys(baseData).length)),
        };
      });
      return gradeData;
    }
    return [];
  }, [dataLessons, dataStages]);
  const dataStudyBooks = useMemo(() => {
    if (dataStudyComplete && classBooks) {
      let tmp = [];
      classBooks.forEach((classBook) => {
        const { book_code, book_sub, book } = classBook;
        const booksub = book_sub ? book_sub : book.book_sub;
        let subject = booksub && (booksub[0]?.sub_title || booksub[0]?.title);
        // let level = subject.replace('_R', '').replace('_L&S', '').replace('_W&G', '');
        const lessons = dataStudyComplete.filter((item) => item.lesson_code.startsWith(book_code));
        // const score = lessons.length ? lessons.reduce((a, b) => a + b.avg, 0) / lessons.length : 0;
        const achievement = lessons.length ? lessons.reduce((a, b) => a + b.completion, 0) / lessons.length : 0;
        // lessons.every((e)=>isPhonics?(e.w1===-1&&e.w2===-1):(e.w1===-1&&e.w2===-1&&e.w3===-1))
        const words = lessons.every((e) => (isPhonics ? e.w1 === -1 && e.w2 === -1 : e.w1 === -1 && e.w2 === -1 && e.w3 === -1))
          ? '-'
          : isPhonics
          ? (lessons.filter((item) => item.w1 !== -1).length + lessons.filter((item) => item.w2 !== -1).length) / 2
          : ((lessons.filter((item) => item.w1 !== -1).length +
              lessons.filter((item) => item.w2 !== -1).length +
              lessons.filter((item) => item.w3 !== -1).reduce((acc, cur) => acc + cur.w3, 0) / 100) *
              100) /
            (lessons.length * 3);
        const ttlevel = book_code.includes('TT') ? parseInt(book_code[4]) : undefined;
        const game =
          lessons.every((e) => (isPhonics ? e.game === -1 && e.wu === -1 : e.game === -1)) || ttlevel > 2
            ? '-'
            : isPhonics
            ?( lessons.filter((item) => item.game !== -1).reduce((acc, cur) => acc + cur.game, 0) +
              lessons.filter((item) => item.wu !== -1).reduce((acc, cur) => acc + cur.wu, 0)) / (lessons.length * 2)
            : lessons.filter((item) => item.game !== -1).reduce((acc, cur) => acc + cur.game, 0) / lessons.length;
        const speaklist = lessons.filter((item) => Object.keys(item).includes('speak'));
        const speak =
          speaklist.length === 0 || speaklist.every((e) => e.speak === -1)
            ? '-'
            : speaklist.filter((item) => item.speak !== -1).reduce((acc, cur) => acc + cur.speak, 0) / speaklist.length;
        const sentenceslist = lessons.filter((item) => Object.keys(item).includes('ks1'));
        const sentences =
          sentenceslist.length === 0 || sentenceslist.every((e) => e.ks1 === -1 && e.ks2 === -1)
            ? '-'
            : (sentenceslist.filter((item) => item.ks1 !== -1).reduce((acc, cur) => acc + cur.ks1, 0) +
                sentenceslist.filter((item) => item.ks2 !== -1).reduce((acc, cur) => acc + cur.ks2, 0)) /
              (sentenceslist.length * 2);
        const listeninglist = lessons.filter((item) => Object.keys(item).includes('lq'));
        const listening =
          listeninglist.length === 0 || listeninglist.every((e) => e.lq === -1)
            ? '-'
            : listeninglist.filter((item) => item.lq !== -1).reduce((acc, cur) => acc + cur.lq, 0) / listeninglist.length;
        const readinglist = lessons.filter((item) => Object.keys(item).includes('rq'));
        const reading =
          readinglist.length === 0 || readinglist.every((e) => e.rq === -1)
            ? '-'
            : readinglist.filter((item) => item.rq !== -1).reduce((acc, cur) => acc + cur.rq, 0) / readinglist.length;
        const comprehension = listening !== '-' && reading !== '-' ? (listening + reading) / 2 : '-';
        const average = [words, game, speak, sentences, comprehension].reduce(
          (acc, cur) => {
            if (_.isNumber(cur) && !isNaN(cur)) {
              acc.sum += cur;
              acc.count++;
            }
            return { ...acc };
          },
          { sum: 0, count: 0 },
        );
        tmp.push({
          // level,
          words: words === '-' ? '-' : Math.round(words),
          game: game === '-' ? '-' : Math.round(game),
          speak: speak === '-' ? '-' : Math.round(speak),
          sentences: sentences === '-' ? '-' : Math.round(sentences),
          listening: listening === '-' ? '-' : Math.round(listening),
          reading: reading === '-' ? '-' : Math.round(reading),
          comprehension: comprehension !== '-' ? Math.round(comprehension) : ttlevel > 2 ? listening : '-',
          average: average.count === 0 ? '-' : Math.round(average.sum / average.count),
          subject,
          achievement: Math.round(achievement),
        });
      });
      return tmp;
    }

    return [];
  }, [dataStudyComplete, classBooks]);
  const classGrade = useMemo(() => {
    const list = dataAllUsers?.getAllUserClassLessonList;
    if (list && dataStages?.bookStageListByBookCodes) {
      const gradeData = list.map((item) => {
        const baseData = {};
        const lessonBookCode = item.book_lesson?.book?.code;
        let stageList = dataStages.bookStageListByBookCodes.filter((stage) => stage.book_code === lessonBookCode);

        for (const stageItem of stageList) {
          const groupCodes = stageItem.book_stage.map((stage) => stage.group_code);

          if (groupCodes instanceof Array && groupCodes.includes(item.book_lesson.stage_group_code)) {
            const grade = getPercentGrade(
              item.book_lesson.stage_group_code,
              stageItem.book_stage,
              item.user_study.filter((study) => study.user_lesson_idx),
            );
            baseData[stageItem.name_abbr] = grade;
          }
        }
        let doneStages = Object.values(baseData).filter((value) => value != -1);
        return {
          ...baseData,
          lesson_code: item.book_lesson.code,
          avg: Math.round((100 * parseInt(doneStages.reduce((a, b) => a + (b || 0), 0))) / parseInt(Object.keys(baseData).length * 100)),
          completion: Math.round((100 * parseInt(doneStages.length)) / parseInt(Object.keys(baseData).length)),
        };
      });
      return gradeData;
    }
    return [];
  }, [dataAllUsers, dataStages]);
  const dataClassStudyBooks = useMemo(() => {
    if (classGrade && classBooks) {
      const words = classGrade.every((e) => (isPhonics ? e.w1 === -1 && e.w2 === -1 : e.w1 === -1 && e.w2 === -1 && e.w3 === -1))
        ? '-'
        : isPhonics
        ? (classGrade.filter((item) => item.w1 !== -1).length + classGrade.filter((item) => item.w2 !== -1).length) /
          (classGrade.length * 2)
        : ((classGrade.filter((item) => item.w1 !== -1).length +
            classGrade.filter((item) => item.w2 !== -1).length +
            classGrade.filter((item) => item.w3 !== -1).reduce((acc, cur) => acc + cur.w3, 0) / 100) *
            100) /
          (classGrade.length * 3);
      // const game = lessons.filter((item)=>item.game!==-1).reduce((acc,cur)=>acc+cur.game,0) /lessons.length
      const speaklist = classGrade.filter((item) => Object.keys(item).includes('speak'));
      const speak =
        speaklist.length === 0 || speaklist.every((e) => e.speak === -1)
          ? '-'
          : speaklist.filter((item) => item.speak !== -1).reduce((acc, cur) => acc + cur.speak, 0) / speaklist.length;
      const sentenceslist = classGrade.filter((item) => Object.keys(item).includes('ks1'));
      const sentences =
        sentenceslist.length === 0 || sentenceslist.every((e) => e.ks1 === -1 && e.ks2 === -1)
          ? '-'
          : (sentenceslist.filter((item) => item.ks1 !== -1).reduce((acc, cur) => acc + cur.ks1, 0) +
              sentenceslist.filter((item) => item.ks2 !== -1).reduce((acc, cur) => acc + cur.ks2, 0)) /
            (sentenceslist.length * 2);
      const listeninglist = classGrade.filter((item) => Object.keys(item).includes('lq'));
      const listening =
        listeninglist.length === 0 || listeninglist.every((e) => e.lq === -1)
          ? '-'
          : listeninglist.filter((item) => item.lq !== -1).reduce((acc, cur) => acc + cur.lq, 0) / listeninglist.length;
      const readinglist = classGrade.filter((item) => Object.keys(item).includes('rq'));
      const reading =
        readinglist.length === 0 || readinglist.every((e) => e.rq === -1)
          ? '-'
          : readinglist.filter((item) => item.rq !== -1).reduce((acc, cur) => acc + cur.rq, 0) / readinglist.length;
      // const comprehension = (listening+reading)/2
      return {
        Vocabulary: words === '-' ? '-' : Math.round(words),
        Sentence: sentences === '-' ? '-' : Math.round(sentences),
        Speaking: speak === '-' ? '-' : Math.round(speak),
        Reading: reading === '-' ? '-' : Math.round(reading),
        Listening: listening === '-' ? '-' : Math.round(listening),
      };
    }
    return { Vocabulary: '-', Sentence: '-', Speaking: '-', Reading: '-', Listening: '-' };
  }, [classGrade, classBooks]);
  //스마트이클래스는 성취도 그래프를 보여준다.(점수 기준임)
  const columnsForGraph = useMemo(() => {
    if (dataStages) {
      return dataStages.bookStageListByBookCode?.map((ele) => ele.book_stage[0].name_abbr);
    }
  }, [dataStages, companyName]);
  const myGradeData = useMemo(() => {
    const tmp = { Vocabulary: 0, Sentence: 0, Speaking: 0, Reading: 0, Listening: 0 };
    if (dataStudyBooks) {
      dataStudyBooks.map((e) => {
        e.words === '-' ? (tmp.Vocabulary += 0) : (tmp.Vocabulary += e.words / dataStudyBooks.length);
        e.sentences === '-' ? (tmp.Sentence += 0) : (tmp.Sentence += e.sentences / dataStudyBooks.length);
        e.speak === '-' ? (tmp.Speaking += 0) : (tmp.Speaking += e.speak / dataStudyBooks.length);
        e.reading === '-' ? (tmp.Reading += 0) : (tmp.Reading += e.reading / dataStudyBooks.length);
        e.listening === '-' ? (tmp.Listening += 0) : (tmp.Listening += e.listening / dataStudyBooks.length);
      });
    }
    return tmp;
  }, [dataStudyBooks]);
  // console.log(myGradeData, 'myGradeData1');
  return (
    <>
      <Col span={24}>
        <Title level={4}>온라인 학습 성취도</Title>
        <StudyTable columns={columns} dataSource={dataStudyBooks} pagination={false} />
      </Col>
      <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}></Col>
      <AchievementGraph
        cols={columnsForGraph}
        myGradeData={myGradeData}
        classGradeData={dataClassStudyBooks}
        myAverage={0}
        class_idx={class_idx}
        isPhonics={isPhonics}
      />
    </>
  );
};

export default StudyCompleteTableAfterSchool;
