import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ScoreBox from '../ScoreBox';
import UserAnswerListBox from '../UserAnswerListBox';
import Keyboard from '../Keyboard';
// import {UnitTestScripter} from "./common/Scripter";
import PopUp from '../PopUp';
import SoundButton from '../SoundButton';
import _ from 'lodash';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useStudyCreate } from '../../../utils/pm/api';
// import {useMyScript} from "./common/MyScriptHook";
import SwitchButtonBox from '../SwitchButtonBox';
import { useLocation } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import { userInfoVar } from 'src/apollo/cache';

const soundBtnStyle1 = {
  position: 'absolute',
  top: '0',
  right: '18px',
};

const soundBtnStyle2 = {
  position: 'absolute',
  top: '-10px',
  right: '10px',
};

const defaultImage = 'https://cdn.cloubot.com/PM/image/default_monster_gray.png';
const wrongSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';

const UnitTestStage = ({ unitInfo, bookInfo, stages, directionEnd }) => {
  const { problem } = stages;
  const correctCountRef = useRef(0);
  const [getKey, setGetKey] = useState([]);
  const [score, setScore] = useState();
  const [fscore, setFscore] = useState('false');
  const [userAnswer, setUserAnswer] = useState([]);
  const [problemNumber, setProblemNumber] = useState(0);
  const [showPopUp, setShowPopUp] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [soundName, setSoundName] = useState('');
  const [wordSound, setWordSound] = useState('');
  const [correctCheck, setCorrectCheck] = useState([]);
  const [correctOrWrongSound, setCorrectOrWrongSound] = useState('');
  const [isShowImage, setIsShowImage] = useState(false);
  // const {loaded, myScriptEditorRef, script, onClear, error} = useMyScript();
  const [clickedSound, setClickedSound] = useState(false);
  const [answerClicked, setAnswerClicked] = useState(false);
  const [problemSolve, setProblemSolve] = useState(false);
  const [checkResult, setCheckResult] = useState(false);
  const [scrollproblemNumber, setSCrollProblemNumber] = useState(0);
  const [scrollValue, setSCrollValue] = useState(50);
  const stageNumber = Number(window.location.pathname.split('/stage/')[1]);

  const location = useLocation();
  const resquiz = location.pathname.split('/');
  const history = useHistory();

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setPopUpType('correct');
    },
  });
  const handleWriteEnd = () => {
    if (!answerClicked) {
      setAnswerClicked(true);
      const newUserAnswer = [...userAnswer];
      const userInputs = getKey && getKey instanceof Array ? getKey.join('') : '';
      newUserAnswer[problemNumber] = userInputs;
      setUserAnswer(newUserAnswer);

      let arr = _.cloneDeep(correctCheck);
      if (problem[problemNumber].word.toLowerCase() === userInputs.toLowerCase()) {
        arr[problemNumber] = true;
        setCorrectCheck(arr);
        correctCountRef.current++;
        // 칭찬 음성을 랜덤하게 가져오는 로직. lodash의 sample 메소드로 구현.
        const correctSound = _.sample([
          'https://cdn.cloubot.com/PM/audio/sound-effect/excellent.mp3',
          'https://cdn.cloubot.com/PM/audio/sound-effect/very-good.mp3',
          'https://cdn.cloubot.com/PM/audio/sound-effect/great.mp3',
        ]);
        setCorrectOrWrongSound(correctSound);
      } else {
        arr[problemNumber] = false;
        setCorrectCheck(arr);
        setCorrectOrWrongSound(wrongSound);
      }
      setIsShowImage(true);
    }
  };

  const handleCorrectWrongSoundEnd = () => {
    setCorrectOrWrongSound('');
    //setIsShowImage(false);
    setWordSound(
      `https://cdn.cloubot.com/PM/audio/sounds/${problem[problemNumber].word === 'june' ? 'June' : problem[problemNumber].word}.mp3`,
    );
  };

  const handleWordSoundEnd = () => {
    setSCrollValue(scrollValue + 50);
    setClickedSound(false);
    setWordSound('');
    if (problemNumber < problem.length - 1) {
      setGetKey([]);
      // onClear();
      setTimeout(() => {
        setProblemNumber((prev) => prev + 1);
        setAnswerClicked(false);
      }, 1000);
      setTimeout(() => {
        setSCrollProblemNumber((prev) => prev + 1);
      }, 4000);
    } else {
      //마지막 문제를 풀면 정답을 체크하고 점수를 매긴다.
      setScore(Math.round((100 * correctCountRef.current) / problem.length));

      handleCreateStudy({
        lesson_code: window.sessionStorage.getItem('lesson_code'),
        stage_no: parseInt(bookInfo.stage),
        user_idx: parseInt(userInfoVar()?.idx),
        user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
        exam_correct: correctCountRef.current,
        exam_total: problem.length,
      });
      // setTimeout(()=>{
      // setShowPopUp(true);
      // },1000)
    }
    setIsShowImage(false);
  };

  const handleGoodJobSoundEnd = () => {
    history.replace({
      pathname: `${window.location.pathname.split('/stage')[0]}`,
      search: `${window.location.search}`,
      state: { lesson: stageNumber > 5 },
    });
  };
  const handleSoundEnd = () => {
    setClickedSound(true);
  };
  useEffect(() => {
    setUserAnswer(Array(problem.length).fill(''));
    setCorrectCheck(Array(problem.length).fill(undefined));
  }, [problem]);

  useEffect(() => {
    if (!_.isEmpty(problem[problemNumber].word)) {
      setSoundName(`${problem[problemNumber].word === 'june' ? 'June' : problem[problemNumber].word}.mp3`);
    }
    // eslint-disable-next-line
  }, [problemNumber]);

  //학습목표 팝업 안 보여줌.
  //useEffect(() => setShowPopUp(true), []);

  useEffect(() => {
    if (directionEnd) {
      console.log('directionEnd');
    }
  }, [directionEnd]);

  useEffect(() => {
    if (_.isNil(getKey)) {
      setGetKey([]);
    }
  }, [getKey]);

  // useEffect(() => {
  // 	setGetKey(script);
  // }, [script]);

  const handleShowScore = (param) => {
    setFscore(param);
  };
  useEffect(() => {
    if (problemNumber === problem.length - 1 && score !== undefined) {
      setTimeout(() => {
        setShowPopUp(true);
        setProblemSolve(true);
        setPopUpType('ProblemSolvePopup');
      }, 2000);
    }
  }, [problemNumber, score]);

  useEffect(() => {
    if (fscore === 'true') {
      setShowPopUp(true);
    }
  }, [fscore]);
  useEffect(() => {
    // document.getElementById(`answer_area_scroll`).scrollTop = scrollValue;
    let scroll = (scrollproblemNumber + 1) % 5;
    if (scroll == 0) {
      document.getElementById(`answer_area_scroll`).scrollTop = scrollValue;
    }
  }, [scrollproblemNumber]);
  const handleProblemScore = (params) => {
    setCheckResult(params);
    setShowPopUp(false);
    setProblemSolve(false);
    setPopUpType('correct');
  };

  return (
    <>
      <S.ColumnFlex style={{ alignSelf: 'stretch', flex: '1 1' }} className={`main_content  stage12_content row2 active`}>
        <S.ImageSelectTopBox style={{ width: 'auto', justifyContent: 'space-around' }} className="top_box">
          <ImageSoundBox
            isShowImage={isShowImage}
            problemNumber={problemNumber}
            problem={problem}
            defaultImage={defaultImage}
            soundName={soundName}
            onSoundEnd={handleSoundEnd}
            answerClicked={answerClicked}
          />
          <S.TestScoreBox className="score_box">
            <ScoreBox score={score} />
            <UserAnswerListBox
              handleScore={handleShowScore}
              resultData={checkResult}
              correctCheck={correctCheck}
              score={score}
              userAnswer={userAnswer}
              problem={problem}
              showAnswer={popUpType !== ''}
            />
          </S.TestScoreBox>
        </S.ImageSelectTopBox>
        <S.PenKeyboardWrapMain className="bottom_box">
          <S.PenKeyboardWrap style={{ padding: '10px 0' }} className="pen_keyboard_wrap">
            {/* <SwitchButtonBox toggleDevice={handleToggle} device={false} /> */}
            <Keyboard getKey={getKey} setGetKey={setGetKey} onWriteEnd={handleWriteEnd} disableSound={answerClicked} />
          </S.PenKeyboardWrap>
        </S.PenKeyboardWrapMain>
      </S.ColumnFlex>

      {/* {!loaded &&  <PopUp popUpType="loading" />} */}
      {showPopUp ? <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
      {problemSolve ? <PopUp popUpType={popUpType} handleProblemPopup={handleProblemScore} setShowPopUp={setShowPopUp} /> : undefined}
      <SoundEffectPM url={correctOrWrongSound} onEnd={handleCorrectWrongSoundEnd} />
      <SoundEffectPM url={wordSound} onEnd={handleWordSoundEnd} />
      {(directionEnd || clickedSound) && <SoundEffectPM url={`https://cdn.cloubot.com/PM/audio/sounds/${soundName}`} />}
    </>
  );
};

export default UnitTestStage;

const ImageSoundBox = ({ isShowImage, problem, problemNumber, defaultImage, soundName, onSoundEnd, answerClicked = false }) => {
  return (
    <S.TestImgBox className="img_box">
      <img
        style={{ height: '26vh' }}
        src={isShowImage ? problem && problem[problemNumber].image : defaultImage}
        className="defaultImg"
        alt="score_box"
      />
      <SoundButton
        style={{ position: 'absolute', top: '0.6rem', right: '0.6rem' }}
        src={soundName}
        onSoundEnd={onSoundEnd}
        disableSound={answerClicked}
      />
    </S.TestImgBox>
  );
};
