import React, { useState, useEffect, useMemo } from 'react';
import { Col, Typography, Space, Checkbox, Select, Row, Button, Form, Input, Pagination, Tooltip } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { TESTS_LIST } from 'src/operations/queries/getTests';
import { useQuery } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import TestCenterMenuGroup from 'src/components/common/TestCenterMenuGroup';
import { userInfoVar } from 'src/apollo/cache';
import { isAfterSchool } from 'src/utils';

const { Title } = Typography;
const { Search } = Input;

const CONTENT_CATEGORY = {
  me: '개인',
  publisher: '본사',
};

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Android.startZoomClass(JSON.stringify(user));
  //   }
  // }
};

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const MainPage = (props) => {
  const query = new URLSearchParams(props.location.search);
  const searchPage = query.get('page');
  const isRefresh = query.get('refresh');

  const companyName = useSelector(classStoreData);
  const isSmartEclass = useMemo(() => {
    return isAfterSchool(companyName);
  }, [companyName]);

  const [tests, setTests] = useState([]);
  const [selectType, setSelectType] = useState('me');
  const [q, setQ] = useState(null);
  const [page, setPage] = useState(searchPage ? parseInt(searchPage) : 0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [checkedList, setCheckedList] = useState(new Array(tests.length).fill(false));
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      type: 'publisher',
      text: null,
      page: 0,
    },
    'we_search',
  );

  const { data, loading, refetch } = useQuery(TESTS_LIST, {
    fetchPolicy: 'no-cache',
    variables: { type: searchValue.type, q: searchValue.text, page: searchValue.page, take },
  });
  console.log('searchValue', searchValue);
  //pageSize: take, total, current: searchValue.page + 1
  useEffect(() => {
    if (data?.testsList) {
      setTests(
        data?.testsList.tests.map((item, key) => {
          return { ...item, item_no: take * searchValue.page + key + 1 };
        }),
      );
      setTotal(data?.testsList.total);
      //props.history.replace({ search: `?page=${page}` });
    }

    if (isRefresh) {
      //refetch();
      //props.history.replace({ search: '' });
    }
  }, [data, page, props.history, searchPage, isRefresh]);

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(tests.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'idx',
      key: 'No',
      align: 'center',
      width: 100,
      render: (_, { item_no }) => item_no,
    },
    {
      title: 'Date',
      dataIndex: 'idate',
      align: 'center',
      key: 'date',
      width: 150,
      render: (text, record) => {
        return <Space size="middle">{text.substr(0, 10)}</Space>;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: '분류',
      width: 100,
      dataIndex: 'category',
      key: 'category',
      align: 'center',
      render: () => CONTENT_CATEGORY[searchValue.type],
    },
    {
      title: 'Preview',
      key: '',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return (
          <Space size="middle">
            <Link to={`/test/new/${record.idx}/preview`}>
              <Button style={{ color: '#1890ff' }}>Preview</Button>
            </Link>
          </Space>
        );
      },
    },
    {
      title: 'Edit',
      key: '',
      align: 'center',
      width: 100,
      render: (text, record) => {
        const disabled = userInfoVar()?.type <= 3 && searchValue.type === 'publisher';
        return (
          <Space size="middle">
            {disabled ? (
              <Button disabled={true} style={{ color: '#1890ff' }}>
                Edit
              </Button>
            ) : (
              <Link to={`/test/manage/action/edit/${record.idx}`}>
                <Button style={{ color: '#1890ff' }}>Edit</Button>
              </Link>
            )}
          </Space>
        );
      },
    },
    {
      title: 'List 보기',
      key: '',
      align: 'center',
      render: (_, record) => {
        return (
          <Tooltip title="시험지 배정하기">
            <Space size="middle">
              <Link to={{ pathname: `/test/manage/action/assign/${record.idx}`, state: { title: record.title } }}>
                <Button style={{ color: '#1890ff' }}>Assign</Button>
              </Link>
            </Space>
          </Tooltip>
        );
      },
      width: 100,
    },
    {
      title: () => {
        return <Checkbox onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'right',
      key: 'action',
      render: (text, record, idx) => {
        const disabled = userInfoVar()?.type <= 3 && searchValue.type === 'publisher';
        return (
          <Space size="middle">
            <Checkbox disabled={disabled} checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
  ];

  const handleTestDelete = () => {
    const astDelete = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    const idxs = tests.filter((s, i) => astDelete.includes(`${i}`)).map((f) => f.idx);
    props.onTestDelete({ idxs });
    setTimeout(() => {
      setCheckedList([]);
      refetch();
    }, 1000);
  };

  function handleTableChange(pagination, filters) {
    console.log('pagination', pagination);
    console.log('filters', filters);
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }
  return (
    <>
      {isSmartEclass && userInfoVar()?.type > 3 ? (
        <div style={{ padding: '10px 20px 0', margin: 0 }}>
          <Title level={3} style={{ margin: 0 }}>
            Test Center
          </Title>
        </div>
      ) : (
        <TestCenterMenuGroup currentMenu="test-management"/>
      )}
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testmanagement-mailblock">
          <Row gutter={[24, 8]} style={{ margin: 0 }}>
            <Col className="contenttest-title" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>
                Test Management
                {isSmartEclass && (
                  <span style={{ fontSize: '0.6em', fontWeight: 'normal' }}>
                    {' '}
                    &nbsp; * 시험지를 등록, 배정하면 온라인으로 응시할 수 있습니다.
                  </span>
                )}
              </Title>
            </Col>
            <Col
              className="contenttest-headitems"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <span style={{ textAlign: 'center', display: 'flex', alignItems: 'center', marginRight: '10px', height: '32px' }}>
                Folder:
              </span>
              <Select
                style={{ width: 150, color: '#065aa9' }}
                defaultValue={searchValue.type}
                placeholder="Select a folder"
                optionFilterProp="children"
                //onChange={(val) => setSelectType(val)}
                onChange={(val) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      type: val,
                    };
                  })
                }
              >
                <Select.Option value="publisher">본사 컨텐츠</Select.Option>
                {!isSmartEclass && <Select.Option value="campus">학원 컨텐츠</Select.Option>}
                <Select.Option value="me">개인 컨텐츠</Select.Option>
              </Select>
              <Search
                className="headitems-search"
                placeholder="Input search text"
                //onSearch={(val) => setQ(val)}
                defaultValue={searchValue.text}
                onSearch={(val) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      text: val,
                    };
                  })
                }
                allowClear
                style={{ width: 250, paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, height: 32 }}
              />

              <Link to="/test/manage/action/add" className="headitems-add">
                <Button style={{ color: '#289428' }}>Add +</Button>
              </Link>
              <Button className="testmanagmentdelete-btn" danger onClick={handleTestDelete}>
                Delete
              </Button>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            loading={loading}
            dataSource={tests}
            columns={columns}
            bordered
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={{ pageSize: take, total, current: searchValue.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            //onChange={(a) => setPage(a.current - 1)}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => onHandleRowClick(record, rowIndex),
            //   };
            // }}
            onChange={handleTableChange}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default withRouter(MainPage);
