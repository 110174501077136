import React, {useMemo} from "react";
import _ from "lodash";
import styled from "styled-components";

const StageProgressBox = ({item, bookName, unit, data, lesson}) => {
	const stageCount = Object.keys(item.stage).length / 2;
	// console.log("StageProgressBox", bookName, unit, data, lesson);
	const performInfo = useMemo(() => {
		if (!_.isEmpty(data)) {
			const sum = data.reduce((acc, cur) => {
				if (_.toNumber(cur.unit) === _.toNumber(unit)) {
					if (lesson === 1) {
						return stageCount >= _.toNumber(cur.stage) ? acc + 1 : acc;
					} else {
						return stageCount < _.toNumber(cur.stage) ? acc + 1 : acc;
					}
				} else if (cur.unit === unit) {
					if (lesson === 1) {
						return stageCount >= _.toNumber(cur.stage) ? acc + 1 : acc;
					} else {
						return stageCount < _.toNumber(cur.stage) ? acc + 1 : acc;
					}
				}
				return acc;
			}, 0);
			return [sum, Math.round((100 * sum) / stageCount)];
		}
		return [0, 0];
		// eslint-disable-next-line
	}, [data, unit, data, lesson]);
	// console.log(performInfo,unit,lesson)
	return (
		<Wrapper>
			<dt>Stage</dt>
			<BoxWrapper>
				<Graph className="graph">
					{_.map(Array(stageCount), (__, key) => (
						<Box key={`graph-${key}`} active={performInfo[0] > key} className={`${performInfo[0] > key ? "active" : ""}`} />
					))}
				</Graph>
				<Percent className="per">{`${performInfo[1]}%`}</Percent>
			</BoxWrapper>
		</Wrapper>
	);
};

export default StageProgressBox;

const Wrapper = styled.dl`
    display: flex;
    font-size: 17px;
	margin: 0;
    padding: 0;
    /* margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate; */
`;
const BoxWrapper = styled.dd`
    display: flex;
    flex: 1 1;
	margin: 0;
    padding: 0;
    /* margin-inline-start: 40px; */
    unicode-bidi: isolate;
`;
const Graph = styled.ul`
    display: flex;
    align-items: center;
    flex: 1 1;
    margin: 0 calc(calc(8vh / 768) * 100);
	list-style: none;
	padding: 0;
	max-width: 461px;
`;
const Box = styled.li`
    flex: 1 1;
    height: 12px;
    border-radius: 3px;
	list-style: none;
	margin: 0;
    padding: 0;
    background-color: ${(props)=>props.active?'#00d2d3':'#e5e5e5'};
	margin-left: calc(calc(4vh / 768) * 100);
`;
const Percent = styled.p`
    margin: 0;
    padding: 0;
	max-width: 55px;
`;