import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import _ from 'lodash';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import SoundButton from './SoundButton';
import AlphabetDroppableBox from './AlphabetDroppableBox';
import AlphabetDraggableBox from './AlphabetDraggableBox';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const AlphabetDnDContextArea = ({ letters, soundBaseUrl, onDragFinish }) => {
  const [dropSound, setDropSound] = useState(undefined);
  const [eachSound, setEachSound] = useState(undefined);
  const [dropped, setDropped] = useState(Array(letters.length).fill(false));

  const [droppedIdx, setDroppedIdx] = useState(-1);

  const [underAlphabet, setUnderAlphabet] = useState(letters);
  const handleDragEnd = useCallback(
    (result) => {
      const { destination } = result;
      if (_.isNil(destination) || destination.droppableId.startsWith('under_')) {
        setDropSound('wrong_sound1');
        return;
      }
      setDropSound('mouse-click');

      const droppedWord = destination.droppableId.split('_')[1];

      const copy2 = _.cloneDeep(underAlphabet);
      console.log('droppedWord', droppedWord);
      copy2.splice(copy2.indexOf(droppedWord), 1);
      console.log('copy2', copy2);
      setUnderAlphabet(copy2);

      const copy = _.cloneDeep(dropped);
      const copyLetters = _.cloneDeep(letters);
      copy[copyLetters.indexOf(droppedWord)] = true;
      console.log('copy', copy);
      setDropped(copy);
      setDroppedIdx(copyLetters.indexOf(droppedWord));
    },
    [dropped, underAlphabet, letters],
  );

  const handleDropSoundEnd = () => {
    setDropSound(undefined);
  };

  const handleEachSoundEnd = () => {
    console.log('handleEachSoundEnd');
    if (dropped && dropped.every((v) => v === true)) {
      onDragFinish();
    }
    setEachSound(undefined);
  };

  useEffect(() => {
    if (droppedIdx !== -1) {
      setEachSound(`${soundBaseUrl}${letters[droppedIdx]}.mp3`);
    }
  }, [droppedIdx]);

  useEffect(() => {
    console.log('여기', letters);
    if (letters && letters.length > 0) {
      setDropped(Array(letters.length).fill(false));
    }
    setUnderAlphabet(letters);
    setDroppedIdx(-1);
  }, [letters]);
  const isAllDropped = !dropped.includes(false);
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <AlphabetWrap className="alphabet_wrap">
          {letters &&
            letters.map((item, key) => (
              <li key={`droppable-letters-key-${key}`}>
                <S.RowFlex style={{ padding: '10px 0', justifyContent: 'center' }} className="button_box">
                  {!isAllDropped && <SoundButton src={`${soundBaseUrl}${item}.mp3`} />}
                </S.RowFlex>
                <AlphabetDroppableBox letter={item} isDropped={dropped && dropped[key]} />
              </li>
            ))}
        </AlphabetWrap>
        <img
          style={{ height: '40px', marginBottom: '1vh' }}
          src="/images/pm/i_arrow_top.svg"
          alt="위로 향하는 화살표"
          className="top_arrow"
        />
        <S.AlphabetBottomWrapUl borderTop={true} className="alphabet_wrap gray_bg_border_top">
          {underAlphabet &&
            underAlphabet.length > 0 &&
            underAlphabet.map((item, key) => <AlphabetDraggableBox letter={item} idx={key} key={`draggable-letter-key-${key}`} />)}
        </S.AlphabetBottomWrapUl>
      </DragDropContext>
      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
      {eachSound && <SoundEffectPM url={eachSound} onEnd={handleEachSoundEnd} />}
    </>
  );
};

export default AlphabetDnDContextArea;
const AlphabetWrap = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  flex: 1 1;
  padding: 10px;
  margin: 0;
`;
