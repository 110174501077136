import React, { useState } from 'react';
import _ from 'lodash';
import SentenceSoundBox from './SentenceSoundBox';
import WritePopup from './WritePopup';
import PageNum from './PageNum';
// import {useMyScript} from "./MyScriptHook";
import { useParams } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
const ImageSoundWrite = ({ problem, onFinish, steps, setSteps }) => {
  const stepCount = steps >= problem.length ? problem.length - 1 : steps;
  const [showWritePopUp, setShowWritePopUp] = useState(false);
  // const {myScriptEditorRef, script, onClear, error} = useMyScript();
  const [sentenceSoundBoxKey, setSentenceSoundBoxKey] = useState(0);
  const { bookName, unit, stage } = useParams();
  let textInPink = '';
  const handleCorrectSelect = () => {
    setShowWritePopUp(true);
  };
  const handleCorrectWriteEnd = () => {
    // onClear();
    setShowWritePopUp(false);
    if (!_.isNil(problem[steps + 1])) {
      setSteps(steps + 1);
    } else {
      onFinish();
    }
  };
  const handlePopupClose = () => {
    // onClear();
    setSentenceSoundBoxKey(sentenceSoundBoxKey + 1);
    setShowWritePopUp(false);
  };
  if (stage == 9 && ['3a', '3b'].includes(bookName)) {
    textInPink = problem[stepCount].correctWord.substr(problem[stepCount].correctWord.length - 3);
  }
  if (stage == 9 && ['3a', '3b'].includes(bookName) && unit == 9) {
    textInPink = 'e';
  }
  return (
    <>
      <S.ColumnFlex style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }} className="main_content stage9_content row2">
        <S.ImageSelectTopBox className="top_box" style={{ position: 'relative' }}>
          <PageNum
            num={steps + 1}
            total={problem && problem.length}
            style={{ position: 'absolute', top: '10px', right: '10px', fontWeight: 'bold', fontSize: '1.3rem' }}
          />
          <img style={{ height: '30vh' }} src={problem[stepCount].image} alt="보기 이미지" />
        </S.ImageSelectTopBox>
        <S.ImageSelectBottomBox className="bottom_box">
          {!showWritePopUp && (
            <SentenceSoundBox
              key={`sentence-sound-box-key${sentenceSoundBoxKey}`}
              problem={problem[stepCount]}
              onCorrectSelect={handleCorrectSelect}
            />
          )}
        </S.ImageSelectBottomBox>
      </S.ColumnFlex>
      {showWritePopUp && (
        <WritePopup
          word={problem[stepCount].correctWord}
          userScript={{}}
          showWritePopUp={showWritePopUp}
          editorRef={{}}
          onClear={{}}
          onCorrectEnd={handleCorrectWriteEnd}
          onClose={handlePopupClose}
          textInPink={textInPink}
          error={{}}
        />
      )}
    </>
  );
};

export default ImageSoundWrite;
