import React, { useState } from 'react';
import SwitchArea from './SwitchArea';
import PopUp from './PopUp';
import ImageWordBox from './ImageWordBox';
import PageNum from './PageNum';
import { useLocation } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';

const ImageWritePattern = ({ userInputWord, hint, steps, setSteps, problem, onFinish, onWriteEnd }) => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const res = location.pathname.split('/');

  return (
    <>
      <S.ColumnFlex
        style={{ flex: '1 1', alignSelf: 'stretch', height: 'calc(100% - 70px)' }}
        className={
          res[2] === '3a' && res[6] == '6' ? 'main_content  stage6_content  row2 look-n-write-popup' : 'main_content  stage6_content  row2'
        }
      >
        <PageNum num={steps + 1} total={problem && problem.length} />

        <S.ImageSelectTopBox className="top_box">
          <ImageWordBox userInputWord={userInputWord} problem={problem} onFinish={onFinish} steps={steps} setSteps={setSteps} hint={hint} />
        </S.ImageSelectTopBox>
        <BottomBox className="bottom_box">
          <SwitchArea
            onWriteEnd={onWriteEnd}
            steps={steps}
            onScriptLoaded={() => {
              setIsLoading(false);
            }}
          />
        </BottomBox>
      </S.ColumnFlex>
      {/* {isLoading && <PopUp popUpType="loading" />} */}
    </>
  );
};

export default ImageWritePattern;
const BottomBox = styled(S.Dnd4BottomBox)`
  align-items: center;
  
`;
