import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import Awesome from './Awesome';
import Evine from './Evine';
import EvineW from './EvineW';
import EvineG from './EvineG';
import SmartEclass from './SmartEclass';
import Creo from './Creo';
import IntoReading from './IntoReading';
import { isAfterSchool,isPturn } from 'src/utils';

const AVAILABLE_BOOK_CODES = { AE: Awesome, EVN: Evine, EVNW: EvineW, EVNG: EvineG, SM: SmartEclass, CR: Creo, ELT: IntoReading }; //이북 추가할 때 여기에 추가하면 됨.

const Ebook = () => {
  const params = useParams();

  const companyName = useSelector(classStoreData);

  const Component = useMemo(() => {
    let rootbookcode = params.rootbookcode;
    if (isPturn(companyName)||companyName==='educosneakers') {
      // 이건 좀 생각해봐야겠다..
      rootbookcode = 'SM';
    }

    if (companyName === 'intoreading') {
      rootbookcode = 'ELT';
    }

    if (rootbookcode && Object.keys(AVAILABLE_BOOK_CODES).includes(rootbookcode)) {
      return AVAILABLE_BOOK_CODES[rootbookcode];
    }
    return undefined;
  }, [params, companyName]);

  if (Component) {
    return <Component />;
  }

  return <h2>Not Found</h2>;
};

export default Ebook;
