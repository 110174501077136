import React, { useState, useEffect, useMemo } from 'react';
import { Form, Select, Radio, message, Button as AButton, InputNumber } from 'antd';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { OPENAI_COMPLETION_QUERY } from 'src/operations/queries/message';
import { AI_TOPIC_USER_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import { UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import styled from 'styled-components';
import { CloseOutlined, InfoCircleFilled, RedoOutlined } from '@ant-design/icons';
import { userInfoVar } from 'src/apollo/cache';

const RUBRIC_REVISE = `Can you rewrite this writing with very easy words in polite way with smooth transitions
without showing corrections and explanations with the difficulty level of ((level)) "((topic))"?
Translate in English and ((language))`;

const AUTO_GENERATE = `Give me rubric(ideas,organization,voice,word_choice,conventions) out of 20 with 5 interval and generate toefl score out of 30 for this writing "((response))"
only return score and nothing else and all scores should be returned as #type:score
Example:
#word_choice:5
#conventions:10
#voice:15
#ideas:20
#organization:15
#toefl:20`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.level ? '#453dd0' : '#ffda00')};
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const StageTitle = styled.div`
  /* border-bottom: 1px solid #4472c4; */
  width: 100%;
  color: #453dd0;
  font-size: 32px;
  font-weight: bold;
  padding: 15px 30px;
  background-color: #f3db4c;
  @media (max-width: 1280px) {
    font-size: 25px;
  }
`;
const StageTitleSpan = styled.span`
  color: '#00b0f0';
  font-size: 25px;
  padding-left: 50px;
  @media (max-width: 1280px) {
    font-size: 20px;
    padding-left: 40px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #2f5597;
  padding: 5px 10px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  padding: 0 45px 15px 45px;
  align-items: center;
`;
const Main = styled.div`
  height: calc(100vh - 145px);
  /* width: 80%; */
  border-radius: 13px;
  padding: 0 0px 25px;
  background-color: #ffffff;
  /* max-width: 1650px; */
  width: 1350px;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
  @media (max-width: 1280px) {
    /* width: 95%; */
    width: 1200px;
  }
  @media (max-width: 1200px) {
    /* width: 95%; */
    width: 1000px;
  }
`;
const FullBody = styled(RowFlex)`
  height: calc(100vh - 212px);
  padding: 15px 20px;
`;
const HalfBody = styled(ColumnFlex)`
  width: 50%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-height: 95%;
`;
const ReportInput = styled.div`
  flex: 1;
  border: 2px #00b0f0 solid;
  height: 100%;
  width: 100%;
  padding: 10px 20px;
  font-size: 15px;
  line-height: 25px;
  font-weight: bold;
  color: #797979;
  flex: 1;
  background-color: white;
  border-radius: 10px;
  overflow: auto;
`;
const NumberBox = styled.div`
  width: 100px;
  height: 30px;
  border-radius: 5px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-weight: normal;
  background-color: #fcf5cc;
`;
const LVSelect = styled(Select)`
  font-size: 14px;
  font-weight: bold;
  /* border: 1px #5b9ddb solid; */
  color: black;
  padding: 0 5px;
  width: 130px;
  height: 30px;
  border-radius: 5px;
  background-color: #fcf5cc;
  .ant-select-arrow {
    color: #ffc000;
    font-size: 18px;
  }
  ::placeholder {
    color: black;
  }
  @media (max-width: 1720px) {
    width: 150px;
    font-size: 12px;
  }
  @media (max-width: 1280px) {
    width: 130px;
    font-size: 12px;
  }
`;
const LVOption = styled(Select.Option)`
  font-size: 14px;
  font-weight: bold;
`;
const ScoreBox = styled(ColumnFlex)`
  color: #000000;
  align-items: center;
  font-weight: 600;
  border: 2px #f3db4c solid;
  border-radius: 15px;
  width: 100%;
  font-size: 25px;
  /* padding-bottom: 10px; */
  height: 90%;
  background-color: #fefbf4;
`;
const ScoreHead = styled(RowFlex)`
  background-color: #f3db4c;
  border: 3px #f3db4c solid;
  text-align: center;
  color: #453ed0;
  width: 101%;
  font-style: 20px;
  padding: 10px 15px;
  border-radius: 15px;
  margin-top: -2px;
  justify-content: space-between;
`;
const ScoreRow = styled(RowFlex)`
  padding: 0 22px;
  /* width: 90%; */
`;
const ScoreSubTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  line-height: 30px;
  text-align: start;
  width: 100%;
  padding: 30px 50px 20px;
  @media (max-width: 1280px) {
    font-size: 18px;
    line-height: 24px;
    padding: 15px 20px;
  }
`;
const ScoreFormBox = styled(ColumnFlex)`
  width: 100%;
  height: 100%;
  align-items: center;
`;
const ScoreDiv = styled(RowFlex)`
  height: ${(props) => (props.head ? '40px' : '60px')};
  border: 1px solid #fbf1ba;
  background-color: ${(props) => (props.head ? '#f2f2f2' : '#fff')};
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 13px;
    height: ${(props) => (props.head ? '30px' : '60px')};
  }
`;
const ScoreRank = styled(ScoreDiv)`
  width: 60px;
  min-width: 60px;
  @media (max-width: 1200px) {
    width: 50px;
    min-width: 50px;
  }
`;
const ScoreDescription = styled(ScoreDiv)`
  width: 350px;
  min-width: 350px;
  line-height: 1.2;
  /* justify-content: left; */
  padding: 10px;
  @media (max-width: 1280px) {
    width: 272px;
    min-width: 272px;
    padding: 5px;
  }
  @media (max-width: 1200px) {
    width: 222px;
    min-width: 222px;
    line-height: 1.1;
  }
`;
const ScorePoint = styled(ScoreDiv)`
  width: 100px;
  min-width: 100px;
  @media (max-width: 1200px) {
    width: 75px;
    min-width: 75px;
  }
`;
const ScoreInputArea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 20px;
  font-size: 12px;
  line-height: 22px;
  color: #333232;
  font-weight: 600;
  border: 1px #ffb600 solid;
  height: 28vh;
  overflow-y: scroll;
  ::placeholder {
    color: black;
    font-size: 15px;
  }
  :focus {
    border: 1px #ffb600 solid;
    box-shadow: none;
  }
  @media (max-width: 1280px) {
    height: 26vh;
  }
`;
const Button = styled(AButton)`
  cursor: pointer;
  border-radius: 20px;
  font-size: 1.1em;
  line-height: 2.3em;
  height: 2.3em;
  font-weight: bold;
  color: #fff;
  background: #ffc615;
  padding: 0px 30px;
  @media (max-width: 1280px) {
    padding: 0px 20px;
    font-size: 1.1em;
    line-height: 2.3em;
    /* padding: 8px 15px; */
  }
`;
const SaveButton = styled(Button)`
  cursor: pointer;
  border-radius: 1.1em;
  font-size: 1.4em;
  line-height: 2.2em;
  height: 2.2em;
  font-weight: bold;
  background-color: #6fbdb4;
  color: #fff;
  padding: 0px 30px;
  @media (max-width: 1280px) {
    padding: 0px 25px;
    font-size: 1.3em;
    /* line-height: 2.4em; */
    /* padding: 8px 15px; */
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #6fbdb4;
    color: #fff;
  }
`;
const StageButton = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 19px;
  line-height: 35px;
  text-align: center;
  background-color: ${(props) => (props.active ? '#ffc615' : '#f3db4c')};
  color: ${(props) => (props.active ? '#fff' : '#c55a11')};
  margin: 7px;
  cursor: pointer;
`;
const ToeflInput = styled(InputNumber)`
  width: 40px;
`;
const MenuButton = styled(AButton)`
  padding-left: 20px;
  padding-right: 20px;
  font-weight: normal;
  /* .ant-btn 
    :hover{
  font-weight: normal;} */
`;

const stageList = ['Ideas', 'Organization', 'Voice', 'Word Choice', 'Conventions', 'Comments'];
const StageMapAuto = {
  ideas: 'Ideas',
  organization: 'Organization',
  voice: 'Voice',
  word_choice: 'Word Choice',
  conventions: 'Conventions',
};
const scores = {
  Ideas: [
    {
      rank: 'a',
      point: 20,
      text: `Focus on accurate and relevant details; original, creative`,
      desc: `You have used interesting ideas and well explained in detail in your writing, and your writing stayed on the topic.`,
    },
    {
      rank: 'b',
      point: 15,
      text: `Addresses ideas and details in broader terms, Not clear yet.`,
      desc: `The writing has some ideas and details, but it does not have specific details to understand.`,
    },
    {
      rank: 'c',
      point: 10,
      text: `Basic ideas listed / not detailed`,
      desc: `It shows basic ideas listed but not in details.`,
    },
    { rank: 'd', point: 5, text: `Just ideas and no details`, desc: `It doesn’t have any new ideas nor the details to understand` },
  ],
  Organization: [
    {
      rank: 'a',
      point: 20,
      text: `Explained well in logical order, predictable, smooth transition.`,
      desc: `The explanation was well-organized and easy to follow.`,
    },
    {
      rank: 'b',
      point: 15,
      text: `Ideas and details are well organized but less smooth in transitions and unnecessary ideas and details.`,
      desc: `There are some unnecessary ideas and details, but it has transitions to make it smooth.`,
    },
    {
      rank: 'c',
      point: 10,
      text: `Ideas and details not well organized. Transition does not connect well.`,
      desc: `The writing has ideas and details, but it is not organized so it is difficult to understand.`,
    },
    {
      rank: 'd',
      point: 5,
      text: `It does not have logical structure nor No transitions.`,
      desc: `The writing is not organized that it is difficult to understand.`,
    },
  ],
  Voice: [
    {
      rank: 'a',
      point: 20,
      text: `Connect to audience clearly, Clear delivery.`,
      desc: `It kept the reader on focus and delivered the ideas clearly in interesting way.`,
    },
    {
      rank: 'b',
      point: 15,
      text: `Approaches audience in more general terms; not focused on the topic sometimes.`,
      desc: `The specific details are not clear, but the general ideas is, and it needs style.`,
    },
    {
      rank: 'c',
      point: 10,
      text: `Not focused on the main idea, not connected to the audience.`,
      desc: `The writing is not connected to the main idea and nothing special or no style of writing.`,
    },
    {
      rank: 'd',
      point: 5,
      text: `Can’t understand the main point.`,
      desc: `We cannot find any style or emotional connection to the main idea.`,
    },
  ],
  'Word Choice': [
    {
      rank: 'a',
      point: 20,
      text: `Words specific, powerful, and engaging Using difficult words for their level.`,
      desc: `The writer used appropriate words to deliver the message.`,
    },
    {
      rank: 'b',
      point: 15,
      text: `Using powerful and specific words, but sometimes doesn’t fit in.`,
      desc: `The writer used appropriate words that are difficult words and easy words.`,
    },
    {
      rank: 'c',
      point: 10,
      text: `Words fit in with the writing, but repeatedly using same words.`,
      desc: `The writer did not use any powerful words, and some words are not appropriate in the writing.`,
    },
    {
      rank: 'd',
      point: 5,
      text: `Only used easy words with no challenges of powerful words.`,
      desc: `The writer did not use any powerful words, and some words do not fit in with the context.`,
    },
  ],
  Conventions: [
    {
      rank: 'a',
      point: 20,
      text: `No grammar, a few punctuation, and spelling mistakes.`,
      desc: `It is well-written with barely no mistakes in grammar.`,
    },
    {
      rank: 'b',
      point: 15,
      text: `There are some grammar mistakes, punctuation, spelling mistakes.`,
      desc: `There are some grammar mistakes and some small mistakes.`,
    },
    {
      rank: 'c',
      point: 10,
      text: `Almost every sentence has a mistake.`,
      desc: `Almost every sentence has a mistake.`,
    },
    {
      rank: 'd',
      point: 5,
      text: `Difficult to understand sometimes.`,
      desc: `It is difficult to understand because too many mistakes.`,
    },
  ],
};

export default function RubricAiCoachPage() {
  const { id } = useParams();
  const { pathname } = useLocation();
  console.log(pathname.split('/').includes('level-up'), 'loc');
  const [ai_topic_user_assignments, setAssignedAITopics] = useState({ ai_request_responses: [] });
  const [revisedData, setRevisdeData] = useState({});
  const [totalScore, setTotalScore] = useState({});
  const [comment, setComment] = useState('');
  const [level, setLevel] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [toefl, setToefl] = useState(null);
  const [stage, setStage] = useState(1);
  const [language, setLanguage] = useState('Korean');
  const [currentMenu, setCurrentMenu] = useState('Original');
  const botChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type !== 'word');
  const wordChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type === 'word');
  const botCheckChat = botChats.filter((chat) => chat.data.type === 'check');
  const botReviseChat = botChats.filter((chat) => chat.data.type === 'revise');
  const botCommentChat = botChats.filter((chat) => chat.data.type === 'comment');
  const botCommentChatFull = botChats.filter((chat) => chat.data.type === 'comment-full');
  const originalFirstChat =
    botCheckChat.length > 0
      ? botCheckChat[0]
      : botReviseChat.length > 0
      ? botReviseChat[0]
      : botCommentChat.length > 0
      ? botCommentChat[0]
      : null;
  const history = useHistory();
  const [form] = Form.useForm();
  const total = Object.values(totalScore).reduce((a, b) => a + b.point, 0);
  const { data, loading } = useQuery(AI_TOPIC_USER_ASSIGNMENT_DETAILS, {
    variables: { idx: parseInt(id) },
    fetchPolicy: 'no-cache',
  });

  let toeflScore = useMemo(() => {
    if (botCommentChatFull.length > 0) {
      let tempStr = botCommentChatFull[botCommentChatFull?.length - 1]?.content;

      let toeflScore = tempStr.match(/TOEFL Score : (\d{1,2})/);
      if (toeflScore) {
        return parseInt(toeflScore[0]);
      }

      toeflScore = tempStr.match(/\d{1,2}/);

      if (toeflScore) {
        return parseInt(toeflScore[0]);
      }
    }
    return 0;
  }, [botCommentChatFull]);

  useEffect(() => {
    if (toeflScore <= 30) {
      setToefl(toeflScore > 0 ? toeflScore : 0);
    } else {
    }
  }, [toeflScore]);

  useEffect(() => {
    setComment(Object.values(totalScore).reduce((a, b) => a + `${b.desc}\n`, ''));
  }, [totalScore]);

  const [updateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });

  const [loadMoreMessages, { data: reviseData, loading: loadingRevise }] = useLazyQuery(OPENAI_COMPLETION_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [loadAutoGenerate, { data: autogenerateData, loading: loadAuto }] = useLazyQuery(OPENAI_COMPLETION_QUERY, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.aiTopicUserAssignmentDetails) {
      if (data?.aiTopicUserAssignmentDetails?.rubric_data) {
        let rubricData = JSON.parse(data?.aiTopicUserAssignmentDetails?.rubric_data);
        setTotalScore(rubricData.totalScore);
        setToefl(rubricData.toefl);
        // setComment(rubricData.comment);
        setLevel(rubricData.level);
        // setRevisdeData(rubricData.revisedData);
      }
      setAssignedAITopics({
        ...data?.aiTopicUserAssignmentDetails,
        start_date: data?.aiTopicUserAssignmentDetails?.start_time,
        ai_request_responses: data?.aiTopicUserAssignmentDetails.ai_request_responses.map((item) => {
          return { ...item, promt: item.prompt_text, content: item.completion_text, data: JSON.parse(item.data), created: item.idate };
        }),
      });
    }
  }, [data]);

  useEffect(() => {
    if (autogenerateData?.getOpenAiCompletion?.text.length > 0) {
      let temp = autogenerateData?.getOpenAiCompletion?.text.split('#');
      let tempScore = {};
      let tempToefl = 0;
      temp.forEach((item) => {
        if (item.length === 0) return;
        let tempItem = item.split(':');
        if (tempItem[0] === 'toefl' || tempItem[0] === 'toefl_score') {
          tempToefl = parseInt(tempItem[1]);
        } else {
          let scorePoint = parseInt(tempItem[1]);
          if (scorePoint % 5 !== 0 || scorePoint > 20) {
            scorePoint = 5;
          }
          let scoreIdex = scores[StageMapAuto[tempItem[0]]].findIndex((e) => e.point === parseInt(scorePoint));
          tempScore[StageMapAuto[tempItem[0]]] = { ...scores[StageMapAuto[tempItem[0]]][scoreIdex], indx: scoreIdex };
        }
      });
      setTotalScore(tempScore);
      setToefl(tempToefl);
      let tempComment = Object.values(tempScore).reduce((a, b) => a + `${b.desc}\n`, '');
      setComment(tempComment);
      onRevise(tempComment);
    }
  }, [autogenerateData]);

  useEffect(() => {
    if (ai_topic_user_assignments.ai_request_responses.length > 0) {
      let resChats = [];
      ai_topic_user_assignments.ai_request_responses.forEach((item) => {
        resChats.push({
          user: ai_topic_user_assignments?.user?.id,
          chat_user_name: ai_topic_user_assignments?.user?.name,
          content: item.prompt_text,
          urls: [],
          created: item.idate,
          data: item?.data,
        });
        resChats.push({
          user: 'bot',
          chat_user_name: 'AI봇',
          content: item.completion_text,
          urls: item.image_urls ? JSON.parse(item.image_urls) : [],
          created: item.idate,
          data: item?.data,
        });
      });
      setChatList(resChats);
      setLevel(ai_topic_user_assignments.ai_request_responses[0]?.data?.level);
    }
  }, [ai_topic_user_assignments]);

  useEffect(() => {
    if (reviseData?.getOpenAiCompletion?.text.length > 0) {
      setRevisdeData({
        ...reviseData,
        text: reviseData?.getOpenAiCompletion?.text,
      });
    }
  }, [reviseData]);

  const getReportText = (type) => {
    if (ai_topic_user_assignments.ai_request_responses.length === 0) return '';
    switch (type) {
      case 'Original':
        return (
          originalFirstChat?.data?.currentText.split('\n').map((line, index) => {
            return (
              <span key={index}>
                {line}
                <br />
              </span>
            );
          }) || ''
        );
      case 'Check':
        return botCheckChat[botCheckChat?.length - 1]?.content.split('\n').map((line, index) => {
          return (
            <span key={index}>
              {line}
              <br />
            </span>
          );
        });
      case 'Final Draft':
        return ai_topic_user_assignments.answer.split('\n').map((line, index) => {
          return (
            <span key={index}>
              {line}
              <br />
            </span>
          );
        });
      case 'Comments':
        if (botCommentChatFull.length > 0) {
          return botCommentChatFull[botCommentChatFull?.length - 1]?.content.split('\n').map((line, index) => {
            return (
              <span key={index}>
                {line}
                <br />
              </span>
            );
          });
        } else {
          return botCommentChat[botCommentChat?.length - 1]?.content.split('\n').map((line, index) => {
            return (
              <span key={index}>
                {line}
                <br />
              </span>
            );
          });
        }

      default:
        return '';
    }
  };

  const handleScoreChange = (e, type) => {
    setTotalScore({ ...totalScore, [type]: { ...scores[type][e.target.value], indx: e.target.value } });
  };

  const handleSave = async () => {
    const data = {
      totalScore: totalScore,
      score: total,
      toefl: toefl,
      comment: comment,
      level: level,
      revisedData: revisedData,
    };
    await updateAITopicUserAssignment({
      variables: {
        idx: parseInt(id),
        rubric_data: JSON.stringify(data),
      },
    });
    history.goBack();
  };

  const onRevise = (tempComment) => {
    if (comment.length === 0 && !tempComment) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempText = tempComment || comment;
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: RUBRIC_REVISE.replaceAll('((level))', level).replaceAll('((topic))', tempText).replaceAll('((language))', language),
        top_p: 1,
        // stop: ['\n', ' '],
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          topic: ai_topic_user_assignments?.ai_topic?.topic || '',
          type: 'revise-teacher',
          content: tempText,
        }),
      },
    });
  };

  const onAutoGenerate = () => {
    if (ai_topic_user_assignments.ai_request_responses.length === 0) {
      message.error('No data to generate rubric');
      return;
    }
    loadAutoGenerate({
      variables: {
        prompt: AUTO_GENERATE.replaceAll('((response))', ai_topic_user_assignments?.answer),
        top_p: 1,
        // stop: ['\n', ' '],
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: ai_topic_user_assignments.ai_request_responses[0]?.data?.level,
          topic: ai_topic_user_assignments?.ai_topic?.topic || '',
          type: 'rubric',
          content: ai_topic_user_assignments?.answer,
        }),
      },
    });
  };

  return (
    <Wrapper level={pathname?.split('/').includes('level-up')}>
      <MainWrapper>
        <Main>
          <StageTitle>
            {pathname?.split('/').includes('level-up') ? 'BOOK REPORT' : ' AI Writing Coach'}
            <StageTitleSpan>
              <span style={{ color: '#262626' }}>Rubric AI Coach</span>
            </StageTitleSpan>
            <CloseOutlined
              style={{ fontSize: 20, color: '#262626', cursor: 'pointer', float: 'right' }}
              onClick={() => {
                history.goBack();
              }}
            />
          </StageTitle>
          <FullBody>
            <HalfBody>
              <RowFlex style={{ width: '100%', justifyContent: 'left', paddingTop: 10, paddingBottom: 0, marginLeft: 20 }}>
                {['Original', 'Check', 'Final Draft', 'Comments'].map((e) => (
                  <MenuButton
                    key={`menu-${e}`}
                    style={{ marginRight: 5 }}
                    type={`${currentMenu === e ? 'primary' : 'default'} `}
                    onClick={() => {
                      if (currentMenu !== e) {
                        setCurrentMenu(e);
                      }
                    }}
                  >
                    {e}
                  </MenuButton>
                ))}
              </RowFlex>
              <ReportInput>{getReportText(currentMenu)}</ReportInput>
            </HalfBody>
            <HalfBody>
              <RowFlex
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                  marginBottom: '10px',
                  alignItems: 'center',
                  padding: '0 15px',
                }}
              >
                <Title style={{ color: '#453ed0' }}>Rubric</Title>
                <RedoOutlined style={{ padding: '3px 10px 0', color: '#4976d2', fontSize: '20px', fontWeight: 'bold' }} />
              </RowFlex>
              <ScoreBox>
                <ScoreHead>
                  <div style={{ color: '#ffa500', fontSize: '30px' }}>
                    <InfoCircleFilled />
                  </div>
                  <SaveButton onClick={onAutoGenerate} loading={loadAuto} style={{ fontSize: '15px', padding: '0 10px' }}>
                    Auto Generate
                  </SaveButton>
                  <RowFlex>
                    Score:
                    <NumberBox>{total}</NumberBox>
                    <LVSelect
                      dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
                      placeholder={` Language `}
                      onChange={(e) => {
                        setLanguage(e);
                      }}
                      value={language}
                      bordered={false}
                    >
                      {[
                        {
                          value: 'Korean',
                          label: `Korean`,
                        },
                        {
                          value: 'Taiwanese',
                          label: `Taiwanese`,
                        },
                        {
                          value: 'Japanese',
                          label: `Japanese`,
                        },
                        {
                          value: 'Spanish',
                          label: `Spanish`,
                        },
                      ].map((e) => (
                        <LVOption key={e.value} value={e.value}>
                          {e.label}
                        </LVOption>
                      ))}
                    </LVSelect>
                  </RowFlex>
                </ScoreHead>
                <ScoreSubTitle>
                  {stageList[stage - 1]} {stage !== 6 && '(20)'}
                </ScoreSubTitle>
                <ScoreFormBox>
                  <Form style={{ width: '100%' }} name="control-ref" form={form} onFinish={{}}>
                    {stageList.map(
                      (e, i) =>
                        stage === i + 1 &&
                        stage !== 6 && (
                          <div
                            key={`${e}-${i}`}
                            name={e.toLowerCase()}
                            rules={[
                              {
                                required: true,
                                message: '점수를 선택해 주세요.',
                              },
                            ]}
                          >
                            <ScoreRow>
                              <ScoreRank head></ScoreRank>
                              <ScoreDescription head>Description</ScoreDescription>
                              <ScorePoint head>Points</ScorePoint>
                              <ScoreRank head></ScoreRank>
                            </ScoreRow>
                            <ScoreRow>
                              <ColumnFlex>
                                {scores[e].map((option) => (
                                  <RowFlex key={`${e}-${option.rank}`}>
                                    <ScoreRank>{option.rank}</ScoreRank>
                                    <ScoreDescription>{option.text}</ScoreDescription>
                                    <ScorePoint>{option.point}/20</ScorePoint>
                                  </RowFlex>
                                ))}
                              </ColumnFlex>
                              <Radio.Group
                                name={e.toLowerCase()}
                                onChange={(et) => handleScoreChange(et, e)}
                                value={totalScore[e] ? totalScore[e].indx : null}
                              >
                                <div>
                                  {scores[e].map((option, indx) => (
                                    <ScoreRank key={`${e}-${option.rank}`}>
                                      <Radio style={{ marginLeft: '10px' }} value={indx}></Radio>
                                    </ScoreRank>
                                  ))}
                                </div>
                              </Radio.Group>
                            </ScoreRow>
                          </div>
                        ),
                    )}
                    {stage === 6 && (
                      // <ColumnFlex>
                      <ColumnFlex style={{ width: '100%', height: '100%' }}>
                        <div style={{ padding: '0 40px 10px', fontSize: '16px', color: '#7232a1' }}>
                          TOEFL Score :{' '}
                          <ToeflInput
                            min={0}
                            max={30}
                            controls={false}
                            value={toefl}
                            onChange={(value) => {
                              setToefl(value);
                            }}
                          />{' '}
                          <span style={{ color: '#6fbdb4' }}> / 30 </span>
                        </div>
                        <div
                          style={{ padding: '0 40px', height: '100%', width: '100%' }}
                          name={stageList[5].toLowerCase()}
                          rules={[
                            {
                              required: true,
                              message: 'Comment를 입력해 주세요.',
                            },
                          ]}
                        >
                          <ScoreInputArea
                            value={revisedData?.text || comment}
                            onChange={(e) => {
                              if (!revisedData?.text) {
                                setComment(e.target.value);
                              }
                            }}
                          />
                          <RowFlex style={{ justifyContent: 'center', marginTop: '12px', alignItems: 'center' }}>
                            <Button onClick={() => onRevise()} loading={loadingRevise}>
                              Revise
                            </Button>
                          </RowFlex>
                        </div>
                      </ColumnFlex>
                    )}
                  </Form>
                </ScoreFormBox>
                <RowFlex style={{ justifyContent: 'space-between', paddingBottom: '5px' }}>
                  {[1, 2, 3, 4, 5, 6].map((e) => (
                    <StageButton
                      key={`stage${e}`}
                      active={stage === e}
                      onClick={() => {
                        setStage(e);
                      }}
                    >
                      {e}
                    </StageButton>
                  ))}
                </RowFlex>
              </ScoreBox>
              <RowFlex style={{ justifyContent: 'center', marginTop: '5px', alignItems: 'center', padding: '10px 10px 0' }}>
                <SaveButton onClick={handleSave}>&nbsp;Save&nbsp;</SaveButton>&nbsp;&nbsp;
                {/* <FilePdfOutlined style={{fontSize:'25px'}}/> */}
              </RowFlex>
            </HalfBody>
          </FullBody>
        </Main>
      </MainWrapper>
    </Wrapper>
  );
}
