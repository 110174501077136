import React, { useState } from 'react';
import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import { ButtonWrapper, HeaderTitle } from 'src/components/common/Styles';
import Manager from 'src/components/DashBoard/MainPage/Manager';
import SubPage from 'src/components/DashBoard/SubPage';
// import SimplePopup from 'src/popups/SimplePopup';
import { useHistory } from 'react-router-dom';
import { isAfterSchool ,isPturn} from 'src/utils';

//강사용 대시보드 메인 페이지
const ManagerPage = () => {
  const [tab, setTab] = useState('main');
  const company = useSelector(classStoreData);
  const history = useHistory();
  return (
    <>
      {/* {('cleveredu2'=== company|| 'classboom'=== company) && <SimplePopup />} */}
      {/* {'englishtap' === company && <SimplePopup />} */}
      <Row justify="space-between" className="buttonlisting-dashboard">
        <HeaderTitle level={4}>{isAfterSchool(company) ? '스터디보드' : '대시보드'}</HeaderTitle>
        <ButtonWrapper className="buttonlisting-wrap">
          {isPturn(company) && (
            <>
              <Button
                className="btn-ebook order"
                type="primary"
                size="large"
                style={{ margin: 5 }}
                onClick={() => {
                  history.push('/book/order');
                }}
              >
                교재주문
              </Button>
              <Button
                className="btn-ebook content"
                type="primary"
                size="large"
                style={{ margin: 5 }}
                onClick={() => {
                  history.push('/folder');
                }}
              >
                수업자료
              </Button>
              <Button
                className="btn-ebook"
                type="primary"
                size="large"
                style={{ margin: 5 }}
                onClick={() => {
                  history.push('/ebook/SM');
                }}
              >
                E-Book
              </Button>
            </>
          )}

          {isAfterSchool(company) ? (
            <>
              {tab === 'sub' && (
                <Button
                  className="btn-schedule"
                  type={tab === 'main' && 'primary'}
                  size="large"
                  style={{ margin: 5 }}
                  onClick={() => setTab('main')}
                >
                  Schedule
                </Button>
              )}
              {tab === 'main'  && (
                <Button className="btn-messageicon" type={tab === 'sub' && 'primary'} size="large" onClick={() => setTab('sub')}>
                  쪽지함
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                className="btn-schedule"
                type={tab === 'main' && 'primary'}
                size="large"
                style={{ margin: 5 }}
                onClick={() => setTab('main')}
              >
                Schedule
              </Button>
              <Button className="btn-messageicon" type={tab === 'sub' && 'primary'} size="large" onClick={() => setTab('sub')}>
                쪽지함
              </Button>
            </>
          )}
        </ButtonWrapper>
      </Row>

      <Row gutter={[24, 16]}>{tab === 'main' ? <Manager /> : <SubPage />}</Row>
    </>
  );
};

export default ManagerPage;
