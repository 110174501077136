export function isUserLogined() {
	return window.sessionStorage.getItem("isLogined") ? window.sessionStorage.getItem("isLogined") === "true" : false;
}
export function isVowel(x) {
	return /[aeiouAEIOU]/.test(x);
}
export function alphabetBoxTypeChecker (type){

		const typeValues = {
			1: 1,
			2: 1.21,
			3: 1.57,
			5: 2.36,
			6: 2.68
		};
	
		if (typeof type === 'string') {
			type = parseInt(type.replace('row', ''), 10);
		}
	
		// 추출된 숫자에 해당하는 값 반환
		return typeValues[type];
}
export function handleAllExceptionLetters  (letter, expectedLetter ="", changeCase = false)  {
	const charsWithSameUpperLowerCase = [
		"c","k","o","p","s","u","v", "w", "x", "y", "z"
	];
	let letterToReturn = letter;
	if (letter === "×" || letter === "✗") {
		letterToReturn = "x";
	}
	if(letter == "9") {
		letterToReturn = "q";
	}
	if (expectedLetter === "l") {
		if (letter === "I" || letter === "1") {
			letterToReturn = "l";
		}
	}
	if(changeCase == "upper" && charsWithSameUpperLowerCase.includes(letter[0].toLowerCase())) {
		return letterToReturn[0].toUpperCase();
	}
	else if(changeCase == "lower" && charsWithSameUpperLowerCase.includes(letter[0].toLowerCase())) {
		return letterToReturn[0].toLowerCase();
	}
	return letterToReturn;
}
export function getTextInPink(fullWord, urlbookname, urlunitname, urlstagename){
	let textInPink = '';
	let words = [];
	if(urlbookname == '4b' && urlunitname == 6 ){
		words = ["ai", "ay"];
	}
	if(urlbookname == '4b' && urlunitname == 7){
		words = ["ee", "ea"];
	}
	if(urlbookname == '4b' && urlunitname == 8 ){
		words = ["oa", "ow"];
	}
	if(urlbookname == '4b' && urlunitname == 9 ){
		words = ["ou", "ow"];
	}
	if(urlbookname == '4b' && urlunitname == 10 ){
		words = ["oo", "oo"];
	}
	if(words.length > 0 ){
		for (var i = words.length - 1; i >= 0; i--) {
			if(fullWord.includes(words[i])) {
				textInPink=words[i];
			}
		}
	}
	return textInPink;
} 
export function replaceSentenceWithVowelEmphasis(sentence) {
	const changed = sentence.replace(/##(\w+)##/g, match => {
		if (match) {
			const words = match
				.replace(/##/g, "")
				.split("")
				.map(item => `<span class='text_default text_${isVowel(item) ? "pink" : "blue"}'>${item}</span>`);
			return words.join("");
		}
	});
	return changed;
}
export function saveRecording(k, filePath) {
	const oldRecords = window.localStorage.getItem("records");
	if (oldRecords) {
		const oldObj = JSON.parse(oldRecords);
		oldObj[k] = filePath;
		const newRecords = {...oldObj};
		window.localStorage.setItem("records", JSON.stringify(newRecords));
	} else {
		const newObj = {};
		newObj[k] = filePath;
		window.localStorage.setItem("records", JSON.stringify(newObj));
	}
}
export function isSaveRecord(k) {
	const records = window.localStorage.getItem("records");
	if (records) {
		const recordObj = JSON.parse(records);
		if (recordObj) {
			if (recordObj[k]) {
				return true;
			}
		}
	}
	return false;
}

export function getSaveRecord(k) {
	const records = window.localStorage.getItem("records");
	if (records) {
		const recordObj = JSON.parse(records);
		if (recordObj) {
			if (recordObj[k]) {
				return recordObj[k];
			}
		}
	}
	return "";
}

//ImgDnD3에서 사용했었는데 필요없어서 삭제할 예정임...
export const whichOne = (sentence, upper) => {
	// 만약 sentence가 array라면...
	if (Array.isArray(sentence)) {
		if (upper[0] && JSON.stringify(sentence) === JSON.stringify(upper[0].sentence)) return true;
	} else if (upper[0] && upper[0].sentence === sentence) {
		return true;
	}
	return false;
};
export const getCurrentToken = () => {
	const culp_token = window.localStorage.getItem('culp_token');
	const { accessToken } = culp_token ? JSON.parse(culp_token) : { accessToken: null };
	return accessToken;
  };

