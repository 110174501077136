import { settings } from 'src/dummy/settings.js';

//스마트이클래스에서 사용하는 스테이지임..(homework result에서 상단에 스테이지 항목 맞추는 함수임..)
export const rcLowFunc = (acc, cur, col, currentStages) => {
  if (['w1'].includes(cur.name_abbr)) {
    return [...acc];
  }
  if (['w1', 'w2', 'w3'].includes(cur.name_abbr)) {
    if (acc.length === 0) {
      acc.push({ title: 'Words', children: [] });
    }
    if (acc[0]?.children) {
      acc[0].children.push(col);
    }

    return [...acc];
  } else if (['ks1', 'ks2'].includes(cur.name_abbr)) {
    if (acc.length === 2) {
      acc.push({ title: 'Key Sentence', children: [] });
    }

    if (acc[2]?.children) {
      acc[2].children.push(col);
    }

    return [...acc];
  } else if (['rq'].includes(cur.name_abbr)) {
    if (acc.length === 3) {
      acc.push({ title: 'Comprehension', align: 'center', children: [] });
    }
    if (acc[3]?.children) {
      acc[3].children.push(col);
    }
    return [...acc];
  }
  if (!currentStages.includes(cur.name_abbr)) {
    return [...acc];
  }
  return [...acc, col];
};

export const lcLowFunc = (acc, cur, col, currentStages) => {
  if (['w1', 'w2', 'w3'].includes(cur.name_abbr)) {
    if (acc.length === 0) {
      acc.push({ title: 'Words', children: [] });
    }
    if (acc[0]?.children) {
      acc[0].children.push(col);
    }

    return [...acc];
  } else if (['ks1', 'ks2'].includes(cur.name_abbr)) {
    if (acc.length === 2) {
      acc.push({ title: 'Key Sentence', children: [] });
    }

    if (acc[2]?.children) {
      acc[2].children.push(col);
    }

    return [...acc];
  } else if (['lq'].includes(cur.name_abbr)) {
    if (acc.length === 3) {
      acc.push({ title: 'Comprehension', align: 'center', children: [] });
    }

    if (acc[3]?.children) {
      acc[3].children.push(col);
    }

    return [...acc];
  }
  if (!currentStages.includes(cur.name_abbr)) {
    return [...acc];
  }
  return [...acc, col];
};

export const rcHighFunc = (acc, cur, col, currentStages) => {
  if (['w1', 'w2', 'w3'].includes(cur.name_abbr)) {
    if (acc.length === 0) {
      acc.push({ title: 'Words', children: [] });
    }
    if (acc[0]?.children) {
      acc[0].children.push(col);
    }

    return [...acc];
  } else if (['ks1', 'ks2'].includes(cur.name_abbr)) {
    if (acc.length === 3) {
      //뭔 짓인지... 순서 맞추는라..
      //acc.push({ title: 'Key Sentence', children: [] });
      acc.splice(2, 0, { title: 'Key Sentence', children: [] });
    }

    if (acc[2]?.children) {
      acc[2].children.push(col);
    }

    return [...acc];
  } else if (['rq'].includes(cur.name_abbr)) {
    if (acc.length === 2) {
      acc.push({ title: 'Comprehension', align: 'center', children: [] });
    }
    if (acc[2]?.children) {
      acc[2].children.push(col);
    }
    return [...acc];
  }
  if (!currentStages.includes(cur.name_abbr)) {
    return [...acc];
  }
  return [...acc, col];
};

export const lcHighFunc = (acc, cur, col, currentStages) => {
  if (['w1', 'w2', 'w3'].includes(cur.name_abbr)) {
    if (acc.length === 0) {
      acc.push({ title: 'Words', children: [] });
    }
    if (acc[0]?.children) {
      acc[0].children.push(col);
    }

    return [...acc];
  } else if (['ks1', 'ks2'].includes(cur.name_abbr)) {
    if (acc.length === 2) {
      acc.push({ title: 'Key Sentence', children: [] });
    }

    if (acc[2]?.children) {
      acc[2].children.push(col);
    }

    return [...acc];
  } else if (['lq'].includes(cur.name_abbr)) {
    if (acc.length === 3) {
      acc.push({ title: 'Comprehension', align: 'center', children: [] });
    }

    if (acc[3]?.children) {
      acc[3].children.push(col);
    }

    return [...acc];
  }
  if (!currentStages.includes(cur.name_abbr)) {
    return [...acc];
  }
  return [...acc, col];
};

export const phonicsFunc = (acc, cur, col, currentStages) => {
  if (['w1', 'w2'].includes(cur.name_abbr)) {
    if (acc.length === 0) {
      acc.push({ title: 'Words', children: [] });
    }
    if (acc[0]?.children) {
      acc[0].children.push(col);
    }

    return [...acc];
  } else if (['game', 'wu'].includes(cur.name_abbr)) {
    if (acc.length === 1) {
      acc.push({ title: 'Word Game', children: [] });
    }

    if (acc[1]?.children) {
      acc[1].children.push(col);
    }

    return [...acc];
  }
  if (!currentStages.includes(cur.name_abbr)) {
    return [...acc];
  }
  return [...acc, col];
};

export const grammarFunc = (acc, cur, col, currentStages) => {
  if (['w1', 'w2', 'w3'].includes(cur.name_abbr)) {
    if (acc.length === 0) {
      acc.push({ title: 'Words', children: [] });
    }
    if (acc[0]?.children) {
      acc[0].children.push(col);
    }

    return [...acc];
  } else if (['ks1', 'ks2'].includes(cur.name_abbr)) {
    if (acc.length === 1) {
      acc.push({ title: 'Key Sentence', children: [] });
    }

    if (acc[1]?.children) {
      acc[1].children.push(col);
    }

    return [...acc];
  } else if (['gq'].includes(cur.name_abbr)) {
    if (acc.length === 2) {
      acc.push({ title: 'Comprehension', align: 'center', children: [] });
    }

    if (acc[2]?.children) {
      acc[2].children.push(col);
    }

    return [...acc];
  }
  if (!currentStages.includes(cur.name_abbr)) {
    return [...acc];
  }
  return [...acc, col];
};

export const getBookStageFunc = (stageGroupCode) => {
  //스테이지가 변경되면 아래 로직도 변경해야 함.
  switch (stageGroupCode) {
    case 'rc_low':
      return rcLowFunc;
    case 'lc_low':
      return lcLowFunc;
    case 'lc_high':
      return lcHighFunc;
    case 'rc_high':
      return rcHighFunc;
    case 'phonics':
      return phonicsFunc;
    case 'grammar':
      return grammarFunc;
    default:
      return rcLowFunc;
  }
};

export const getStageName = (companyName, stageNameAbbr) => {
  return settings[companyName]?.stageNames && settings[companyName]?.stageNames[stageNameAbbr];
};

export const getStageTableHeader = (companyName, stageData, level = -1) => {
  let prev_cate_name = '';
  return stageData.reduce((acc, cur) => {
    let bookStage = cur.book_stage[0];
    if (level !== -1 && settings[companyName]?.stageLevelCategory && settings[companyName]?.stageLevelCategory[level]) {
      const filtetedStage = cur.book_stage.filter((item) => settings[companyName]?.stageLevelCategory[level].includes(item.group_code));
      if (filtetedStage.length > 0) {
        bookStage = filtetedStage[0];
      }
    }

    const title = getStageName(companyName, bookStage.name_abbr) || bookStage.name;
    const top_title = bookStage.category_name;

    if (bookStage.category_name) {
      let col = null;
      const curItem = {
        key: cur.name_abbr,
        title: top_title.replace('_', ' '),
        title_temp: title,
        dataIndex: cur.name_abbr,
        align: 'center',
      };

      if (prev_cate_name === top_title) {
        if (!acc[acc.length - 1]?.children) {
          const orgElement = acc[acc.length - 1];
          acc[acc.length - 1] = { title: top_title.replace('_', ' '), children: [] };
          acc[acc.length - 1].children.push({ ...orgElement, title: orgElement.title_temp });
        }

        acc[acc.length - 1].children.push({ ...curItem, title });
      } else {
        col = curItem;
      }
      prev_cate_name = bookStage.category_name;

      return col ? [...acc, col] : [...acc];
    } else {
      return [...acc, { key: cur.name_abbr, title, dataIndex: cur.name_abbr, align: 'center' }];
    }
  }, []);
};

const DISPLAY_NAME_CREO = {
  st: 'Small Talk',
  //vc1: 'Voca Intro',
  vc1: 'Intro',
  //vc2: 'Voca Meaning',
  vc2: 'Meaning',
  //vc3: 'Voca Usage',
  vc3: 'Usage',
  //ks1: 'Key Sentences Intro',
  ks1: 'Intro',
  //ks2: 'Key Sentences Listen',
  ks2: 'Listen',
  ks3: 'Unscramble',
  ks4: 'Dictation',
  quiz: 'Quiz',
  //sr: 'Speed Reading',
  sr: 'Acc. / WPM',
  dr: 'Drills',
  rp: 'Role Play',
};

export const getStageColsCreo = (stageList, lessonStage,isESL=false) => {
  const abbrList = lessonStage.map((item) => item.name_abbr);
  return stageList.reduce((acc, cur) => {
    if (!abbrList.includes(cur.name_abbr)) {
      return [...acc];
    }
    const col = {
      key: `stage${cur.stage_no}`,
      title: DISPLAY_NAME_CREO[cur.name_abbr],
      dataIndex: `stage${cur.stage_no}`,
      align: 'center',
      stage: cur.stage_no,
      book_stages: cur.book_stage,
    };
    if (['vc1', 'vc2', 'vc3'].includes(cur.name_abbr)) {
      if (acc.length === 1) {
        acc.push({ title: isESL?'Words':'Vocabulary', children: [] });
      }
      if (acc[1]?.children) {
        acc[1].children.push(col);
      }

      return [...acc];
    } else if (['ks1', 'ks2', 'ks3', 'ks4'].includes(cur.name_abbr)) {
      if (acc.length === 2) {
        acc.push({ title: 'Key Sentence', children: [] });
      }
      if (acc[2]?.children) {
        acc[2].children.push(col);
      }

      return [...acc];
    } else if (['quiz', 'dr'].includes(cur.name_abbr)) {
      if (acc.length === 3) {
        acc.push({ title: 'Activity', children: [] });
      }
      acc[3].children.push(col);
      return [...acc];
    } else if (['sr'].includes(cur.name_abbr)) {
      if (acc.length === 4) {
        acc.push({ title: 'Fluency', children: [] });
      }
      acc[4].children.push(col);
      return [...acc];
    }
    return [...acc, col];
  }, []);
};

const DISPLAY_NAME_LUCID = {
  w1: 'Flashcard',
  w2: 'Practice',
  ks1: 'Flashcard',
  ks2: 'Pattern Drills',
  ks3: 'Unscramble',
  st: 'Small Talk',
  w3: 'Dictation',
  w4: 'Quiz',
  ks4: 'Dictations',
  sq2: 'Fluency',
  sq1: 'Word Test',
  sq3: 'Sentence Test',
  sq4: 'Role play',
  ws1: 'Flascard',
  ws2: 'Practice',
  ws3: 'Flascard',
  ws4: 'Practice',
  wq1: 'Quiz',
  wq2: 'Quiz',
};

const LUCID_GROUP_NAMES = {
  Vocabulary_pre: 'Vocabulary',
  Vocabulary_post: 'Vocabulary',
  Key_Sentences_pre: 'Sentences',
  Key_Sentences_post: 'Sentences',
  Small_Talk: 'Small Talk',
  Speaking: 'Speaking',
  Vocabulary: 'Vocabulary',
  Phonetics: 'Phonetics',
};

const LIDIC_STAGE_GROUP = {
  grammar: [
    {
      key: `part-a`,
      title: 'Part A',
      align: 'center',
      children: ['Vocabulary_pre', 'Key_Sentences_pre'],
    },
    {
      key: `part-b`,
      title: 'Part B',
      align: 'center',
      children: ['Small_Talk', 'Vocabulary_post', 'Key_Sentences_post', 'Speaking'],
    },
  ],
  reading: [
    {
      key: `part-a`,
      title: 'Part A',
      align: 'center',
      children: ['Vocabulary_pre', 'Key_Sentences_pre'],
    },
    {
      key: `part-b`,
      title: 'Part B',
      align: 'center',
      children: ['Small_Talk', 'Vocabulary_post', 'Key_Sentences_post', 'Speaking'],
    },
  ],
  listening: [
    {
      key: `part-a`,
      title: 'Part A',
      align: 'center',
      children: ['Vocabulary_pre', 'Key_Sentences_pre'],
    },
    {
      key: `part-b`,
      title: 'Part B',
      align: 'center',
      children: ['Small_Talk', 'Vocabulary_post', 'Key_Sentences_post', 'Speaking'],
    },
  ],
  phonics: [
    {
      key: `part-a`,
      title: 'Phonics',
      align: 'center',
      children: ['Vocabulary', 'Phonetics'],
    },
  ],
  'phonics-voca': [
    {
      key: `part-a`,
      title: 'Phonics',
      align: 'center',
      children: ['Vocabulary'],
    },
  ],
  grammar1: [
    {
      key: `w1`,
      title: 'Word Flash Card',
      align: 'center',
    },
    {
      key: `w2`,
      title: 'Word Practice',
      align: 'center',
    },
    {
      key: `w3`,
      title: 'Word Dictation',
      align: 'center',
    },
    {
      key: `k4`,
      title: 'Key Sentence Dictation',
      align: 'center',
    },
    {
      key: `sq1`,
      title: 'Word Test',
      align: 'center',
    },
    {
      key: `sq2`,
      title: 'Fluency',
      align: 'center',
    },
  ],
  reading1: [
    {
      key: `w1`,
      title: 'Word Flash Card',
      align: 'center',
    },
    {
      key: `w2`,
      title: 'Word Practice',
      align: 'center',
    },
    {
      key: `w3`,
      title: 'Word Dictation',
      align: 'center',
    },
    {
      key: `k4`,
      title: 'Key Sentence Dictation',
      align: 'center',
    },
    {
      key: `sq1`,
      title: 'Word Test',
      align: 'center',
    },
    {
      key: `sq2`,
      title: 'Fluency',
      align: 'center',
    },
  ],
  listening1: [
    {
      key: `w1`,
      title: 'Word Flash Card',
      align: 'center',
    },
    {
      key: `w2`,
      title: 'Word Practice',
      align: 'center',
    },
    {
      key: `w3`,
      title: 'Word Dictation',
      align: 'center',
    },
    {
      key: `k4`,
      title: 'Key Sentence Dictation',
      align: 'center',
    },
    {
      key: `sq1`,
      title: 'Word Test',
      align: 'center',
    },
    {
      key: `sq2`,
      title: 'Fluency',
      align: 'center',
    },
  ],
};

export const getStageColsLucid = (stageList, lessonStageFull, stageGroupCode) => {
  const getFromStageList = (lessonStage) => {
    const abbrList = lessonStage.map((item) => item.name_abbr);
    return stageList.reduce((acc, cur) => {
      if (!abbrList.includes(cur.name_abbr)) {
        return [...acc];
      }
      const col = {
        key: `stage${cur.stage_no}`,
        title: DISPLAY_NAME_LUCID[cur.name_abbr],
        dataIndex: `stage${cur.stage_no}`,
        align: 'center',
        stage: cur.stage_no,
        book_stages: cur.book_stage,
      };
      return [...acc, col];
    }, []);
  };

  return LIDIC_STAGE_GROUP[stageGroupCode].map((item) => {
    if (item.children) {
      const children = item.children.map((child) => {
        let lessonStage = lessonStageFull.filter((stage) => stage.category_name === child);
        const subCHilds = getFromStageList(lessonStage);
        if (child === 'Small_talk') {
          return subCHilds[0];
        } else {
          return {
            key: `key-${child}`,
            title: LUCID_GROUP_NAMES[child],
            align: 'center',
            children: subCHilds,
          };
        }
      });
      return {
        key: item.key,
        title: item.title,
        align: 'center',
        children,
      };
    } else {
      let lessonStage = lessonStageFull.filter((stage) => stage.category_name || stage.name_abbr === item.key);
      let subCHilds = getFromStageList(lessonStage);
      subCHilds = subCHilds.length > 0 ? subCHilds[0] : null;
      return {
        key: item.key,
        title: item.title,
        align: 'center',
        dataIndex: subCHilds ? subCHilds.dataIndex : null,
        stage: subCHilds ? subCHilds.stage : null,
        book_stages: subCHilds ? subCHilds.book_stages : null,
      };
    }
  });
};
