import React, { useEffect, useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { isVowel } from '../../utils/pm/util';
import { useStudyCreate } from '../../utils/pm/api';
import { useParams } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
import { userInfoVar } from 'src/apollo/cache';
let bookNameUnitStage = undefined;
let bookNameUnitStageChecklist = undefined;
let bookNameUnitStageAdd = undefined;
const WordDnD = ({ upperWord, bookInfo, setPopUpType, setShowPopUp }) => {
  // 여기서 isCorrect는 단순히 정답체크가 아니라 정답이 들어있는 것의 체크이다.
  const [correctType, setCorrectType] = useState(undefined);
  const [vowelType, setVowelType] = useState(undefined);
  const [dropSound, setDropSound] = useState(undefined);

  const [isCorrect, setIsCorrect] = useState([]);
  const { level, unit, stage } = useParams();
  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setPopUpType('correct');
      setShowPopUp(true);
    },
  });

  if (level == '3b' && unit == 9 && stage == 4) {
    bookNameUnitStage = true;
    bookNameUnitStageChecklist = { e: true };
    bookNameUnitStageAdd = true;
  }

  const isAllDropped = () => {
    if (isCorrect.length === 0) return false;

    if (upperWord.length === 0) return false;
    const shortCount = isCorrect['short'] ? isCorrect['short'].length : 0;
    const longCount = isCorrect['long'] ? isCorrect['long'].length : 0;
    if (upperWord.length === shortCount + longCount) {
      return true;
    }
    return false;
  };

  const isDropped = (word) => {
    if (isCorrect.length === 0) return false;

    if (upperWord.length === 0) return false;

    if (isCorrect['short'] && isCorrect['short'].indexOf(word) !== -1) {
      return true;
    }

    if (isCorrect['long'] && isCorrect['long'].indexOf(word) !== -1) {
      return true;
    }

    return false;
  };

  const handleDropSoundEnd = () => {
    setDropSound(undefined);
  };

  const handleDragEnd = useCallback(
    (result) => {
      const { destination } = result;
      if (_.isNil(destination)) {
        setDropSound('wrong_sound1');
        return;
      } else {
        setDropSound('mouse-click');
      }
      if (isCorrect.length === 0 && _.isNil(isCorrect[result.type])) {
        setIsCorrect({ [result.type]: [result.draggableId] });
      } else if (_.isNil(isCorrect[result.type])) {
        setIsCorrect({ ...isCorrect, [result.type]: [result.draggableId] });
      } else {
        setIsCorrect({ ...isCorrect, [result.type]: [...isCorrect[result.type], result.draggableId] });
      }
    },
    [isCorrect],
  );
  useEffect(() => {
    // upperWord에는 answer, type 두가지가 존재한다.
    if (upperWord.length === 0 && _.isNil(vowelType)) return;
    //if (upperWord.length === 0) {
    if (isAllDropped()) {
      handleCreateStudy({
        lesson_code: window.sessionStorage.getItem('lesson_code'),
        stage_no: parseInt(bookInfo.stage),
        user_idx: parseInt(userInfoVar()?.idx),
        user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
      });
    }
    const vowels = Object.values(upperWord).reduce((prev, item) => {
      if (!prev.includes(item.type)) prev.push(item.type);
      return prev;
    }, []);
    const longs = Object.values(_.cloneDeep(upperWord)).filter((item) => item.type === 'long');
    const shorts = Object.values(_.cloneDeep(upperWord)).filter((item) => item.type === 'short');
    const vowel = {
      vowels: vowelType && vowelType['vowels'] ? vowelType['vowels'] : vowels,
      longs,
      shorts,
      random:
        vowelType && vowelType['random']
          ? _.reduce(
              vowelType['random'],
              (acc, cur) => {
                const a = upperWord.map((item) => item.answer);
                const b = cur.answer;
                cur.dropped = true;
                if (a.includes(b)) {
                  cur.dropped = false;
                }
                acc.push(cur);
                return acc;
              },
              [],
            )
          : _.shuffle(_.cloneDeep(Object.values(upperWord))),
    };
    // console.log("vowel", vowel);
    setVowelType(vowel);
    setCorrectType(isCorrect);
    // eslint-disable-next-line
  }, [upperWord, isCorrect]);

  return (
    // <DragDropContext onDragEnd={dragEnd}>
    // 	<div className="contents_hrbox hrbox_t">
    // 		{vowelType &&
    // 			_.map(vowelType.vowels, item => (
    // 				<div key={item} className={`${item}_wrap`}>
    // 					<dl className="step_title">
    // 						<dt>{item.charAt(0).toUpperCase() + item.slice(1)} Vowel</dt>
    // 					</dl>
    // 					<Droppable droppableId={`drop${item}`} type={item}>
    // 						{(provided, snapshot) => (
    // 							<>
    // 								<ul
    // 									className="word_wrap"
    // 									ref={provided.innerRef}
    // 									style={{
    // 										backgroundColor: snapshot.isDraggingOver ? "#00b3e6" : "#69db7c",
    // 										width: "50%",
    // 										maxWidth: "299px",
    // 										marginBottom: "1vh",
    // 										borderRadius: "50%",
    // 										alignItems: "center",
    // 										padding: "15px 40px",
    // 									}}
    // 								>
    // 									{correctType &&
    // 										_.map(correctType[item], (item, key) => (
    // 											<li key={`${item}-word-${key}`} className="block_3">
    // 												{_.map(item, (letter, idx) => (
    // 													<span key={`${item}-${letter}-letter-${idx}`} className={isVowel(letter) ? "text_pink" : "text_blue"}>
    // 														{letter}
    // 													</span>
    // 												))}
    // 											</li>
    // 										))}
    // 								</ul>
    // 								<ul style={{display: "none"}}>{provided.placeholder}</ul>
    // 							</>
    // 						)}
    // 					</Droppable>
    // 				</div>
    // 			))}
    // 	</div>
    // 	<div className="contents_hrbox hrbox_b">
    // 		<ul>
    // 			{vowelType &&
    // 				_.map(vowelType.random, (item, index) => (
    // 					<Droppable key={item.answer} droppableId={item.answer + index} type={item.type} isDropDisabled={true}>
    // 						{provided => (
    // 							<li
    // 								ref={provided.innerRef}
    // 								style={{width: "25%", display: "flex", justifyContent: "center", visibility: item.dropped ? "hidden" : "visible"}}
    // 							>
    // 								<Draggable draggableId={item.answer} index={index} type={item.type}>
    // 									{provided => (
    // 										<div className="block_3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
    // 											{_.map(item.answer, (letter, index) => (
    // 												<span key={letter + index} className={isVowel(letter) ? "text_pink" : "text_blue"}>
    // 													{letter}
    // 												</span>
    // 											))}
    // 										</div>
    // 									)}
    // 								</Draggable>
    // 								{provided.placeholder}
    // 							</li>
    // 						)}
    // 					</Droppable>
    // 				))}
    // 		</ul>
    // 	</div>
    // </DragDropContext>
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <S.ColumnFlex
          style={{ flex: '1 1', alignSelf: 'stretch', height: 'calc(100% - 70px)' }}
          id="lv3_unit9_stage4_content"
          className="main_content row2 boxstyle_none"
        >
          <TopBox className="top_box">
            {vowelType &&
              _.map(vowelType.vowels, (item) => (
                <TopInnerBox className={`${item}_area`}>
                  <S.RibbonTitle
                    className="tit_ribbon"
                    style={{ width: '200px', paddingBottom: '20px', marginBottom: '8px', color: '#fff' }}
                  >
                    <span style={{ color: '#fff', fontSize: 24 }}>{item.charAt(0).toUpperCase() + item.slice(1)} Vowel</span>
                  </S.RibbonTitle>

                  <Droppable droppableId={`drop${item}`} type={item}>
                    {(provided, snapshot) => (
                      <>
                        <TopWordWrapUl
                          className="word_wrap"
                          ref={provided.innerRef}
                          style={{
                            backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : '#69db7c',
                          }}
                        >
                          {correctType &&
                            _.map(correctType[item], (item, key) => (
                              <li key={`${item}-word-${key}`}>
                                <WordBox className="word_box">
                                  {bookNameUnitStage && bookNameUnitStageAdd
                                    ? _.map(item, (letter, idx) => (
                                        <span
                                          key={`${item}-${letter}-letter-${idx}`}
                                          style={{ color: bookNameUnitStageChecklist[letter] == true ? '#f368e0' : '#2e86de' }}
                                          className={bookNameUnitStageChecklist[letter] == true ? 'font_pink' : 'font_blue'}
                                        >
                                          {letter}
                                        </span>
                                      ))
                                    : _.map(item, (letter, idx) => (
                                        <span
                                          key={`${item}-${letter}-letter-${idx}`}
                                          style={{ color: isVowel(letter) ? '#f368e0' : '#2e86de' }}
                                          className={isVowel(letter) ? 'font_pink' : 'font_blue'}
                                        >
                                          {letter}
                                        </span>
                                      ))}
                                </WordBox>
                              </li>
                            ))}
                        </TopWordWrapUl>
                        <ul style={{ display: 'none' }}>{provided.placeholder}</ul>
                      </>
                    )}
                  </Droppable>
                </TopInnerBox>
              ))}
          </TopBox>

          <BottomBox className="bottom_box">
            <BottomWordWrapUl className="word_wrap">
              {vowelType &&
                _.map(vowelType.random, (item, index) => (
                  <Droppable key={item.answer} droppableId={item.answer + index} type={item.type} isDropDisabled={true}>
                    {(provided) => (
                      <li ref={provided.innerRef} style={{ visibility: isDropped(item.answer) ? 'hidden' : 'visible' }}>
                        <Draggable draggableId={item.answer} index={index} type={item.type}>
                          {(provided) => (
                            <WordBox
                              className="word_box"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {bookNameUnitStage && bookNameUnitStageAdd
                                ? _.map(item.answer, (letter, index) => (
                                    <span
                                      key={letter + index}
                                      style={{ color: bookNameUnitStageChecklist[letter] == true ? '#f368e0' : '#2e86de' }}
                                      className={bookNameUnitStageChecklist[letter] == true ? 'font_pink' : 'font_blue'}
                                    >
                                      {letter}
                                    </span>
                                  ))
                                : _.map(item.answer, (letter, index) => (
                                    <span
                                      style={{ color: isVowel(letter) ? '#f368e0' : '#2e86de' }}
                                      key={letter + index}
                                      className={isVowel(letter) ? 'font_pink' : 'font_blue'}
                                    >
                                      {letter}
                                    </span>
                                  ))}
                            </WordBox>
                          )}
                        </Draggable>
                        {provided.placeholder}
                      </li>
                    )}
                  </Droppable>
                ))}
            </BottomWordWrapUl>
          </BottomBox>
        </S.ColumnFlex>
      </DragDropContext>
      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
    </>
  );
};

export default WordDnD;
const TopBox = styled(S.RowFlex)`
  flex: 2 1;
  margin-bottom: 16px;
  padding: 0;
  border-radius: 0;
  border: 0 none;
  background-color: transparent;
`;
const TopInnerBox = styled(S.ImgDndBox)`
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
`;
const TopWordWrapUl = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 389px;
  height: 150px;
  padding: 8px 0;
  border-radius: 16px;
  border: 2px dotted #ffe266;
  background-color: #fffefa;
  margin: 0;
  list-style: none;
`;
const BottomBox = styled(S.Dnd4BottomBox)`
  padding: 0;
`;
const BottomWordWrapUl = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-height: 192px;
  padding: 0 8;
  margin: 0;
  list-style: none;
`;
const WordBox = styled(S.RowFlex)`
  justify-content: center;
  align-items: center;
  width: 152px;
  margin: 8px;
  padding: 8px;
  font-weight: 800;
  font-size: 28px;
  background: url(/images/pm/word_candy.svg) no-repeat center / cover;
`;
