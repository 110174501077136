import React, { useState, useEffect } from 'react';
//import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import _ from 'lodash';
import PopUp from '../PopUp';
//import {isVowel} from "../utils";
import { useStudyCreate } from '../../../utils/pm/api';
import AlphabetDnDCommon from '../AlphabetDnDCommon';
import ImgDnD2 from '../ImgDnD2';
import ImgDnD3 from '../ImgDnD3';
import ImgDnD4 from '../ImgDnD4';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useHistory } from 'react-router-dom';
import WordDnD from '../WordDnD';
import MultiWordDnd from '../MultiWordDnd';
import MultiWordDndWrite from '../MultiWordDndWrite';
import ImgDnD from '../ImgDnD';
import ImageDndAlphabet from '../ImageDndAlphabet';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';

const DndStage = ({ bookInfo, unitInfo, stages, steps, setSteps, directionEnd, handleDndSound }) => {
  // 모든 문제 받아서 state로 세팅하기.. 문제를 다 풀었는지 여부도 얘가 체크한다
  const [problems, setProblems] = useState('');
  const [totalCnt, setTotalCnt] = useState('');

  // 위 아래로 나뉘는데 이거 바꿀 수 있을 것 같기도.... 두개만 있어도 사실 충분하지 않나...? 나중에 생각해보자
  const [upperWord, setUpperWord] = useState([]);
  const [underWord, setUnderWord] = useState([]);
  const [upperWordRight, setUpperWordRight] = useState([]);
  const [underWordRight, setUnderWordRight] = useState([]);
  const [type, setType] = useState('');

  const [question, setQuestion] = useState('');

  // 팝업 보이고 감추는 거
  const [showPopUp, setShowPopUp] = useState('');
  const [popUpType, setPopUpType] = useState('');

  // 결과 텍스트 보여주는 거
  const [resultTxt, setResultTxt] = useState('');

  const [soundName, setSoundName] = useState(undefined);
  const [dropSound, setDropSound] = useState(undefined);
  const [tempName, setTempName] = useState(undefined);
  const [canGo, setCanGo] = useState(false); // 다음 문제로 넘어갈 수 있는지 없는지를 사운드 effect를 감지해서 변경해줌.. onDragEnd에서는 반드시 false로 놓아주어야 함

  const [img3Index, setImg3Index] = useState(undefined);

  // unit 9 2-2 counting
  // const [consCount, setConsCount] = useState(undefined);
  const [combi, setCombi] = useState(undefined);

  const history = useHistory();

  // 기본값 false를 문제수만큼 갖는 배열.. reset할 때도 얘를 쓸거임
  const defaultArr = [];
  for (let i = 0; i < stages.customData.totalCount; i++) {
    defaultArr.push(false);
  }
  const [isCorrect, setIsCorrect] = useState(defaultArr);

  // only for step2
  const [delay, setDelay] = useState(false);

  //imageDndAlphabet 에서만 사용
  const [isSecondStepEnd, setIsSecondStepEnd] = useState(false);
  const [resetStepCount, setResetStepCount] = useState(0);
  const location = useLocation();
  const resUrl = location.pathname.split('/');

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setShowPopUp(false);
      history.replace({
        pathname: `${window.location.pathname.split('/stage')[0]}`,
        search: `${window.location.search}`,
        state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
      });
    },
  });

  useEffect(() => {
    setProblems(_.cloneDeep(stages.customData.problem));
    setTotalCnt(stages.customData.totalCount);
    // setShowPopUp(true); // 이 것을 통해서 페이지 진입시 생기는 popup을 관리할 수 있다
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (totalCnt === 0 && canGo) {
      if (!_.isNil(problems[steps].exampleWord)) {
        setPopUpType('exampleWord');
        setShowPopUp(true);
      } else {
        setSteps((steps) => steps + 1);
      }
      // stage2에서 totalCount가 step에 따라서 다른 문제 때문에 고민중..
      // 우선 alphabetDnd는 stage 2 하나 뿐이고(아직까지는...), 여기에 candidate라는게 있다는 건 step2라는 뜻이므로 이렇게 처리하도록
      if (
        stages.customData.type === 'alphabetDnd' &&
        stages.customData.problem[steps + 1] &&
        stages.customData.problem[steps + 1].candidate
      ) {
        setTotalCnt(stages.customData.problem[steps + 1].candidate.length);
        setIsCorrect(Array(stages.customData.problem[steps + 1].candidate.length).fill(false));
      } else {
        setTotalCnt(stages.customData.totalCount);
        setIsCorrect(defaultArr);
      }
    }
    // eslint-disable-next-line
  }, [totalCnt, problems, defaultArr, steps]);
  useEffect(() => {
    if (isMobile) {
      if (stages.customData.type === 'alphabetDnd' || stages.customData.type === 'imgDnd2') {
        if (type === 'dndType1' || type === 'imgDnd2') {
          if (
            (resUrl[2] === '2a' || resUrl[2] === '2b' || resUrl[2] === '3a' || resUrl[2] === '3b') &&
            (resUrl[6] === '2' || resUrl[6] === '1' || resUrl[6] === '7')
          ) {
            if (upperWord !== '' && underWordRight !== '') {
              handleDndSound(true);
            }
          }
        }
      }
    }
  }, [upperWord, underWordRight]);

  useEffect(() => {
    if (!_.every([problems, totalCnt], (v) => v !== '')) return;

    if (problems.length <= steps) {
      showGoodJobPopup();
      return;
    }
    if (problems.length > steps || (!_.isNil(problems.question) && problems.question.length > steps)) {
      console.log('**** mystage ******');
      console.log(stages.customData.type);
      console.log('**** mystage ******');
      if (stages.customData.type === 'alphabetDnd') {
        if (problems[steps].type === 'dndType1') {
          setType(problems[steps].type);
          setUpperWord(problems[steps][1]);
          setUnderWord(problems[steps][1]);
          setUpperWordRight(problems[steps][2]);
          setUnderWordRight(problems[steps][2]);
        } else if (problems[steps].type === 'dndType2') {
          setUpperWord(problems[steps].candidate);
          setQuestion(problems[steps].question);
          setResultTxt('');
          if (document.getElementById('changeTxt')) {
            document.getElementById('changeTxt').innerText = '';
          }
        } else if (problems[steps].type === 'dndType3') {
          setUpperWord(_.shuffle(problems[steps].answer));
        } else if (problems[steps].type === 'dndType4') {
          //
        } else if (problems[steps].type === 'dndType5') {
          //
        } else if (problems[steps].type === 'dndType6') {
          //
        } else if (problems[steps].type === 'dndType7') {
          //
        } else {
          throw new Error('no Dnd type defined');
        }
      } else if (stages.customData.type === 'imgDnd') {
        // 랜덤하게 뿌려주기
        const imsi = _.cloneDeep(problems[steps]);
        imsi.candidate = _.shuffle(imsi.candidate);
        setUpperWord(imsi);
      } else if (stages.customData.type === 'imgDnd2') {
        // 랜덤하게 뿌려주기
        const imsi = _.cloneDeep(problems[steps]);
        imsi.candidate = _.map(imsi.candidate, (arr) => _.shuffle(arr));
        setUpperWord(imsi);
        setType('imgDnd2');
      } else if (stages.customData.type === 'imgDnd3') {
        setUpperWord(stages.customData.problem.question);
      } else if (stages.customData.type === 'imgDnd4') {
        setUpperWord(problems[steps]);
      } else if (stages.customData.type === 'wordDnd') {
        setUpperWord(stages.customData.problem);
      } else if (stages.customData.type === 'multiWordDnd') {
        //
      } else if (stages.customData.type === 'multiWordDndWrite') {
        //
      } else if (stages.customData.type === 'imageDndAlphabet') {
        //
      } else {
        throw new Error('no Dnd type defined');
      }
    }
    // eslint-disable-next-line
  }, [stages, problems, steps]);
  console.log(isSecondStepEnd, 'endss', showPopUp, 'showPopUp');
  useEffect(() => {
    if (problems && problems[steps] && problems[steps].type === 'dndType3' && upperWord.length === 0) {
      showGoodJobPopup();
    }
    // eslint-disable-next-line
  }, [upperWord]);

  const showGoodJobPopup = () => {
    setPopUpType('correct');
    setShowPopUp(true);
  };

  const handleGoodJobSoundEnd = () => {
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
    });
  };

  const dragEnd = (result) => {
    setCanGo(false);

    const { destination } = result;
    if (_.isNil(destination)) {
      setDropSound('wrong_sound1');
      return;
    } else {
      setDropSound('mouse-click');
    }
    if (stages.customData.type === 'imgDnd') {
      const name = upperWord.question + upperWord.answer;
      const copied = _.cloneDeep(upperWord);
      copied.candidate.splice(copied.candidate.indexOf(problems[steps].answer), 1);
      setUpperWord(copied);
      setSoundName(`${upperWord.question}_${upperWord.answer}_${name}`);
      document.getElementById(upperWord.answer).className = 'block_green block_3';
      document.getElementById('hidden').style.display = null;
    } else if (stages.customData.type === 'imgDnd2') {
      const name = _.isNil(stages.customData.ignorePhoneme)
        ? `${upperWord.question}_${upperWord.answer}_${upperWord.question + upperWord.answer}`
        : `${upperWord.question + upperWord.answer}`;
      setTotalCnt((totalCnt) => totalCnt - 1);
      if (totalCnt === 1) {
        setSoundName(name);
      }
      if (isCorrect.length === 1) {
        setIsCorrect([true]);
      } else {
        let copied = _.cloneDeep(upperWord);
        switch (destination.droppableId) {
          case problems[steps].question:
            copied.candidate[0].splice(copied.candidate[0].indexOf(problems[steps].question), 1);
            setUpperWord(copied);
            setIsCorrect([true, isCorrect[1]]);
            break;
          case problems[steps].answer:
            copied.candidate[1].splice(copied.candidate[1].indexOf(problems[steps].answer), 1);
            setUpperWord(copied);
            setIsCorrect([isCorrect[0], true]);
            break;
          default:
            break;
        }
      }
    } else if (stages.customData.type === 'imgDnd3') {
      const copy = _.cloneDeep(upperWord);
      let answerIdx;
      _.each(copy, (item, index) => {
        if (item.sentence === destination.droppableId) answerIdx = index;
      });
      const slided = copy.splice(answerIdx, 1);
      setImg3Index(slided);
      const nameOfCard = slided[0].image.split('/images/')[1].split('.png')[0];

      // after todo : card 이름에 따라서 재생되도록 변경함.. 나중에 이것도 손봐야 되겠다
      // 손봐야 될 때가 왔다!!!!!
      //setSoundName(`Lv3_U${nameOfCard[5]}_readers_${nameOfCard[11]}`);
      setSoundName(nameOfCard);
      let copiedCorrect = _.cloneDeep(isCorrect);
      let num;
      _.each(stages.customData.problem.question, (item, index) => {
        if (JSON.stringify(item) === JSON.stringify(slided[0])) num = index;
      });
      copiedCorrect[num] = true;
      setIsCorrect(copiedCorrect);
    } else if (stages.customData.type === 'imgDnd4') {
      const name = upperWord.question + upperWord.answer;
      setTotalCnt((totalCnt) => totalCnt - 1);
      if (totalCnt === 1) {
        setSoundName(name);
      }
      const copy = _.cloneDeep(upperWord);
      _.each(copy, (item, index) => {
        if (item.sentence === destination.droppableId) copy.candidate.splice(index, 1);
      });
      setUpperWord(copy);
      setIsCorrect([true]);
    } else if (stages.customData.type === 'alphabetDnd') {
      if (problems[steps].type === 'dndType1') {
        const copy = _.cloneDeep(underWord);
        const slided = copy.splice(copy.indexOf(destination.droppableId.slice(-1)), 1);
        setUnderWord(copy);
        let copiedCorrect = _.cloneDeep(isCorrect);
        copiedCorrect[upperWord.indexOf(slided[0])] = true;
        setIsCorrect(copiedCorrect);
        setTotalCnt(totalCnt - 1);
        if (copy.length === 0) setTempName(upperWord.join(''));
      } else if (problems[steps].type === 'dndType2') {
        const copy = _.cloneDeep(upperWord);
        const slided = copy.splice(copy.indexOf(result.draggableId.slice(-1)), 1);
        setUpperWord(copy);
        setTotalCnt((totalCnt) => totalCnt - 1);
        setIsCorrect([true]);
        document.getElementById('changeTxt').innerText = slided[0];
        const rightAnswer = `${slided[0]}${problems[steps].question}`;
        setTimeout(() => {
          // setDelay를 해주는 부분과 이곳이 다르다보니까 어쩔 수 없이...
          setResultTxt(rightAnswer);
        }, 2500);
        setSoundName(`${slided[0]}_${problems[steps].question}_${slided[0]}${problems[steps].question}`);
      } else if (problems[steps].type === 'dndType3') {
        const correction = _.cloneDeep(isCorrect);
        const dragged = result.draggableId;
        let word;
        if (_.isNil(combi)) {
          setCombi(dragged);
          if (dragged.length === 1) {
            correction[0] = true;
            setIsCorrect(correction);
          } else {
            correction[1] = true;
            setIsCorrect(correction);
          }
        } else if (combi.length === 1) {
          word = combi + dragged;
          if (dragged.length === 1) {
            correction[0] = true;
            setIsCorrect(correction);
          } else {
            correction[1] = true;
            setIsCorrect(correction);
          }
        } else {
          word = dragged + combi;
          if (dragged.length === 1) {
            correction[0] = true;
            setIsCorrect(correction);
          } else {
            correction[1] = true;
            setIsCorrect(correction);
          }
        }
        if (correction[0] && correction[1]) {
          correction[2] = true;
          setIsCorrect(correction);
        }

        if (word) setTempName(word);
      }
    } else if (stages.customData.type === 'wordDnd') {
      if (isCorrect.length === 0 && _.isNil(isCorrect[result.type])) {
        setIsCorrect({ [result.type]: [result.draggableId] });
      } else if (_.isNil(isCorrect[result.type])) {
        setIsCorrect({ ...isCorrect, [result.type]: [result.draggableId] });
      } else {
        setIsCorrect({ ...isCorrect, [result.type]: [...isCorrect[result.type], result.draggableId] });
      }
      setUpperWord((prev) => {
        return prev.reduce((acc, cur) => {
          if (cur.answer !== result.draggableId) acc.push(cur);
          return acc;
        }, []);
      });
    }
  };

  const dragEndRight = (result) => {
    setCanGo(false);
    const { destination } = result;
    if (_.isNil(destination)) {
      setDropSound('wrong_sound1');
      return;
    } else {
      setDropSound('mouse-click');
    }
    const copy = _.cloneDeep(underWordRight);
    const slided = copy.splice(copy.indexOf(destination.droppableId), 1);
    setUnderWordRight(copy);
    let copiedCorrect = _.cloneDeep(isCorrect);
    copiedCorrect[upperWordRight.indexOf(slided[0]) + 2] = true;
    setIsCorrect(copiedCorrect);
    setTotalCnt(totalCnt - 1);
    if (copy.length === 0) setTempName(upperWordRight.join(''));
  };

  const handleSoundEnd = () => {
    setCanGo(true);
    setSoundName('');
    setTempName(undefined);
    if (problems[steps] && problems[steps].type === 'dndType3') {
      const copiedUpper = _.cloneDeep(upperWord);
      if (tempName) {
        copiedUpper.splice(copiedUpper.indexOf(tempName), 1);
        setUpperWord(copiedUpper);
      }
      setCombi(undefined);
      setIsCorrect(Array(3).fill(false));
    }
    if (stages.customData.type === 'imgDnd3') {
      const copiedUpper = _.cloneDeep(upperWord);
      copiedUpper.forEach((element, index) => {
        if (element.image === img3Index[0].image) copiedUpper.splice(index, 1);
      });
      setUpperWord(copiedUpper);
    }
    if (stages.customData.type === 'imgDnd3' && isCorrect.every((v) => v)) {
      showGoodJobPopup();
    }
    if (problems[steps] && problems[steps].type === 'dndType2' && upperWord.length !== 0) {
      setIsCorrect([false]);
      document.getElementById('changeTxt').innerText = '';
      setResultTxt('');
    }
    if (stages.customData.type === 'imgDnd') {
      document.getElementById(upperWord.answer).className = 'block_default block_3';
      setTotalCnt((totalCnt) => totalCnt - 1);
      document.getElementById('hidden').style.display = 'none';
    }
  };

  const handleDropSoundEnd = (tempName) => {
    // tempName은 기존에 setSoundName할 때 나왔던 것들을 대체할 예정...
    // 이건 dropsound때문에 생긴 일..
    setDropSound('');
    if (!_.isNil(tempName)) setSoundName(tempName);
  };
  if (stages.customData.type === 'alphabetDnd') {
    // unit 1 stage 2의 경우
    return (
      <>
        <AlphabetDnDCommon
          //공통 props
          problemType={!_.isNil(problems) && !_.isNil(problems[steps]) && problems[steps].type}
          popUpType={popUpType}
          setPopUpType={setPopUpType}
          setShowPopUp={setShowPopUp}
          showPopUp={showPopUp}
          upperWord={upperWord}
          dragEnd={dragEnd}
          isCorrect={isCorrect}
          handleGoodJobSoundEnd={handleGoodJobSoundEnd}
          isMagic={stages.customData.isMagic}
          problems={problems}
          steps={steps}
          setSteps={setSteps}
          customData={stages.customData}
          //dndType1
          isShortVowel={stages.customData.isShortVowel}
          underWord={underWord}
          upperWordRight={upperWordRight}
          underWordRight={underWordRight}
          dragEndRight={dragEndRight}
          //dndType2
          delay={delay}
          setDelay={setDelay}
          stages={stages}
          resultTxt={resultTxt}
          question={question}
          directionEnd={directionEnd}
          setSoundName={setSoundName}
        />
        <SoundEffectPM
          url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
          onEnd={() => handleDropSoundEnd(tempName)}
        />
        <SoundEffectPM
          url={
            !_.isNil(soundName)
              ? `${
                  !_.isNil(stages.customData.soundBaseUrl) ? stages.customData.soundBaseUrl : 'https://cdn.cloubot.com/PM/audio/sounds/'
                }${soundName}.mp3`
              : undefined
          }
          onEnd={handleSoundEnd}
        />
      </>
    );
  } else if (stages.customData.type === 'wordDnd') {
    return (
      <>
        <WordDnD upperWord={upperWord} bookInfo={bookInfo} setPopUpType={setPopUpType} setShowPopUp={setShowPopUp} />
        {showPopUp ? <PopUp setShowPopUp={setShowPopUp} popUpType={popUpType} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
        <SoundEffectPM
          url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
          onEnd={() => handleDropSoundEnd(tempName)}
        />
        <SoundEffectPM
          url={
            !_.isNil(soundName)
              ? `${
                  !_.isNil(stages.customData.soundBaseUrl) ? stages.customData.soundBaseUrl : 'https://cdn.cloubot.com/PM/audio/sounds/'
                }${soundName}.mp3`
              : undefined
          }
          onEnd={handleSoundEnd}
        />
      </>
    );
  } else if (stages.customData.type === 'imgDnd') {
    return (
      <>
        <ImgDnD problems={stages.customData.problem} steps={steps} setSteps={setSteps} />
        {showPopUp ? <PopUp setShowPopUp={setShowPopUp} popUpType={popUpType} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
      </>
    );
  } else if (stages.customData.type === 'imgDnd2') {
    return (
      <>
        <ImgDnD2
          hasSoundBtn={stages.customData.hasSoundBtn}
          answerAbsolute={stages.customData.answerAbsolute}
          isCorrect={isCorrect}
          stages={stages}
          dragEnd={dragEnd}
          upperWord={upperWord}
          steps={steps}
          setSteps={setSteps}
          directionEnd={directionEnd}
          setSoundName={setSoundName}
        />
        {showPopUp ? <PopUp setShowPopUp={setShowPopUp} popUpType={popUpType} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
        <SoundEffectPM
          url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
          onEnd={() => handleDropSoundEnd(tempName)}
        />
        <SoundEffectPM
          url={!_.isNil(soundName) ? `https://cdn.cloubot.com/PM/audio/sounds/${soundName}.mp3` : undefined}
          onEnd={handleSoundEnd}
        />
      </>
    );
  } else if (stages.customData.type === 'imgDnd3') {
    return (
      <ImgDnD3
        setShowPopUp={setShowPopUp}
        showPopUp={showPopUp}
        upperWord={upperWord}
        question={stages.customData.problem.question}
        isCorrect={isCorrect}
        customData={stages.customData}
        handleGoodJobSoundEnd={handleGoodJobSoundEnd}
      />
    );
  } else if (stages.customData.type === 'imgDnd4') {
    return (
      <>
        <ImgDnD4
          hasSoundBtn={stages.customData.hasSoundBtn}
          hideWordBox={stages.customData.hideWordBox}
          answerAbsolute={stages.customData.answerAbsolute}
          isCorrect={isCorrect}
          stages={stages}
          dragEnd={dragEnd}
          upperWord={upperWord}
          steps={steps}
          setSteps={setSteps}
          directionEnd={directionEnd}
          soundName={soundName}
          problems={stages.customData.problem}
        />
        {showPopUp ? <PopUp setShowPopUp={setShowPopUp} popUpType={popUpType} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
        <SoundEffectPM
          url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
          onEnd={() => handleDropSoundEnd(tempName)}
        />
        <SoundEffectPM
          url={!_.isNil(soundName) ? `https://cdn.cloubot.com/PM/audio/sounds/${soundName}.mp3` : undefined}
          onEnd={handleSoundEnd}
        />
      </>
    );
  } else if (stages.customData.type === 'multiWordDnd') {
    return (
      <>
        <MultiWordDnd problems={stages.customData.problem} steps={steps} setSteps={setSteps} />
        {showPopUp ? <PopUp setShowPopUp={setShowPopUp} popUpType={popUpType} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
      </>
    );
  } else if (stages.customData.type === 'multiWordDndWrite') {
    return (
      <>
        <MultiWordDndWrite problems={stages.customData.problem} steps={steps} setSteps={setSteps} directionEnd={directionEnd} />
        {showPopUp ? <PopUp setShowPopUp={setShowPopUp} popUpType={popUpType} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
      </>
    );
  } else if (stages.customData.type === 'imageDndAlphabet') {
    const handleImageSoundStepEnd = () => {
      setIsSecondStepEnd(true);
    };

    const handlePopupClose = () => {
      setShowPopUp(false);
      setIsSecondStepEnd(false);
      setResetStepCount(resetStepCount + 1);
    };
    return (
      <>
        <ImageDndAlphabet
          setPopUpType={setPopUpType}
          setShowPopUp={setShowPopUp}
          setSteps={setSteps}
          step={steps}
          total={problems && problems.length}
          problem={problems && problems[steps]}
          isSecondStepEnd={isSecondStepEnd}
          setIsSecondStepEnd={setIsSecondStepEnd}
          resetStep={resetStepCount}
          onPopupClose={handlePopupClose}
        />
        {showPopUp ? (
          <PopUp
            popUpType={popUpType}
            setPopUpType={setPopUpType}
            setShowPopUp={setShowPopUp}
            onSoundEffectEnd={handleGoodJobSoundEnd}
            steps={steps}
            setSteps={setSteps}
            problems={problems}
            problem={problems && problems[steps]}
            onStepEnd={handleImageSoundStepEnd}
            onClose={handlePopupClose}
          />
        ) : undefined}
      </>
    );
  } else {
    throw new Error('No dnd type defined, bro. Just go back to bookInfo file and check it.');
  }
};

export default DndStage;
