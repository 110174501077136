import React, { useState, useEffect } from 'react';
import { Input, Modal, Select, Spin, message, Tabs, Button as AButton, Tooltip } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
import { ArrowLeftOutlined, ArrowRightOutlined, RedoOutlined } from '@ant-design/icons';
import { OPENAI_COMPLETION_QUERY } from 'src/operations/queries/message';
import styled from 'styled-components';
import { BOOK_REPORT_WORD_LIST } from 'src/constants/common';
const { TextArea } = Input;
const { Option } = Select;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const SearchBtn = styled(AButton)`
  background-color: #ffc60b;
  height: 35px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;
const SearchResultBox = styled.div`
  width: 80%;
  min-height: 500px;
  background-color: #fff;
  margin: 0 auto 20px;
  border: 1px solid lightgray;
  padding: 15px;
  line-height: 2;
`;
const LVSelect = styled(Select)`
  font-size: 14px;
  font-weight: bold;
  border: 1px #5b9ddb solid;
  color: #5b9ddb;
  padding: 0 5px;
  width: 170px;
  height: 34px;
  .ant-select-arrow {
    color: #5b9ddb;
  }
  ::placeholder {
    color: #5b9ddb;
  }
  @media (max-width: 1720px) {
    width: 150px;
    font-size: 12px;
  }
  @media (max-width: 1280px) {
    width: 130px;
    font-size: 12px;
  }
`;
const LVOption = styled(Option)`
  font-size: 14px;
  font-weight: bold;
`;
const Dictionary = styled(Modal)`
  .ant-modal-header {
    background-color: #f0effb;
    border-radius: 20px;
  }
  .ant-modal-content {
    border: 10px solid #453dd0;
    border-radius: 20px;
    background-color: #f0effb;
  }
`;
const DictionaryInput = styled(Input)`
  resize: none;
  padding: 5px;
  width: 55%;
  font-size: 15px;
  color: #333232;
  font-weight: 500;
  border: 2px #453dd0 solid;
  /* border-radius: 10px; */
  ::placeholder {
    color: black;
    font-size: 15px;
  }
  :focus {
    border: 2px #ffc000 solid;
    box-shadow: none;
    border-right-width: unset !important;
  }
`;
export default function Dictionary2({ visible, onCancel, language, level }) {
  const [chatList, setChatList] = useState([]);
  const [wordLevel, setWordLevel] = useState('first grade level');
  const [currentWord, setCurrentWord] = useState('');
  const campusIdx = userInfoVar()?.campus?.idx;
  const wordChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type === 'word');
  const [loadMoreMessages, { data, loading, error }] = useLazyQuery(OPENAI_COMPLETION_QUERY, {
    fetchPolicy: 'no-cache',
  });
  const onChangeWordLevel = (value) => {
    setWordLevel(value);
    console.log(value);
  };
  const onWordCheck = () => {
    if (currentWord.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }

    if (!wordLevel) {
      message.error('레벨을 선택해주세요.');
      return;
    }

    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentWord,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: BOOK_REPORT_WORD_LIST[wordLevel]
          .replaceAll('((level))', wordLevel)
          .replaceAll('((word))', currentWord)
          .replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'word',
          currentText: currentWord,
        }),
      },
    });
  };
  const handleWordClear = () => {
    setCurrentWord('');
    const tmp = chatList.filter((e) => (!e.data ? true : e.data?.type !== 'word'));
    setChatList(tmp);
  };
  return (
    <Dictionary
      title={<div>Dictionary ( Eng / Korean )</div>}
      onCancel={onCancel}
      visible={visible}
      handleCancel={onCancel}
      footer={''}
      width={'45%'}
    >
      <RowFlex style={{ justifyContent: 'center', alignItems: 'center', paddingBottom: '20px' }}>
        {' '}
        <DictionaryInput allowClear value={currentWord} onPressEnter={onWordCheck} onChange={(e) => setCurrentWord(e.target.value)} />
        <SearchBtn
          onClick={() => {
            if (currentWord !== '') {
              onWordCheck();
            }
          }}
        >
          Search
        </SearchBtn>
        <LVSelect
          dropdownStyle={{
            fontSize: '24px',
            fontWeight: 'bold',
            alignItems: 'center',
            maxWidth: '100%',
            border: 'none',
          }}
          placeholder={` Level `}
          onChange={onChangeWordLevel}
          bordered={false}
          value={wordLevel}
        >
          {[
            { value: 'Grade 1', label: 'Basic A' },
            { value: 'Grade 4', label: 'Basic B' },
            {
              value: 'Grade 7',
              label: 'Intermediate',
            },
            {
              value: 'Grade 11',
              label: 'Advanced',
            },
            { value: 'College Level', label: 'College' },
          ].map((e) => (
            <LVOption key={e.value} value={e.value}>
              {e.label}
            </LVOption>
          ))}
        </LVSelect>
        <Tooltip title="검색 기록 삭제">
          <RedoOutlined
            onClick={handleWordClear}
            style={{ cursor: 'pointer', padding: '0 0 0 10px', color: '#4976d2', fontWeight: 'bold', fontSize: '20px' }}
          />
        </Tooltip>
      </RowFlex>
      <SearchResultBox>
        {wordChats?.length && wordChats[wordChats?.length - 1]?.content
          ? wordChats[wordChats?.length - 1]?.content.split('\n').map((line, index) => {
              return (
                <span key={index}>
                  {line}
                  <br />
                </span>
              );
            })
          : ''}
      </SearchResultBox>
    </Dictionary>
  );
}
