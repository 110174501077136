import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import Keyboard from '../Keyboard';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import PopUp from '../PopUp';
import { useStudyCreate } from '../../../utils/pm/api';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
import { userInfoVar } from 'src/apollo/cache';
const MAX_WORD_LENGTH = 4;
const veryGoodSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/very-good.mp3';
const woopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';

const SpellingChangeStage = ({ bookInfo, unitInfo, stages, steps, setSteps }) => {
  const { problem } = stages;
  const safeSteps = Math.min(steps, problem.length - 1);
  const [showPopUp, setShowPopUp] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [getKey, setGetKey] = useState([]);
  const [isCorrect, setIsCorrect] = useState(false);
  const [soundCorrectWrong, setSoundCorrectWrong] = useState('');

  const history = useHistory();

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setShowPopUp(false);
      history.replace({
        pathname: `${window.location.pathname.split('/stage')[0]}`,
        search: `${window.location.search}`,
        state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
      });
    },
  });

  const handleWriteEnd = () => {
    if (problem[safeSteps].answer === getKey.join('')) {
      //alert("정답");
      setIsCorrect(true);
      setSoundCorrectWrong(veryGoodSound);
      //
    } else {
      setSoundCorrectWrong(woopsSound);
    }
  };
  const handleStepSoundEnd = () => {
    if (soundCorrectWrong === veryGoodSound) {
      setSteps((prev) => prev + 1);
      setIsCorrect(false);
    }
    setSoundCorrectWrong('');
  };
  const handleGoodJobSoundEnd = () => {
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
    });
  };
  useEffect(() => {
    if (steps >= problem.length) {
      setPopUpType('correct');
      setShowPopUp(true);
    }
  }, [steps, problem]);
  useEffect(() => {
    // if (MAX_WORD_LENGTH === getKey.length) {
    // 	if (problem[safeSteps].answer === getKey.join("")) {
    // 		alert("정답");
    // 	}
    // }
    if (getKey.length > MAX_WORD_LENGTH) {
      setGetKey(getKey.slice(0, MAX_WORD_LENGTH));
    }
  }, [getKey]);

  return (
    <>
      <S.ColumnFlex
        style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% -70px)' }}
        id="lv3_unit10_review5_content"
        className="main_content"
      >
        <S.RowFlex style={{ alignItems: 'center', flex: '1 1' }} className="top_box">
          <ImgBox className="img_box  box_default_style">
            <img style={{ height: '17vh' }} src={problem[safeSteps].before.image} alt="단어 이미지" />
            <Txt className="word_box">
              {reactStringReplace(problem[safeSteps].before.word, /##(\w+)##/g, (match) => (
                <strong>{match}</strong>
              ))}
            </Txt>
          </ImgBox>
          <img style={{ height: '40px', margin: '0 12px' }} src="/images/pm/i_arrow_right.svg" alt="오른쪽으로 향하는 화살표" />

          <ImgBox className="img_box  box_default_style">
            {isCorrect ? (
              <img style={{ height: '17vh' }} src={problem[safeSteps].after.image} alt="단어 이미지" />
            ) : (
              <img style={{ height: '17vh' }} src="https://cdn.cloubot.com/PM/image/default_monster_gray.png" alt="숨기기 이미지" />
            )}

            {isCorrect ? (
              <Txt className={`word_box ${isCorrect ? '' : 'area_none'}`}>
                {reactStringReplace(problem[safeSteps].after.word, /##(\w+)##/g, (match) => (
                  <strong>{match}</strong>
                ))}
              </Txt>
            ) : (
              <></>
            )}
          </ImgBox>
        </S.RowFlex>

        <Middlebox className="middle_box">
          <MiddleSentence className="sentence">
            {reactStringReplace(problem[safeSteps].direction, /##(\w+)##/g, (match) => (
              <strong style={{ margin: '0 8px', color: '#ee5353' }}>{match}</strong>
            ))}
          </MiddleSentence>
          <WordBoxWrap className="word_box_wrap">
            {Array(MAX_WORD_LENGTH)
              .fill(0)
              .map((__, key) => (
                <WordBox className="box_default_style" key={`each-spelling-${key}`}>
                  {!_.isNil(getKey[key]) ? getKey[key] : ''}
                </WordBox>
              ))}
          </WordBoxWrap>
        </Middlebox>

        <BottomBox className="bottom_box  box_default_style">
          <Keyboard imageWordWrite={true} onWriteEnd={handleWriteEnd} getKey={getKey} setGetKey={setGetKey} enabledWritePreview={false} />
        </BottomBox>
      </S.ColumnFlex>

      <SoundEffectPM url={soundCorrectWrong} onEnd={handleStepSoundEnd} />

      {showPopUp ? <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
    </>
  );
};

export default SpellingChangeStage;
const ImgBox = styled(S.BorderColumnBox)`
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 1 1;
  padding: 8px;
  font-size: 24px;
`;
const Txt = styled.p`
  font-size: 24px;
  margin: 0;
  padding: 0;
`;
const Middlebox = styled(S.ColumnFlex)`
  align-items: center;
  margin: 16px 0;
`;
const MiddleSentence = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44%;
  margin: 0;
  margin-bottom: 8px;
  border-radius: 8px;
  font-weight: 800;
  font-size: 28px;
  background-color: #ffefcb;
  padding: 0;
`;
const WordBoxWrap = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;
const WordBox = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  font-weight: 600;
  font-size: 30px;
  border-radius: 16px;
  border: 4px solid #ffb000;
  background-color: #fff;
`;
const BottomBox = styled(S.BorderRowBox)`
  align-items: center;
  flex: 1 1;
  padding: 25px 8px 20px;
  /* padding-bottom: 30px; */
  margin-top: -10px;
  justify-content: center;
`;
