import React, { useState, useEffect, useMemo } from 'react';
import { Input, Modal, Select, Spin, message, Tabs, Button as AButton, Tooltip, Upload } from 'antd';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import { OPENAI_COMPLETION_QUERY } from 'src/operations/queries/message';
import styled from 'styled-components';
import moment from 'moment';
import { ArrowLeftOutlined, ArrowRightOutlined, RedoOutlined, UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import * as axios from 'axios';
import Timer from 'src/components/LevelUp/Timer';
import {
  BOOK_REPORT_DEFAULT_FILL,
  BOOK_REPORT_REVISE_FILL,
  BOOK_REPORT_DEFAULT_FILL_LIST,
  BOOK_REPORT_REVISE_FILL_LIST,
  BOOK_REPORT_COMMENT_LIST,
  BOOK_REPORT_WORD_LIST,
} from 'src/constants/common';
import { LEVELUP_AI_TOPIC_USER_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import { LEVELUP_UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { openNotification } from 'src/components/common/Notification';
import Recording from './Recording';
import queryString from 'query-string';
import Dictionary2 from 'src/components/LevelUp/Dictionary';
const { TextArea } = Input;
const { Option } = Select;
const Wrapper = styled.div`
  width: 100%;

  height: 100%;
  background-color: #453dd0;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const StageTitle = styled.div`
  /* border-bottom: 1px solid #4472c4; */
  width: 100%;
  color: #453dd0;
  font-size: 32px;
  font-weight: bold;
  padding: 15px 30px;
  margin-bottom: 15px;
  background-color: #f3db4c;
  @media (max-width: 1280px) {
    font-size: 25px;
  }
`;
const StageTitleSpan = styled.span`
  color: '#00b0f0';
  font-size: 25px;
  padding-left: 50px;
  @media (max-width: 1280px) {
    font-size: 20px;
    padding-left: 40px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #2f5597;
  padding: 5px 10px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  padding: 0 45px 15px 45px;
  align-items: center;
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 13px;
  padding: 0 0px 25px;
  background-color: #ffffff;
  max-width: 1650px;
  min-width: 1350px;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 850px;
  }
`;

const HalfBody = styled.div`
  width: 50%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 85%;
`;
const TutorInput = styled.div`
  flex: 1;
  border: 2px #00b0f0 solid;
  height: 100%;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: bold;
  color: #797979;
  flex: 1;
  background-color: white;
  border-radius: 10px;
  overflow: auto;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #404040;
  line-height: 30px;
  @media (max-width: 1280px) {
    font-size: 14px;
    line-height: 24px;
  }
`;
const NumberBox = styled.div`
  width: 100px;
  height: 30px;
  border: 1px solid #dae3f3;
  border-radius: 5px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  color: ${(props) => props.color};
  font-weight: bold;
`;
const TopicArea = styled(TutorInput)`
  color: #000000;
  font-weight: 500;
  border: 2px #0070c0 solid;
  max-height: 70px;
`;
const InputArea = styled(TextArea)`
  resize: none;
  padding: 20px;
  font-size: 15px;
  color: #333232;
  font-weight: 600;
  border: 2px #ffc000 solid;
  border-radius: 10px;
  ::placeholder {
    color: black;
    font-size: 15px;
  }
  :focus {
    border: 2px #ffc000 solid;
    box-shadow: none;
    border-right-width: unset !important;
  }
`;
const InputArea2 = styled(TextArea)`
  resize: none;
  padding: 20px;
  font-size: 15px;
  color: #333232;
  font-weight: 600;
  border: 2px #00b0f0 solid;
  border-radius: 10px;
  ::placeholder {
    color: black;
    font-size: 15px;
  }
  :focus {
    border: 2px #00b0f0 solid;
    box-shadow: none;
    border-right-width: unset !important;
  }
`;
const LVSelect = styled(Select)`
  font-size: 14px;
  font-weight: bold;
  border: 1px #5b9ddb solid;
  color: #5b9ddb;
  padding: 0 5px;
  width: 170px;
  height: 34px;
  .ant-select-arrow {
    color: #5b9ddb;
  }
  .ant-select-selection-item {
    font-weight: bolder;
  }
  ::placeholder {
    color: #5b9ddb;
  }
  @media (max-width: 1720px) {
    width: 150px;
    font-size: 12px;
  }
  @media (max-width: 1280px) {
    width: 130px;
    font-size: 12px;
  }
`;
const LVOption = styled(Option)`
  font-size: 14px;
  font-weight: bold;
`;
const Button = styled.button`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: ${(props) => (props.radius ? '20px' : '10px')};
  font-size: 1.1em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  padding: ${(props) => (props.radius ? '9px 30px' : '9px 17px')};
  border: ${(props) => (props.radius ? 'none' : '2px #dae3f3 solid')};
  @media (max-width: 1720px) {
    padding: ${(props) => (props.radius ? '9px 20px' : '8px 15px')};
    font-size: 1em;
    /* padding: 8px 15px; */
  }
  @media (max-width: 1280px) {
    padding: ${(props) => (props.radius ? '9px 17px' : '8px 15px')};
    font-size: 0.8em;
    font-weight: normal;
    padding: 8px 14px;
  }
`;

const Paginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  overflow: auto;
  padding: 0px 20px;
  border-radius: 15px;
  max-width: 100%;
  /* background-color: #c7e3fd; */
`;

const PageButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.3em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  padding: 6px 20px;
  margin: 0 10px;
`;

const LoadingScreen = styled.div`
  height: 100%;
  background: #ffffff88;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Dictionary = styled(Modal)`
  .ant-modal-header {
    background-color: #f0effb;
    border-radius: 20px;
  }
  .ant-modal-content {
    border: 10px solid #453dd0;
    border-radius: 20px;
    background-color: #f0effb;
  }
`;
const ReWriteModal = styled(Modal)`
  .ant-modal-close-x {
    font-size: 24px;
    width: 40px;
    height: 40px;
    padding-right: 10px;
  }
  .ant-modal-header {
    background-color: #453dd0;
    border-radius: 0px;
    padding: 0px;
  }
  .ant-modal-content {
    border: 20px solid #453dd0;
    border-radius: 20px;
    background-color: #453dd0;
  }
  .ant-modal-body {
    border-radius: 0 0 20px 20px;
    background-color: #fff;
  }
`;
const DictionaryInput = styled(Input)`
  resize: none;
  padding: 5px;
  width: 55%;
  font-size: 15px;
  color: #333232;
  font-weight: 500;
  border: 2px #453dd0 solid;
  /* border-radius: 10px; */
  ::placeholder {
    color: black;
    font-size: 15px;
  }
  :focus {
    border: 2px #ffc000 solid;
    box-shadow: none;
    border-right-width: unset !important;
  }
`;

export default function AiEssayCorrection() {
  const { code, external_study_idx } = queryString.parse(window.location.search);
  const history = useHistory();
  const [wordLevel, setWordLevel] = useState('Grade 1');
  const [chatList, setChatList] = useState([]);
  const [startTime, setStartTime] = useState(new Date());
  const [level, setLevel] = useState(chatList.length ? chatList[chatList.length - 1]?.data?.level : '');
  const [defaultLevel, setDefaultLevel] = useState('');
  const [copyPaste, setCopyPaste] = useState(false);
  const [currentText, setCurrentText] = useState('');
  const [currentWord, setCurrentWord] = useState('');
  const [page, setPage] = useState(-1);
  const [writingType, setWritingType] = useState('');
  const [defaultWritingType, setDefaultWritingType] = useState('');
  const [language, setLanguage] = useState('Korean');
  const [defaultFill, setDefaultFill] = useState(BOOK_REPORT_DEFAULT_FILL);
  const [reviseFill, setReviseFill] = useState(BOOK_REPORT_REVISE_FILL);
  const [commentFill, setCommentFill] = useState(BOOK_REPORT_COMMENT_LIST['min']);
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [dictionaryVisible, setDictionaryVisible] = useState(false);
  const [rewriteModalVisible, setRewriteModalVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');
  const [rewriteModalText, setRewriteModalText] = useState('');
  const [topicData, setTopicData] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [answer, setAnswer] = useState('');
  const [showRecording, setShowRecording] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [revise, setRevise] = useState(false);
  const [editType, setEditType] = useState('check');
  const [loading, setLoading] = useState(false);
  const [fullFeedbackDone, setFullFeedbackDone] = useState(false);
  const botChats = chatList.filter(
    (chat) =>
      (chat.user === 'bot' && chat.data.type !== 'word' && chat.data.type !== 'comment-full' && feedback) ||
      (!feedback && chat.data.type !== 'comment' && chat.user === 'bot' && chat.data.type !== 'word' && chat.data.type !== 'comment-full'),
  );
  const wordChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type === 'word');
  const botCheckChat = botChats.filter((chat) => chat.data.type === 'check');
  const botReviseChat = botChats.filter((chat) => chat.data.type === 'revise');
  const botCommentChat = botChats.filter((chat) => chat.data.type === 'comment');

  const [loadMoreMessages, { data, loading: loadingChat, error }] = useLazyQuery(OPENAI_COMPLETION_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const isSelfBookReport = useMemo(() => {
    let etcData = userInfoVar()?.campus?.etc
      ? JSON.parse(userInfoVar()?.campus?.etc)
      : {
          isSelfBookReport: false,
        };

    return etcData?.isSelfBookReport === false ? false : true;
  }, []);

  const isLevelupRecording = useMemo(() => {
    let etcData = userInfoVar()?.campus?.etc
      ? JSON.parse(userInfoVar()?.campus?.etc)
      : {
          isLevelupRecording: true,
        };

    return etcData?.isLevelupRecording === false ? false : true;
  }, []);

  const exportToS3 = (filename, url, cb) => {
    setLoading(true);
    return new Promise(async (resolve, reject) => {
      try {
        const fileData = await axios.post('https://llf63tb67l3wj7nqerumdipm440stovy.lambda-url.ap-northeast-2.on.aws', {
          url: url,
          fileName: '-' + filename + '.pdf',
        });
        const fileUrl = fileData.data;
        // const fullUploader = await upload({ variables: { url: url, filename: '-' + filename + '.pdf' } });

        await cb(fileUrl);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setCurrentText(value);
    setFullFeedbackDone(false);
  };

  useEffect(() => {
    if (level) {
      setDefaultFill(BOOK_REPORT_DEFAULT_FILL_LIST[level]);
      setReviseFill(BOOK_REPORT_REVISE_FILL_LIST[level]);
    }
  }, [level]);

  const {
    data: dataHistory,
    loading: loadingHistory,
    error: errorHistory,
  } = useQuery(LEVELUP_AI_TOPIC_USER_ASSIGNMENT_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      external_study_idx: parseInt(external_study_idx),
    },
  });
  const [levelUpUpdateAITopicUserAssignment, { loading: loadingUpdate }] = useMutation(LEVELUP_UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });
  useEffect(() => {
    if (dataHistory?.levelUpAiTopicUserAssignmentDetails && dataHistory !== undefined) {
      if (dataHistory?.levelUpAiTopicUserAssignmentDetails?.assigned_external_study?.external_study_data) {
        const externalStudy = dataHistory?.levelUpAiTopicUserAssignmentDetails?.assigned_external_study?.external_study_data
          ? JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.assigned_external_study?.external_study_data)
          : [];
        setDefaultLevel(externalStudy?.level ? externalStudy?.level : 'Grade 7');
        setCopyPaste(externalStudy?.copyPaste ? externalStudy?.copyPaste : false);
        setLevel(externalStudy?.level ? externalStudy?.level : 'Grade 7');
        setDefaultWritingType(externalStudy?.sentences);
        setWritingType(externalStudy?.sentences ? externalStudy?.sentences : 'sentences');
        setFeedback(externalStudy?.feedback);
        setRevise(externalStudy?.revise);
      }
      if (dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic?.length > 0) {
        let prmpt = [];
        let custompropmt = [];
        if (dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic[0]?.data) {
          try {
            prmpt = JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic[0]?.data);
            custompropmt = JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.external_study_data?.external_study_data ?? '{}');
            setTopicData(prmpt?.book?.topic);
            setPrompt(custompropmt.revisedPrompt ? custompropmt.revisedPrompt : prmpt?.book?.prompt);
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }
      }
      const externalStudyData = JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.data);
      setAnswer(externalStudyData?.answer);
      setRewriteModalText(externalStudyData?.answer);
      if (externalStudyData?.status === 2) {
        history.push('/');
      } else if (externalStudyData?.status === 1) {
        setShowRecording(true);
      }

      if (dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic?.length > 0) {
        let data = [];
        if (dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic[0]?.data !== undefined) {
          try {
            data = JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic[0]?.data);
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }
        // const data = JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic[0]?.data);
        // if (data?.book?.level && !level) {
        //   setLevel(data.book.level);
        // }
        // if (data?.feedback && !feedback) {
        //   setFeedback(data?.feedback);
        // }
        // if (data?.revise && !revise) {
        //   setRevise(data?.revise);
        // }
      }

      let resChats = [];
      dataHistory?.levelUpAiTopicUserAssignmentDetails?.ai_request_response?.forEach((item) => {
        resChats.push({
          user: userInfoVar()?.id,
          chat_user_name: userInfoVar()?.name,
          content: item.prompt_text,
          urls: [],
          created: item.idate,
          data: item?.data ? JSON.parse(item?.data) : {},
        });
        resChats.push({
          user: 'bot',
          chat_user_name: 'AI봇',
          content: item.completion_text,
          urls: item.image_urls ? JSON.parse(item.image_urls) : [],
          created: item.idate,
          data: item?.data ? JSON.parse(item?.data) : {},
        });
      });
      let fullFeedbackCount = resChats.filter((chat) => chat.user === 'bot' && chat.data.type === 'comment').length;
      setChatList(resChats);
      setFullFeedbackDone(fullFeedbackCount > 0 ? true : false);
      setPage(resChats.filter((chat) => chat.user === 'bot' && chat.data.type !== 'word').length - 1);
    }
  }, [dataHistory, history]);

  useEffect(() => {
    console.log('chatList', chatList);
    if (chatList?.length) {
      setCurrentText(
        chatList[chatList.length - 1] ? chatList[chatList.length - 1]?.data?.currentText : chatList[0]?.content?.data?.currentText,
      );
      setLevel(chatList[chatList.length - 1] ? chatList[chatList.length - 1]?.data?.level : chatList[0]?.content?.data?.level);
    }
  }, [chatList]);

  useEffect(() => {
    if (writingType !== 'sentences') {
      setCommentFill(BOOK_REPORT_COMMENT_LIST[writingType === 'essay' ? 'full' : 'min']);
    }
  }, [writingType]);
  useEffect(() => {
    if (data?.getOpenAiCompletion?.text?.length > 0) {
      setChatList([
        ...chatList,
        {
          user: 'bot',
          chat_user_name: 'AI봇',
          prompt: currentText,
          content: data.getOpenAiCompletion?.text,
          urls: data.getOpenAiCompletion?.urls,
          created: data?.getOpenAiCompletion?.date,
          data: data?.getOpenAiCompletion?.chat?.data ? JSON.parse(data?.getOpenAiCompletion?.chat?.data) : null,
        },
      ]);
      setPage(botChats.length);
    }
  }, [data]);

  const onSubmit = () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
        data: {
          type: 'check',
          currentText: currentText,
          level: level,
        },
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');

    loadMoreMessages({
      variables: {
        prompt: defaultFill?.replaceAll('((level))', level)?.replaceAll('((topic))', currentText)?.replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        external_user_study_idx: parseInt(external_study_idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'check',
          currentText: currentText,
        }),
      },
    });
  };

  const onRevise = () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
        data: {
          type: 'revise',
          currentText: currentText,
          level: level,
        },
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: reviseFill?.replaceAll('((level))', level)?.replaceAll('((topic))', currentText)?.replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        external_user_study_idx: parseInt(external_study_idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'revise',
          currentText: currentText,
        }),
      },
    });
  };

  const onComment = () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
        data: {
          type: 'comment',
          currentText: currentText,
          level: level,
        },
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: commentFill?.replaceAll('((level))', level)?.replaceAll('((topic))', currentText)?.replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        external_user_study_idx: parseInt(external_study_idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'comment',
          currentText: currentText,
        }),
      },
    });

    setFullFeedbackDone(true);
  };

  const onWordCheck = () => {
    if (currentWord.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }

    if (!wordLevel) {
      message.error('레벨을 선택해주세요.');
      return;
    }

    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentWord,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: BOOK_REPORT_WORD_LIST[wordLevel]
          ?.replaceAll('((level))', wordLevel)
          ?.replaceAll('((word))', currentWord)
          ?.replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        external_user_study_idx: parseInt(external_study_idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'word',
          currentText: currentWord,
        }),
      },
    });
  };
  const handleWordClear = () => {
    setCurrentWord('');
    const tmp = chatList.filter((e) => (!e.data ? true : e.data?.type !== 'word'));
    setChatList(tmp);
  };
  //   const handleClear = ()=>{
  //     form.resetFields();
  //     console.log(form)
  //   }
  const onChangeLevel = (value) => {
    setLevel(value);
  };
  const onChangeWordLevel = (value) => {
    setWordLevel(value);
    console.log(value);
  };
  const onClear = () => {
    // setChatList([]);
    setCurrentText('');
    setPage(0);
  };
  const handleCopyPaste = (e) => {
    e.preventDefault();
    alert('복사, 붙여넣기는 허용되지 않습니다.');
  };
  const example = [
    'Word Weekly News Magazine',
    '2023-06 W3',
    'Lego goes to the moon!',
    'Write about how people can help others to save their lives.',
  ];

  const handleModalOpen = (type) => {
    setEditType(type);
    if (type === 'check') {
      setModalText(defaultFill);
    }
    if (type === 'revise') {
      setModalText(reviseFill);
    }

    if (type === 'comment') {
      setModalText(commentFill);
    }

    setModalVisible(true);
  };

  const handleModalChange = (e) => {
    setModalText(e.target.value);
  };

  const handleModalOk = () => {
    setModalVisible(false);
    if (editType === 'check') {
      setDefaultFill(modalText);
    }
    if (editType === 'revise') {
      setReviseFill(modalText);
    }
    if (editType === 'comment') {
      setCommentFill(modalText);
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setModalText('');
  };

  const handleRewriteModalCancel = () => {
    setRewriteModalVisible(false);
    // setRewriteModalText('');
  };

  const handleRewriteModalOk = async () => {
    // setReviseFill(rewriteModalText);

    await levelUpUpdateAITopicUserAssignment({
      variables: {
        idx: parseInt(external_study_idx),
        answer: rewriteModalText,
      },
    });
    setRewriteModalVisible(false);
  };
  const handleDictionaryModalOk = () => {
    setDictionaryVisible(false);
    // setReviseFill(rewriteModalText);
  };

  const handleRewriteModalChange = (e) => {
    setRewriteModalText(e.target.value);
  };

  const onRewrite = () => {
    setRewriteModalVisible(true);
    if (!feedback && !fullFeedbackDone) {
      onComment();
    }
  };

  const onAnswerSubmit = () => {
    if (rewriteModalText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }

    Modal.confirm({
      icon: null,
      title: 'Submit',
      content: (
        <Title level={4} style={{ textAlign: 'center', lineHeight: 2 }}>
          Would you like to submit?
        </Title>
      ),
      onOk: () => {
        saveData({
          idx: parseInt(external_study_idx),
          answer: rewriteModalText,
          start_time: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
          end_time: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          status: isLevelupRecording ? 1 : 2,
        });
      },
      onCancel: () => {},
    });
  };

  const saveData = async (variables) => {
    await levelUpUpdateAITopicUserAssignment({
      variables,
    });

    if (!isLevelupRecording) {
      if (isSelfBookReport && !dataHistory?.assigned_idx) {
        await exportToS3(
          `-level-up-report-result-${external_study_idx}`,
          `https://culp.cloubot.com/level-up/report/generate/${external_study_idx}?pdfType=student`,
          async (fileUrl) => {
            let externalData = dataHistory?.levelUpAiTopicUserAssignmentDetails?.data
              ? JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.data)
              : {};
            await levelUpUpdateAITopicUserAssignment({
              variables: {
                idx: parseInt(external_study_idx),
                status: 2,
                rubric_data: JSON.stringify(
                  externalData?.rubric_data ? { ...JSON.parse(externalData?.rubric_data || '{}'), pdf_url: fileUrl } : { pdf_url: fileUrl },
                ),
              },
            });
          },
        );
      }
      openNotification('답변이 저장되었습니다!');
      history.goBack();
    } else {
      setAnswer(variables?.answer);
      setShowRecording(true);
    }
  };

  return showRecording && answer ? (
    <Recording
      dataNew={[
        {
          page: 1,
          text: answer,
        },
      ]}
      articleData={topicData}
      idx={external_study_idx || null}
      userData={userInfoVar()}
      code={code}
      start_time={moment(startTime).format('YYYY-MM-DD HH:mm:ss')}
      end_time={moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')}
      externalUserStudyData={dataHistory?.levelUpAiTopicUserAssignmentDetails}
    />
  ) : (
    <Wrapper>
      <MainWrapper>
        <Main>
          <StageTitle>
            BOOK REPORT :{' '}
            <StageTitleSpan>
              <span style={{ color: '#002060' }}>
                Automatic
                <span
                // onClick={() => handleModalOpen('check')}
                >
                  {' '}
                  Proofreading
                </span>
                , Editing,{' '}
                <span
                // onClick={() => handleModalOpen('revise')}
                >
                  Rewriting
                </span>
                , and{' '}
                <span
                //  onClick={() => handleModalOpen('comment')}
                >
                  Explanation{' '}
                </span>
              </span>
            </StageTitleSpan>
          </StageTitle>
          <RowFlex style={{ height: '100%', padding: '0 20px' }}>
            <HalfBody>
              <Title style={{ margin: '5px 0 5px 0' }}>Essay Topic / Prompt : {topicData}</Title>
              {/* <TopicArea>{example[3]}</TopicArea> */}
              <TopicArea>{prompt}</TopicArea>
              <RowFlex style={{ justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px', alignItems: 'center' }}>
                <Title style={{ color: '#ff9900' }}>Editor</Title>
                <RowFlex style={{ padding: '5px 0' }}>
                  <RowFlex>
                    <SubTitle>Word Count : </SubTitle>
                    <NumberBox color={'#0070c0'}>{currentText?.split(' ').filter((word) => word !== '').length}</NumberBox>
                  </RowFlex>
                  <RowFlex>
                    <SubTitle>Time : </SubTitle>
                    <NumberBox color={'#ff0000'}>
                      <Timer />
                    </NumberBox>
                  </RowFlex>
                </RowFlex>
              </RowFlex>
              <div style={{ flex: 1, resize: 'none', padding: '0' }}>
                {loading || loadingChat ? (
                  <LoadingScreen>
                    <Spin fontSize="24px" />
                  </LoadingScreen>
                ) : (
                  <InputAreaWithOCRUpload
                    value={currentText}
                    onChange={handleInputChange}
                    placeholder={!level ? 'Select Your Grade' : ''}
                    disabled={!level || loading}
                  />
                )}
              </div>

              <RowFlex style={{ justifyContent: 'space-between', marginTop: '12px', alignItems: 'center', padding: '10px 10px 0' }}>
                <Button back={'#ff7e1d'} color={'white'} onClick={onClear} radius={true}>
                  CLEAR
                </Button>

                <LVSelect
                  dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
                  placeholder={` Level `}
                  onChange={onChangeLevel}
                  bordered={false}
                  // disabled={chatList.length ? chatList[chatList.length - 1]?.data?.level : ''}
                  value={defaultLevel !== '' ? defaultLevel : level}
                  disabled={defaultLevel !== ''}
                >
                  {[
                    { value: 'Grade 1', label: 'Basic A' },
                    { value: 'Grade 4', label: 'Basic B' },
                    {
                      value: 'Grade 7',
                      label: 'Intermediate',
                    },
                    {
                      value: 'Grade 11',
                      label: 'Advanced',
                    },
                    { value: 'College Level', label: 'College' },
                  ].map((e) => (
                    <LVOption key={e.value} value={e.value}>
                      {e.label}
                    </LVOption>
                  ))}
                </LVSelect>
                <LVSelect
                  dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
                  placeholder={` Sentences `}
                  onChange={(e) => setWritingType(e)}
                  bordered={false}
                  value={defaultWritingType !== '' ? defaultWritingType : writingType}
                  disabled={defaultLevel !== ''}
                >
                  {[
                    {
                      value: 'sentences',
                      label: `Sentences`,
                    },
                    {
                      value: '60',
                      label: `Min. 60W`, //Minimum 60 words
                    },
                    {
                      value: '100',
                      label: `Min. 100W`, //Minimum 100 words
                    },
                    {
                      value: '150',
                      label: `Min. 150W`, //Minimum 150 words
                    },
                    {
                      value: '200',
                      label: `Min. 200W`, //Minimum 250 words
                    },
                    {
                      value: 'essay',
                      label: `Full Feedback`, //Minimum 250 words
                    },
                  ].map((e) => (
                    <LVOption key={e.value} value={e.value}>
                      {e.label}
                    </LVOption>
                  ))}
                </LVSelect>
                <Button back={'#00abff'} color={'white'} onClick={() => setDictionaryVisible(true)} radius={true}>
                  &nbsp;&nbsp;DIC&nbsp;&nbsp;
                </Button>
              </RowFlex>
              <RowFlex style={{ justifyContent: 'space-between', alignItems: 'center', padding: '15px 10px 0' }}>
                <Button disabled={loading} onClick={onSubmit} back={'#64a6f7'} color={'white'} radius={false}>
                  1. CHECK
                </Button>
                <Button
                  back={writingType === 'sentences' || !feedback ? 'gray' : '#64a6f7'}
                  color={'white'}
                  radius={false}
                  onClick={() => {
                    if (writingType !== 'sentences' && feedback) {
                      onComment();
                    }
                  }}
                  disabled={!feedback}
                >
                  2. FEEDBACK
                </Button>
                <Button
                  back={!revise ? 'gray' : '#64a6f7'}
                  color={'white'}
                  onClick={revise ? onRevise : ''}
                  radius={false}
                  disabled={!revise}
                  // style={!revise? {cursor:"not-allowed",opacity:"0.5"} : {}}
                >
                  3. REVISE
                </Button>
                <Button
                  back={!botChats?.length ? '#5085C6' : '#64a6f7'}
                  color={'white'}
                  radius={false}
                  onClick={() => {
                    if (botChats?.length) {
                      onRewrite();
                    }
                  }}
                >
                  4. REWRITE
                </Button>
                <Button
                  back={'#ffb600'}
                  color={'white'}
                  radius={false}
                  onClick={() => {
                    if (botChats?.length) {
                      // onRewrite();
                      onAnswerSubmit();
                    }
                  }}
                >
                  5. UPLOAD
                </Button>
              </RowFlex>
            </HalfBody>
            <HalfBody>
              <RowFlex style={{ justifyContent: 'space-between', paddingBottom: '5px' }}>
                <Title style={{ marginTop: '6px' }}>AI Writing Tutor</Title>
                <RowFlex style={{ justifyContent: 'right', fontSize: '27px' }}>
                  <LVSelect
                    dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
                    placeholder={` Language `}
                    onChange={(e) => {
                      setLanguage(e);
                    }}
                    value={language}
                    bordered={false}
                  >
                    {[
                      {
                        value: 'Korean',
                        label: `Korean`,
                      },
                      {
                        value: 'Taiwanese',
                        label: `Taiwanese`,
                      },
                      {
                        value: 'Japanese',
                        label: `Japanese`,
                      },
                      {
                        value: 'Spanish',
                        label: `Spanish`,
                      },
                    ].map((e) => (
                      <LVOption key={e.value} value={e.value}>
                        {e.label}
                      </LVOption>
                    ))}
                  </LVSelect>
                  <RedoOutlined style={{ padding: '0 10px', color: '#4976d2', fontWeight: 'bold' }} />
                </RowFlex>
              </RowFlex>
              <TutorInput>
                {botChats?.length && botChats[page]?.content
                  ? botChats[page]?.content.split('\n').map((line, index) => {
                      return (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      );
                    })
                  : ''}
              </TutorInput>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Paginate>
                  <PageButton
                    color={'#ffffff'}
                    back={'#ed7d31'}
                    onClick={() => {
                      if (page > 0) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    <ArrowLeftOutlined />
                  </PageButton>

                  {botChats?.length
                    ? botChats.map((chat, index) => {
                        return (
                          <PageButton
                            key={index}
                            color={page === index ? '#ffffff' : '#000000'}
                            back={page === index ? '#ed7d31' : '#ffffff'}
                            onClick={() => setPage(index)}
                          >
                            {index + 1}
                          </PageButton>
                        );
                      })
                    : ''}

                  <PageButton
                    color={'#ffffff'}
                    back={'#ed7d31'}
                    onClick={() => {
                      if (page < botChats.length - 1) {
                        setPage(page + 1);
                      }
                    }}
                  >
                    <ArrowRightOutlined />
                  </PageButton>
                </Paginate>
              </div>
            </HalfBody>
          </RowFlex>
        </Main>
      </MainWrapper>
      <Modal title="Edit script" visible={modalVisible} onOk={handleModalOk} onCancel={handleModalCancel}>
        <TextArea value={modalText} onChange={handleModalChange} rows={6} />
      </Modal>
      <ReWriteModal
        title={
          <Title
            style={{
              backgroundColor: '#ffda00',
              borderTopRightRadius: '20px',
              borderTopLeftRadius: '20px',
              padding: '20px',
            }}
          >
            Book Report: Final Draft
          </Title>
        }
        visible={rewriteModalVisible}
        onOk={handleRewriteModalOk}
        onCancel={handleRewriteModalCancel}
        copyPaste={copyPaste}
        width={`80%`}
        footer={null}
      >
        {/* <Title style={{ margin: '15px 0 0px 0', backgroundColor: '#ffda00' }}>Book Report: Final Draft</Title> */}
        <RowFlex style={{ height: '100%' }}>
          <HalfBody>
            <Tabs defaultActiveKey="1" onChange={(e) => setCurrentTab(e)} value={currentTab} type="card" style={{ height: '100%' }}>
              <Tabs.TabPane tab="Original" key="1" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                <TutorInput
                  onCopy={!copyPaste && handleCopyPaste}
                  onPaste={!copyPaste && handleCopyPaste}
                  value={currentText}
                  style={{ height: '50vh' }}
                >
                  {currentText}
                </TutorInput>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Check" key="2" style={{ height: '100%' }}>
                <TutorInput onCopy={!copyPaste && handleCopyPaste} onPaste={!copyPaste && handleCopyPaste} style={{ height: '50vh' }}>
                  {botCheckChat?.length && botCheckChat[botCheckChat?.length - 1]?.content
                    ? botCheckChat[botCheckChat?.length - 1]?.content.split('\n').map((line, index) => {
                        return (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        );
                      })
                    : ''}
                </TutorInput>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Comment" key="3" style={{ height: '100%' }}>
                <TutorInput onCopy={!copyPaste && handleCopyPaste} onPaste={!copyPaste && handleCopyPaste} style={{ height: '50vh' }}>
                  {botCommentChat?.length && botCommentChat[botCommentChat?.length - 1]?.content
                    ? botCommentChat[botCommentChat?.length - 1]?.content.split('\n').map((line, index) => {
                        return (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        );
                      })
                    : ''}
                </TutorInput>
              </Tabs.TabPane>
            </Tabs>
          </HalfBody>
          <HalfBody>
            <Title style={{ margin: '5px 0', textAlign: 'center' }}>Final Draft</Title>
            <InputAreaWithOCRUpload
              value={rewriteModalText}
              style={{ height: '50vh' }}
              onChange={handleRewriteModalChange}
              handleCopyPaste={handleCopyPaste}
              blockCopyPaste={!copyPaste}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <Button
                back={'#6ac5b8'}
                color={'white'}
                style={{ width: '100px', borderRadius: '20px', textAlign: 'center' }}
                onClick={handleRewriteModalOk}
              >
                Save
              </Button>
            </div>
          </HalfBody>
        </RowFlex>
      </ReWriteModal>
      <Dictionary
        title={<div>Dictionary ( Eng / Korean )</div>}
        onCancel={handleDictionaryModalOk}
        visible={dictionaryVisible}
        handleCancel={handleDictionaryModalOk}
        footer={''}
        width={'45%'}
      >
        <RowFlex style={{ justifyContent: 'center', alignItems: 'center', paddingBottom: '20px' }}>
          {' '}
          <DictionaryInput allowClear value={currentWord} onPressEnter={onWordCheck} onChange={(e) => setCurrentWord(e.target.value)} />
          <SearchBtn
            onClick={() => {
              if (currentWord !== '') {
                onWordCheck();
              }
            }}
          >
            Search
          </SearchBtn>
          <LVSelect
            dropdownStyle={{
              fontSize: '24px',
              fontWeight: 'bold',
              alignItems: 'center',
              maxWidth: '100%',
              border: 'none',
            }}
            placeholder={` Level `}
            onChange={onChangeWordLevel}
            bordered={false}
            value={wordLevel}
          >
            {[
              { value: 'Grade 1', label: 'Basic A' },
              { value: 'Grade 4', label: 'Basic B' },
              {
                value: 'Grade 7',
                label: 'Intermediate',
              },
              {
                value: 'Grade 11',
                label: 'Advanced',
              },
              { value: 'College Level', label: 'College' },
            ].map((e) => (
              <LVOption key={e.value} value={e.value}>
                {e.label}
              </LVOption>
            ))}
          </LVSelect>
          <Tooltip title="검색 기록 삭제">
            <RedoOutlined
              onClick={handleWordClear}
              style={{ cursor: 'pointer', padding: '0 0 0 10px', color: '#4976d2', fontWeight: 'bold', fontSize: '20px' }}
            />
          </Tooltip>
        </RowFlex>
        <SearchResultBox>
          {wordChats?.length && wordChats[wordChats?.length - 1]?.content
            ? wordChats[wordChats?.length - 1]?.content.split('\n').map((line, index) => {
                return (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                );
              })
            : ''}
        </SearchResultBox>
      </Dictionary>
      {/* <Dictionary2 onCancel={handleDictionaryModalOk} visible={dictionaryVisible} language={language} level={level} /> */}
    </Wrapper>
  );
}

const InputAreaWithOCRUpload = ({ value, onChange, disabled, style = {}, placeholder = '', handleCopyPaste, blockCopyPaste }) => {
  const [loadingOCR, setLoadingOCR] = useState(false);

  const handleOCR = async (data) => {
    setLoadingOCR(true);
    try {
      const res = await axios('https://krjupjiewlg33kkrta2cgu2zue0chzbg.lambda-url.ap-northeast-2.on.aws/', {
        method: 'POST',
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-extention': data.name.split('.').pop(),
        },
      });
      console.log(res);
      if (res.data) {
        onChange({ target: { value: res.data } });
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingOCR(false);
  };

  return (
    <>
      <span style={{ float: 'left', zIndex: 1000, width: 0, height: 0, position: 'relative' }}>
        <Upload accept=".jpg,.jpeg,.png" action={handleOCR} showUploadList={false}>
          <UploadOutlined style={{ fontSize: '20px', color: '#ff9900', cursor: 'pointer', padding: '5px' }} />
        </Upload>
      </span>
      <InputArea
        style={{ resize: 'none', height: '100%', ...style }}
        value={value}
        onChange={onChange}
        disabled={disabled || loadingOCR}
        placeholder={placeholder}
        onCopy={blockCopyPaste && handleCopyPaste}
        onPaste={blockCopyPaste && handleCopyPaste}
      />
    </>
  );
};

const SearchBtn = styled(AButton)`
  background-color: #ffc60b;
  height: 35px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;
const SearchResultBox = styled.div`
  width: 80%;
  min-height: 500px;
  background-color: #fff;
  margin: 0 auto 20px;
  border: 1px solid lightgray;
  padding: 15px;
  line-height: 2;
`;
