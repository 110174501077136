import React, {useState, useEffect} from "react";
import _ from "lodash";
// import {MidiumScripter} from "./Scripter";
import PopUp from "./PopUp";
import PageNum from "./PageNum";
import SwitchButtonBox from "./SwitchButtonBox";
import Keyboard from "./Keyboard";
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from "styled-components";
const correctSound = "https://cdn.cloubot.com/PM/audio/sound-effect/game-win-notify.mp3";
const wrongSound = "https://cdn.cloubot.com/PM/audio/sound-effect/wrong-error-sound.mp3";

const greatSound = "https://cdn.cloubot.com/PM/audio/sound-effect/great.mp3";
const oopsSound = "https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3";
const tryAgainSound = "https://cdn.cloubot.com/PM/audio/sound-effect/try-again.mp3";
const MAX_WRONG_COUNT = 2;

const ImageWordSelectWrite = ({problem, steps, setSteps, onFinish}) => {
	const [mode, setMode] = useState(false);
	const [getKey, setGetKey] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [suffledWords, setSuffedWords] = useState([]);
	const [clickSound, setClickSound] = useState(undefined);
	const [writeCompleteSound, setWriteCompleteSound] = useState(undefined);
	const [isCorrectSelected, setIsCorrectSelected] = useState(false);
	const [isCompleteWritten, setIsCompleteWritten] = useState(false);
	const [isCorrectComplete, setIsCorrectComplete] = useState(false);
	const [wrongCount, setWrongCount] = useState(0);

	const safeSteps = steps > problem.length - 1 ? problem.length - 1 : steps;
	const handleMyScriptLoaded = () => {
		setIsLoading(false);
	};
	const handleSelectWord = word => {
		if (isCorrectSelected) {
			return false;
		}
		if (word === problem[safeSteps].answer) {
			setClickSound(correctSound);
			setIsCorrectSelected(true);
		} else {
			setClickSound(wrongSound);
		}
	};

	const handleToggle = () => {
		setMode(prevMode => !prevMode);
	};

	useEffect(() => {
		if (isError) {
			setMode(false);
		}
	}, [isError]);

	const handleSoundEnd = () => {
		setClickSound(undefined);
	};
	const handleWriteSoundEnd = () => {
		setWriteCompleteSound(undefined);
		if (writeCompleteSound === tryAgainSound) {
			return;
		}
		if (steps >= problem.length - 1) {
			onFinish();
		} else {
			setSteps(steps + 1);
		}
	};
	const handleAnswerComplete = isCorrect => {
		//setWriteCompleteSound(greatSound);
		setWrongCount(0);
		setIsCorrectComplete(isCorrect);
		setIsCompleteWritten(true);
	};
	const handleWrong = () => {
		setWrongCount(wrongCount + 1);
		if (wrongCount >= MAX_WRONG_COUNT - 1) {
			setWriteCompleteSound(oopsSound);
			handleAnswerComplete(false);
			setWrongCount(0);
		} else {
			setWriteCompleteSound(tryAgainSound);
		}
	};

	useEffect(() => {
		setSuffedWords(_.shuffle(problem[safeSteps].exWords));
		setIsCorrectSelected(false);
		setIsCompleteWritten(false);
		setIsCorrectComplete(false);
	}, [safeSteps]);

	useEffect(() => {
		if (isCorrectSelected && isCompleteWritten) {
			setWriteCompleteSound(isCorrectComplete ? greatSound : oopsSound);
		}
	}, [isCorrectSelected, isCompleteWritten, isCorrectComplete]);

	const handleWriteEnd = () => {
		if (getKey.join("") === problem[safeSteps].answer) {
			setWriteCompleteSound(greatSound);
			handleAnswerComplete(true);
		} else {
			handleWrong();
		}
		setGetKey([]);
	};

	return (
		<>
			<S.RowFlex className="lv4_review7_content main_content  col2">
				<PageNum total={problem.length} num={safeSteps + 1} style={{top: "20px",position:'absolute',right:'1.8rem',fontSize:'1.3rem',fontWeight:'bold'}}/>
				<S.ImageWordSelectWriteBox className="left_box">
					<S.ColumnFlex style={{justifyContent:'center', textAlign:'center'}} className="inner">
						<div className="img_box">
							<img style={{margin: '20px auto',width: '65%'}} src={problem[safeSteps].image} alt="보기 이미지" />
						</div>
						<S.ImageWriteBtnBox className="btn_box">
							{suffledWords &&
								suffledWords.map((item, key) => (
									<S.ImageSelectBtnLi key={`exword-key-${key}`}>
										<S.ImageSelectBtn
											type="button"
											active={isCorrectSelected && item === problem[safeSteps].answer}
											className={isCorrectSelected && item === problem[safeSteps].answer ? "active" : ""}
											onClick={() => {
												handleSelectWord(item);
											}}
										>
											{item}
										</S.ImageSelectBtn>
									</S.ImageSelectBtnLi>
								))}
						</S.ImageWriteBtnBox>
					</S.ColumnFlex>
				</S.ImageWordSelectWriteBox>
				<S.ImageWordSelectWriteBox style={{marginLeft:'24px',justifyContent:'center'}} className="right_box">
					<div className="inner" style={{position: "relative"}}>
						{/* {isError ? <SwitchButtonBox toggleDevice={handleToggle} device={false} /> : <SwitchButtonBox toggleDevice={handleToggle} device={true} />} */}
						<div className="pen_keyboard_wrap" style={{zIndex: 0}}>
							{mode ? (
								<></>
								// <MidiumScripter
								// 	answer={problem[safeSteps].answer}
								// 	steps={safeSteps}
								// 	isEnabled={true}
								// 	onLoaded={() => {
								// 		handleMyScriptLoaded();
								// 	}}
								// 	onError={() => {
								// 		setIsError(true);
								// 	}}
								// 	onComplete={isCorrect => {
								// 		handleAnswerComplete(isCorrect);
								// 	}}
								// 	onWrong={handleWrong}
								// 	isForceLowerCase={!_.isNil(problem[safeSteps].isForceLowerCase) ? problem[safeSteps].isForceLowerCase : true}
								// />
							) : (
								<>
									{/* <p className="output_area">{getKey ? getKey : ""}</p> */}
									<Keyboard
										disabled={!isCorrectSelected || mode}
										enabledWritePreview={true}
										getKey={getKey}
										setGetKey={setGetKey}
										imageWordWrite={true}
										onWriteEnd={handleWriteEnd}

									/>
								</>
							)}
						</div>
					</div>
				</S.ImageWordSelectWriteBox>
			</S.RowFlex>
			{/* {isLoading && <PopUp popUpType="loading" />} */}
			<SoundEffectPM url={clickSound} onEnd={handleSoundEnd} />
			<SoundEffectPM url={writeCompleteSound} onEnd={handleWriteSoundEnd} />
		</>
	);
};

export default ImageWordSelectWrite;