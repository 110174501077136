import React, {useState, useEffect} from "react";
// import {MidiumScripter} from "./Scripter";
import PopUp from "./PopUp";
import PageNum from "./PageNum";
import SwitchButtonBox from "./SwitchButtonBox";
import Keyboard from "./Keyboard";
import SoundEffectPM from "./SoundEffectPM";
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const greatSound = "https://cdn.cloubot.com/PM/audio/sound-effect/great.mp3";
const oopsSound = "https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3";
const tryAgainSound = "https://cdn.cloubot.com/PM/audio/sound-effect/try-again.mp3";
const MAX_WRONG_COUNT = 2;

const ImageAlphabetWrite = ({problem, onFinish, steps, setSteps}) => {
	const safeSteps = steps < problem.length ? steps : problem.length - 1;
	const [mode, setMode] = useState(false);
	const [getKey, setGetKey] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [wrongCount, setWrongCount] = useState(0);

	const [writeCompleteSound, setWriteCompleteSound] = useState(undefined);

	const handleToggle = () => {
		setMode(prevMode => !prevMode);
	};

	useEffect(() => {
		if (isError) {
			setMode(false);
		}
	}, [isError]);

	const handleMyScriptLoaded = () => {
		setIsLoading(false);
	};
	const handleAnswerComplete = isCorrect => {
		setWrongCount(0);
		setWriteCompleteSound(isCorrect ? greatSound : oopsSound);
	};
	const handleWrong = () => {
		setWrongCount(wrongCount + 1);
		if (wrongCount >= MAX_WRONG_COUNT - 1) {
			setWriteCompleteSound(oopsSound);
			handleAnswerComplete(false);
			setWrongCount(0);
		} else {
			setWriteCompleteSound(tryAgainSound);
		}
	};
	const handleWriteSoundEnd = () => {
		setWriteCompleteSound(undefined);
		if (writeCompleteSound === tryAgainSound) {
			return;
		}
		if (steps >= problem.length - 1) {
			onFinish();
		} else {
			setSteps(steps + 1);
		}
	};

	const handleWriteEnd = () => {
		if (getKey.join("") === problem[safeSteps].answer) {
			setWriteCompleteSound(greatSound);
			handleAnswerComplete(true);
		} else {
			handleWrong();
		}
		setGetKey([]);
	};

	return (
		<>
			<S.RowFlex className="lv4_review7_content main_content  col2">
				<PageNum total={problem.length} num={safeSteps + 1} style={{top: "20px",position:'absolute',right:'1.8rem',fontSize:'1.3rem',fontWeight:'bold'}}/>

				<S.ImageWordSelectWriteBox className="left_box">
					<S.ColumnFlex style={{justifyContent:'center', textAlign:'center'}} className="inner">
						<div className="img_box">
							<img style={{width:'95%', margin: '1.5rem auto'}} src={problem[safeSteps].image} alt="보기 이미지" className="large" />
						</div>
					</S.ColumnFlex>
				</S.ImageWordSelectWriteBox>
				<S.ImageWordSelectWriteBox style={{marginLeft:'24px',justifyContent:'center'}} className="right_box">
					<div className="inner"  style={{position: "relative"}}>
						{/* {isError ? <SwitchButtonBox toggleDevice={handleToggle} device={false} /> : <SwitchButtonBox toggleDevice={handleToggle} device={true} />} */}
						<div className="pen_keyboard_wrap" style={{zIndex: 0}}>
							{mode ? (<></>
								// <MidiumScripter
								// 	answer={problem[safeSteps].answer}
								// 	steps={safeSteps}
								// 	isEnabled={true}
								// 	onLoaded={() => {
								// 		handleMyScriptLoaded();
								// 	}}
								// 	onError={() => {
								// 		setIsError(true);
								// 	}}
								// 	onComplete={isCorrect => {
								// 		handleAnswerComplete(isCorrect);
								// 	}}
								// 	onWrong={handleWrong}
								// 	isForceLowerCase={false}
								// />
							) : (
								<>
									{/* <p className="output_area">{getKey ? getKey : ""}</p> */}
									<Keyboard enabledWritePreview={true} imageWordWrite={true} getKey={getKey} setGetKey={setGetKey} onWriteEnd={handleWriteEnd} />
								</>
							)}
						</div>
					</div>
				</S.ImageWordSelectWriteBox>
			</S.RowFlex>
			{/* {isLoading && <PopUp popUpType="loading" />} */}
			<SoundEffectPM url={writeCompleteSound} onEnd={handleWriteSoundEnd} />
		</>
	);
};

export default ImageAlphabetWrite;
