import { gql } from '@apollo/client';

export const SAVE_COMPANY_BOOK_DISPLAY_INFO = gql`
  mutation saveCompanyBookDisplayInfo($book_info: [CompanyBookDisplayUpdateInput!]!) {
    saveCompanyBookDisplayInfo(book_info: $book_info) {
      idx
    }
  }
`;

export const UPDATE_COMPANY_BOOK_DISPLAY_INFO = gql`
  mutation updateCompanyBookDisplayInfo($book_info: CompanyBookDisplayUpdateInput!) {
    updateCompanyBookDisplayInfo(book_info: $book_info) {
      idx
      book {
        code
        parent_code
        level_name
        volume_name
        book_sub {
          title
        }
      }

      book_list {
        code
      }
      is_asp
      is_main
      is_bundle
      is_use_credit
      shopping_mall_product
      cnt_preview
      price
      prices {
        idx
        book_idx
        price
        days
      }
    }
  }
`;

export const CREATE_COMPANY_BOOK_DISPLAY_INFO = gql`
  mutation createCompanyBookDisplayInfo($fc_company_idx: Int!, $goods: [String!]!) {
    createCompanyBookDisplayInfo(fc_company_idx: $fc_company_idx, goods: $goods) {
      idx
    }
  }
`;

export const UPDATE_COMPANY_BOOK_DISPLAY_PRICE = gql`
  mutation updateCompanyBookDisplayPrice($fc_company_idx: Int!, $goods: [JSONObject!]) {
    updateCompanyBookDisplayPrice(fc_company_idx: $fc_company_idx, goods: $goods)
  }
`;

export const CREATE_COMPANY_FC = gql`
  mutation createCompanyFc($fc_company_info: [JSONObject!]) {
    createCompanyFc(fc_company_info: $fc_company_info) {
      name
      phone
      address
      fax
      is_fc
    }
  }
`;

export const CREATE_BRANCH = gql`
  mutation createBranch($branch_info: [JSONObject!]) {
    createBranch(branch_info: $branch_info) {
      name
      phone
      address
      fax
    }
  }
`;

export const UPDATE_BRANCH = gql`
  mutation updateBranch($idx: Int!, $branch_info: JSONObject!) {
    updateBranch(idx: $idx, branch_info: $branch_info) {
      idx
    }
  }
`;
export const DELETE_BOOK_INFO = gql`
  mutation deleteBookInfo($idx: Int!) {
    deleteBookInfo(idx: $idx) 
  }
`;

export const ADD_COMPANY_USER_MENU_ITEM = gql`
  mutation addCompanyUserMenuItem($menu_item_info: CompanyUserMenuItemCreateInput!) {
    addCompanyUserMenuItem(company_user_menu_item_info: $menu_item_info) {
      idx
      parent_id
      item_name
      is_hidden
      show_icon
      icon_url
      index
      route_item {
        idx
        name
        route
        type
        pass_user_info
        is_root_only
      }
    }
  }
`;

export const UPDATE_COMPANY_USER_MENU_ITEM = gql`
  mutation updateCompanyUserMenuItem($menu_item_info: CompanyUserMenuItemUpdateInput!) {
    updateCompanyUserMenuItem(company_user_menu_item: $menu_item_info) {
      idx
    }
  }
`;

export const DELETE_COMPANY_USER_MENU_ITEM = gql`
  mutation deleteCompanyUserMenuItem($menu_item_idx: Int!) {
    deleteCompanyUserMenuItem(menu_item_idx: $menu_item_idx)
  }
`;

export const UPDATE_COMPANY_USER_MENU_ITEM_LIST = gql`
  mutation updateCompanyUserMenuItemList($menu_items: [JSONObject!]!) {
    updateCompanyUserMenuItemList(company_user_menu_items: $menu_items) {
      idx
    }
  }
`;

export const CREATE_COMPANY_DEFAULT_MENUS = gql`
  mutation createCompanyDefaultMenus($company_idx: Int!) {
    createCompanyDefaultMenus(company_idx: $company_idx)
  }
`;