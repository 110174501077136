import React, { useState, useEffect } from 'react';
import Sound from 'react-sound';
import _ from 'lodash';
import * as S from 'src/pages/PhonicsMonster/style';
const getSoundUrls = (src) => {
  if (_.isNil(src)) {
    return '';
  }
  if (src instanceof Array) {
    return src.map((item) => {
      if (item.indexOf('/') !== -1) {
        return `${item}`;
      } else {
        return `https://cdn.cloubot.com/PM/audio/sounds/${item}`;
      }
    });
  }
  if (src.indexOf('/') !== -1) {
    return [src];
  }
  return [`https://cdn.cloubot.com/PM/audio/sounds/${src}`];
};
const SoundButton = ({ src, style, isStartPlaying = false, onSoundEnd, disableSound = false, onStart, height = undefined }) => {
  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
  const [soundIndex, setSoundIndex] = useState(0);
  const urls = getSoundUrls(src);
  const url = urls[soundIndex];
  //console.log("SoundButton", url);

  const handleFinishPlay = () => {
    setPlayStatus(Sound.status.STOPPED);
    if (urls.length > 1 && soundIndex < urls.length - 1) {
      setSoundIndex(soundIndex + 1);
      setPlayStatus(Sound.status.PLAYING);
    }

    if (urls.length > 1 && soundIndex === urls.length - 1) {
      setSoundIndex(0);
    }
    if (!_.isNil(onSoundEnd) && onSoundEnd instanceof Function) {
      onSoundEnd();
    }
  };
  const handleClick = () => {
    if (url && !disableSound) {
      if (!_.isNil(onStart) && onStart instanceof Function) {
        onStart();
      }
      // make sure to toggle the status
      if (playStatus == Sound.status.PLAYING) {
        setPlayStatus(Sound.status.STOPPED);
        setTimeout(() => {
          setPlayStatus(Sound.status.PLAYING);
        }, 100);
      } else {
        setPlayStatus(Sound.status.PLAYING);
      }
    }
  };
  useEffect(() => {
    if (isStartPlaying) {
      handleClick();
    }
    return () => {
      setSoundIndex(0);
    };
  }, [src, isStartPlaying]);

  return (
    <>
      <S.Button1 type="button" className="btn_type1" onClick={handleClick} style={{ ...style, cursor: 'pointer' }}>
        <img style={{ width: height ? 110 : 40, height: height ? 110 : 40 }} src="/images/pm/i_speaker.svg" alt="스피커 아이콘" />
      </S.Button1>
      <Sound url={url} playStatus={playStatus} onFinishedPlaying={handleFinishPlay} />
    </>
  );
};

export default SoundButton;
