import React, { useState } from 'react';
import { Col, Row, Radio, Button } from 'antd';
import {
  BookCard,
  BookCover,
  BookMeta,
  BookTitle,
  BOOK_COL_STYLE,
  SMART_ECLASS_LINK_BUTTON_STYLE,
  SMART_ECLASS_CDN_BASE_URL,
  THUMBNAIL_BASE_URL,
} from './CommonComp';

const GROUPS = ['SB', 'WB', 'Readers'];
const VOLUMES = [1, 2, 3, 4, 5, 6];
const EBOOK_BASE_URL = 'https://online.flipbuilder.com/sangbong91/';
const SB_URL = ["phjc",	"msqi",	"iguc",	"bwet",	"ihzc",	"wzdb",];
const WB_URL = ['basf', 'xgea', 'dcyu', 'gepy', 'xfpl', 'pbvt'];
const Readers_URL = ['zrno', 'dbam', 'gbej', 'yaou', 'yjfb', 'wdet'];
const STUDY_TOOL_HOST = process.env.REACT_APP_STUDY_TOOL_HOST;
const handleSetupFileDownload = (volume) => {
  //window.open(`http://admin.smart-eclass.co.kr/include/common/downFile3.php?filename=kids_tap_${volume}.zip`);
  window.open(`${SMART_ECLASS_CDN_BASE_URL}common/kids_tap/kids_tap_${volume}.zip`);
};

// const BOOKS_NAME = ['PH_', , 'PH_R_'];

const KidsTap = () => {
  const [groupNo, setGroupNo] = useState(0);
  const handleChangeGroup = (evt) => {
    const {
      target: { value },
    } = evt;
    setGroupNo(value);
  };

  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      <Radio.Group
        value={groupNo}
        style={{ marginBottom: 16, marginLeft: 16 }}
        buttonStyle="solid"
        onChange={handleChangeGroup}
        size="middle"
      >
        {GROUPS.map((groupName, key) => (
          <Radio.Button key={`radio-level-key${key}`} value={key} style={{ width: 160, textAlign: 'center' }}>
            {groupName}
          </Radio.Button>
        ))}
      </Radio.Group>
      <Row gutter={[16, 16]} style={{ margin: '0 10px' }}>
        {VOLUMES.map((v, key) => (
          <Col span={4} key={`col-${key}`} style={{ ...BOOK_COL_STYLE, borderRight: `${v === 6 ? '1px solid #dedede' : ''}` }}>
            <BookTitle level={5}>
              {groupNo === 0
                ? `Kids Tap Phonics ${key + 1} SB`
                : groupNo === 1
                ? `Kids Tap Phonics ${key + 1} WB`
                : `Kids Tap Readers ${key + 1}`}
            </BookTitle>
            <BookCard
              style={{ marginLeft: 5, marginRight: 5 }}
              onClick={() => {
                window.open(
                  // groupNo === 2? `http://admin.kidstapenglish.com/flipBook/Phonics/${BOOKS_NAME[groupNo]}${v}/`:
                     `${EBOOK_BASE_URL}${(groupNo === 0 ? SB_URL :groupNo===1? WB_URL:Readers_URL)[key]}`,
                  '_blank',
                  'width=1400, height=900',
                );
              }}
              cover={
                <BookCover
                  style={{
                    backgroundImage: `url(${THUMBNAIL_BASE_URL}kids-tap-phonics${groupNo === 0 ? '' : groupNo === 1 ? '_WB' : '-readers'}-${
                      key + 1
                    }.jpg)`,
                  }}
                />
              }
            >
              {groupNo === 0 && <BookMeta description={<EbookLinkGroup volume={v} />} />}
            </BookCard>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default KidsTap;

const EbookLinkGroup = ({ volume }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5, paddingBottom: 5 }}>
      <Button
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, textAlign: 'center', fontSize: '0.8em' }}
        onClick={(e) => {
          e.stopPropagation();
          const culp_token = window.localStorage.getItem('culp_token');
          window.open(`${STUDY_TOOL_HOST}/KTP-L1V${volume}U1D1?token=${culp_token}`, '_blank');
        }}
      >
        E-learning
      </Button>
      <Button
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, textAlign: 'center', fontSize: '0.8em' }}
        onClick={() => {
          window.open(`https://admin.kidstapenglish.com/TG/Phonics/PH_${volume}/`, '_blank', 'width=1400, height=900');
        }}
      >
        TG
      </Button>
    </div>
  );
};
