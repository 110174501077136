import React from 'react';
import DefaultLayout from 'src/layouts/DefaultLayout';
import LevelUpLayout from 'src/layouts/LevelUpLayout';
import AICoachLayout from 'src/layouts/AICoachLayout';
import AITalkingTutorLayout from 'src/layouts/AITalkingTutorLayout';
import ToolLayout from 'src/layouts/ToolLayout';
import BookrLayout from 'src/layouts/BookrLayout';
import DashBoard from 'src/pages/DashBoard';
import Test from 'src/pages/Test';
import Recording from 'src/pages/Recording';
import RecordingStart from 'src/pages/Recording/RecordingStart';
import Zoom from 'src/pages/Zoom';
import TestDetails from 'src/pages/Test/TestDetails';
// import DailyTestDetails from 'src/pages/Test/DailyTestDetail';
import Tool from 'src/pages/Tool';
import Manage from 'src/pages/Test/Manage';
import Syllabus from 'src/pages/Test/Syllabus';
import SyllabusAssign from 'src/pages/Test/Syllabus/Assign';
import Tearchers from 'src/pages/Teachers';
import Students from 'src/pages/Students';
import StudentsBatch from 'src/pages/Students/StudentsBatch';
import ClassRoom from 'src/pages/ClassRoom';
import ClassDetail from 'src/pages/ClassRoom/ClassDetail';
import ClassDetailForAfterSchool from 'src/pages/ClassRoom/ClassDetail/ClassDetailForAfterSchool.js';
import { userInfoVar } from 'src/apollo/cache';
import { isPturn } from 'src/utils/index';
// import DailyTestQuiz from 'src/pages/Test/DailyTestSyllabus/DailyTestQuiz';
import DailyTestSyllabus from 'src/pages/Test/DailyTestSyllabus';
import DailyTestResult from 'src/components/Test/dailyTestResult';
import DailyTestGroupResult from 'src/components/Test/dailyTestGroupResult';
import ClassResult from 'src/components/Test/classResult';
import TestResultClassWise from 'src/components/Test/testResultClassWise';
import StudentTestResultClassWise from 'src/components/Test/studentTestResultClassWise';
// import DailyTestGroupQuiz from 'src/components/Test/DailyTestGroupQuiz';
import { DailySyllabusEdit } from 'src/components/Test';
import { DailyTestSmallZipUpload } from 'src/components/Test';
import { EditTest } from 'src/components/Test';
import { TestResult } from 'src/components/Test';
import Learning from 'src/pages/Learning';
import TicketForm from 'src/pages/Tickets/TicketForm';
import AssignTicket from 'src/pages/Tickets/AssignTicket';
import RecallTicket from 'src/pages/Tickets/RecallTicket';
import TicketStatistics from 'src/pages/Tickets/TicketStatistics';
import TicketStatus from 'src/pages/Tickets/TicketStatus';
import Consulting from 'src/pages/Consulting';
import ConsultingMeta from 'src/pages/ConsultingMeta';
import ScheduleMeta from 'src/pages/ScheduleMeta';
import Ebook from 'src/pages/Ebook';
import PopupPage from 'src/pages/PopupPage';
import Purchase from 'src/pages/Purchase';
import Board from 'src/pages/Board';
import Account from 'src/pages/Settings/Account';
import BookInfo from 'src/pages/Settings/BookInfo';
import Campuses from 'src/pages/Campuses';
import Credits from 'src/pages/Credits';
import Folder from 'src/pages/Folder';
import IframePage from 'src/pages/Iframe';
import TearcherDetail from 'src/pages/Teachers/TearcherDetail';
import StudentDetail from 'src/pages/Students/StudentDetail';
import LearningDetail from 'src/pages/Learning/LearningDetail';
import BoardDetail from 'src/pages/Board/BoardDetail';
import StudyResult from 'src/pages/StudyResult';
import Employee from 'src/pages/Settings/Employee';
import ClassStudyResult from 'src/pages/StudyResult/ClassStudyResult';
import ElibraryAssignBooks from 'src/pages/ElibraryAssign';
import ManageAssignBooks from 'src/pages/ElibraryAssign/Manage';

import ManageAssignBooksEdit from 'src/pages/ElibraryAssign/Manageedit';
import AssignLevelUpBooksEdit from 'src/pages/LevelUp/Manageedit';
import ElibraryManageAssignBooks from 'src/pages/ElibraryAssign/Homework/Manage';
import ElibraryLearning from 'src/pages/ElibraryAssign/Learning';
import ElibraryLearningGroup from 'src/pages/ElibraryAssign/Learninggroup';
import ElibraryHomework from 'src/pages/ElibraryAssign/Homework';

import LevelUpManage from 'src/pages/LevelUp/Report';
import LevelUpHistory from 'src/pages/LevelUp/Results/History';

import BookrBook from 'src/pages/Bookr';
import BookrAssign from 'src/pages/Bookr/Assign';
import AssignedBookrList from 'src/pages/Bookr/List';
import BookrPortfolioList from 'src/pages/Bookr/PortfolioList';
import BookrEdit from 'src/pages/Bookr/Edit';
import BookrResult from 'src/pages/Bookr/Result';
import BookrResultDetail from 'src/pages/Bookr/Result/Detail';

import ScriboSubscriptionList from 'src/pages/Scribo/List';

import { SyllabusAssignedDetail, SyllabusEdit } from 'src/components/Test';
import LearningClass from 'src/pages/LearningClass';
import LearningElibrary from 'src/pages/LearningElibrary';
import LearningClassStudyResult from 'src/pages/LearningClass/LearningClassStudyResult';
import ReportCard from 'src/pages/ReportCard';

import OrderAdmin from 'src/pages/Order/Admin';
import BranchAdmin from 'src/pages/Order/BranchAdmin';
import OrderShipping from 'src/pages/Order/Shipping';

import BookOrder from 'src/pages/BookStore/BookOrder';
import BookOrderPay from 'src/pages/BookStore/BookOrderPay';
import BookOrderPayComplete from 'src/pages/BookStore/BookOrderPayComplete';
import BookOrderPayCancelled from 'src/pages/BookStore/BookOrderPayCancelled';
import BookCreditList from 'src/pages/BookCredit/List';
import BookCreditAssign from 'src/pages/BookCredit/Assign';
import BookrHistory from 'src/pages/Bookr/History';
import SettingPayAdmin from 'src/pages/Settings/Pay/Admin';
import BookOrderList from 'src/pages/BookStore/BookOrderList';

import PhonicsElibraryAssign from 'src/pages/PhonicsAssign';
import PhonicsManageAssignBooks from 'src/pages/PhonicsAssign/Manage';
import PhonicsManageAssignBooksEdit from 'src/pages/PhonicsAssign/Manageedit';
import PhonicsResults from 'src/pages/PhonicsAssign/Result';
import PhonicsResultClass from 'src/pages/PhonicsAssign/ResultClass';
import PhonicsResultClassStudent from 'src/pages/PhonicsAssign/PhonicsResultClassStudent';
import PhonicsResultsGroup from 'src/pages/PhonicsAssign/Resultgroup';

import SmsManage from 'src/pages/SmsManage';
import BookReturn from 'src/pages/BookStore/BookReturn';
import BookReturnList from 'src/pages/BookStore/BookReturnList';
import QuestionExtractor from 'src/pages/QuestionExtractor';
import TestDocumentList from 'src/pages/QuestionExtractor/list';
import BookReturnRequest from 'src/pages/BookStore/BookReturnRequest';

import BookReturnAdmin from 'src/pages/BookReturn/Admin';
import PlacementTest from 'src/pages/PlacementTest';

import AssignLessonPage from 'src/pages/Offline/AssignLessonPage';

import OfflineSyllabus from 'src/pages/Offline';
import CampusPageView from 'src/pages/Offline/CampusPageView';
import CampusDashboardPageView from 'src/pages/Offline/CampusDashboardPageView';

import OfflineAddSyllabus from 'src/pages/Offline/AddSyllabusPage';
import OfflineEditSyllabus from 'src/pages/Offline/EditSyllabusPage';

import OfflineEditSyllabusAdminView from 'src/pages/Offline/EditSyllabusPageAdminView';

import OfflineEditSyllabusUpdate from 'src/pages/Offline/EditSyllabusPageUpdate';

import OfflineEditSyllabusView from 'src/pages/Offline/EditSyllabusViewPage';

import OfflineTextBookList from 'src/pages/Offline/TextBookListPage';
import OfflineAssign from 'src/pages/Offline/AssignOfflinePage';

import OfflineAssignEdit from 'src/pages/Offline/AssignOfflineEditPage';

import AssignLessonPlanPage from 'src/pages/Offline/AssignLessonPlanPage';
import ElibraryHistory from 'src/pages/ElibraryHistory';
import ExternalStudyHistory from 'src/pages/ExternalStudyHistory';
import ExternalStudyAssign from 'src/pages/ExternalStudyAssign';
import ManageAssignExternalStudy from 'src/pages/ExternalStudyAssign/Manage';

import ManageAssignExternalStudyEdit from 'src/pages/ExternalStudyAssign/Manageedit';
import ExternalStudyManageAssignBooks from 'src/pages/ExternalStudyAssign/Homework/Manage';
import ExternalStudyLearning from 'src/pages/ExternalStudyAssign/Learning';
import ExternalStudyLearningGroup from 'src/pages/ExternalStudyAssign/Learninggroup';
import ExternalStudyHomework from 'src/pages/ExternalStudyAssign/Homework';
import MyBooks from 'src/pages/MyBooks';

import Elibrary from 'src/pages/Elibrary';

import Contents from 'src/pages/Contents';
import ExternalStudyLearningGroupDateWise from 'src/pages/ExternalStudyAssign/LearninggroupDateWise';
import AITopic from 'src/pages/AITopic';
import ManageAITopic from 'src/pages/AITopic/Manage';
import NewsOMaticStatics from 'src/pages/MetaExam/NewsOMaticStatics';
import NewsOMaticStaticsDetail from 'src/pages/MetaExam/NewOMaticsStaticsDetail';
import DetailsByDate from 'src/pages/AITopic/detailsByDate';
import LevelUpDetailsByDate from 'src/pages/LevelUp/Report/detailsByDate';
import AIcoachDetailByDate from 'src/pages/AiCoach/Report/aIcoachDetailByDate';
import AITalkingDetailsByDate from 'src/pages/AITalkingTutor/Report/aITalkingDetailByDate';
import RubricAiCoachPage from 'src/pages/AiCoach/Result/RubricAiCoach';
import LevelUpBookReportStudy from 'src/pages/LevelUp/Report/study';
import BookDescriptionPage from 'src/pages/LevelUp/BookDescription';
import LevelUpBookListForAssign from 'src/pages/LevelUp/Assign/BookList';
import LevelUpAssignedList from 'src/pages/LevelUp/Assign/AssignedList';
import LevelUpAssignedResult from 'src/pages/LevelUp/Assign/AssignedResult';
import LevelUpResultPage from 'src/pages/LevelUp/Results';
import LevelUpReadersPage from 'src/pages/LevelUp/Results/Readers';
import ExtReadersPage from 'src/pages/LevelUp/Results/ExtResult';
import AssignLevelUp from 'src/pages/LevelUp/Assign';
import LevelUpEdit from 'src/pages/LevelUp/Assign/Edit';
import AssignWritingResult from 'src/pages/LevelUp/Results/AssignWritingResult';

import WritingCoachAssign from 'src/pages/AiCoach/Assign';
import AssignAiTalkingTutor from 'src/pages/AITalkingTutor/Assign';
import WritingCoachEdit from 'src/pages/AiCoach/Edit';
import WritingCoachSingleEdit from 'src/pages/AiCoach/Edit/AiCoachSingleEdit';
import AITalkingTutorEdit from 'src/pages/AITalkingTutor/Edit';
import WritingCoachTopicList from 'src/pages/AiCoach/Assign/TopicList';
import WritingCoachExtReadersPage from 'src/pages/AiCoach/ExtResult';
import AiTalkingTutor from 'src/pages/AITalkingTutor/Assign/TopicList';
import AiContentList from 'src/pages/AITalkingTutor/Assign/ContentList'
import AddAITalkingTutor from 'src/pages/AITalkingTutor/AddGroupList';
import EditTalkingTutor from 'src/pages/AITalkingTutor/EditGroupList';
import WritingCoachAssignList from 'src/pages/AiCoach/List';
import AssignAITalkingList from 'src/pages/AITalkingTutor/List';
import WritingCoachAssignDateList from 'src/pages/AiCoach/DateList';
import TalkingTutorDateList from 'src/pages/AITalkingTutor/DateList'
import WritingCoachResult from 'src/pages/AiCoach/Result';
import StudentResults from 'src/pages/AiCoach/Result/Student'
import TalkingTutorResult from 'src/pages/AITalkingTutor/Result';
import TalkingTutorExtReadersPage from 'src/pages/AITalkingTutor/ExtResult';
import AITutorStudentResults from 'src/pages/AITalkingTutor/Result/Student'
import EditBook from 'src/pages/LevelUp/ManageSingleEdit';
import GroupList from 'src/pages/LevelUp/GroupList';


function CampusManager() {
  const company_name = userInfoVar()?.company_name;
  // console.log("company_name",company_name);
  return (
    <>
      <DefaultLayout exact path="/dashboard" component={DashBoard} />

      <DefaultLayout exact path="/daily/test/syllabus/:type" component={DailyTestSyllabus} />
      <DefaultLayout exact path="/daily/test/syllabus/edit/:idx" component={DailySyllabusEdit} />
      {/* <DefaultLayout exact path="/daily/test/syllabus/:id/assign" component={DailySyllabusAssign} /> */}
      <DefaultLayout exact path="/daily/test/uploadtest/:groupIdx/:groupCode" component={DailyTestSmallZipUpload} />
      <DefaultLayout exact path="/daily/test/editTest/:groupIdx/:groupCode/:testIdx" component={EditTest} />
      <DefaultLayout exact path="/daily/manage/test/result" component={TestResult} />
      <DefaultLayout exact path="/daily/test/result" component={DailyTestGroupResult} />
      <DefaultLayout exact path="/daily/test/result/:idx" component={DailyTestResult} />
      {/* <DefaultLayout exact path="/daily/test/result" component={DailyTestResult} /> */}
      <DefaultLayout exact path="/daily/test/result/:idx/:classIdx" component={ClassResult} />
      <DefaultLayout exact path="/daily/test/result/:idx/:classIdx/:testIdx" component={TestResultClassWise} />
      <DefaultLayout exact path="/daily/test/result/:idx/:classIdx/:testIdx/:userIdx" component={StudentTestResultClassWise} />

      {/* <DefaultLayout exact path="/daily/test/syllabus/assign" component={DailySyllabusAssign} /> */}
      {/* <DefaultLayout exact path="/group/test" component={DailyTestQuiz} />
      <DefaultLayout path="/daily/test" component={DailyTestQuiz}/> */}

      <DefaultLayout exact path="/test/syllabus/assigned/:id" component={SyllabusAssignedDetail} />
      <DefaultLayout exact path="/test/syllabus/:type" component={Syllabus} />
      <DefaultLayout exact path="/test/syllabus/:id/assign" component={SyllabusAssign} />
      <DefaultLayout exact path="/test/syllabus/edit/:idx" component={SyllabusEdit} />
      <DefaultLayout exact path="/test/manage/:type" component={Test} />
      <DefaultLayout exact path="/test/manage/action/:type/:id" component={Manage} />
      <DefaultLayout exact path="/test/manage/action/:type/" component={Manage} />
      <DefaultLayout exact path="/test/:id" component={TestDetails} />
      {/* <DefaultLayout exact path="/daily/test/detail/:id/:testIdx" component={DailyTestDetails} /> */}
      <DefaultLayout exact path="/test/new/:id/:type" component={TestDetails} />
      {/* <DefaultLayout exact path="/daily/test/new/:id/:type" component={DailyTestDetails} /> */}
      <ToolLayout exact path="/tool/:type/:code" component={Tool} />
      <DefaultLayout exact path="/i-frame/:url" component={IframePage} isHeaderStatic={true} />
      <DefaultLayout exact path="/teachers" component={Tearchers} />
      <DefaultLayout exact path="/teachers/:id" component={TearcherDetail} />
      <DefaultLayout exact path="/zoom/manage/:type" component={Zoom} />
      <DefaultLayout exact path="/recording" component={Recording} />
      <DefaultLayout exact path="/recording/start" component={RecordingStart} />
      <DefaultLayout exact path="/students" component={Students} />
      <DefaultLayout exact path="/students-batch" component={StudentsBatch} />
      <DefaultLayout exact path="/students/:id" component={StudentDetail} />
      <DefaultLayout exact path="/classroom" component={ClassRoom} />
      <DefaultLayout exact path="/classroom/:id" component={isPturn(company_name) ? ClassDetailForAfterSchool : ClassDetail} />
      <DefaultLayout exact path="/learning" component={Learning} />
      <DefaultLayout exact path="/learning/:class_idx/:lesson_code" component={LearningDetail} />
      <DefaultLayout exact path="/learning-class" component={LearningClass} />
      <DefaultLayout exact path="/learning-class/study-result/:class_idx/:user_idx" component={LearningClassStudyResult} />
      <DefaultLayout exact path="/learning-elibrary" component={LearningElibrary} />
      <DefaultLayout exact path="/addTicket" component={TicketForm} />
      <DefaultLayout exact path="/assignTicket" component={AssignTicket} />
      <DefaultLayout exact path="/recallTicket" component={RecallTicket} />

      {/* Placement Test 신청 리스트 (홈페이지를 통해서 신청한 것들) */}
      <DefaultLayout exact path="/consulting" component={Consulting} />
      <DefaultLayout exact path="/consultingmeta" component={ConsultingMeta} />
      <DefaultLayout exact path="/schedulemeta" component={ScheduleMeta} />

      <DefaultLayout exact path="/ebook" component={Ebook} />
      <DefaultLayout exact path="/ebook/:rootbookcode" component={Ebook} />
      <DefaultLayout exact path="/popup" component={PopupPage} />
      <DefaultLayout exact path="/purchase" component={Purchase} />
      <DefaultLayout exact path="/board/:name" component={Board} />
      <DefaultLayout exact path="/board/:name/:id" component={BoardDetail} />
      <DefaultLayout exact path="/settings/account" component={Account} />
      <DefaultLayout exact path="/settings/account2" component={Account} />
      <DefaultLayout exact path="/settings/account3" component={BookInfo} />
      <DefaultLayout exact path="/settings/employee" component={Employee} />
      <DefaultLayout exact path="/settings" component={BookInfo} />
      <DefaultLayout exact path="/folder" component={Folder} />

      <DefaultLayout exact path="/ticketStatistics" component={TicketStatistics} />
      <DefaultLayout exact path="/ticketStatus" component={TicketStatus} />
      <DefaultLayout exact path="/campus" component={Campuses} />
      <DefaultLayout exact path="/credit" component={Credits} />
      <DefaultLayout exact path="/report-card" component={ReportCard} />

      <DefaultLayout exact path="/order/admin" component={OrderAdmin} />
      <DefaultLayout exact path="/order/branch-admin" component={BranchAdmin} />
      <DefaultLayout exact path="/order/shipping" component={OrderShipping} />
      <DefaultLayout exact path="/setting/pay" component={SettingPayAdmin} />

      <DefaultLayout exact path="/book-return/admin" component={BookReturnAdmin} />

      <DefaultLayout exact path="/book/order" component={BookOrder} />
      <DefaultLayout exact path="/book/order/list" component={BookOrderList} />
      <DefaultLayout exact path="/book/order-pay" component={BookOrderPay} />
      <DefaultLayout exact path="/book/order-complete" component={BookOrderPayComplete} />
      <DefaultLayout exact path="/book/order-cancelled" component={BookOrderPayCancelled} />

      <DefaultLayout exact path="/book/return" component={BookReturn} />
      <DefaultLayout exact path="/book/return-request" component={BookReturnRequest} />
      <DefaultLayout exact path="/book/return/list" component={BookReturnList} />

      <DefaultLayout exact path="/book-credit/list" component={BookCreditList} />
      <DefaultLayout exact path="/book-credit/assign/:book_code" component={BookCreditAssign} />
      <DefaultLayout exact path="/sms-manage" component={SmsManage} />

      {/* Placement Test 리스트 (관리자가 직성) */}
      <DefaultLayout exact path="/placement-test" component={PlacementTest} />

      {/* for student */}
      <DefaultLayout exact path="/study-result/:class_idx" component={StudyResult} />
      <DefaultLayout exact path="/studyResult" component={ClassStudyResult} />
      <DefaultLayout exact path="/elibrary-history" component={ElibraryHistory} />
      <DefaultLayout exact path="/my-external-study" component={ExternalStudyHistory} />
      <DefaultLayout exact path="/my-bookr-study" component={BookrHistory} />
      <DefaultLayout exact path="/external-study" component={ExternalStudyAssign} />
      <DefaultLayout exact path="/external-study/manage/action/:type" component={ManageAssignExternalStudy} />
      <DefaultLayout
        exact
        path="/external-study/manage/action/edit/:type/:class_idx/:title/:key"
        component={ManageAssignExternalStudyEdit}
      />
      <DefaultLayout exact path="/external-study/manage/result/:type" component={ExternalStudyManageAssignBooks} />

      <DefaultLayout
        exact
        path="/external-study/learning/:class_idx/:assigned_external_study_group_idx"
        component={ExternalStudyLearning}
      />
      <DefaultLayout exact path="/external-study/homework/results/:class_idx/:idx" component={ExternalStudyLearningGroup} />
      <DefaultLayout
        exact
        path="/external-study/learning/:class_idx/:assigned_external_study_group_idx/:idx"
        component={ExternalStudyLearning}
      />
      <DefaultLayout exact path="/external-study/homeworkresult" component={ExternalStudyHomework} />

      <DefaultLayout exact path="/contents" component={Contents} />

      <DefaultLayout exact path="/elibrary/homeworkresult" component={ElibraryHomework} />

      <DefaultLayout exact path="/elibrary/learning/:class_idx/:assigned_elibrary_group_idx" component={ElibraryLearning} />
      <DefaultLayout exact path="/elibrary/homework/results/:class_idx/:idx" component={ElibraryLearningGroup} />
      <DefaultLayout exact path="/elibrary/learning/:class_idx/:assigned_elibrary_group_idx/:idx" component={ElibraryLearning} />

      <DefaultLayout exact path="/elibrary/books" component={ElibraryAssignBooks} />
      <DefaultLayout exact path="/books/manage/action/:type" component={ManageAssignBooks} />
      <DefaultLayout exact path="/books/manage/action/edit/:type/:class_idx/:title/:key" component={ManageAssignBooksEdit} />
      <DefaultLayout exact path="/level-up/books/manage/action/edit/:type/:class_idx/:title/:key" component={AssignLevelUpBooksEdit} />
      <DefaultLayout exact path="/elibrary/manage/action/:type" component={ElibraryManageAssignBooks} />

      <DefaultLayout exact path="/bookr" component={BookrBook} />
      <DefaultLayout exact path="/bookr/assign" component={BookrAssign} />
      <DefaultLayout exact path="/bookr/edit/:class_idx/:title/:key" component={BookrEdit} />
      <DefaultLayout exact path="/bookr/list" component={AssignedBookrList} />
      <DefaultLayout exact path="/portfolio/:type/list" component={BookrPortfolioList} />
      <DefaultLayout exact path="/bookr/result" component={BookrResult} />
      <DefaultLayout exact path="/bookr/result/:class_idx/:idx" component={BookrResultDetail} />
      {/* <DefaultLayout exact path="/my-external-study/:type=bookr" component={BookrHistory} /> */}
      <DefaultLayout exact path="/learningschedule/levels" component={PhonicsElibraryAssign} />
      <DefaultLayout exact path="/learningschedule/levels/manage/action/:type" component={PhonicsManageAssignBooks} />
      <DefaultLayout
        exact
        path="/learningschedule/levels/manage/action/edit/:type/:class_idx/:title/:key"
        component={PhonicsManageAssignBooksEdit}
      />
      <DefaultLayout exact path="/learningschedule/results" component={PhonicsResults} />
      <DefaultLayout exact path="/learningschedule/results-class" component={PhonicsResultClass} />
      <DefaultLayout exact path="/learningschedule/results-class/:class_idx/:user_idx" component={PhonicsResultClassStudent} />
      <DefaultLayout exact path="/mypage/results-class/:class_idx/:user_idx" component={PhonicsResultClassStudent} />
      <DefaultLayout exact path="/learningschedule/results/:book_idx/:start_date/:class_idx" component={PhonicsResultsGroup} />
      <DefaultLayout exact path="/question/extractor/add" component={QuestionExtractor} />
      <DefaultLayout exact path="/question/extractor/edit/:id" component={QuestionExtractor} />
      <DefaultLayout exact path="/question/extractor/list" component={TestDocumentList} />

      <DefaultLayout exact path="/offline/lesson/assign" component={AssignLessonPage} />
      <DefaultLayout exact path="/offline/lesson/create/:programidx/:programlevel" component={OfflineSyllabus} />

      <DefaultLayout exact path="/offline/lesson/view/:programidx/:programlevel" component={CampusPageView} />
      <DefaultLayout exact path="/offline/lesson/dashboardview/:programidx/:programlevel" component={CampusDashboardPageView} />

      <DefaultLayout exact path="/offline/add/syllabus" component={OfflineAddSyllabus} />
      <DefaultLayout exact path="/offline/edit/syllabus/:bookprogramname/:bookprogramlevel/view" component={OfflineEditSyllabus} />
      <DefaultLayout
        exact
        path="/offline/adminedit/syllabus/:bookprogramname/:bookprogramlevel/adminview"
        component={OfflineEditSyllabusAdminView}
      />

      <DefaultLayout exact path="/offline/edit/syllabus/:bookprogramname/:bookprogramlevel/update" component={OfflineEditSyllabusUpdate} />

      <DefaultLayout exact path="/offline/editview/syllabus/:bookprogramname/:bookprogramlevel" component={OfflineEditSyllabusView} />

      <DefaultLayout exact path="/offline/textbook/list" component={OfflineTextBookList} />
      <DefaultLayout exact path="/offline/assign/:booklevel/:booktitle/:bookprogram/:bookrowno" component={OfflineAssign} />

      <DefaultLayout
        exact
        path="/offline/assign/edit/:booklevel/:bookprogram/:bookclassidx/:rowbookclassidx/:bookrono"
        component={OfflineAssignEdit}
      />
      <DefaultLayout exact path="/offline/lesson/plan" component={AssignLessonPlanPage} />

      <DefaultLayout exact path="/my-books" component={MyBooks} />

      <BookrLayout exact path="/e-booklibrary" component={Elibrary} />
      <DefaultLayout
        exact
        path="/external-study/homework/multiple/results/:class_idx/:date/:idx"
        component={ExternalStudyLearningGroupDateWise}
      />
      <DefaultLayout exact path="/scribo/subscription-list" component={ScriboSubscriptionList} />
      <DefaultLayout exact path="/ai-topic/manage/:type/:id/:class_idx?/:title?" component={ManageAITopic} />
      <DefaultLayout exact path="/ai-topic/:type" component={AITopic} />
      <DefaultLayout exact path="/ai-topic/manage/:type" component={ManageAITopic} />
      <DefaultLayout exact path="/ai-topic/details-by-date/:idx" component={DetailsByDate} />
      <DefaultLayout exact path="/news-o-matic-statics" component={NewsOMaticStatics} />
      <DefaultLayout exact path="/news-o-matic-statics/details/:type/:campus_idx/:value" component={NewsOMaticStaticsDetail} />

      <LevelUpLayout exact path="/level-up/results/readers" component={LevelUpReadersPage} removeMargin={true} isHeaderStatic={false} />
      <LevelUpLayout exact path="/level-up/results/extreaders" component={ExtReadersPage} removeMargin={true} isHeaderStatic={false} />
      <LevelUpLayout exact path="/level-up/booklist" component={LevelUpBookListForAssign} />
      <LevelUpLayout exact path="/level-up/booklist/assign" component={AssignLevelUp} />
      <LevelUpLayout exact path="/level-up/assignedlist" component={LevelUpAssignedList} />
      <LevelUpLayout exact path="/level-up/assignedresult" component={LevelUpAssignedResult} />
      <DefaultLayout exact path="/level-up/edit/:class_idx/:title/:key" component={LevelUpEdit} />
      <LevelUpLayout
        exact
        path="/level-up/assignedlist/results/:idx"
        component={LevelUpResultPage}
        removeMargin={true}
        isHeaderStatic={false}
      />
      <LevelUpLayout exact path="/level-up/report" component={LevelUpManage} />
      <LevelUpLayout exact path="/level-up/report/:type" component={LevelUpManage} />
      <LevelUpLayout exact path="/level-up/report/details-by-date/:idx" component={LevelUpDetailsByDate} />
      <LevelUpLayout exact path="/level-up/results/history" component={LevelUpHistory} removeMargin={true} />
      <LevelUpLayout exact path="/level-up/description/:idx" component={BookDescriptionPage} removeMargin={true} />
      <LevelUpLayout
        exact
        path="/level-up/report/study/:idx"
        component={LevelUpBookReportStudy}
        removeMargin={true}
        isHeaderStatic={true}
      />
      <LevelUpLayout exact path="/level-up/results/bookreport/:user_idx" component={AssignWritingResult} removeMargin={true} isHeaderStatic={false} />
      <LevelUpLayout exact path="/level-up/books/manage/:assigned_external_study_idx/:type/:class_idx/:title/:key" component={EditBook} />
      <LevelUpLayout exact path="/level-up/assigned-grouplist" component={GroupList} removeMargin={true} isHeaderStatic={false} />

      <AICoachLayout exact path="/aicoach/report/details-by-date/:idx" component={AIcoachDetailByDate} />
      <AICoachLayout exact path="/aicoach/results/rubric/:idx" component={RubricAiCoachPage} />
      <AICoachLayout exact path="/aicoach/singleedit/:key" isHeaderStatic={false} component={WritingCoachSingleEdit} />
      <AICoachLayout exact path="/aicoach/topiclist" isHeaderStatic={false} component={WritingCoachTopicList} />
      <AICoachLayout exact path="/aicoach/edit/:class_idx/:id" isHeaderStatic={false} component={WritingCoachEdit} />
      <AICoachLayout exact path="/aicoach/topiclist/assign" isHeaderStatic={false} removeMargin={false} component={WritingCoachAssign} />
      <AICoachLayout exact path="/aicoach/list" isHeaderStatic={false} removeMargin={false} component={WritingCoachAssignList} />
      <AICoachLayout exact path="/aicoach/date-list" isHeaderStatic={false} removeMargin={false} component={WritingCoachAssignDateList} />
      <AICoachLayout exact path="/aicoach/results/extreaders" isHeaderStatic={false} removeMargin={false} component={WritingCoachExtReadersPage} />
      <AICoachLayout exact path="/aicoach/result" isHeaderStatic={false} removeMargin={false} component={WritingCoachResult} />
      <AICoachLayout exact path="/aicoach/result/:idx" isHeaderStatic={false} removeMargin={true} component={StudentResults} />
      {/* AI-talking tutor */}
      <AITalkingTutorLayout exact path="/ai-talking-tutor/topiclist" isHeaderStatic={false} component={AiTalkingTutor} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/contentlist" isHeaderStatic={false} component={AiContentList} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/add" isHeaderStatic={false} component={AddAITalkingTutor} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/topiclist/assign/:group_idx" isHeaderStatic={false} removeMargin={false} component={AssignAiTalkingTutor} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/edit/:class_idx/:id" isHeaderStatic={false} component={AITalkingTutorEdit} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/list" isHeaderStatic={false} removeMargin={false} component={AssignAITalkingList} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/date-list" isHeaderStatic={false} removeMargin={false} component={TalkingTutorDateList} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/result" isHeaderStatic={false} removeMargin={false} component={TalkingTutorResult} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/result/:idx" isHeaderStatic={false} removeMargin={false} component={AITutorStudentResults} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/results/extreaders" isHeaderStatic={false} removeMargin={false} component={TalkingTutorExtReadersPage} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/edit/:idx" isHeaderStatic={false} component={EditTalkingTutor} />
      {/* <AITalkingTutorLayout exact path="/ai-talking-tutor/edit/:class_idx/:id" isHeaderStatic={false} component={AITalkingTutorEdit} /> */}
      <AITalkingTutorLayout exact path="/ai-talking-tutor/report/details-by-date/:idx" component={AITalkingDetailsByDate} />

      <DefaultLayout exact path="/" component={DashBoard} />
    </>
  );
}

export default CampusManager;
